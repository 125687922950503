export const setColor = (nr) => {
    return {
        type: "SET_COLOR",
        color: nr
    };
};
export const setCustomJson = (nr) => {
    return {
        type: "SET_CUSTOM_JSON",
        state: nr
    }
}

export const setOpacity = (type) => {
    return {
        type: "SET_OPACITY",
        opacity: type
    }
}

export const setActiveTab = (tab) => {
    return {
        type: "SET_ACTIVE_TAB",
        payload:tab,
    };
};

export const clearActiveTab = () => {
    return {
        type: "CLEAR_ACTIVE_TAB",
    };
};

export const setCanvas = (nr) => {
    return {
        type: "SET_CANVAS",
        canvas: nr
    };
};
export const setObjSpeed = (nr) => {
    return {
        type: "SET_SPEED",
        speed: nr
    };
};
export const setDrawState = (nr) => {
    return {
        type: "SET_DRAW_STATE",
        state: nr
    };
};
export const setConnect = (nr) => {
    return {
        type: "SET_CONNECT_OBJECT",
        state: nr
    };
};
export const whitePitch = (nr) => {
    return {
        type: "SET_WHITE_PITCH",
        state: nr
    };
};
export const setSelectedColor = (color) => {
    return {
        type: "SET_SELECTED_COLOR",
        state: color
    };
};
export const setSrc = (payload) => {
    return {
        type: "SET_SIDE_SRC",
        state: payload
    };
};
export const setSpriteIndex = (payload) => {
    return {
        type: "SET_SPRITE_INDEX",
        state: payload
    };
};
export const setTeam = (payload) => {
    return {
        type:"SET_TEAM",
        state:payload
    }
}
export const setPlayerSizes = (payload) => {
    return {
        type:"SET_PLAYER_SIZE",
        state:payload
    }
}
export const setIsModalOpen = (payload) =>{
    return {
        type:"TOGGLE_MODAL",
        state:payload
    }
}
export const setModalLeft = (payload) =>{
    return {
        type:"SET_MODAL_LEFT",
        state:payload
    }
}
export const setModalTop = (payload) =>{
    return {
        type:"SET_MODAL_TOP",
        state:payload
    }
}
export const setPlayerType= (payload) =>{
    return {
        type:"SET_PLAYER_TYPE",
        state:payload
    }
}

export const setColorPopup = (nr) => {
    return {
        type: "SET_COLOR_POPUP",
        state: nr
    };
};
export const setDefaultColor = (nr) => {
    return {
        type: "OBJECT_DEFAULT_COLOR",
        state: nr
    };
};
export const setShowDrawer = (nr) => {
    return {
        type: "SET_SHOW_DRAWER",
        state: nr
    };
};
export const setTextField = (payload)=>{
    return {
        type: "SET_TEXT_FIELD",
        state:payload
    }
}
export const setCircleImg = (payload)=>{
    return {
        type: "SET_CIRCLE_IMG",
        state:payload
    }
}
export const setObjUpdatedType = (type) => {
    return {
        type: "SET_OBJECT_TYPE",
        state: type
    };
};
export const setPlayerState = (nr) => {
    return {
        type: "SET_PLAYER_STATE",
        action: nr
    };
};


export const setSetingsState = (direction) => {
    return {
        type: "SET_SETTINGS_STATE",
        action: direction || { direction: "ltr" }
    };
};


export const setColorsState = (nr) => {
    return {
        type: "SET_COLORS_STATE",
        state: nr
    };
};
export const setActivePanel = (key) => {
    return {
        type: "SET_ACTIVE_PANEL",
        key
    };
};

export const setObjectsState = (nr) => {
    return {
        type: "SET_OBJECTS_STATE",
        action: nr
    };
};
export const copyToClipboard = (fabricObject) => {
    return {
        type: "COPY_TO_CLIPBOARD",
        payload: fabricObject, // The Fabric.js object you want to copy
    };
};
export const clearClipBoard = () => {
    return {
        type: "CLEAR_CLIPBOARD",
    };
};


export const setBrush = (nr) => {
    return {
        type: "SET_BRUSH",
        brush: nr
    };
};
export const setBackground = (nr) => {
    return {
        type: "SET_BACKGROUND",
        background: nr
    };
};
export const setBackgroundUpdate = (nr) => {
    return {
        type: "SET_BACKGROUND_UPDATE",
        background: nr
    };
};
export const setTools = (type) => {
    return {
        type: "SET_TOOLS_STATE",
        state: type,
    };
};

export const setText = (nr) => {
    return {
        type: "SET_TEXT",
        text: nr,
    };
};

export const setEditingMode = (val) => {
    return {
        type: 'SET_EDITING_MODE',
        mode: val
    }
}


export const setAnimation = (nr) => {
    return {
        type: "SET_ANIMATION",
        state: nr,
    };
};


export const setAnimationStates = (nr) => {
    return {
        type: "SET_ANIMATION_STATES",
        action: nr,
    };
};

export const setBackgroundOpacity = (nr) => {
    return {
        type: "SET_BG_OPACITY",
        state: nr
    };
};

export const textBackgroundToggler = (nr) => {
    return {
        type: "SET_BG_TOGGLE",
        state: nr
    };
};
export const highlightBackground = (nr) => {
    return {
        type: "SET_BG_HIGHLIGHT",
        state: nr
    };
};
export const setLoader = (nr) => {
    return {
        type: "SET_LOADER",
        payload: nr
    };
};

export const resetCanvas = () => {
    return {
        type: "RESET_CANVAS",
        canvas: null,
    };
};
export const setTextBackCol = (color) => {
    return {
        type: "SET_TEXT_BACK_COLOR",
        color: color
    };
}

export const setTacticBoard = (data) => {
    return {
        type: "SET_CUSTOM_JSON",
        state: data
    };
};

export const setPlayerShowNumbers = (payload) => {
    return {
        type:"SET_SHOW_PLAYER_NUMBERS",
        state:payload
    }

}

export const setDisableSides = (payload) => {
    return {
        type: "SET_DISABLE_SIDES",
        state: payload
    }
}