import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { en_US, he_IL } from "./locals";
import { connect } from "react-redux";

const locales = {
    "en-US": en_US,
    "he-IL": he_IL,
};

const Localization = ({ children, locale }) => {
    const currentLocale = locales[locale] || locales["he-IL"];

    return (
        <ConfigProvider locale={currentLocale.localeAntd}>
            <IntlProvider locale={locale} messages={locales[locale]}>
                <ConfigProvider locale={currentLocale}>{children}</ConfigProvider>
            </IntlProvider>
        </ConfigProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        locale: "en-US",
    };
};

export default connect(mapStateToProps)(Localization);
