export const fullScreen = (isFullScreen, setFullScreen) => {
    let elem = document.documentElement;
    if (!isFullScreen) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
        setFullScreen(true)
    }
}
export const exitFullScreen = (isFullScreen, setFullScreen) => {
    if (isFullScreen) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        setFullScreen(false)
    }
}