import { fabric } from "fabric";
import {defaultTime, getColorScheme, setPlayerNameDim} from "./utils";
import { Pitch } from "./pitchDetails";
import {copyToClipboard} from "../actions";
import Shirt from '../assets/shirt.svg';
import {getMobileView} from "../components/helpers";



export const handleAddPlayer = (
    props,
    scaleProps,
    sizeFactor,
    formation,
    customProps,
    updateUndoRedoState,
    canvas,
    animationState,
    activeFrameVal,
    setActiveObject,
    isDropped,
    dispatch
) => {
    const pitch = new Pitch(canvas,props.team || 'left',props.isSingle);
    // console.log(pitch.getPlayerAngle());
    var shadow = new fabric.Shadow({
        blur: 10,
        color: "rgba(0,0,0,0.3)",
        id: 1,
        offsetX: 10,
        offsetY: 10,
    });
    let group = new fabric.Group("", {
        left: props.left,
        top: props.top,
        ref_id: props.ref_id,
        name: "player",
        objecttype: "disabled_borders",
        originX: "center",
        originY: "center",
        is_animation: true,
        bgrOpacity: 1,
        opacity: 1,
        hasControls: true,
        fadeFlag: "no-fade-out",
        time: defaultTime,
        position: customProps ? customProps.position : "top",
        team:props.team || 'left',
        formation: formation.action,
        lockMovementX:customProps?.lockMovementX || false,
        lockMovementY:customProps?.lockMovementY || false,
        sizeFactor: customProps ? customProps.sizeFactor : sizeFactor,
        parentFrame: animationState ? activeFrameVal.data_num : null,
        startAngleD: customProps ? customProps.startAngle : pitch.getPlayerAngle(props.team || 'left'),
    });
    let playerColor = "#fff";
    if (props.color === "#fff") {
        playerColor = "#00000";
    }

    var circle = new fabric.Circle({
        showHighlight: false,
        highlightColor: "#C40E1265",
        bgrOpacity: 1,
        radius: 19,
        fill: props.color,
        stroke: props.color === "#F7CF00" ? "#fff" : playerColor,
        strokeWidth: 2,
        originX: "center",
        originY: "center",
        ref_id: props.ref_id,
        name: "circle",
        hasBorders: false,
        hasControls: false,
        // shadow: shadow,
    });
    var text = new fabric.IText(props.text, {
        fill: props.color === "#F7CF00" ? "#000000" : playerColor,
        originX: "center",
        originY: "center",
        fontSize: props.fontSize,
        top: 0,
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "sans-serif",
        textAlign: "center",
        selectionBackgroundColor: "white",
        name: "text",
        isPlayer: true,
        ref_id: props.ref_id,
    });

    var arrowCircle = new fabric.Circle({
        highlightColor: "#C40E1265",
        radius: 19,
        fill: "transparent",
        arrowFill: props.color,
        strokeWidth: 2,
        arrowAngle: 0,
        originX: "center",
        originY: "center",
        ref_id: props.ref_id,
        name: "arrowCircle",
        hasBorders: false,
        hasControls: false,
        direction: customProps ? customProps.direction : true,
    });
    group.add(circle);
    if (customProps) {
        circle.set("dirty", true);
        circle.showHighlight = customProps.showHighlight;
        circle.highlightColor = customProps.highlightColor;
    }
    group.add(text);
    (props?.team!=="none") && group.add(arrowCircle);
    // group.add(arrow)
    group.addWithUpdate();

    if (sizeFactor === "small") {
        group.scaleToHeight(scaleProps.height / 1.45);
        group.initialSize = scaleProps.height / 1.45;
    } else if (sizeFactor === "medium") {
        group.scaleToHeight(scaleProps.height / 1.2);
        group.initialSize = scaleProps.height / 1.2;
    } else {
        group.scaleToHeight(scaleProps.height / 0.9);
        group.initialSize = scaleProps.height / 0.9;
    }
    let textColor = "#fff";
    if (props.color === "#fff" || props.color === "#F7CF00") {
        textColor = "#00000";
    }
    var nameText = new fabric.IText("", {
        textAlign: "center", //"left", "center", "right" or "justify".
        fill: textColor,
        fillStyle: props.color === "#fff" ? "#ffffff95" : props.color + 95,
        fontSize: 13,
        ref_id: props.ref_id,
        originX: "center",
        originY: "top",
        hoverCursor: "text",
        hasControls: false,
        hasBg: false,
        hasBorders: false,
        name: "custom_image_name",
        fontFamily: "sans-serif",
        fontWeight: "bold",
        formation: formation.action,
        evented: true,
    });
    setPlayerNameDim(nameText, group, canvas);
    if (!customProps  && !isDropped) canvas.add(nameText);
    group.set("nameText", {
        fillStyle: customProps
            ? customProps.nameText.fillStyle
            : nameText.fillStyle,
        text: customProps ? customProps.nameText.text : nameText.text,
        fontSize: customProps ? customProps.nameText.fontSize : nameText.fontSize,
        fontWeight: customProps
            ? customProps.nameText.fontWeight
            : nameText.fontWeight,
        fill: customProps ? customProps.nameText.fill : nameText.fill,
        textLength: customProps ? customProps.textLength : nameText.textLength,
    });
    group.set({nameInstance:nameText});
    group.dirty = true;
    canvas.requestRenderAll();
    group.set("bgrOpacity", 1);
    if (customProps) {
        group.scaleX = customProps.scaleX;
        group.scaleY = customProps.scaleY;
    }

    if (group._objects[2])
        group._objects[2].set({
            angle: customProps ? customProps.arrowAngle : pitch.getPlayerAngle(),
        });
    if(!isDropped){
        canvas.add(group);
        props.res && props.res();
    }
    else{
        dispatch(copyToClipboard(group));
        canvas.discardActiveObject();
        canvas.defaultCursor = 'crosshair';
        canvas.selection = false;
    }
    // if (!customProps && !isDropped) setActiveObject(group);
    if (formation.index === 10 && !isDropped) {
        updateUndoRedoState(group, "added");
    }
    group.setTextPosition(nameText, canvas);
};

export const handleAddPlayerTriangle = (
    props,
    scaleProps,
    sizeFactor,
    formation,
    customProps,
    updateUndoRedoState,
    canvas,
    animationState,
    activeFrameVal,
    setActiveObject,
    isDropped,
    dispatch
) => {
    const pitch = new Pitch(canvas,props.team || 'left',props.isSingle);
    var shadow = new fabric.Shadow({
        blur: 10,
        color: "rgba(0,0,0,0.3)",
        id: 1,
        offsetX: 10,
        offsetY: 10,
    });
    let group = new fabric.Group("", {
        // backgroundColor: "triangle", // backgroundColor used as custom param for the moment
        left: props.left,
        top: props.top,
        ref_id: props.ref_id,
        name: "player",
        polygon: "triangle",
        objecttype: "disabled_borders",
        originX: "center",
        originY: "center",
        is_animation: true,
        bgrOpacity: 1,
        opacity: 1,
        hasControls: true,
        fadeFlag: "no-fade-out",
        time: defaultTime,
        position: customProps ? customProps.position : "top",
        formation: formation.action,
        sizeFactor: customProps ? customProps.sizeFactor : sizeFactor,
        parentFrame: animationState ? activeFrameVal.data_num : null,
        startAngle: customProps ? customProps.startAngle : pitch.getPlayerAngle(),
        lockMovementX:customProps?.lockMovementX || false,
        lockMovementY:customProps?.lockMovementY || false,
    });

    let playerColor = "#fff";
    if (props.color === "#fff") {
        playerColor = "#00000";
    }
    var circle = new fabric.Triangle({
        showHighlight: false,
        highlightColor: "#C40E1265",
        bgrOpacity: 1,
        radius: 19,
        fill: props.color,
        stroke: props.color === "#F7CF00" ? "#fff" : playerColor,
        strokeWidth: 2,
        originX: "center",
        originY: "center",
        ref_id: props.ref_id,
        name: "circle",
        hasBorders: false,
        hasControls: false,
        // shadow: shadow,
    });
    var text = new fabric.IText(props.text, {
        fill: props.color === "#F7CF00" ? "#000000" : playerColor,
        originX: "center",
        originY: "center",
        fontSize: 48,
        top: 16,
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "sans-serif",
        textAlign: "center",
        selectionBackgroundColor: "white",
        name: "text",
        isPlayer: true,
        ref_id: props.ref_id,
    });

    group.add(circle);
    if (customProps) {
        circle.set("dirty", true);
        circle.showHighlight = customProps.showHighlight;
        circle.highlightColor = customProps.highlightColor;
    }
    group.add(text);
    group.addWithUpdate();

    if (sizeFactor === "small") {
        group.scaleToHeight(scaleProps.height / 1.45);
        group.initialSize = scaleProps.height / 1.45;
    } else if (sizeFactor === "medium") {
        group.scaleToHeight(scaleProps.height / 1.2);
        group.initialSize = scaleProps.height / 1.2;
    } else {
        group.scaleToHeight(scaleProps.height / 0.9);
        group.initialSize = scaleProps.height / 0.9;
    }
    let textColor = "#fff";
    if (props.color === "#fff" || props.color === "#F7CF00") {
        textColor = "#00000";
    }
    var nameText = new fabric.IText("", {
        textAlign: "center", //"left", "center", "right" or "justify".
        fill: textColor,
        fillStyle: props.color === "#fff" ? "#ffffff95" : props.color + 95,
        fontSize: 12,
        ref_id: props.ref_id,
        originX: "center",
        originY: "top",
        hoverCursor: "text",
        hasControls: false,
        hasBg: false,
        hasBorders: false,
        name: "custom_image_name",
        fontFamily: "sans-serif",
        fontWeight: "bold",
        formation: formation.action,
        evented: true,
    });
    setPlayerNameDim(nameText, group, canvas);
    if (!customProps && !isDropped) canvas.add(nameText);
    group.set("nameText", {
        fillStyle: customProps
            ? customProps.nameText.fillStyle
            : nameText.fillStyle,
        text: customProps ? customProps.nameText.text : nameText.text,
        fontSize: customProps ? customProps.nameText.fontSize : nameText.fontSize,
        fontWeight: customProps
            ? customProps.nameText.fontWeight
            : nameText.fontWeight,
        fill: customProps ? customProps.nameText.fill : nameText.fill,
        textLength: customProps ? customProps.textLength : nameText.textLength,
    });
    group.set({nameInstance:nameText});
    group.dirty = true;
    canvas.requestRenderAll();
    group.set("bgrOpacity", 1);
    group.set('angle',props.team!=="none" ?( props.angle || pitch.getTriangleAngle()) : 0);
    if (customProps) {
        group.scaleX = customProps.scaleX;
        group.scaleY = customProps.scaleY;
    }
    if (group._objects[2])
        group._objects[2].set({
            angle: customProps ? customProps.arrowAngle : pitch.getPlayerAngle(),
        });
    if(!isDropped) canvas.add(group);
    else{
        dispatch(copyToClipboard(group));
        canvas.discardActiveObject();
        canvas.defaultCursor = 'crosshair';
        canvas.selection = false;
    }
    if (!customProps && !isDropped) setActiveObject(group);
    if (formation.index == 10) {
        updateUndoRedoState(group, "added");
    }
    group.setTextPosition(nameText, canvas);
    canvas.renderAll();
};
export const changeShirtColors = (group,canvas, fill)=>{
    let objs= group._objects;
    for(let ind =0; ind<objs.length; ind++){
        let ob = objs[ind];
        let textFill = ['#fff','#FFF','#ffffff','#FFFFFF','#F7CF00'].includes(fill) ? '#000000':'#ffffff';
        if(ob.name === 'text') ob.set({fill:textFill});
        if(ob.hasOwnProperty('id')){
            const colorScheme = getColorScheme(fill);
            if(ob.id === 'shirt') ob.set({fill:fill});
            else ob.set({fill:colorScheme[ob.id]})
        }
    }
}

export const addPlayerShirt = (props,
                            scaleProps,
                            sizeFactor,
                            formation,
                            customProps,
                            updateUndoRedoState,
                            canvas,
                            animationState,
                            activeFrameVal,
                            setActiveObject,
                            isDropped,
                            dispatch,res=false)=> {
    fabric.loadSVGFromURL(Shirt,(function (objects) {
    const pitch = new Pitch(canvas, props.team || 'left', props.isSingle);
    let group = new fabric.Group(objects, {
        // backgroundColor: "triangle", // backgroundColor used as custom param for the moment
        left: props.left,
        isSvg:true,
        top: props.top,
        ref_id: props.ref_id,
        name: "player",
        objecttype: "disabled_borders",
        originX: "center",
        originY: "center",
        is_animation: true,
        bgrOpacity: 1,
        opacity: 1,
        hasControls: true,
        fadeFlag: "no-fade-out",
        time: defaultTime,
        position: customProps ? customProps.position : "top",
        team:props.team || 'left',
        formation: formation.action,
        sizeFactor: customProps ? customProps.sizeFactor : sizeFactor,
        parentFrame: animationState ? activeFrameVal.data_num : null,
        startAngle: customProps ? customProps.startAngle : pitch.getPlayerAngle(),
    });
    changeShirtColors(group, canvas,  props.color)
    let playerColor = "#fff";
    if (props.color === "#fff") {
        playerColor = "#00000";
    }
        var text = new fabric.IText(props.text || '', {
            fill: props.color === "#F7CF00" ? "#000000" : playerColor,
            originX: "center",
            originY: "center",
            fontSize: customProps?props.fontSize:props.fontSize/1.5,
            top: 0,
            fontWeight: "400",
            fontFamily: "Oswald",
            textAlign: "center",
            selectionBackgroundColor: "white",
            name: "text",
            isPlayer: true,
            ref_id: props.ref_id,
        });
        group.add(text);

        if (sizeFactor === "small") {
            group.scaleToHeight(scaleProps.height / 1.2);
            group.initialSize = scaleProps.height / 1.9;
        } else if (sizeFactor === "medium") {
            group.scaleToHeight(scaleProps.height / 0.9);
            group.initialSize = scaleProps.height / 0.9;
        } else {
            group.scaleToHeight(scaleProps.height / 0.6);
            group.initialSize = scaleProps.height / 0.6;
        }
    let textColor = "#fff";
    if (props.color === "#fff" || props.color === "#F7CF00") {
        textColor = "#00000";
    }
    var nameText = new fabric.IText("", {
        textAlign: "center", //"left", "center", "right" or "justify".
        fill: textColor,
        fillStyle: props.color === "#fff" ? "#ffffff95" : props.color + 95,
        fontSize: 12,
        ref_id: props.ref_id,
        originX: "center",
        originY: "top",
        hoverCursor: "text",
        hasControls: false,
        hasBg: false,
        hasBorders: false,
        name: "custom_image_name",
        fontFamily: "sans-serif",
        fontWeight: "bold",
        formation: formation.action,
        evented: true,
    });
    setPlayerNameDim(nameText, group, canvas);
    if (!customProps && !isDropped) canvas.add(nameText);
    group.set("nameText", {
        fillStyle: customProps
            ? customProps.nameText.fillStyle
            : nameText.fillStyle,
        text: customProps ? customProps.nameText.text : nameText.text,
        fontSize: customProps ? customProps.nameText.fontSize : nameText.fontSize,
        fontWeight: customProps
            ? customProps.nameText.fontWeight
            : nameText.fontWeight,
        fill: customProps ? customProps.nameText.fill : nameText.fill,
        textLength: customProps ? customProps.textLength : nameText.textLength,
    });
    group.set({nameInstance: nameText});
    // group.dirty = true;
    canvas.requestRenderAll();
    group.set("bgrOpacity", 1);
    // group.set('angle', props.angle || pitch.getTriangleAngle());
    if (customProps) {
        group.scaleX = customProps.scaleX;
        group.scaleY = customProps.scaleY;
    }
    // if (group._objects[2])
    //     group._objects[2].set({
    //         angle: customProps ? customProps.arrowAngle : pitch.getPlayerAngle(),
    //     });
    if (!isDropped) {
        canvas.add(group);
        props.res && props.res();
    }
    else {
        dispatch(copyToClipboard(group));
        canvas.discardActiveObject();
        canvas.defaultCursor = 'crosshair';
        canvas.selection = false;
    }
    if (!customProps && !isDropped) setActiveObject(group);
    if (formation.index == 10) {
        updateUndoRedoState(group, "added");
    }
    group.setTextPosition(nameText, canvas);
    canvas.renderAll();
    res && res('resolved');
}))
}
