export const manageDeletedObjs = (props)=>{
    let {shadowFrames,canvas,activeFrameVal,callback,updateRemovedProps} = props;
    let tempMid = [];
    let objs = [...canvas._objects];
    for (let i = 0; i < objs.length; i++) {
        let obj = objs[i];
        if (obj.name === 'pX') {
            tempMid.push(obj);
        }
        if (obj.ref_id.includes('shadow') || obj.hoverCursor === "arrow" || obj.name === 'shadow-line') {
            if (obj.name === 'shadow-line') {
                obj.opacity = 0;
                const { ref_id, scaleX, scaleY, path } = obj;
                const lineIndex = shadowFrames[activeFrameVal.data_num].shadowLines.findIndex(f=>f.ref_id === ref_id);
                if (shadowFrames[activeFrameVal.data_num].shadowLines && lineIndex === -1) {
                        shadowFrames[activeFrameVal.data_num].shadowLines.push({ref_id, scaleX, scaleY, path})
                        updateRemovedProps(false, false, {shadowFrames})
                }
            }
            canvas.remove(obj)
        }
    }
    canvas.renderAll();
        callback && callback()
}