import {fabric} from "fabric";

export const handleCurveShadowPoint = (left, top, line1, line2, line3, scaleProps,setObjectPadding,setLineToCircle)=>{
    var c = new fabric.Circle({
        left: left,
        top: top,
        strokeWidth: scaleProps.strokeWidth,
        radius: scaleProps.width * 0.16,
        fill: '#fff',
        stroke: '#000',
        padding: 2,
        originX: 'center',
        originY: 'center',
        opacity: 0,
        objecttype: "curve_line",
        hoverCursor: "pointer",
    });
    setObjectPadding(c, 5, 2)

    c.hasBorders = c.hasControls = c.selectable = false;
    setLineToCircle(c, line1, line2, line3)

    return c;
}