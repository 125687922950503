import {fabric} from "fabric";
import {getFabricImageFromURL} from "./addImage";

let countLetter = 0;

const addImage = (src, obj, fileName, canvas, activeSelection,resolveImg) => new Promise((resolve, reject)=>{
    const uuid = require("uuid");
    let id = uuid.v4();
    if(obj?.ref_id) id = obj.ref_id;
    getFabricImageFromURL(src,(imgInstance)=>{
        imgInstance.set({
            crossOrigin : "Anonymous",
            ref_id: id,
            left: obj.left,
            top: obj.top,
            flipX:obj.flipX,
            originX: 'center',
            originY: 'center',
            name: "image",
            objecttype: obj.objecttype,
            is_animation: true,
            position:obj.position
        })
        let scaleVal = obj.getScaledHeight()
        imgInstance.scaleToHeight(scaleVal);
        imgInstance.initialSize = scaleVal
        imgInstance.imageFileName = fileName;
        imgInstance.nameText = obj.nameText;
        imgInstance.formation = obj.formation;
        if(obj){
            canvas.remove(obj);
        }
        canvas.add(imgInstance);
        resolveImg && resolveImg(imgInstance)
        canvas.requestRenderAll();
        imgInstance.setCoords();
        if(!activeSelection) canvas.setActiveObject(imgInstance);
        resolve()
    })
});
const getDesireChar =(char,isLeft= false)=>{
    let desireChar = char
    if (desireChar === 0 && !isLeft) {
        desireChar = 7
    }
    else if (desireChar === 7 && isLeft) {
        desireChar = 0
    } else {
        if(isLeft)desireChar = desireChar + 1
        else{
            desireChar = desireChar - 1
        }
    }
    return desireChar.toString();
}

const replaceImage = (obj,color,codeNo, canvas,activeSelection,resolveImg) => new Promise( async (res,rej)=>{
    obj.setControlsVisibility({  editctr: false, leftAction: false, rightAction: false,flipAction:false, loaderAction:true })
    // import(`../assets/Attacking/${color}/${codeNo}.png`).then(async srcSprite => {
        let newSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/Attacking/${color}/${codeNo}.png`
        await addImage(newSrc, obj, `Players:${color}_${codeNo}`, canvas, activeSelection,resolveImg)
        obj.setControlsVisibility({  editctr: false, leftAction: true, rightAction: true,flipAction:true, loaderAction:false })
        res()
    // });
});
const replaceEquipmentImage = (obj,color,codeNo, canvas,type,size,activeSelection,resolveImg) => new Promise( async (res,rej)=>{
    obj.setControlsVisibility({  editctr: false, leftAction: false, rightAction: false,flipAction:false, loaderAction:true })
    let newSrc = ''
    if(type === 'Gate'){
        newSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/Gate/${size}-${color}-${codeNo}.png`
            await addImage(newSrc, obj, `Gate:${size}-${color}-${codeNo}`, canvas, activeSelection,resolveImg)
            obj.setControlsVisibility({  editctr: false, leftAction: true, rightAction: true,flipAction:false, loaderAction:false })
            res()
    }
    else{
            newSrc = `https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/${size}/${color}${codeNo}.png`
            await addImage(newSrc, obj, `Equipments:${size}-${color}${codeNo}`, canvas, activeSelection,resolveImg)
            obj.setControlsVisibility({  editctr: false, leftAction: true, rightAction: true,flipAction:false, loaderAction:false })
            res()
    }

})
let isAdded = true

export const flipObj = (canvas)=>{
    let obj = canvas?.getActiveObject();
    if(obj){
        obj.flipX = !obj.flipX
    }
}
export const changePlayerAction=async (side, canvas)=>{

    if (isAdded) {
        isAdded = false
        let obj = canvas?.getActiveObject();
        let items;
        if(obj.type === 'activeSelection'){
            let promises = [];
         items = obj._objects;
         canvas.discardActiveObject();
         canvas.requestRenderAll();
         for (let ind =0 ; ind<items.length;ind++){
             promises[ind] = new Promise(async  (resolve,reject)=>{
                 if( items[ind].name === 'image' && (items[ind].imageFileName?.includes('Stand') || items[ind].imageFileName?.includes('SG') || items[ind].objecttype === 'sprite-image')) {
                     let cObjInd = canvas._objects.findIndex(f => f.name === 'image' && f.ref_id === items[ind].ref_id);
                     if (cObjInd > -1){
                         await handleObj(canvas._objects[cObjInd], side, canvas, true,resolve);
                         resolve(items[ind]);
                     }
                     else{
                         resolve(items[ind]);
                     }
                 }
                 else{
                     resolve(items[ind]);
                 }
             })
         }
         Promise.all(promises).then(val=>{
             let sel = new fabric.ActiveSelection([...val], {
                 canvas: canvas,
             });
             canvas.setActiveObject(sel);
             canvas.requestRenderAll();
         })
        }
        else{
          await handleObj(obj,side,canvas)
        }

    }
}
const handleObj = async (obj,side,canvas,activeSelection = false,resolveImg)=>{
    return new Promise(async (resolve) => {
        if (!obj || !obj.imageFileName) return;
        if(obj.objecttype === 'sprite-image'){
            let fileName = obj.imageFileName.split(':')[1];
            let color = fileName.split('_')[0];
            let codeNo = fileName.split('_')[1];
            if (side === 'left') countLetter = getDesireChar(parseInt(codeNo[4]), true);
            else countLetter = getDesireChar(parseInt(codeNo[4]));
            let codeArr = codeNo.split('');
            codeArr[4] = countLetter;
            codeNo = codeArr.join('')
            await replaceImage(obj, color, codeNo, canvas, activeSelection,resolveImg)
            resolve('resolved')
            isAdded = true
        }
        else{
            isAdded = false
            let type = obj.imageFileName.split(':')[0];
            let fileName = obj.imageFileName.split(':')[1];
            if(type === 'Gate'){
                let codeNo = fileName.split('-')[2];
                let size = fileName.split('-')[0];
                if (side === 'left') countLetter = getDesireChar(parseInt(codeNo[3]), true);
                else countLetter = getDesireChar(parseInt(codeNo[3]));
                let codeArr = codeNo.split('');
                let color = fileName.split('-')[1];
                codeArr[3] = countLetter;
                codeNo = codeArr.join('');
                replaceEquipmentImage(obj,color,codeNo,canvas,type,size,activeSelection,resolveImg)
            }
            else{
                let codeColorString = fileName.split('-')[1];
                let color = codeColorString.split('');
                let codeNo = color.splice(color.length-4);
                let size = fileName.split('-')[0];
                color = color.join('');
                codeNo = codeNo.join('');
                if (side === 'left') countLetter = getDesireChar(parseInt(codeNo[3]), true);
                else countLetter = getDesireChar(parseInt(codeNo[3]));
                let codeArr = codeNo.split('');
                codeArr[3] = countLetter;
                codeNo = codeArr.join('');
                replaceEquipmentImage(obj,color,codeNo,canvas,'Equipments',size,activeSelection,resolveImg);
            }
            isAdded = true
        }

    })


}