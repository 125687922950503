import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './index.scss';
import 'antd/dist/antd.css'
import {IntlProvider} from "react-intl";
import EnMessages from './translations/en.json';
import HeMessages from './translations/he.json';
import {createStore, applyMiddleware} from 'redux';
import { createLogger } from 'redux-logger';
import allReducer from './components/tacticsboard/reducers';

import {Provider} from 'react-redux';

const messages = {
  en: EnMessages,
  he: HeMessages,
}
const logger = createLogger({
    level: "info",
    collapsed: true,
});

const store = createStore(allReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// const store = createStore(allReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),applyMiddleware(logger))
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider locale="en" messages={messages.en} onError={()=>{console.log('Warning! Error in IntlProvider')}}>
          <App/>
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
