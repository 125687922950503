import {Button, Col} from "antd";
import React, {useEffect, useRef, useState} from "react";
import '../tacticsboard/utils/fabric-overrides';
import '../tacticsboard/tacticsboard.scss';
import {fabric} from "fabric";
import axios from "axios";
import { useParams } from "react-router-dom";
import {addAllObjs, startAnimation} from "../tacticsboard/utils/animations";
import PathShadowHolder from "../tacticsboard/components/tools/pathHolder";
import {io} from 'socket.io-client'
import {setBackground} from "../tacticsboard/actions";
var path = require("path");
const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_ADDRESS || 'https://api-animation.easycoach.club'}/` // add the address of your own server on which node js is being hosted
    // baseURL: 'https://video-football.uforiaprojects.com:7000/' for uforia server
    // baseURL: 'http://localhost:5001/'  // for local testing
});
let canvas,allFrames,shadowFrames,frames,canvasBackgroundClass,recorder;
let originalZoom ,originalWidth ,originalHeight ;
let recordedBlobs = [];
let bufferArr = [];
let isRecording = true;
let counter = 1;
const HeadlessPlayer = ()=>{
    const pitchContainer = useRef(null);
    const params = useParams();
    const [svgPathElementss,setSvgs] = useState([]);
    const [recordedBlobss, setRecordedBlobss] = useState([]);
    useEffect(()=>{
        canvas = new fabric.StaticCanvas("tacticsboard__canvas", {
            skipTargetFind: true,
            selection: false,
            objectCaching:true,
            preserveObjectStacking: true,
            isVideoPlayer: true,
            isMediaRecorder:true,
        });
        window.canvas = canvas
        console.log('pitchContainer',pitchContainer.current.style)
        // loadJsonFromServer(params.id);
        loadJsonFromLocal(params.id)

    },[])
    const setCanvasBackground = (canvasEl, callback) => {
        /*import(`../tacticsboard/assets/images/${canvasBackgroundClass}.jpg`).then(srcSprite => {
            let img = new Image();
            img.onload = function () {
                canvasEl.setBackgroundImage(img.currentSrc, () => {
                    canvasEl.renderAll.bind(canvasEl)
                    callback && callback();
                }, {
                    scaleX: (canvasEl.width / canvasEl.getZoom()) / img.width,
                    scaleY: (canvasEl.height / canvasEl.getZoom()) / img.height,
                });
            };
            img.src = srcSprite.default;
        });*/
        callback && callback();

    }
    const playAnimation = () => {
        console.log("Play animation")
        playCurvedAnimation(1);
    }
    const stopCurvedAnimation = (bufferArr)=>{
        const jsonBuffer = JSON.stringify(bufferArr);
        const datals = {
            jsonBuffer,height:canvas.height,width:canvas.width
        }
        const blob = new Blob([JSON.stringify(datals)], {type: "application/json"});
        recordedBlobs = [];
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${params.id}.json`;
        document.body.appendChild(a);
        a.click();
        isRecording = false;
    }
    const playCurvedAnimation = async (id)=>{
        console.log("playCurvedAnimation::::",id)
        const socket = io(`${process.env.API_SOCKET_ADDRESS || 'https://socket-animation.easycoach.club'}`); //UNCOMMENT ON PRODUCTION
        // const socket = io(`http://localhost:8080`);
        socket.emit(`send-message${id}`, "Downloading started");
        if(id !== 1)
            await addAllObjs(frames, canvas);
        canvas.hasAnimationStarted = true;
        startAnimation(1, true, canvas, frames, shadowFrames, null, stopCurvedAnimation,socket,id)


    }
    const loadJsonFromLocal = async (id)=>{
        let newApi = `${process.env.REACT_APP_API_ADDRESS || 'https://api-animation.easycoach.club'}/libraries/chromeVideoGeneration/jsonTemp/${id}.json`
        // let newApi = `http://localhost:5001/libraries/chromeVideoGeneration/jsonTemp/${id}.json` // for local testing
        fetch(newApi)
            .then(function(response){
                console.log(response)
                return response.json();
            })
            .then(function(myJson) {
                console.log(myJson);
                setSvgs([...myJson.svgPaths])
                frames = myJson.frames;
                canvas.setDimensions({
                    width:myJson.canvasWidth,
                    height:myJson.canvasHeight
                })
                shadowFrames = myJson.shadowFrames;
                canvasBackgroundClass = myJson.bg;
                setExportVideoSize();
                setTimeout(()=>{
                    playCurvedAnimation(id)
                },3000)
            });
    }
    const loadJsonFromServer = async (id)=>{
        api.post(`/loadVideo`, {
            id: id,
        }).then(res=>{
            let myJson = res.data.data
            setSvgs([...myJson.svgPaths])
            frames = myJson.frames;
            canvas.setDimensions({
                width:myJson.canvasWidth,
                height:myJson.canvasHeight
            })
            shadowFrames = myJson.shadowFrames;
            canvasBackgroundClass = myJson.bg;
            console.log('newData',res.data.data);
            console.log(canvas.getWidth(),canvas.getHeight())
            playCurvedAnimation()
        });

    }
    const fnBrowserDetect = () => {
        let userAgent = navigator.userAgent;
        if (userAgent.match(/chrome|chromium|crios/i)) {
            // browserName = "chrome";
            return { type: 'video/webm; codecs=h264', browser: 'chrome' };
        } else if (userAgent.match(/firefox|fxios/i)) {
            // browserName = "firefox";
            return { type: 'video/webm; codecs:h264', browser: 'firefox' };
        }
        else if (userAgent.match(/safari/i)) {
            // browserName = "safari";
            return { type: 'video/mp4;codecs:h264', browser: 'safari' }
        }
        else if (userAgent.match(/Android/i)) {
            return '';
        }
        else {
            return ''
        }

    }
    const setExportVideoSize = (reset = false) => {
        if (!reset) {
            originalZoom = canvas.getZoom();
            originalWidth = canvas.getWidth();
            originalHeight = canvas.getHeight();



            const width = canvas.getWidth() * canvas.getZoom();
            const height = canvas.getHeight() * canvas.getZoom();
            const ratio = height / width;
            const videoWidth = 1280;
            let zoom = videoWidth / width;
            canvas.setZoom(zoom);
            canvas.setWidth(videoWidth)
            canvas.setHeight(videoWidth * ratio);

        }
        else {
            canvas.setZoom(originalZoom);
            canvas.setWidth(originalWidth)
            canvas.setHeight(originalHeight);

        }


    }


    return(
        <>
            <PathShadowHolder svgElements={svgPathElementss} />
            <Button onClick={() => playAnimation()} type="primary" size="default">Play</Button>
            <div ref = {pitchContainer} id="tacticsboard__canvas_container" className="tacticsboard__pitch_container">

                <canvas id="tacticsboard__canvas" />
            </div>
        </>
    )
}
export default HeadlessPlayer