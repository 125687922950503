const text = (state = "", action)=>{
    if(action.type === "SET_TEXT"){
        state = action.text
    }
    if(action.type === "RESET_CANVAS"){
        state = "";
    }

    return state;
}

export default text;