export const setObjectControls = (object, type = 'default') => {
    let controls = {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: false, // rotate icon
        editctr: false, // edit text
        leftAction: false, //left Action
        rightAction: false, //Right Action
        flipAction:false, //Flip Action
        loaderAction: false, //Right Action
        rotateAction: false,//angle rotate action
    }
    switch (type) {
        case 'ellipse_outlined':
        case 'ellipse_solid':
        case 'hoop':
        case 'dot':
        case 'sprite-image':
            object.setControlsVisibility({ ...controls, br: true })
            break;
        case 'player_image':
            object.controls.editctr.offsetX = -(object.getScaledWidth() * 0.2);
            object.controls.editctr.offsetY = -(object.getScaledHeight() * 0.2);
            object.setControlsVisibility({ ...controls, editctr: false })
            break;
        case 'ball':
        case 'activeSelection':
            object.setControlsVisibility(controls)
            break;
        case 'default':
            object.setControlsVisibility({ ...controls, br: true, mtr: true })
            break;
    }
}