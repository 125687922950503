import React from "react";
import GlobalStyle from "./globalStyles";
import TacticsBoard from "./components/tacticsboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import HeadlessPlayer from "./components/videoPlayer/HeadlessPlayer";
import Localization from "./localization";
import MediaPlayer from "./components/videoPlayer/MediaPlayer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <TacticsBoard />,
  },
  {
    path: "videoPlayer/:id",
    element: <HeadlessPlayer />,
  },
  {
    path: "mediaPlayer/:id",
    element: <MediaPlayer />,
  }
]);

function App() {
  console.log("Deployed");
  return (
    <Localization>
      <div className="App">
        {/* <PleaseRotate/> */}
        <GlobalStyle />
        <RouterProvider router={router} />
      </div>
    </Localization>
  );
}

export default App;
