import {activeSelectionConnectionLine} from "./utils";

const percentageIncrease = (num) => {
    return parseFloat((5 / 100) * num)
}
const setLineDimension = (setConnect,canvas) => {
    if (setConnect) {
        activeSelectionConnectionLine(canvas)
    } else {
        return
    }
}

const setTextDimensionOnSize = (obj, canvas) => {
    if (obj.name === "player" || obj.objecttype === "sprite-image" || obj.name === 'player_custom_image') {
        let imgIndex = canvas._objects.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
        if(imgIndex>-1) obj.setTextPosition(canvas._objects[imgIndex],canvas);
    }
}
export const updateSize = ({state, canvas, setConnect,modifiedObject})=>{
    const obj = canvas.getActiveObject();
    if (obj) {
        if (obj.objecttype !== "arrow_line" && obj.objecttype !== "simple_line" && obj.objecttype !== "curve_line") {
            let sizeX
            let sizeY
            switch (state) {
                case "increase":
                    sizeX = obj.scaleX + percentageIncrease(obj.scaleX)
                    sizeY = obj.scaleY + percentageIncrease(obj.scaleY)
                    setTextDimensionOnSize(obj, canvas)
                    setLineDimension(setConnect,canvas)
                    break;
                case "decrease":
                    sizeX = obj.scaleX - percentageIncrease(obj.scaleX)
                    sizeY = obj.scaleY - percentageIncrease(obj.scaleY)
                    setTextDimensionOnSize(obj, canvas)
                    setLineDimension(setConnect,canvas)
                    break;
                default:
                    if (obj.name === "drawLine" || obj.name === "shape") {
                        if (obj.objecttype === "star") {
                            obj.scaleToHeight(obj.initialSize);
                            canvas.renderAll()
                            modifiedObject({ target: obj })
                        } else {
                            sizeX = 1
                            sizeY = 1
                        }
                    } else if (obj.name === "player" || obj.name === "image") {
                        obj.scaleToHeight(obj.initialSize);
                        canvas.renderAll()
                        modifiedObject({ target: obj })
                    }
                    break;
            }

            if (sizeX > 0 && sizeY > 0) {
                obj.set("scaleX", sizeX)
                obj.set("scaleY", sizeY)
                canvas.renderAll()
                modifiedObject({ target: obj })
            }
        }
    }
}