export const handleUndoUpdates = (props)=>{
let {
    line,animationState,activeFrameVal,updateUndoProps,undoStates,activeStateIndex,animation_state
} = props;
    let undoVal, stateIndex
    if (animationState || animation_state) {
        undoVal = activeFrameVal.undoStates
        stateIndex = activeFrameVal.activeStateIndex
    } else {
        undoVal = undoStates
        stateIndex = activeStateIndex
    }
    updateUndoProps({undoStates,activeStateIndex,activeFrameVal})

    switch (line.objecttype) {
        case "simple_line":
            stateIndex = stateIndex - 2
            var obj = {
                action: "array",
                data: [undoVal[undoVal.length - 3], undoVal[undoVal.length - 2], undoVal[undoVal.length - 1]]
            }
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.push(obj)
            if (animationState || animation_state) {
                activeFrameVal.activeStateIndex = stateIndex
            } else {
                activeStateIndex = stateIndex
            }
            updateUndoProps({undoStates,activeStateIndex,activeFrameVal})
            break;
        case "arrow_line":
            stateIndex = stateIndex - 3
            var obj = {
                action: "array",
                data: [undoVal[undoVal.length - 4], undoVal[undoVal.length - 3], undoVal[undoVal.length - 2], undoVal[undoVal.length - 1]]
            }
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.push(obj)

            if (animationState || animation_state) {
                activeFrameVal.activeStateIndex = stateIndex
            } else {
                activeStateIndex = stateIndex
            }
            updateUndoProps({undoStates,activeStateIndex,activeFrameVal})
            break;
        case "curve_line":
            if (line.pa) {
                stateIndex = stateIndex - 4

            } else {
                stateIndex = stateIndex - 3

            }
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            undoVal.splice(undoVal.length - 1, 1)
            if (line.pa) {
                undoVal.splice(undoVal.length - 1, 1)
            }
            if (animationState || animation_state) {
                activeFrameVal.activeStateIndex = stateIndex
            } else {
                activeStateIndex = stateIndex
            }
            updateUndoProps({undoStates,activeStateIndex,activeFrameVal})
            break;
    }
}