import {fabric} from "fabric";
import {defaultTime, setImage, setTextDimensions} from "./utils";
import {Pitch} from "./pitchDetails";
import {copyToClipboard} from "../actions";
import {getFabricImageFromURL} from "./addImage";

export const updateAllCustomPlayerImageDimensions = (canvas) => {
    canvas._objects.forEach(obj => {
        if (obj.name === 'player_custom_image') {
            setCustomPlayerImageDimensions(canvas,obj, obj.scaleX, obj.scaleY);
        }
    })
}
export const setCustomPlayerImageDimensions = (canvas, object, scaleX, scaleY) =>{
    if(!object && !object.customImage)
    {
        console.log("Custom Image not found");
        return
    }
    getFabricImageFromURL(object.customImage,(fabImg)=>{
        let newSrc = fabImg.getSrc();
        fabric.Image.fromURL(newSrc, function (img) {
            if(img._element) {
                var patternSourceCanvas = new fabric.StaticCanvas();
                object.width *= object.scaleX;
                object.height *= object.scaleY;
                object.getObjects()[0].radius *= object.scaleX;
                object.getObjects()[0].width *= object.scaleX;
                object.getObjects()[1].width *= object.scaleX;
                if(object.getObjects()[2])object.getObjects()[2].width *= object.scaleX;
                if(object.getObjects()[2])object.getObjects()[2].radius *= object.scaleX;
                object.getObjects()[0].height *= object.scaleY;
                object.getObjects()[1].height *= object.scaleY;
                if(object.getObjects()[2])object.getObjects()[2].height *= object.scaleY;
                const maxWidth = object.width;
                //if it is a horizontal image, we set the height to 40, otherwise, we set the width to 40
                if(img.width > img.height)
                    img.scaleToHeight(maxWidth);
                else
                    img.scaleToWidth(maxWidth);
                let offsetX = 0;
                let offsetY = 0;
                patternSourceCanvas.setDimensions({
                    width: img.getScaledWidth(),
                    height: img.getScaledHeight(),
                });
                //Width of Pattern image is larger then our object, so we need to set offset
                if(patternSourceCanvas.width > maxWidth)
                {
                    offsetX = (maxWidth-patternSourceCanvas.width)/2;
                }
                if(patternSourceCanvas.height > maxWidth)
                {
                    offsetY = (maxWidth-patternSourceCanvas.height)/2;
                }
                patternSourceCanvas.add(img);
                patternSourceCanvas.renderAll();
                var pattern = new fabric.Pattern({
                    source: patternSourceCanvas.toCanvasElement(),
                    repeat: 'no-repeat',
                    offsetX : offsetX,
                    offsetY : offsetY,
                });
                //console.log(patternSourceCanvas.toDataURL());
                object._objects[0].set('fill', pattern)
                object._objects[1].set('text', '');
                object.scaleX = 1;
                object.scaleY = 1;
                object.setCoords();
                object.set("dirty",true);
                canvas.requestRenderAll();

            }
        },{
            crossOrigin: 'anonymous'
        });

    })



}

export const addCustomPlayerImage = async (canvas,src, props, scaleProps,setActiveObject,getSurName, isMobileView, isDropped=false,dispatch=(null)) => {
    const pitch = new Pitch(canvas);
    let img = new Image();
    let fullName;
    let fileData = props.text.split('-');
    let pSize = fileData[0] // size
    let pBname = fileData[1] // name
    let playerType = fileData[3]
    let surName = getSurName(pBname);
    let hebrewCheck = /[\u0590-\u05FF]/;
    let isHebrew = hebrewCheck.test(pBname);
    let pShirtNo = fileData[2] // shirt no
    let nameTextLimit = 6;
    if (pSize === "medium") nameTextLimit = 6
    if (pSize === "large") nameTextLimit = 6
    let currName = pBname.split(' ').filter(f => f !== '');
    let nameArr = currName;
    let imgFirstName = nameArr[0][0];
    let imgLastName = nameArr.length > 1 ? nameArr[nameArr.length - 1][0] : '';
    fullName = pBname.split(' ').length > 1 ? `${imgFirstName}${imgLastName}` : `${imgFirstName}`;
    if (playerType === 'image') {
        surName = `${pShirtNo} ${surName}`
    }
    var shadow = new fabric.Shadow({
        blur: 10,
        color: "rgba(0,0,0,0.3)",
        id: 1,
        offsetX: 10,
        offsetY: 10,
    });
    let group = new fabric.Group('', {
        left: props.left,
        top: props.top,
        ref_id: props.ref_id,
        height: scaleProps.height / 3,
        width: scaleProps.width / 3,
        // width:20,
        name: "player_custom_image",
        objecttype: 'player_image',
        originX: 'center',
        originY: 'center',
        selectionBackgroundColor: '',
        background: '',
        opacity:1,
        startAngle:pitch.getPlayerAngle(),
        fadeFlag:'no-fade-out',
        time:defaultTime,
        is_animation: true,
        position:'top'
    });
    let playerColor = '#fff';
    if (props.color === "#fff") {
        playerColor = '#00000'
    }
    var circle = new fabric.Circle({
        showHighlight: false,
        highlightColor: '#C40E1265',
        radius: 19,
        bgrOpacity:1,
        stroke: props.color === "#F7CF00" ? '#fff' : playerColor,
        strokeWidth: 2,
        fill: props.color,
        originX: 'center',
        originY: 'center',
        ref_id: props.ref_id,
        name: "circle",
        hasBorders: false,
        hasControls: false,
        evented: false,
        // shadow: shadow
    });

    var text = new fabric.IText(`${pShirtNo}`, {
        fontFamily: 'sans-serif',
        fill: props.color === "#F7CF00" ? '#000000' : playerColor,
        originX: 'center',
        originY: 'center',
        fontSize: 18,
        top: 0,
        fontWeight: 'bold',
        selectionBackgroundColor: 'white',
        name: "text",
        isPlayer:true,
        ref_id: props.ref_id
    })
    var arrowCircle = new fabric.Circle({
        highlightColor: '#C40E1265',
        radius: 19,
        fill: 'transparent',
        arrowFill:props.color,
        strokeWidth: 2,
        arrowAngle:0,
        originX: 'center',
        originY: 'center',
        ref_id: props.ref_id,
        name: "arrowCircle",
        hasBorders: false,
        hasControls: false,
        direction:true,
    });
    group.add(circle);
    group.add(text);
    group.add(arrowCircle);
    group.addWithUpdate()
    group.scaleToHeight(scaleProps.height / 1.25);
    group.initialSize = scaleProps.height / 1.25

    group.dirty = true

    // ----------------------------------------------------------------------

    let textColor = '#fff';
    if (props.color === "#fff" || props.color === "#F7CF00") {
        textColor = '#00000'
    }
    var nameText = new fabric.IText(surName, {
        textAlign: 'center', //"left", "center", "right" or "justify".
        fill: textColor,
        fillStyle: props.color === '#fff' ? '#ffffff95' : props.color + 95,
        fontWeight: 'bold',
        hasBg: false,
        fontSize: 13,
        hoverCursor:'text',
        direction: isHebrew ? 'rtl' : 'ltr',
        ref_id: props.ref_id,
        originX: 'center',
        originY: 'top',
        hasControls: false,
        hasBorders: false,
        textLength: nameTextLimit,
        name: 'custom_image_name',
        fontFamily: 'sans-serif',
    });
    var shirtCircle = new fabric.Circle({
        radius: 5,
        fill: 'white',
        originX: 'center',
        originY: 'center',
        ref_id: props.ref_id,
        name: "shirtCircle",
        hasBorders: false,
        hasControls: false,
    });
    var shirtNo = new fabric.IText(pShirtNo, {
        textAlign: 'center', //"left", "center", "right" or "justify".
        fontSize: 10,
        fill: '#000',
        ref_id: props.ref_id,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        textLength: 3,
        name: 'custom_image_shirtno',
        fontFamily: 'sans-serif',
    });
    let numGroup = new fabric.Group('', {
        ref_id: props.ref_id,
        name: "custom_image_shirtno",
        originX: 'center',
        originY: 'center',
        hasControls: false,
        opacity: 0,
        evented: false,
        selectionBackgroundColor: '',
    });
    numGroup.add(shirtCircle);
    numGroup.add(shirtNo);
    numGroup.addWithUpdate()
    numGroup.scaleToHeight(scaleProps.height / 8);
    numGroup.initialSize = scaleProps.height / 8

    if (pSize === "medium") {
        if (img.width > img.height) group.scaleToWidth(scaleProps.width / 2.5);
        else group.scaleToHeight(scaleProps.height / 3.1);
        nameText.fontSize = isMobileView ? 10 : 13;
        numGroup._objects[0].radius = 7;
        numGroup._objects[1].fontSize = 10;
    }
    else if (pSize === "large") {
        if (img.width > img.height) group.scaleToWidth(scaleProps.width);
        else group.scaleToHeight(scaleProps.height / 2.5);
        nameText.fontSize = isMobileView ? 10 : 13;
        numGroup._objects[1].fontSize = 12;
        numGroup._objects[0].radius = 9;
    }
    else {
        if (img.width > img.height) group.scaleToWidth(scaleProps.width / 3.1);
        else group.scaleToHeight(scaleProps.height / 3.7);
        nameText.fontSize = isMobileView ? 10 : 13;
        numGroup._objects[0].radius = 7;
        numGroup._objects[1].fontSize = 10
    }
    group.set('shirtNo', {
        text: shirtNo.text,
        fontSize: shirtNo.fontSize,
        fill: shirtNo.fill,
        textLength: shirtNo.textLength,
        radius: shirtCircle.radius,
        left: numGroup.left,
        top: numGroup.top,
        scaleX: numGroup.scaleX,
        scaleY: numGroup.scaleY,
        height: numGroup.height,
        width: numGroup.width,
    });
    group.set('nameText', {
        text: getSurName(pBname),
        fontSize: nameText.fontSize,
        fill: nameText.fill,
        fillStyle: nameText.fillStyle,
        fontWeight: nameText.fontWeight,
        textLength: nameText.textLength
    });
    group.set({nameInstance:nameText})
    group.set({numInstance:numGroup})
    let imgLoaded = await isImageLoaded(src);
    if (playerType === 'image' && imgLoaded) {
        group.customImage = src;
        setImage(group, src, canvas,isDropped,dispatch);
    }
    else{
        if(!isDropped) canvas.add(group);
        else{
            dispatch(copyToClipboard(group));
            canvas.discardActiveObject();
            canvas.defaultCursor = 'crosshair';
            canvas.selection = false;
        }
    }
    if(!isDropped) {
        canvas.add(nameText);
        canvas.add(numGroup);
        if (group._objects[2]) group._objects[2].set({angle: pitch.getPlayerAngle()})
        group.setTextPosition(nameText, canvas);
        setActiveObject(group)
    }
    // setTextDimensions(nameText, numGroup, group, canvas);

    numGroup.evented = false;
    canvas.renderAll();
}

function isImageLoaded(imageSrc) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            // Image loaded successfully
            resolve(true);
        };

        img.onerror = () => {
            // Error loading the image
            resolve(false);
        };

        img.src = imageSrc;
    });
}