
export const handleObjectType = (obj,canvas)=>{
    if (obj.type === 'activeSelection') return;
    let arrowInd = canvas._objects.findIndex(f => f.name === 'arrow' && f.ref_id === obj.ref_id);
    let desType = obj.objecttype;
    if (obj.name === "text") desType = 'text';
    else if (obj.name === "free-shape") desType = 'free_shape';
    else if (obj.name === 'Line Arrow') desType = 'Line Arrow';
    else if (obj.objecttype === "simple_line" && obj.strokeDashArray) desType = 'simple_line_dashed'
    else if (obj.objecttype === "arrow_line" && obj.strokeDashArray && arrowInd > -1) desType = 'arrow_line_dashed'
    else if (obj.objecttype === "curve_line" && obj.strokeDashArray && !obj.pa) desType = 'curve_line_dashed'
    else if (obj.objecttype === "curve_line" && obj.pa && !obj.strokeDashArray) desType = 'curve_arrow_line'
    else if (obj.objecttype === "curve_line" && obj.strokeDashArray && obj.pa) desType = 'curve_arrow_line_dashed'
    else if (obj.objecttype === "star") {
        if (obj.shapeType === 'outlined') desType = 'star_outlined'
        else desType = 'star_solid'
    }
    else if (obj.objecttype === "equipments" && obj.imageFileName) {
        const name = obj.imageFileName.split(':')[1].split('-')[0];
        desType = name
        if (name === "Cones") desType = obj.imageFileName.split(':')[1].split('_')[0];
    }
    return desType;
}