const updatedObjectType = (state = '', action)=>{
    if(action.type === "SET_OBJECT_TYPE"){
        state = action.state
    }
    if(action.type === "RESET_CANVAS"){
        state = '';
    }

    return state;
}

export default updatedObjectType;