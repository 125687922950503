import React from 'react';
import { Button, Layout  } from 'antd';
import { setTools } from '../actions';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import './tacticsboardtoolsheader.scss';
const { Header, Footer, Sider, Content } = Layout;

const TacticsBoardToolsHeader = () => {

    // const { direction } = useSelector(state => state.settings);
    const direction = "ltr";

    const dispatch = useDispatch();

    const closeTools = () => {
        dispatch(setTools(false));
    }

    return (
      <Header>
          <Button onClick={closeTools} size='small' shape='round' type='danger' ><FormattedMessage id="general.close" /></Button>
      </Header>

    )
}

export default TacticsBoardToolsHeader;
