import React, {useEffect, useState} from 'react';
import './leftSidePitches.scss';
import {useDispatch, useSelector} from 'react-redux';
import {setBackground, setBackgroundUpdate, setDefaultColor} from "../../actions";
import {drawsObjsWithColor} from "../../utils/utils";

const TacticsBoardLeftSidePitches = () => {
    const background = useSelector(state => state.background)
    const canvas = useSelector(state => state.canvas)

    const [selectedBackground, setSelectedBackground] = useState('light_p8');
    const [pitchName, setPitchName] = useState('');

    const dispatch = useDispatch();

    useEffect(()=>{
        if(background){
            setSelectedBackground(background)
            setPitchName(background.slice(-2))
        }
        else{
            let pitchName = document.querySelector("#tacticsboard__canvas").parentElement.classList[1].split('_')
            setPitchName(pitchName[pitchName.length-1])
        }
    },[])

    const onSelectBackgroundToGo = (background) => {
        let lines = canvas._objects.filter(f => f.name === 'connectionLine')
        if(lines.length){
            if(background.includes('white')){
                dispatch(setDefaultColor("#000000"))
                for(let i = 0; i<lines.length; i++){
                    lines[i].set({fill:'#000',stroke:'#000'})
                }
            }else{
                dispatch(setDefaultColor("#fff"))
                for(let i = 0; i<lines.length; i++){
                    lines[i].set({fill:'#fff',stroke:'#fff'})
                }
            }
            canvas.renderAll()
        }

        dispatch(setBackgroundUpdate(true))
        dispatch(setBackground(''));
        dispatch(setBackground(background));
        setSelectedBackground(background)
    }

    return (
        <div className={`tacticsboard__header_pitches_container`}>
            <div onClick={() => onSelectBackgroundToGo(`deepGreen_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_deepGreen_${pitchName} primary_border_mark ${selectedBackground === `deepGreen_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`line_deepGreen_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_line_deepGreen_${pitchName} primary_border_mark ${selectedBackground === `line_deepGreen_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`dark_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_dark_${pitchName} primary_border_mark ${selectedBackground === `dark_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`line_dark_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_line_dark_${pitchName} primary_border_mark ${selectedBackground === `line_dark_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`light_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_light_${pitchName} primary_border_mark ${selectedBackground === `light_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`line_light_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_line_light_${pitchName} primary_border_mark ${selectedBackground === `line_light_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`white_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_white_${pitchName} primary_border_mark ${selectedBackground === `white_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`line_white_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_line_white_${pitchName} primary_border_mark ${selectedBackground === `line_white_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`default_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_default_${pitchName} primary_border_mark ${selectedBackground === `default_${pitchName}` && 'primary_border_marked'}`} />
            <div onClick={() => onSelectBackgroundToGo(`line_default_${pitchName}`)} className={`tacticsboard__background_leftSide tacticsboard__background_line_default_${pitchName} primary_border_mark ${selectedBackground === `line_default_${pitchName}` && 'primary_border_marked'}`} />
           </div>
    )
}
export default TacticsBoardLeftSidePitches;