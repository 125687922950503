import React, { useEffect, useState } from 'react';
import { SvgIcon } from '@mui/material';
import {FormattedMessage, injectIntl} from 'react-intl';
import { Button, Checkbox, Col, Input, Row, Slider, Switch, Tooltip } from 'antd';
import {
    AngleLeftIcon,
    AngleRightIcon,
    ColorIconEditor,
    DeleteIconEditor, DirectionIconEditor,
    DuplicateIconEditor, FadingIcon,
    LayersIconEditor,
    OpacityIconEditor,
    RotateIconEditor, ShapeIconEditor, ShowHighlightIcon,
    SizeIconEditor,
    TextIconEditor,
    Left, Right, Bottom, Top, Position
} from '../../assets/icons';
import './leftsideextra.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fabric } from "fabric";
import { setBackgroundOpacity, setColorPopup, setEditingMode, setOpacity, setSelectedColor, } from "../../actions";
import { activeSelectionConnectionLine, hexToRgbA, setPlayerNameDim, setTextDimensions } from "../../utils/utils";
import ColorPopup from "../tools/colorPopup/colorPopup";
import { strokeWidth } from "../fabric/defaults";
import highlighter from "../../assets/images/highlighter.png";
import {duplicateObject} from "../../utils/duplicate";
import {isTri} from "../../utils/updateObjectsStates";

const iconColor = '#000000', selectedIconColor = '#5A5FE7'

const TacticsBoardLeftSideExtra = ({ intl, activeFrame, deleteCanvasObject, updateAllProps, exportVideo, cancelMove, reinitFreeShape, updateUndoStates, moveEnd2, moveLine, modifiedObject, movingObject, mouseUp, reinitpath, activeBar,isText }) => {
    const dispatch = useDispatch();
    const canvas = useSelector(state => state.canvas);
    const objectStates = useSelector(state => state.canvasObjectStates)
    const isColorPopup = useSelector(state => state.colorPopup);
    const objOpacity = useSelector(state => state.opacity)
    const setBgOpacity = useSelector(state => state.backgroundOpacity);
    const setConnect = useSelector(state => state.connectObject);
    const whitePitch = useSelector(state => state.getWhitePitch);
    const {textField} = useSelector(state => state.player);

    const defaultCheckedList = [''];
    const plainOptions = ['FadeIn', 'FadeOut'];
    const { direction } = useSelector((state) => state.settings);

    const [objectText, setObjectText] = useState('Text');
    const [playerName, setPlayerName] = useState('Player Name');
    const [shirtNumber, setShirtNumber] = useState('Shirt Number');
    const [isImgSelected, setImgText] = useState(false);
    const [isPlayerSelected, setPlayerText] = useState(false);
    const [textSelected, setTextSelection] = useState(false);
    const [toggleBold, setToggleBold] = useState(false);
    const [menuItemPanel, setMenuItemPanel] = useState('5');
    const [borderStyle, setBorderStyle] = useState(false);
    const [playerDirection, setDirection] = useState(false);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [activeSelection, setActiveSelection] = useState(false);
    const [heiglightCheck, setHeiglightCheck] = useState(false);
    const [equipmentObj, setEquipmentObj] = useState(false);
    const { formatMessage } = intl;

    useEffect(() => {
        console.log('text field',textField);

        let obj = canvas.getActiveObject()

        handleTextObject()
        if (obj?.objecttype === "sprite-image" || obj?.objecttype === 'ball') {
            setEquipmentObj(false)
        } else {
            setEquipmentObj(true)
        }
        if (objectStates.player || objectStates.playerImage || multiSelectDirection()) {
            if (obj.name === 'custom_image_name') {
                let playerInd = canvas._objects.findIndex(f => (f.name === 'player' || f.name === 'image' || f.name === 'player_custom_image') && f.ref_id === obj.ref_id);
                if (playerInd > -1) {
                    canvas.discardActiveObject();
                    canvas.setActiveObject(canvas._objects[playerInd]);
                    obj = canvas.getActiveObject();
                }
            }
            setDirection(objectStates.direction);
            if (obj._objects[0]?.showHighlight) {
                setHeiglightCheck(true)
            } else {
                setHeiglightCheck(false)
            }
        }
        if (obj != null)
            if (obj.type === 'activeSelection') {
                setActiveSelection(true)
                return
            }
            else {
                setActiveSelection(false)
                if (obj.fadeFlag === 'fade-in-out') {
                    setCheckedList(plainOptions);
                }
                else if (obj.fadeFlag === 'fade-in-stay') {
                    setCheckedList(['FadeIn']);
                }
                else if (obj.fadeFlag === 'fade-out') {
                    setCheckedList(['FadeOut']);
                } else {
                    setCheckedList([])
                }
            }
        else {
            setCheckedList(defaultCheckedList);
        }
        if (objectStates.shape) {
            if (obj.strokeDashArray) {
                setBorderStyle(true)
            } else {
                setBorderStyle(false)
            }
        }
        if (objectStates.playerImage || (objectStates.player && !objectStates.svg)) setMenuItemPanel('9')
        else setMenuItemPanel('5')
        return () => {
            toolgeTextModel();
            dispatch(setEditingMode(false));
        }
    }, [activeBar, objectStates])

    // adding text to canvas
    const getSurName = (playerName) => {
        let fullName = [];
        let nameArr = playerName.split(' ');
        if (nameArr.length <= 1) {
            return nameArr[0];
        } else {
            for (let i = 0; i < nameArr.length - 1; i++) {
                fullName.push(nameArr[i][0]);
            }
            fullName.push(nameArr[nameArr.length - 1])
            return fullName.join('.')
        }
    }
    const duplicateObjectHelper = ()=>{
        const props = {
            canvas, reinitFreeShape, updateUndoStates,moveLine,moveEnd2, movingObject,reinitpath,mouseUp
        }
        duplicateObject(props);
    }
    const updatePlayer = (e) => {
        let obj = canvas.getActiveObject()
        let hebrewCheck = /[\u0590-\u05FF]/
        if (obj) {
            let text;
            let pName;
            let shirtNum;
            switch (obj.name) {
                case "player":
                    let plNameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
                    if (e.target.id === 'input_player-name' && plNameInd > -1) {
                        canvas._objects[plNameInd].text = e.target.value;

                        obj.nameText.text = e.target.value;
                        setObjectText(e.target.value)
                        if (e.target.value.length === 0) {
                            canvas._objects[plNameInd].evented = false;
                            canvas._objects[plNameInd].opacity = 0
                        } else {
                            canvas._objects[plNameInd].evented = true;
                            canvas._objects[plNameInd].opacity = 1;
                        }
                        setPlayerName(e.target.value);
                        setObjectText(obj._objects[1].text);
                        if(isTri(obj) || obj.isSvg) setObjectText(obj._objects[obj._objects.length - 1].text);
                            modifiedObject({ target: canvas._objects[plNameInd], action: 'text-changed' })
                    } else if (e.target.id === 'input_player') {
                        text = obj._objects[obj._objects.length - 2];
                        if(isTri(obj) || obj.isSvg) text = obj._objects[obj._objects.length - 1];
                        text.set({ text: e.target.value })
                        setObjectText(e.target.value)
                        modifiedObject({ target: obj, action: 'text-changed' })
                    }
                    break;
                case "text":
                    text = obj
                    text.set({ text: e.target.value })
                    setObjectText(e.target.value)
                    modifiedObject({ target: obj, action: 'text-changed' })
                    break;
                case "player_custom_image":
                    let objNameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
                    let objShirtIndex = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === obj.ref_id);
                    if (objNameInd > -1 && objShirtIndex > -1) {
                        if (e.target.id === 'input_player-shirtNo') {
                            if (e.target.value.length > 2) return
                            canvas._objects[objShirtIndex]._objects.find(f => f.name === 'custom_image_shirtno').set('text', e.target.value);
                            pName = canvas._objects[objNameInd].text;
                            shirtNum = e.target.value;
                            if (typeof (obj._objects[0].fill) === 'object') {
                                canvas._objects[objNameInd].direction = hebrewCheck.test(obj.nameText.text) ? 'rtl' : 'ltr';
                                canvas._objects[objNameInd].text = `${e.target.value} ${obj.nameText.text}`
                                // setPlayerName(obj.nameText.text)
                            } else {
                                obj._objects[1].set({ text: shirtNum })
                            }
                            obj.shirtNo.text = shirtNum;
                            setShirtNumber(shirtNum);
                            setObjectText(e.target.value);
                            modifiedObject({ target: canvas._objects[objShirtIndex]._objects[1], action: 'text-changed' })
                        } else if (e.target.id === 'input_player-name') {
                            let imgInd = canvas._objects.findIndex(f => f.name === 'player_custom_image' && f.ref_id === obj.ref_id);
                            let newName = getSurName(e.target.value);
                            let nameArr = newName.split('.');
                            let imgFirstName = nameArr[0][0];
                            let imgLastName = nameArr.length > 1 ? nameArr[nameArr.length - 1][0] : '';
                            let fullName = nameArr.length > 1 && imgLastName ? !imgFirstName ? `${imgLastName}` : `${imgFirstName}${imgLastName}` : `${imgFirstName ? imgFirstName : ''}`;
                            let sInd = canvas._objects[imgInd]._objects.findIndex(f => f.name === 'text');
                            if (sInd > -1) {
                                canvas._objects[imgInd]._objects[sInd].set('text', '');
                                canvas.renderAll();
                            }
                            canvas._objects[objNameInd].set('text', getSurName(e.target.value));
                            pName = e.target.value;
                            shirtNum = canvas._objects[objShirtIndex]._objects.find(f => f.name === 'custom_image_shirtno').text;
                            canvas._objects[imgInd].nameText.text = getSurName(e.target.value)
                            if (typeof (canvas._objects[imgInd]._objects[0].fill) === 'object') {
                                canvas._objects[objNameInd].set('text', shirtNum + ' ' + getSurName(e.target.value));
                                canvas._objects[objNameInd].direction = hebrewCheck.test(obj.nameText.text) ? 'rtl' : 'ltr';
                            } else canvas._objects[imgInd]._objects[sInd].set('text', shirtNum)
                            if (!canvas._objects[objNameInd].text.length) canvas._objects[objNameInd].opacity = 0;
                            else canvas._objects[objNameInd].opacity = 1;
                            setPlayerName(pName);
                            setShirtNumber(shirtNum);
                            setObjectText(e.target.value)
                            modifiedObject({ target: canvas._objects[objNameInd], action: 'text-changed' })

                        }
                    }
                    break;
                case 'image':
                    if (obj.objecttype === 'sprite-image') {
                        let objNameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
                        if (e.target.id === 'input_player' && objNameInd > -1) {
                            canvas._objects[objNameInd].text = e.target.value;
                            setPlayerName(e.target.value);
                            obj.nameText.text = e.target.value;
                            setObjectText(e.target.value)
                            if (e.target.value.length === 0) {
                                canvas._objects[objNameInd].evented = false;
                                canvas._objects[objNameInd].opacity = 0
                            } else {
                                canvas._objects[objNameInd].evented = true;
                                canvas._objects[objNameInd].opacity = 1;
                            }
                            modifiedObject({ target: canvas._objects[objNameInd], action: 'text-changed' })
                        }
                    }
                    break;
            }

            modifiedObject({ target: obj, action: 'text-changed' })
            canvas.renderAll()
        }
    }




    const handleTextObject = () => {
        dispatch(setColorPopup(false))
        const obj = canvas.getActiveObject();
        if (obj) {
            let text;
            let pName;
            let shirtNum;
            switch (obj.name) {
                case "player":
                    text = obj._objects[obj._objects.length - 2].text
                    if(isTri(obj) || obj.isSvg) text = obj._objects[obj._objects.length - 1].text
                    let plNameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
                    if (plNameInd > -1) {
                        pName = canvas._objects[plNameInd].text;
                    }
                    setObjectText(text);
                    setPlayerName(pName);
                    setImgText(false);
                    setTextSelection(false);
                    if (canvas._objects[plNameInd].fontWeight === 'bold') setToggleBold(true)
                    else {
                        setToggleBold(false)
                    }
                    break;
                case "image":
                    setImgText(false);
                    if (obj.objecttype === 'sprite-image') {
                        setPlayerText(true)
                        let objNameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
                        if (objNameInd > -1) {
                            text = canvas._objects[objNameInd].text;
                        }
                        setObjectText(text);
                        setTextSelection(false);
                        if (canvas._objects[objNameInd].fontWeight === 'bold') setToggleBold(true)
                        else {
                            setToggleBold(false)
                        }
                    }
                    break;
                case "text":
                    text = obj
                    setObjectText(text.text)
                    setImgText(false);
                    setTextSelection(true);
                    if (obj.fontWeight === 'bold') setToggleBold(true)
                    else {
                        setToggleBold(false)
                    }
                    if (obj.backgroundColor) {
                        dispatch(setBackgroundOpacity(true))
                    } else {
                        dispatch(setBackgroundOpacity(false))
                    }
                    break;
                case "player_custom_image":
                    setImgText(true);
                    let objNameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
                    let objShirtIndex = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === obj.ref_id);
                    if (objNameInd > -1 && objShirtIndex > -1) {
                        text = canvas._objects[objNameInd].text;
                        text = text.split('.').join(' ');
                        shirtNum = canvas._objects[objShirtIndex]._objects.find(f => f.name === 'custom_image_shirtno').text;
                    }
                    if (typeof (obj._objects[0].fill) === 'object') {
                        setPlayerName(obj.nameText.text);
                    } else {
                        setPlayerName(text)
                    }
                    setShirtNumber(shirtNum);
                    setTextSelection(false);
                    if (canvas._objects[objNameInd].fontWeight === 'bold') setToggleBold(true)
                    else {
                        setToggleBold(false)
                    }
                    break;
            }
        }
    }
    const changeColor = () => {
        setMenuItemPanel('3')
        let actObject = canvas.getActiveObject()
        dispatch(setSelectedColor(''));
        if (actObject.name === 'free-shape' || actObject.name === 'shape') {
            dispatch(setColorPopup({
                flag: !isColorPopup.flag,
                type: 'ind_object',
                freeShape: true,
                text: false,
                imgWithText: false,
                showHighlight: false
            }))
        } else if (actObject.name === 'text') {
            dispatch(setColorPopup({
                flag: !isColorPopup.flag,
                type: 'ind_object',
                freeShape: false,
                text: true,
                imgWithText: false,
                showHighlight: false
            }))
        } else if (actObject.name === "player" || (actObject.objecttype === "sprite-image" && actObject.name === 'image') || actObject.name === "player_custom_image") {
            dispatch(setColorPopup({
                flag: !isColorPopup.flag,
                type: 'ind_object',
                freeShape: false,
                text: false,
                imgWithText: true,
                showHighlight: false
            }))
        } else {
            dispatch(setColorPopup({
                flag: !isColorPopup.flag,
                type: 'ind_object',
                freeShape: false,
                text: false,
                imgWithText: false,
                showHighlight: false
            }))
        }
    }

    const setHighlighter = () => {
        setMenuItemPanel('9')
        dispatch(setSelectedColor(''));
        dispatch(setColorPopup({
            flag: !isColorPopup.flag,
            type: 'highlight',
            freeShape: false,
            text: false,
            imgWithText: false,
            showHighlight: true
        }))
    }

    const sendForward = () => {
        let obj = canvas.getActiveObject();
        let pX = canvas._objects.findIndex(f => f.name === 'pX' && f.ref_id === `${obj.ref_id}::active`);
        if (obj) {
            if (obj.name === "square1" || obj.name === "square2") {
                let objLine = obj.line
                canvas.bringForward(objLine);
                canvas.bringForward(obj)
                modifiedObject({ target: objLine, state: true })
            } else if (obj.name === 'line') {
                canvas.bringForward(obj);
                if (obj.pa) canvas.bringForward(obj.pa);
                canvas.bringForward(obj.p0)
                canvas.bringForward(obj.p1)
                if (pX) canvas.bringForward(canvas._objects[pX]);
                canvas.bringForward(obj.p2)
                modifiedObject({ target: obj, state: true })
            } else if (obj.name && obj.name.includes("custom_image") || obj.name === 'player' || obj.objecttype === 'sprite-image') {
                customImageSendFB(obj, 'front')
                modifiedObject({ target: obj, state: true })
            } else if (obj.name === 'arrow_line') {
                let sq1 = returnObject(obj.ref_id, 'square1');
                let sq2 = returnObject(obj.ref_id, 'square2');
                canvas.bringForward(obj);
                canvas.bringForward(sq1);
                canvas.bringForward(sq2);
            } else {
                canvas.bringForward(obj);
                modifiedObject({ target: obj, state: true })
            }
            canvas.renderAll()
        }
    }

    const sendBackward = () => {
        let obj = canvas.getActiveObject();
        let pX = canvas._objects.findIndex(f => f.name === 'pX' && f.ref_id === `${obj.ref_id}::active`);
        if (obj) {
            if (obj.name === "square1" || obj.name === "square2") {
                obj = obj.line;
                canvas.sendBackwards(obj);
                modifiedObject({ target: obj, state: true })
            } else if (obj.name && obj.name.includes("custom_image") || obj.name === 'player' || obj.objecttype === 'sprite-image') {
                customImageSendFB(obj)
                modifiedObject({ target: obj, state: true })
            } else if (obj.name === 'line') {
                canvas.sendBackwards(obj.p2)
                canvas.sendBackwards(obj.p1)
                if (pX) canvas.sendBackwards(canvas._objects[pX]);
                canvas.sendBackwards(obj.p0)
                if (obj.pa) canvas.sendBackwards(obj.pa);
                canvas.sendBackwards(obj);
                modifiedObject({ target: obj, state: true })
            } else {
                canvas.sendBackwards(obj);
                modifiedObject({ target: obj, state: true })
            }
            canvas.renderAll()
        }
    }

    const customImageSendFB = (customImage, action = 'back') => {
        let refId = customImage.ref_id;
        if (!refId) return;
        let imgArr = canvas._objects.filter(f => f.ref_id.includes(customImage.ref_id));
        if (imgArr.length) {
            if (action === "front") {
                for (let i = 0; i < imgArr.length; i++) {
                    canvas.bringToFront(imgArr[i]);
                }
            } else {
                for (let i = 0; i < imgArr.length; i++) {
                    canvas.sendBackwards(imgArr[i]);
                }
            }
        }
        canvas.renderAll();
    }

    const returnObject = (ref_id, name) => {
        for (var i = 0; i < canvas._objects.length; i++) {
            var obj = canvas._objects[i]
            if (obj.ref_id === ref_id && obj.name === name) {
                return obj
            }
        }
    }

    const setLineDimension = () => {
        if (setConnect) {
            activeSelectionConnectionLine(canvas)
        } else {
            return
        }
    }

    const setTextDimensionOnSize = (obj) => {
        if (obj.name === "player" || obj.objecttype === "sprite-image" || obj.name === 'player_custom_image') {
            let imgIndex = canvas._objects.findIndex(f=>f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
            if(imgIndex>-1) obj.setTextPosition(canvas._objects[imgIndex],canvas);
        }
    }
    const updateSize = (state) => {
        const obj = canvas.getActiveObject();
        if (obj) {
            if (obj.objecttype !== "arrow_line" && obj.objecttype !== "simple_line" && obj.objecttype !== "curve_line") {
                let sizeX
                let sizeY
                switch (state) {
                    case "increase":
                        sizeX = obj.scaleX + percentageIncrease(obj.scaleX)
                        sizeY = obj.scaleY + percentageIncrease(obj.scaleY)
                        setTextDimensionOnSize(obj)
                        setLineDimension()
                        break;
                    case "decrease":
                        sizeX = obj.scaleX - percentageIncrease(obj.scaleX)
                        sizeY = obj.scaleY - percentageIncrease(obj.scaleY)
                        setTextDimensionOnSize(obj)
                        setLineDimension()
                        break;
                    default:
                        if (obj.name === "drawLine" || obj.name === "shape") {
                            if (obj.objecttype === "star") {
                                obj.scaleToHeight(obj.initialSize);
                                canvas.renderAll()
                                modifiedObject({ target: obj })
                            } else {
                                sizeX = 1
                                sizeY = 1
                            }
                        } else if (obj.name === "player" || obj.name === "image") {
                            obj.scaleToHeight(obj.initialSize);
                            canvas.renderAll()
                            modifiedObject({ target: obj })
                        }
                        break;
                }

                if (sizeX > 0 && sizeY > 0) {
                    obj.set("scaleX", sizeX)
                    obj.set("scaleY", sizeY)
                    canvas.renderAll()
                    modifiedObject({ target: obj })
                }
            }
        }
    }
    const percentageIncrease = (num) => {
        return parseFloat((5 / 100) * num)
    }
    const toolgeTextModel = () => {
        setPlayerText(false);
        setTextSelection(false);
        dispatch(setEditingMode(false))

    }
    const updateOpacity = (state) => {
        const obj = canvas.getActiveObject();
        if (!obj) return;
        if (obj.type === 'activeSelection') return
        if (obj) {
            if (obj.name === 'player' || obj.name === 'image') {
                let frInd = canvas._objects.findIndex(o => o.ref_id === obj.ref_id && o.name.includes('custom_image_name'));
                let textObj, textOpc
                if (frInd > -1) {
                    textObj = canvas._objects[frInd]
                    textOpc = textObj.opacity
                }
                if (state > .1 && state <= 1) {
                    if (textOpc && textOpc > 0) textObj.set("opacity", state);
                    obj.set("opacity", state);
                    obj.set('bgOpacity', state);
                    obj.newOpacity = state
                }
                canvas.renderAll()
                modifiedObject({ target: obj })
            } else if (obj.name === "free-shape" || obj.name === "shape") {
                let tempFill = obj.fill
                if (tempFill === 'White') tempFill = '#fff';
                let newColor = hexToRgbA(tempFill, state);
                obj.set('fill', newColor);
                obj.set('bgOpacity', state)
                canvas.renderAll()
                modifiedObject({ target: obj })
            } else if (obj.name === 'text') {
                let tempFill = obj.backgroundColor
                if (tempFill === 'White') tempFill = '#fff';
                let newColor = hexToRgbA(tempFill, state);
                obj.set('backgroundColor', newColor);
                obj.set('bgOpacity', state)
                canvas.renderAll()
                modifiedObject({ target: obj })
            } else if (obj.name.includes('custom_image')) {
                let objects = canvas._objects
                let OpcArray = [];
                for (let i = 0; i <= objects.length; i++) {
                    if (objects[i]?.ref_id === obj?.ref_id && objects[i]?.name !== 'custom_image_shirtno') {
                        OpcArray.push(objects[i])
                    }
                }
                if (state > .1 && state <= 1) {
                    OpcArray.forEach((obj) => {
                        obj.set("opacity", state)
                        obj.newOpacity = state
                    })
                }
                canvas.renderAll()
                modifiedObject({ target: obj })
            } else if (obj.name === 'line' || obj.name === 'arrow_line' || obj.name === 'drawLine' || obj.name === 'Line Arrow') {
                let objects = canvas._objects;
                for (let i = 0; i < objects.length; i++) {
                    if (objects[i].ref_id === obj.ref_id && (objects[i].name !== 'p0' && objects[i].name !== 'p1' && objects[i].name !== 'p2') && objects[i].name !== 'pX' && objects[i].name !== 'square1' && objects[i].name !== 'square2') {
                        objects[i].set("opacity", state);
                        objects[i].set('bgOpacity', state);
                        objects[i].set('newOpacity', state);
                    }
                }
                canvas.renderAll();
                // modifiedObject({target: obj})
            }
            dispatch(setOpacity(state))
        }
    }

    const togglingBold = () => {
        let obj = canvas.getActiveObject()
        if (obj.type === 'i-text') {
            if (obj.fontWeight === 'bold') {
                setToggleBold(false)
                obj.set({
                    fontWeight: '500'
                })
            } else {
                setToggleBold(true)
                obj.set({
                    fontWeight: 'bold'
                })
            }
        } else if (obj.name === 'player_custom_image' || obj.objecttype === 'sprite-image' || obj.name === "player") {
            let frInd = canvas._objects.findIndex(o => o.ref_id === obj.ref_id && o.name.includes('custom_image_name'));
            if (frInd > -1) {
                let obj = canvas._objects[frInd]
                if (obj.fontWeight === 'bold') {
                    setToggleBold(false)
                    obj.set({
                        fontWeight: 'normal'
                    })
                } else {
                    setToggleBold(true)
                    obj.set({
                        fontWeight: 'bold'
                    })
                }
            }
        }
        canvas.renderAll()
    }
    const connectObjects = () => {
        let ref_id;
        let obj = canvas.getActiveObjects().filter((f) => f.name === 'player' || f.name === 'player_custom_image' || f.objecttype === 'sprite-image')
        if (obj && obj.length > 1 && obj.length <= 2) {
            let lines = canvas._objects.filter((f) => f.name === 'connectionLine' && f.ref_id.includes(obj[0].ref_id) && f.ref_id.includes(obj[1].ref_id))
            if (lines.length) {
                return
            } else {
                ref_id = obj[0].ref_id + '_' + obj[1].ref_id;
                let from = obj[0].calcTransformMatrix();
                let to = obj[1].calcTransformMatrix();
                let line = makeLine([from[4], from[5], to[4], to[5]], ref_id);
                canvas.add(line);
                line.sendToBack()
                canvas.renderAll()
            }
        }
    }
    const makeLine = (coords, ref_id) => {
        return new fabric.Line(coords, {
            name: 'connectionLine',
            ref_id: ref_id,
            fill: whitePitch ? '#000' : '#fff',
            stroke: whitePitch ? '#000' : '#fff',
            strokeWidth: 4,
            selectable: false
        });
    }
    const updateLineWidth = (state) => {
        const obj = canvas.getActiveObject()
        if (obj) {
            if (obj.objecttype === "arrow_line" || obj.objecttype === "simple_line" || obj.objecttype === "curve_line") {
                let size;
                let arrowHeight;
                let arrowWidth;
                let arrow;
                let lineArrow = canvas._objects.findIndex(f => (f.name === 'pa' || f.name === 'arrow') && f.ref_id === obj.ref_id);

                if (obj.name === 'p0' || obj.name === 'p1' || obj.name === 'p2' || obj.name === 'line') {
                    arrow = returnObject(obj.ref_id, "line")
                } else if (obj.name === 'square1' || obj.name === 'square2') {
                    arrow = obj.line
                } else {
                    arrow = obj
                }
                switch (state) {
                    case "l0":
                        if (arrow.widthFactor === 'l1') {
                            size = arrow.strokeWidth - 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 2;
                                arrowWidth = canvas._objects[lineArrow].width - 2;
                                canvas._objects[lineArrow].widthFactor = 'l0'
                            }
                            arrow.widthFactor = 'l0'
                        }
                        else if (arrow.widthFactor === 'l2') {
                            size = arrow.strokeWidth - 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 4;
                                arrowWidth = canvas._objects[lineArrow].width - 4;
                                canvas._objects[lineArrow].widthFactor = 'l0'
                            }
                            arrow.widthFactor = 'l0'
                        } else if (arrow.widthFactor === 'l3') {
                            size = arrow.strokeWidth - 3;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 6;
                                arrowWidth = canvas._objects[lineArrow].width - 6;
                                canvas._objects[lineArrow].widthFactor = 'l0'
                            }
                            arrow.widthFactor = 'l0'
                        } else if (arrow.widthFactor === 'l4') {
                            size = arrow.strokeWidth - 4;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 8;
                                arrowWidth = canvas._objects[lineArrow].width - 8;
                                canvas._objects[lineArrow].widthFactor = 'l0'
                            }
                            arrow.widthFactor = 'l0'
                        } else if (arrow.widthFactor === 'l5') {
                            size = arrow.strokeWidth - 5;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 10;
                                arrowWidth = canvas._objects[lineArrow].width - 10;
                                canvas._objects[lineArrow].widthFactor = 'l0'
                            }
                            arrow.widthFactor = 'l0'
                        }
                        break;
                    case "l1":
                        if (arrow.widthFactor === 'l0') {
                            size = arrow.strokeWidth + 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 2;
                                arrowWidth = canvas._objects[lineArrow].width + 2;
                                canvas._objects[lineArrow].widthFactor = 'l1'
                            }
                            arrow.widthFactor = 'l1'
                        }
                        else if (arrow.widthFactor === 'l2') {
                            size = arrow.strokeWidth - 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 2;
                                arrowWidth = canvas._objects[lineArrow].width - 2;
                                canvas._objects[lineArrow].widthFactor = 'l1'
                            }
                            arrow.widthFactor = 'l1'
                        } else if (arrow.widthFactor === 'l3') {
                            size = arrow.strokeWidth - 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 4;
                                arrowWidth = canvas._objects[lineArrow].width - 4;
                                canvas._objects[lineArrow].widthFactor = 'l1'
                            }
                            arrow.widthFactor = 'l1'
                        } else if (arrow.widthFactor === 'l4') {
                            size = arrow.strokeWidth - 3;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 6;
                                arrowWidth = canvas._objects[lineArrow].width - 6;
                                canvas._objects[lineArrow].widthFactor = 'l1'
                            }
                            arrow.widthFactor = 'l1'
                        } else if (arrow.widthFactor === 'l5') {
                            size = arrow.strokeWidth - 4;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 8;
                                arrowWidth = canvas._objects[lineArrow].width - 8;
                                canvas._objects[lineArrow].widthFactor = 'l1'
                            }
                            arrow.widthFactor = 'l1'
                        }
                        break;
                    case "l2":
                        if (arrow.widthFactor === 'l0') {
                            size = arrow.strokeWidth + 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 4;
                                arrowWidth = canvas._objects[lineArrow].width + 4;
                                canvas._objects[lineArrow].widthFactor = 'l2'
                            }
                            arrow.widthFactor = 'l2'
                        }
                        else if (arrow.widthFactor === 'l1') {
                            size = arrow.strokeWidth + 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 2;
                                arrowWidth = canvas._objects[lineArrow].width + 2;
                                canvas._objects[lineArrow].widthFactor = 'l2'
                            }
                            arrow.widthFactor = 'l2'
                        } else if (arrow.widthFactor === 'l3') {
                            size = arrow.strokeWidth - 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 2;
                                arrowWidth = canvas._objects[lineArrow].width - 2;
                                canvas._objects[lineArrow].widthFactor = 'l2'
                            }
                            arrow.widthFactor = 'l2'
                        } else if (arrow.widthFactor === 'l4') {
                            size = arrow.strokeWidth - 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 4;
                                arrowWidth = canvas._objects[lineArrow].width - 4;
                                canvas._objects[lineArrow].widthFactor = 'l2'
                            }
                            arrow.widthFactor = 'l2'
                        } else if (arrow.widthFactor === 'l5') {
                            size = arrow.strokeWidth - 3;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 6;
                                arrowWidth = canvas._objects[lineArrow].width - 6;
                                canvas._objects[lineArrow].widthFactor = 'l2'
                            }
                            arrow.widthFactor = 'l2'
                        }
                        break;
                    case "l3":
                        if (arrow.widthFactor === 'l0') {
                            size = arrow.strokeWidth + 3;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 6;
                                arrowWidth = canvas._objects[lineArrow].width + 6;
                                canvas._objects[lineArrow].widthFactor = 'l3'
                            }
                            arrow.widthFactor = 'l3'
                        }
                        else if (arrow.widthFactor === 'l1') {
                            size = arrow.strokeWidth + 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 4;
                                arrowWidth = canvas._objects[lineArrow].width + 4;
                                canvas._objects[lineArrow].widthFactor = 'l3'
                            }
                            arrow.widthFactor = 'l3'
                        } else if (arrow.widthFactor === 'l2') {
                            size = arrow.strokeWidth + 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 2;
                                arrowWidth = canvas._objects[lineArrow].width + 2;
                                canvas._objects[lineArrow].widthFactor = 'l3'
                            }
                            arrow.widthFactor = 'l3'
                        } else if (arrow.widthFactor === 'l4') {
                            size = arrow.strokeWidth - 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 2;
                                arrowWidth = canvas._objects[lineArrow].width - 2;
                                canvas._objects[lineArrow].widthFactor = 'l3'
                            }
                            arrow.widthFactor = 'l3'
                        } else if (arrow.widthFactor === 'l5') {
                            size = arrow.strokeWidth - 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 4;
                                arrowWidth = canvas._objects[lineArrow].width - 4;
                                canvas._objects[lineArrow].widthFactor = 'l4'
                            }
                            arrow.widthFactor = 'l4'
                        }
                        break;
                    case "l4":
                        if (arrow.widthFactor === 'l0') {
                            size = arrow.strokeWidth + 4;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 8;
                                arrowWidth = canvas._objects[lineArrow].width + 8;
                                canvas._objects[lineArrow].widthFactor = 'l4'
                            }
                            arrow.widthFactor = 'l4'
                        } else if (arrow.widthFactor === 'l1') {
                            size = arrow.strokeWidth + 3;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 6;
                                arrowWidth = canvas._objects[lineArrow].width + 6;
                                canvas._objects[lineArrow].widthFactor = 'l4'
                            }
                            arrow.widthFactor = 'l4'
                        } else if (arrow.widthFactor === 'l2') {
                            size = arrow.strokeWidth + 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 4;
                                arrowWidth = canvas._objects[lineArrow].width + 4;
                                canvas._objects[lineArrow].widthFactor = 'l4'
                            }
                            arrow.widthFactor = 'l4'
                        } else if (arrow.widthFactor === 'l3') {
                            size = arrow.strokeWidth + 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 2;
                                arrowWidth = canvas._objects[lineArrow].width + 2;
                                canvas._objects[lineArrow].widthFactor = 'l4'
                            }
                            arrow.widthFactor = 'l4'
                        } else if (arrow.widthFactor === 'l5') {
                            size = arrow.strokeWidth - 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height - 2;
                                arrowWidth = canvas._objects[lineArrow].width - 2;
                                canvas._objects[lineArrow].widthFactor = 'l4'
                            }
                            arrow.widthFactor = 'l4'
                        }
                        break;
                    case 'l5':
                        if (arrow.widthFactor === 'l0') {
                            size = arrow.strokeWidth + 5;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 10;
                                arrowWidth = canvas._objects[lineArrow].width + 10;
                                canvas._objects[lineArrow].widthFactor = 'l5'
                            }
                            arrow.widthFactor = 'l5'
                        } else if (arrow.widthFactor === 'l1') {
                            size = arrow.strokeWidth + 4;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 8;
                                arrowWidth = canvas._objects[lineArrow].width + 8;
                                canvas._objects[lineArrow].widthFactor = 'l5'
                            }
                            arrow.widthFactor = 'l5'
                        } else if (arrow.widthFactor === 'l2') {
                            size = arrow.strokeWidth + 3;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 6;
                                arrowWidth = canvas._objects[lineArrow].width + 6;
                                canvas._objects[lineArrow].widthFactor = 'l5'
                            }
                            arrow.widthFactor = 'l5'
                        } else if (arrow.widthFactor === 'l3') {
                            size = arrow.strokeWidth + 2;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 4;
                                arrowWidth = canvas._objects[lineArrow].width + 4;
                                canvas._objects[lineArrow].widthFactor = 'l5'
                            }
                            arrow.widthFactor = 'l5'
                        } else if (arrow.widthFactor === 'l4') {
                            size = arrow.strokeWidth + 1;
                            if (lineArrow > -1) {
                                arrowHeight = canvas._objects[lineArrow].height + 2;
                                arrowWidth = canvas._objects[lineArrow].width + 2;
                                canvas._objects[lineArrow].widthFactor = 'l5'
                            }
                            arrow.widthFactor = 'l5'
                        }
                        break

                    default:
                        size = strokeWidth
                        break;
                }

                if (size > 0) {
                    arrow.set("strokeWidth", size);
                    if (lineArrow > -1) {
                        canvas._objects[lineArrow].set('height', arrowHeight);
                        canvas._objects[lineArrow].set('width', arrowWidth);
                    }
                    canvas.renderAll()
                    modifiedObject({ target: arrow, state: true, case: 'width' })
                }
            } else if (obj.name === 'Line Arrow') {
                let size = '';
                let col = obj.color;
                switch (state) {
                    case "l0":
                        size = "l0";
                        import(`../../assets/sinWave-${col}/sinWave-${size}-${col}.png`).then(img => {
                            fabric.util.loadImage(img.default, function (img) {
                                obj.set('stroke', new fabric.Pattern({
                                    source: img,
                                    repeat: 'repeat-x'
                                }))
                                if (obj.size === 'l1') obj.set('arrowSize', obj.arrowSize - 2)
                                else if (obj.size === 'l2') obj.set('arrowSize', obj.arrowSize - 4)
                                else if (obj.size === 'l3') obj.set('arrowSize', obj.arrowSize - 6)
                                else if (obj.size === 'l4') obj.set('arrowSize', obj.arrowSize - 8)
                                else if (obj.size === 'l5') obj.set('arrowSize', obj.arrowSize - 10)
                                obj.set('size', size);

                                canvas.renderAll();
                            }, { crossOrigin: 'anonymous' });
                        })
                        break;
                    case "l1":
                        size = "l1";
                        import(`../../assets/sinWave-${col}/sinWave-${size}-${col}.png`).then(img => {
                            fabric.util.loadImage(img.default, function (img) {
                                obj.set('stroke', new fabric.Pattern({
                                    source: img,
                                    repeat: 'repeat-x'
                                }))
                                if (obj.size === 'l0') obj.set('arrowSize', obj.arrowSize + 2)
                                else if (obj.size === 'l2') obj.set('arrowSize', obj.arrowSize - 2)
                                else if (obj.size === 'l3') obj.set('arrowSize', obj.arrowSize - 4)
                                else if (obj.size === 'l4') obj.set('arrowSize', obj.arrowSize - 6)
                                else if (obj.size === 'l5') obj.set('arrowSize', obj.arrowSize - 8)
                                obj.set('size', size);

                                canvas.renderAll();
                            }, { crossOrigin: 'anonymous' });
                        })
                        break;
                    case 'l2':
                        size = "l2";
                        import(`../../assets/sinWave-${col}/sinWave-${size}-${col}.png`).then(img => {
                            fabric.util.loadImage(img.default, function (img) {
                                obj.set('stroke', new fabric.Pattern({
                                    source: img,
                                    repeat: 'repeat-x'
                                }))
                                if (obj.size === 'l0') obj.set('arrowSize', obj.arrowSize + 4)
                                else if (obj.size === 'l1') obj.set('arrowSize', obj.arrowSize + 2)
                                else if (obj.size === 'l3') obj.set('arrowSize', obj.arrowSize - 2)
                                else if (obj.size === 'l4') obj.set('arrowSize', obj.arrowSize - 4)
                                else if (obj.size === 'l5') obj.set('arrowSize', obj.arrowSize - 6)
                                obj.set('size', size);
                                canvas.renderAll();
                            }, { crossOrigin: 'anonymous' });
                        })
                        break;
                    case "l3":
                        size = "l3";
                        import(`../../assets/sinWave-${col}/sinWave-${size}-${col}.png`).then(img => {
                            fabric.util.loadImage(img.default, function (img) {
                                obj.set('stroke', new fabric.Pattern({
                                    source: img,
                                    repeat: 'repeat-x'
                                }))
                                if (obj.size === 'l0') obj.set('arrowSize', obj.arrowSize + 6)
                                else if (obj.size === 'l1') obj.set('arrowSize', obj.arrowSize + 4)
                                else if (obj.size === 'l2') obj.set('arrowSize', obj.arrowSize + 2)
                                else if (obj.size === 'l4') obj.set('arrowSize', obj.arrowSize - 2)
                                else if (obj.size === 'l5') obj.set('arrowSize', obj.arrowSize - 4)
                                obj.set('size', size);
                                canvas.renderAll();
                            }, { crossOrigin: 'anonymous' });
                        })
                        break;
                    case "l4":
                        size = "l4";
                        import(`../../assets/sinWave-${col}/sinWave-${size}-${col}.png`).then(img => {
                            fabric.util.loadImage(img.default, function (img) {
                                obj.set('stroke', new fabric.Pattern({
                                    source: img,
                                    repeat: 'repeat-x'
                                }))
                                if (obj.size === 'l0') obj.set('arrowSize', obj.arrowSize + 8)
                                else if (obj.size === 'l1') obj.set('arrowSize', obj.arrowSize + 6)
                                else if (obj.size === 'l2') obj.set('arrowSize', obj.arrowSize + 4)
                                else if (obj.size === 'l3') obj.set('arrowSize', obj.arrowSize + 2)
                                else if (obj.size === 'l5') obj.set('arrowSize', obj.arrowSize - 2)
                                obj.set('size', size);
                                canvas.renderAll();
                            }, { crossOrigin: 'anonymous' });
                        })
                        break;
                    case "l5":
                        size = "l5";
                        import(`../../assets/sinWave-${col}/sinWave-${size}-${col}.png`).then(img => {
                            fabric.util.loadImage(img.default, function (img) {
                                obj.set('stroke', new fabric.Pattern({
                                    source: img,
                                    repeat: 'repeat-x'
                                }))
                                if (obj.size === 'l0') obj.set('arrowSize', obj.arrowSize + 10)
                                else if (obj.size === 'l1') obj.set('arrowSize', obj.arrowSize + 8)
                                else if (obj.size === 'l2') obj.set('arrowSize', obj.arrowSize + 6)
                                else if (obj.size === 'l3') obj.set('arrowSize', obj.arrowSize + 4)
                                else if (obj.size === 'l4') obj.set('arrowSize', obj.arrowSize + 2)
                                obj.set('size', size);
                                canvas.renderAll();
                            }, { crossOrigin: 'anonymous' });
                        })
                        break;
                }
            } else if (obj.name === 'drawLine') {
                let cObj = canvas._objects.find(f => f.name === 'line-end-point_shadow-object' && f.ref_id === obj.ref_id);
                switch (state) {
                    case "l0":
                        if (cObj.widthFactor === 'l1') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 1);
                                    tObj.set('height', tObj.height - 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 1);
                                    // tObj.set('height',tObj.height -1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 1);
                                }
                            })
                            cObj.widthFactor = 'l0'
                        } else if (cObj.widthFactor === 'l2') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 2);
                                    tObj.set('height', tObj.height - 2);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 2);
                                    // tObj.set('height',tObj.height -1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 2);
                                }
                            })
                            cObj.widthFactor = 'l0'
                        } else if (cObj.widthFactor === 'l3') {

                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 3);
                                    tObj.set('height', tObj.height - 3);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 3);
                                    // tObj.set('height',tObj.height -2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 3);
                                }
                            })
                            cObj.widthFactor = 'l0'
                        } else if (cObj.widthFactor === 'l4') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 4);
                                    tObj.set('height', tObj.height - 4);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 4);
                                    // tObj.set('height',tObj.height -3);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 4);
                                }
                            })
                            cObj.widthFactor = 'l0'
                        } else if (cObj.widthFactor === 'l5') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 5);
                                    tObj.set('height', tObj.height - 5);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 5);
                                    // tObj.set('height',tObj.height -4);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 5);
                                }
                            })
                            cObj.widthFactor = 'l0'
                        }
                        break;
                    case "l1":
                        if (cObj.widthFactor === 'l0') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 1);
                                    tObj.set('height', tObj.height + 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 1);
                                    // tObj.set('height',tObj.height -1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 1);
                                }
                            })
                            cObj.widthFactor = 'l1'
                        }
                        else if (cObj.widthFactor === 'l2') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 1);
                                    tObj.set('height', tObj.height - 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 1);
                                    // tObj.set('height',tObj.height -1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 1);
                                }
                            })
                            cObj.widthFactor = 'l1'
                        } else if (cObj.widthFactor === 'l3') {

                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 2);
                                    tObj.set('height', tObj.height - 2);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 2);
                                    // tObj.set('height',tObj.height -2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 2);
                                }
                            })
                            cObj.widthFactor = 'l1'
                        } else if (cObj.widthFactor === 'l4') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 3);
                                    tObj.set('height', tObj.height - 3);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 3);
                                    // tObj.set('height',tObj.height -3);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 3);
                                }
                            })
                            cObj.widthFactor = 'l1'
                        } else if (cObj.widthFactor === 'l5') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 4);
                                    tObj.set('height', tObj.height - 4);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 4);
                                    // tObj.set('height',tObj.height -4);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 4);
                                }
                            })
                            cObj.widthFactor = 'l1'
                        }
                        break;
                    case "l2":
                        if (cObj.widthFactor === 'l0') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 2);
                                    tObj.set('height', tObj.height + 2)
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 2)
                                    // tObj.set('height', tObj.height + 1)
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 2);
                                }


                            })
                            cObj.widthFactor = 'l2'
                        } else if (cObj.widthFactor === 'l1') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 1);
                                    tObj.set('height', tObj.height + 1)
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 1)
                                    // tObj.set('height', tObj.height + 1)
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 1);
                                }


                            })
                            cObj.widthFactor = 'l2'
                        } else if (cObj.widthFactor === 'l3') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 1);
                                    tObj.set('height', tObj.height - 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 1);
                                    // tObj.set('height',tObj.height -1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 1);
                                }

                            })
                            cObj.widthFactor = 'l2'
                        } else if (cObj.widthFactor === 'l4') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 2);
                                    tObj.set('height', tObj.height - 2);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 2);
                                    // tObj.set('height',tObj.height -2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 2);
                                }
                            })
                            cObj.widthFactor = 'l2'
                        } else if (cObj.widthFactor === 'l5') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 3);
                                    tObj.set('height', tObj.height - 3);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 3);
                                    // tObj.set('height',tObj.height -3);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 3);
                                }
                            })
                            cObj.widthFactor = 'l2'
                        }
                        break;
                    case "l3":
                        if (cObj.widthFactor === 'l0') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 3);
                                    tObj.set('height', tObj.height + 3);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 3);
                                    // tObj.set('height',tObj.height +2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 3);
                                }
                            })
                            cObj.widthFactor = 'l3'
                        } else if (cObj.widthFactor === 'l1') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 2);
                                    tObj.set('height', tObj.height + 2);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 2);
                                    // tObj.set('height',tObj.height +2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 2);
                                }
                            })
                            cObj.widthFactor = 'l3'
                        } else if (cObj.widthFactor === 'l2') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 1);
                                    tObj.set('height', tObj.height + 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 1);
                                    // tObj.set('height',tObj.height +1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 1);
                                }
                            })
                            cObj.widthFactor = 'l3'
                        } else if (cObj.widthFactor === 'l4') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 1);
                                    tObj.set('height', tObj.height - 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 1);
                                    // tObj.set('height',tObj.height -1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 1);
                                }
                            })
                            cObj.widthFactor = 'l3'
                        } else if (cObj.widthFactor === 'l5') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 2);
                                    tObj.set('height', tObj.height - 2);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 2);
                                    // tObj.set('height',tObj.height -2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 2);
                                }
                            })
                            cObj.widthFactor = 'l4'
                        }
                        break;
                    case "l4":
                        if (cObj.widthFactor === 'l0') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 4);
                                    tObj.set('height', tObj.height + 4);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 4);
                                    // tObj.set('height',tObj.height + 3);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 4);
                                }
                            })
                            cObj.widthFactor = 'l4'
                        }
                        else if (cObj.widthFactor === 'l1') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 3);
                                    tObj.set('height', tObj.height + 3);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 3);
                                    // tObj.set('height',tObj.height + 3);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 3);
                                }
                            })
                            cObj.widthFactor = 'l4'
                        } else if (cObj.widthFactor === 'l2') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 2);
                                    tObj.set('height', tObj.height + 2);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 2);
                                    // tObj.set('height',tObj.height +2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 2);
                                }
                            })
                            cObj.widthFactor = 'l4'
                        } else if (cObj.widthFactor === 'l3') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 1);
                                    tObj.set('height', tObj.height + 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 1);
                                    // tObj.set('height', tObj.height + 1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 1);
                                }
                            })
                            cObj.widthFactor = 'l4'
                        } else if (cObj.widthFactor === 'l5') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width - 1);
                                    tObj.set('height', tObj.height - 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width - 1);
                                    // tObj.set('height', tObj.height - 1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth - 1)
                                }
                            })
                            cObj.widthFactor = 'l4'
                        }
                        break;
                    case 'l5':
                        if (cObj.widthFactor === 'l0') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 5);
                                    tObj.set('height', tObj.height + 5);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 5);
                                    // tObj.set('height',tObj.height +4);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 5);
                                }
                            })
                            cObj.widthFactor = 'l5'
                        } else if (cObj.widthFactor === 'l1') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 4);
                                    tObj.set('height', tObj.height + 4);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 4);
                                    // tObj.set('height',tObj.height +4);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 4);
                                }
                            })
                            cObj.widthFactor = 'l5'
                        } else if (cObj.widthFactor === 'l2') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 3);
                                    tObj.set('height', tObj.height + 3);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 3);
                                    // tObj.set('height',tObj.height +3);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 3);
                                }
                            })
                            cObj.widthFactor = 'l5'
                        } else if (cObj.widthFactor === 'l3') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 2);
                                    tObj.set('height', tObj.height + 2);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 2);
                                    // tObj.set('height',tObj.height +2);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 2);
                                }
                            })
                            cObj.widthFactor = 'l5'
                        } else if (cObj.widthFactor === 'l4') {
                            obj._objects.forEach(tObj => {
                                if (tObj.name === "drawElementArrow") {
                                    tObj.set('width', tObj.width + 1);
                                    tObj.set('height', tObj.height + 1);
                                } else if (obj.name === "drawElementBlock") {
                                    tObj.set('width', tObj.width + 1);
                                    // tObj.set('height',tObj.height +1);
                                } else {
                                    tObj.set('strokeWidth', tObj.strokeWidth + 1);
                                }
                            })
                            cObj.widthFactor = 'l5'
                        }
                        break

                    default:

                        break;
                }
                canvas.renderAll();
            }
        }
    }
    const updateAngle = (state) => {
        const obj = canvas.getActiveObject();
        if (obj) {
            if (obj.objecttype !== "arrow_line" && obj.objecttype !== "simple_line" && obj.objecttype !== "curve_line") {
                let angle
                switch (state) {
                    case "increase":
                        angle = obj.angle - 10
                        break;
                    case "decrease":
                        angle = obj.angle + 10
                        break;
                    default:
                        angle = 0
                        break;
                }
                obj.set("angle", angle)
                canvas.renderAll()
                modifiedObject({ target: obj })
            }
            if (obj.type === 'activeSelection') {
                activeSelectionConnectionLine(canvas)
            }
        }
    }
    const shapeBorder = (val) => {
        let obj = canvas.getActiveObject()
        if (val === 'dotted') {
            setBorderStyle(true)
            if (obj.name === 'free-shape') {
                obj.set({
                    strokeDashArray: [2, 1]
                })
            } else {
                obj.set({
                    strokeDashArray: [3, 2]
                })
            }
        } else {
            setBorderStyle(false)
            obj.set({
                strokeDashArray: null
            })
        }
        canvas.renderAll()
    }
    const fadingAnimation = (list) => {
        setCheckedList(list);
        let action = 'no-fade-out';
        if (list.length == 2) {
            action = 'fade-in-out'
        }
        else if (list.length == 1) {
            action = list[0] === 'FadeIn' ? 'fade-in-stay' : 'fade-out'
        }
        else {
            action = 'no-fade-out'
        }
        let obj = canvas.getActiveObject();
        if (obj && obj.type !== 'activeSelection') {
            if (obj.name === 'arrow_line') {
                let arrow = returnObject(obj.ref_id, 'arrow')
                arrow && arrow.set('fadeFlag', action);
            }
            else if (obj.name === 'line') {
                let pa = returnObject(obj.ref_id, 'pa')
                pa && pa.set('fadeFlag', action);
            }
            obj.set('fadeFlag', action);
        }
    }
    const setPlayerDirection = (checked) => {
        let obj = canvas.getActiveObject();
        if (obj) {
            if (obj.type === 'activeSelection') {
                obj._objects.forEach(e => {
                    if ((e.name === 'player' || e.name === 'player_custom_image') && e._objects[2]) {
                        e._objects[2].set({dirty:true});
                        e._objects[2].set({ direction: checked })
                        e.setControlsVisibility({ rotateAction: checked })
                        updateAllProps(e);
                    }
                })
            }
            else {
                obj._objects[2].set({dirty:true});
                obj._objects[2].set({direction: checked })
                obj.setControlsVisibility({ rotateAction: checked })
                updateAllProps(obj)
            }

            canvas.renderAll();
        }
        setDirection(checked);
    }
    const panelSwitch = (activeKey) => {
        switch (activeKey) {
            case '1':
                return (
                    ((objectStates.player || objectStates.text)) &&
                    <div className={'popup-content'}>
                        {!isImgSelected &&
                            <>
                                <div className="inputTextBtnWrapper">
                                    <span
                                        className='pg__form_label'>{isPlayerSelected ? 'Player Name' : objectStates.player ? <FormattedMessage id="general.playerNumber" /> : <FormattedMessage id="general.innerText" />}</span>
                                    <Input.TextArea
                                        id="input_player"
                                        value={objectText || ''}
                                        placeholder={isPlayerSelected ? 'Player Name' : objectStates.player ? <FormattedMessage id="general.playerNumber" /> : <FormattedMessage id="general.text" />}
                                        bordered={false}
                                        autoSize={{ minRows: 1, maxRows: 3 }}
                                        className='pg__form_input'
                                        onChange={updatePlayer}
                                        onFocus={() => { dispatch(setEditingMode(true)) }}
                                    />
                                    {!objectStates.player &&
                                        <button className={`boldBtn ${toggleBold ? 'bg_purple' : ''}`}
                                            onClick={togglingBold}>B</button>}
                                </div>
                                {(!isPlayerSelected && !textSelected) &&
                                    <div>
                                        <span className='pg__form_label'><FormattedMessage id="general.playerName" /></span>
                                        <Input.TextArea
                                            id="input_player-name"
                                            value={playerName}
                                            placeholder={formatMessage({ id: "Player Name" })}
                                            bordered={false}
                                            autoSize={{ minRows: 1, maxRows: 3 }}
                                            className='pg__form_input'
                                            onChange={updatePlayer}
                                            onFocus={() => { dispatch(setEditingMode(true)) }}
                                        />
                                        <button className={`boldBtn ${toggleBold ? 'bg_purple' : ''}`}
                                            onClick={togglingBold}>B
                                        </button>
                                    </div>
                                }
                            </>
                        }
                        {isImgSelected &&
                            <>
                                <div>
                                    <span className='pg__form_label'><FormattedMessage id="general.playerName" /></span>
                                    <Input.TextArea
                                        id="input_player-name"
                                        value={playerName}
                                        placeholder={formatMessage({ id: "general.text" })}
                                        bordered={false}
                                        autoSize={{ minRows: 1, maxRows: 3 }}
                                        className='pg__form_input'
                                        onChange={updatePlayer}
                                        onFocus={() => { dispatch(setEditingMode(true)) }}
                                    />
                                    <button className={`boldBtn ${toggleBold ? 'bg_purple' : ''}`}
                                        onClick={togglingBold}>B
                                    </button>
                                </div>
                                {!isPlayerSelected &&
                                    <div>
                                        <span className='pg__form_label'><FormattedMessage id="general.shirtNumber" /></span>
                                        <Input.TextArea
                                            id="input_player-shirtNo"
                                            value={shirtNumber}
                                            placeholder={formatMessage({ id: "general.text" })}
                                            bordered={false}
                                            autoSize={{ minRows: 1, maxRows: 3 }}
                                            className='pg__form_input'
                                            maxLength={2}
                                            onChange={updatePlayer}
                                            onFocus={() => { dispatch(setEditingMode(true)) }}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                )
                break;
            case '2':
                return (
                    objectStates.shape &&
                    <>
                        <div className={`${objectStates.shape ? '' : 'd__none'}`}>
                            <div className="shapeBorderStyle">
                                <input type="radio" id="s1" name="line" />
                                <label htmlFor="s1" onClick={() => shapeBorder('simple')}
                                    className={`simpleLine ${!borderStyle ? 'dottedStyle' : ''}`}>
                                    <hr />
                                </label>
                                <input type="radio" id="s2" name="line" />
                                <label htmlFor="s2" onClick={() => shapeBorder('dotted')}
                                    className={`dashedLine ${borderStyle ? 'dottedStyle' : ''}`}>
                                    <hr />
                                </label>
                            </div>
                        </div>
                    </>
                )
                break;
            case '3':
                return (
                    <ColorPopup modifiedObject={modifiedObject} />)
                break;
            case '4':
                return (
                    (objectStates.activeSelection || objectStates.object || objectStates.shape || objectStates.text || objectStates.player || objectStates.draw && !objectStates.playerImage) &&
                    <div className="angle_setting">
                        <Button className={`tacticsBoard_mainTool_icon`} onClick={() => updateAngle("decrease")}
                            icon={<SvgIcon><AngleLeftIcon /></SvgIcon>} />
                        <Button className={`tacticsBoard_mainTool_icon`}
                            onClick={() => updateAngle("reset")}><FormattedMessage id="general.reset" /></Button>
                        <Button className={`tacticsBoard_mainTool_icon`} onClick={() => updateAngle("increase")}
                            icon={<SvgIcon><AngleRightIcon /></SvgIcon>} />
                    </div>
                )
                break;
            case '5':
                return (
                    (objectStates.line || objectStates.draw) ?
                        <>
                            <Row className="line_width_setting" style={{ marginBottom: "0px" }}>
                                <Col span={8} className="tacticsboard__header_extra_item line-width-column"
                                    style={{ padding: "10px 0px" }} onClick={() => updateLineWidth("l0")}>
                                    <div className="line-width-svg-l0"></div>
                                </Col>  <Col span={8} className="tacticsboard__header_extra_item line-width-column"
                                    style={{ padding: "10px 0px" }} onClick={() => updateLineWidth("l1")}>
                                    <div className="line-width-svg-l1"></div>
                                </Col>
                                <Col span={8} className="tacticsboard__header_extra_item line-width-column"
                                    style={{ padding: "10px 0px" }} onClick={() => updateLineWidth("l2")}>
                                    <div className="line-width-svg-l2"></div>
                                </Col>
                                <Col span={8} className="tacticsboard__header_extra_item line-width-column"
                                    style={{ padding: "10px 0px" }} onClick={() => updateLineWidth("l3")}>
                                    <div className="line-width-svg-l3"></div>
                                </Col>
                                <Col span={8} className="tacticsboard__header_extra_item line-width-column"
                                    style={{ padding: "10px 0px" }} onClick={() => updateLineWidth("l4")}>
                                    <div className="line-width-svg-l4"></div>
                                </Col>
                                <Col span={8} className="tacticsboard__header_extra_item line-width-column"
                                    style={{ padding: "10px 0px" }} onClick={() => updateLineWidth("l5")}>
                                    <div className="line-width-svg-l5"></div>
                                </Col>
                            </Row>
                        </>
                        :
                        <div className="layer_setting">
                            <button onClick={() => updateSize("decrease")}><FormattedMessage id="general.decreaseSize" /></button>
                            <button onClick={() => updateSize("increase")}><FormattedMessage id="general.increaseSize" /></button>
                        </div>
                )
                break;
            case '6':
                return (
                    (!objectStates.activeSelection) &&
                    <div
                        className={`leftSide_Opacity ${objectStates.shape || objectStates.line || objectStates.playerImage || objectStates.object || objectStates.player || setBgOpacity ? '' : 'd__none'}`}>
                        <Slider min={0.0} max={1.0} value={objOpacity} step={0.2} onChange={updateOpacity} />
                    </div>
                )
                break;
            case '7':
                return (
                    <div className={`layer_setting`}>
                        <button onClick={sendForward}><FormattedMessage id="general.sendForward" /></button>
                        <button onClick={sendBackward}><FormattedMessage id="general.sendBackward" /></button>
                    </div>
                )
                break;
            case '8':
                return (
                    <>
                        {
                            (equipmentObj && !activeSelection && exportVideo) && !objectStates.player && !objectStates.playerImage &&
                            <div>
                                <span><FormattedMessage id="general.fadingAnimation" /></span>
                                <div className="shapeBorderStyle">
                                    <Checkbox.Group options={plainOptions} value={checkedList} onChange={fadingAnimation} />
                                </div>
                            </div>
                        }
                        {
                            ((objectStates.player || objectStates.playerImage) && heiglightCheck) && exportVideo && !objectStates.shape && !objectStates.line &&
                            <div>
                                <span><FormattedMessage id="general.fadingAnimation" /></span>
                                <div className="shapeBorderStyle">
                                    <Checkbox.Group options={plainOptions} value={checkedList} onChange={fadingAnimation} />
                                </div>
                            </div>
                        }
                    </>
                )
                break;
            case '9':
                return (
                    <ColorPopup modifiedObject={modifiedObject} />
                )
                break;
            case '10':
                return (
                    <div className="toggle_bg_Btn">
                        <span><FormattedMessage id="general.showDirection" /></span>
                        <Switch checked={playerDirection} onChange={setPlayerDirection} />
                    </div>
                )
                break;
            case '11':
                return (
                    <div className="toggle_bg_Btn">
                        <div className='text-options'>
                            <Tooltip title={'Left'}><Button className={`tacticsBoard_mainTool_icon`} icon={<SvgIcon><Left fill='red' /></SvgIcon>} onClick={() => { changePosition('left') }} /></Tooltip>
                            <Tooltip title={'Top'}><Button className={`tacticsBoard_mainTool_icon`} icon={<SvgIcon><Top fill='red' /></SvgIcon>} onClick={() => { changePosition('top') }} /></Tooltip>
                            <Tooltip title={'Bottom'}><Button className={`tacticsBoard_mainTool_icon`} icon={<SvgIcon><Bottom fill='red' /></SvgIcon>} onClick={() => { changePosition('bottom') }} /></Tooltip>
                            <Tooltip title={'Right'}><Button className={`tacticsBoard_mainTool_icon`} icon={<SvgIcon><Right fill='red' /></SvgIcon>} onClick={() => { changePosition('right') }} /></Tooltip>
                        </div>
                    </div>
                )
                break;
            default:
                break;
        }
    }
    const changePosition = (val) => {
        let obj = canvas.getActiveObject();
        if (obj) {
            obj.set({ position: val });
            let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === obj.ref_id);
            if (nameInd > -1) obj.setTextPosition(canvas._objects[nameInd], canvas);
            canvas.renderAll();
        }
    }
    const multiSelectDirection = () => {
        let obj = canvas.getActiveObject();
        if (!obj) return;
        if (obj.type === "activeSelection") {
            let directionCount = obj.getObjects().filter(fInd => (fInd.name === 'player' || fInd.name === 'player_custom_image') && fInd._objects[2]);
            if (directionCount.length) return true;
            return false
        }
        return false;
    }
    return (
        <>
            {textField?
                ((objectStates.player || objectStates.text)) &&
                <div className={'popup-content'}>
                    {!isImgSelected &&
                    <>
                        <div className="inputTextBtnWrapper">
                                    <span
                                        className='pg__form_label'>{isPlayerSelected ? <FormattedMessage id="general.playerName" /> : objectStates.player ? <FormattedMessage id="general.playerNumber" /> : <FormattedMessage id="general.innerText" />}</span>
                            <Input.TextArea
                                id="input_player"
                                value={objectText}
                                placeholder={isPlayerSelected ? 'Player Name' : objectStates.player ? <FormattedMessage id="general.playerNumber" /> : <FormattedMessage id="general.text" />}
                                bordered={false}
                                autoSize={{ minRows: 1, maxRows: 3 }}
                                className='pg__form_input'
                                onChange={updatePlayer}
                                onFocus={() => { dispatch(setEditingMode(true)) }}
                            />
                            {!objectStates.player &&
                            <button className={`boldBtn ${toggleBold ? 'bg_purple' : ''}`}
                                    onClick={togglingBold}>B</button>}
                        </div>
                        {(!isPlayerSelected && !textSelected) &&
                        <div>
                            <span className='pg__form_label'><FormattedMessage id="general.playerName" /></span>
                            <Input.TextArea
                                id="input_player-name"
                                value={playerName}
                                placeholder={formatMessage({ id: "Player Name" })}
                                bordered={false}
                                autoSize={{ minRows: 1, maxRows: 3 }}
                                className='pg__form_input'
                                onChange={updatePlayer}
                                onFocus={() => { dispatch(setEditingMode(true)) }}
                            />
                            <button className={`boldBtn ${toggleBold ? 'bg_purple' : ''}`}
                                    onClick={togglingBold}>B
                            </button>
                        </div>
                        }
                    </>
                    }
                    {isImgSelected &&
                    <>
                        <div>
                            <span className='pg__form_label'><FormattedMessage id="general.playerName" /></span>
                            <Input.TextArea
                                id="input_player-name"
                                value={playerName}
                                placeholder={formatMessage({ id: "general.text" })}
                                bordered={false}
                                autoSize={{ minRows: 1, maxRows: 3 }}
                                className='pg__form_input'
                                onChange={updatePlayer}
                                onFocus={() => { dispatch(setEditingMode(true)) }}
                            />
                            <button className={`boldBtn ${toggleBold ? 'bg_purple' : ''}`}
                                    onClick={togglingBold}>B
                            </button>
                        </div>
                        {!isPlayerSelected &&
                        <div>
                            <span className='pg__form_label'><FormattedMessage id="general.shirtNumber" /></span>
                            <Input.TextArea
                                id="input_player-shirtNo"
                                value={shirtNumber}
                                placeholder={formatMessage({ id: "general.text" })}
                                bordered={false}
                                autoSize={{ minRows: 1, maxRows: 3 }}
                                className='pg__form_input'
                                maxLength={2}
                                onChange={updatePlayer}
                                onFocus={() => { dispatch(setEditingMode(true)) }}
                            />
                        </div>
                        }
                    </>
                    }
                </div>:
                <div>
                <div className="modal_body_properties">
                    <div className='tacticsboard__header_extra_container'>
                        <div dir={direction}
                             className={`tacticsboard__header_lftextra_container ${objectStates.objectActive ? '' : 'd__none'}`}>
                            {(objectStates.text || objectStates.player || objectStates.playerImage) &&
                            <div className={`editor_element ${menuItemPanel === '1' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     handleTextObject()
                                     setMenuItemPanel('1')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`} icon={<SvgIcon><TextIconEditor
                                    fill={menuItemPanel === '1' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.label" /></span>
                            </div>
                            }
                            {objectStates.shape &&
                            <div className={`editor_element ${menuItemPanel === '2' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     setMenuItemPanel('2')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><ShapeIconEditor
                                            fill={menuItemPanel === '2' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.border" /></span>
                            </div>
                            }
                            <div className={`editor_element ${menuItemPanel === '3' ? 'selectedPanel' : ''}`}
                                 onClick={changeColor}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><ColorIconEditor
                                            fill={menuItemPanel === '3' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.color" /></span>
                            </div>
                            {!objectStates.line &&
                            <div className={`editor_element ${menuItemPanel === '4' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     setMenuItemPanel('4')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><RotateIconEditor
                                            fill={menuItemPanel === '4' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.rotate" /></span>
                            </div>
                            }
                            <div className={`editor_element ${menuItemPanel === '5' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     setMenuItemPanel('5')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><SizeIconEditor
                                            fill={menuItemPanel === '5' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.size" /></span>
                            </div>
                            {/*{(!objectStates.shape) &&*/}
                            <div
                                className={`editor_element ${objectStates.shape || objectStates.line || objectStates.playerImage || objectStates.object || objectStates.player || setBgOpacity ? '' : 'd__none'} ${menuItemPanel === '6' ? 'selectedPanel' : ''}`}
                                onClick={() => {
                                    setMenuItemPanel('6')
                                }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><OpacityIconEditor
                                            fill={menuItemPanel === '6' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.opacity" /></span>
                            </div>
                            {/*}*/}
                            <div className={`editor_element ${menuItemPanel === '7' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     setMenuItemPanel('7')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><LayersIconEditor
                                            fill={menuItemPanel === '7' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.layers" /></span>
                            </div>
                            {((activeFrame?.data_num > 0) && (((objectStates.player || objectStates.playerImage) && heiglightCheck) && exportVideo && !objectStates.shape && !objectStates.line) || ((equipmentObj && !activeSelection && exportVideo) && !objectStates.player && !objectStates.playerImage)) &&
                            <div className={`editor_element ${menuItemPanel === '8' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     setMenuItemPanel('8')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><FadingIcon
                                            fill={menuItemPanel === '8' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.fading" /></span>
                            </div>
                            }
                            {(objectStates.playerImage || (objectStates.player && !objectStates.svg)) &&
                            <div className={`editor_element ${menuItemPanel === '9' ? 'selectedPanel' : ''}`}
                                 onClick={setHighlighter}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><ShowHighlightIcon
                                            fill={menuItemPanel === '9' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.highlight" /></span>
                            </div>
                            }
                            {(objectStates.playerImage || (objectStates.player && !objectStates.triangle  && !objectStates.svg) || multiSelectDirection()) &&
                            <div className={`editor_element ${menuItemPanel === '10' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     setMenuItemPanel('10')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><DirectionIconEditor
                                            fill={menuItemPanel === '10' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.direction" /></span>
                            </div>
                            }
                            {(objectStates.playerImage || objectStates.player || objectStates.sprite) &&
                            <div className={`editor_element ${menuItemPanel === '11' ? 'selectedPanel' : ''}`}
                                 onClick={() => {
                                     setMenuItemPanel('11')
                                 }}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><Position
                                            fill={menuItemPanel === '11' ? selectedIconColor : iconColor}/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.position" /></span>
                            </div>
                            }
                            <div className={`editor_element`} onClick={duplicateObjectHelper}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon><DuplicateIconEditor/></SvgIcon>}/>
                                <span className="tools_title"><FormattedMessage id="general.duplicate" /></span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal_body_color">
                {
                    panelSwitch(menuItemPanel)
                }
                </div>
                </div>
            }
        </>
    )
}

export default injectIntl(TacticsBoardLeftSideExtra);