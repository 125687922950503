import {updateShadowLine} from "../../utils/HandleShadowObjs/updateShadowLine";

export const useModify = ()=>{
    const modifyObject = (props)=>{
        let {
            e,canvas,animationState,shadowFrames,frames,animation_state,allFrames,updateArrowLinesArray,updateFrameObjects,
            updateObjectsStates,reinitAfterWidthChange,reinitpath,updateUndoRedoState,setFrames,updateRemovedProps
        } = props;

        if (e.target.name === 'custom_image_name' || e.target.name === 'custom_image_shirtno') {
            let imgInd = canvas._objects.findIndex(f => (f.name === 'player_custom_image' || f.objecttype === 'sprite-image' || f.name === 'player') && f.ref_id === e.target.ref_id);
            if (imgInd > -1) {
                if (e.target.name === 'custom_image_name') {
                    canvas._objects[imgInd].nameText.text = e.target.text;
                    if (animationState) {
                        shadowFrames.forEach((i) => {
                            let nInd = i.objects.findIndex(f => f.ref_id === e.target.ref_id);
                            if (nInd > -1) i.objects[nInd].nameText.text = e.target.text;
                        })
                        allFrames.forEach(fr => {
                            let tempJson = JSON.parse(fr.json)
                            let frObjInd = tempJson.objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === e.target.ref_id);
                            if (frObjInd > -1) tempJson.objects[frObjInd].text = e.target.text;
                            fr.json = JSON.stringify(tempJson)
                        })
                        setFrames(allFrames);
                        updateRemovedProps(false,false,{shadowFrames});

                    }
                }
                if (e.target.name === 'custom_image_shirtno') {
                    canvas._objects[imgInd].shirtNo.text = e.target.text;
                }
            }
        }

        // For shadow
        if (e.target.type !== 'activeSelection' && e.target.name.includes('custom_image')) updateFrameObjects("update", e.target);
        if (animation_state && (frames.length > 0 || allFrames.length > 0) && e.target.type !== "activeSelection" && !(e.target?.name?.startsWith("custom_image_"))) {
            updateShadowLine(e.target,canvas);
            if (e.target.name === 'p0' || (e.target.name === 'p1' && e.target.objecttype !== 'shadow_curve_point')
                || e.target.name === 'p2' ||
                e.target.name === 'arrow_line' || e.target.name === 'line' || e.target.name === 'square1'
                || e.target.name === 'square2' || e.target.name === 'arrow') {
                updateArrowLinesArray()
            };
            updateFrameObjects("update", e.target);
        }
        ////
        if ((e.target.name === 'player' && !e.target.isSvg) || e.target.name === 'player_custom_image') {
            e.action === 'highlighted' && updateObjectsStates(e.target)
            if (e.target._objects[0].fill === '#fff') {
                e.target._objects[1].set('fill', 'black');
                e.target._objects[0].set('stroke', 'black');

                canvas.renderAll();
            } else if (e.target._objects[0].fill === '#F7CF00') {
                e.target._objects[1].set('fill', 'black');
                e.target._objects[0].set('stroke', 'white');
            } else {
                e.target._objects[1].set('fill', '#fff');
                e.target._objects[0].set('stroke', 'white');
                canvas.renderAll();
            }
        }
        if (e.target.name === 'line' && e.case === 'width') {
            reinitAfterWidthChange(e.target)
        }
        if (!e.state) {
            let isDropped = e.isDropped || false;
            reinitpath(e,null,isDropped)
        }
        updateUndoRedoState(e.target, "modified");
    }
    return {modifyObject}
}