export const reArrangeHelper = (props)=>{
let {
    canvas,shadowFrames,activeFrameVal,svgPathElements,setSvgPathElementss,
    updateObjectDPath,updateFrameObjects,updateRemovedProps
} = props;

    for(let i=0; i<canvas._objects.length; i++){
        let ob = canvas._objects[i];
        if (ob?.name?.startsWith("custom_image_")) {
            let newObInd = -1;
            for (let j = 0; j < canvas._objects.length; j++) {
                const iObj = canvas._objects[j];
                if (
                    (iObj.name === "player_custom_image" ||
                        iObj.objecttype === "sprite-image" ||
                        iObj.name === "player") &&
                    iObj.ref_id === ob.ref_id
                ) {
                    newObInd = j;
                    break;
                }
            }
            if (newObInd > -1) {
                ob = canvas._objects[newObInd];
            }
        }
        let tempObjs = [...shadowFrames[activeFrameVal.data_num].objects];
        if (!tempObjs.length) continue;
        let objInd = -1;
        for (let j = 0; j < tempObjs.length; j++) {
            const o = tempObjs[j];
            if (o.ref_id === ob.ref_id) {
                objInd = j;
                break;
            }
        }
        if (objInd > -1) {
            let objInd = -1;
            for (let j = 0; j < canvas._objects.length; j++) {
                const o = canvas._objects[j];
                if (
                    o.ref_id === `${ob.ref_id}__shadow-object` &&
                    o.name === "shadow-line"
                ) {
                    objInd = j;
                    break;
                }
            }
            if (objInd > -1) {
                canvas._objects[objInd].path[1][3] = ob.left;
                canvas._objects[objInd].path[1][4] = ob.top;
                svgPathElements = svgPathElements.filter((f) => f != undefined);
                let actObjInd = -1;
                for (let j = 0; j < svgPathElements.length; j++) {
                    const el = svgPathElements[j];
                    if (
                        el.id ===
                        `line-svg:${activeFrameVal.data_num}:${canvas._objects[objInd].ref_id}`
                    ) {
                        actObjInd = j;
                        break;
                    }
                }
                let nextObjInd = -1;
                for (let j = 0; j < svgPathElements.length; j++) {
                    const el = svgPathElements[j];
                    if (
                        el.id ===
                        `line-svg:${activeFrameVal.data_num + 1}:${
                            canvas._objects[objInd].ref_id
                        }`
                    ) {
                        nextObjInd = j;
                        break;
                    }
                }
                if (actObjInd > -1) {
                    let actObj = { ...svgPathElements[actObjInd] };
                    let d = actObj.d.split(" ");
                    d[4] = canvas._objects[objInd].path[1][1].toString();
                    d[5] = canvas._objects[objInd].path[1][2].toString();
                    d[6] = ob.left.toString();
                    d[7] = ob.top.toString();
                    actObj.d = d.join(" ");
                    svgPathElements[actObjInd] = actObj;
                    updateRemovedProps(false,{svgPathElements});
                    setSvgPathElementss([...svgPathElements]);
                    updateObjectDPath(actObj, d);
                    canvas.renderAll();
                }
                if (nextObjInd > -1) {
                    let actObj = { ...svgPathElements[nextObjInd] };
                    let d = actObj.d.split(" ");
                    d[1] = ob.left.toString();
                    d[2] = ob.top.toString();
                    actObj.d = d.join(" ");
                    svgPathElements[nextObjInd] = actObj;
                    updateRemovedProps(false,{svgPathElements});
                    setSvgPathElementss([...svgPathElements]);
                    updateObjectDPath(actObj, d, activeFrameVal.data_num + 1);
                    canvas.renderAll();
                }
                updateFrameObjects("update", ob);
            }
        }
    }

}