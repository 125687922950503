import {fabric} from "fabric";

export const applyWidthChangeEffects = (e,returnObject,canvas,setLineToCircle,setObjectPadding)=>{
    if (e) {
        if (e.objecttype === 'curve_line') {
            let is_dashed = false
            var ref_id = e.ref_id
            var p0 = returnObject(e.ref_id, "p0")
            var p1 = returnObject(e.ref_id, "p1")
            var p2 = returnObject(e.ref_id, "p2")
            var pa = returnObject(e.ref_id, "pa")
            var line = returnObject(e.ref_id, "line")

            const strokeWidthVal = line.strokeWidth
            if (line.strokeDashArray) {
                is_dashed = true
            }

            p0.setCoords();
            p1.setCoords();
            p2.setCoords();
            canvas.remove(line);
            var path = line.path;
            line = new fabric.Path(path, {
                perPixelTargetFind: true,
                fill: '',
                stroke: line.stroke,
                strokeWidth: strokeWidthVal,
                objectCaching: false,
                name: "line",
                objecttype: "curve_line",
                ref_id: ref_id,
                widthFactor: line.widthFactor,
                opacity:line.opacity,
                newOpacity:line.newOpacity
            });
            line.hasBorders = line.hasControls = false;
            if (is_dashed) {
                line.set({strokeDashArray: [5, 5]})
            }
            canvas.add(line);
            if (pa) {
                pa.opacity = line.opacity
                line.objecttype = p0.objecttype = p1.objecttype = p2.objecttype = pa.objecttype = "curve_line"
                line.p0 = p1.p0 = p2.p0 = pa.p0 = p0;
                line.p1 = p0.p1 = p2.p1 = pa.p1 = p1;
                line.p2 = p1.p2 = p0.p2 = pa.p2 = p2;
                line.pa = p0.pa = p1.pa = p2.pa = pa;
                p0.line = p1.line = p2.line = pa.line = line;
            } else {
                line.objecttype = p0.objecttype = p1.objecttype = p2.objecttype = "curve_line"
                line.p0 = p1.p0 = p2.p0 = p0;
                line.p1 = p0.p1 = p2.p1 = p1;
                line.p2 = p1.p2 = p0.p2 = p2;
                p0.line = p1.line = p2.line = line;
            }
            setLineToCircle(p1, null, line, null)
            setLineToCircle(p0, line, p1, null);
            setLineToCircle(p2, null, p1, line);

            setObjectPadding(line, 20, 10)
            line.moveTo(0)

            if (e.state) {
                p0.opacity = p1.opacity = p2.opacity = 0
                p0.selectable = p1.selectable = p2.selectable = true;
                canvas.setActiveObject(line)
                canvas.renderAll()
            } else {
                canvas.setActiveObject(line)
            }
        }
    }

};