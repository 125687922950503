import React, {useEffect, useRef, useState} from 'react';
import './BottomPanel.scss';
import {SvgIcon} from "@mui/material";
import {
    CirclePlayerIcon,
    CustomPlayerNumberIcon,
    GkIcon, LargeSizeIcon, MediumSizeIcon,
    PlayerDirIcon,
    PlayerNumberIcon, PlayerShirtIcon,
    PlayersIcon,
    PlayerTriangle,
    PlusSolidIcon,
    PositionIcon,
    RotateIcon,
    SmallSizeIcon
} from "../assets/icons";
import {FormattedMessage} from "react-intl";
import {Button, Col, Popover, Row} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import TacticsBoardToolsColors from "../components/tools/colors";
import {panelKeys} from "../components/tools";
import TacticsBoardPlayersSprites from "../components/tools/players-sprites";
import TacticsBoardGoalkeepers from "../components/tools/goolkeepers/goalkeepers";
import TacticsBoardToolsPlayers from "../components/tools/players";
import TacticsBoardToolsDraw from "../components/tools/draw";
import TacticsShapes from "../components/tools/shapes/shapes";
import TacticsBoardToolsEquipment from "../components/tools/equipment";
import TacticsboardFormation from "../components/tools/formation";
import CustomPlayers from "../components/tools/customPlayers/customPlayers";
import CustomPlayerNumbers from "../components/tools/customPlayers/customPlayerNumbers";
import TacticsBoardToolsPlayersTriangles from "../components/tools/triangles";
import TacticsBoardPlayerPositions from "../components/tools/playerPositions";
import DirectionsTab from "../components/tools/Directions";
import PlayerSide from "../components/tools/PlayerSide";
import {setActivePanel} from "../actions";
import PlayerSize from "../components/tools/PlayerSize";
import TacticsBoardPlayerShirts from "../components/tools/player-shirts";
import PlayerType from "../components/tools/PlayerType";
import SquadType from "../components/tools/SquadType";
import TogglePlayerNumbers from "../components/tools/TogglePlayerNumbers";

const iconColor = '#000000', selectedIconColor = '#5A5FE7'


const PlayersPanel = ({activeKey}) => {
    const dispatch = useDispatch();
    const menuItems = [
        {
            label: 'general.position',
            icon: <PositionIcon fill={activeKey === '12' ? selectedIconColor : iconColor} key='12'/>,
            key: '12'
        },
        {
            label: 'general.numbers',
            icon: <PlayerNumberIcon fill={activeKey === '10' ? selectedIconColor : iconColor} key='10'/>,
            key: '10'
        },
        {
            label: 'general.shirts',
            icon: <PlayerShirtIcon fill={activeKey === '10' ? selectedIconColor : iconColor} key='10'/>,
            key: '13'
        },
        {
            label: 'general.triangles',
            icon: <PlayerTriangle fill={activeKey === '11' ? selectedIconColor : iconColor} key='11'/>,
            key: '11'
        },
        {
            label: 'general.squadImages',
            icon: <PlayersIcon fill={activeKey === '8' ? selectedIconColor : iconColor} key='8'/>,
            key: '8'
        },
        // {
        //     label: 'general.numbers',
        //     icon: <CustomPlayerNumberIcon fill={activeKey === '9' ? selectedIconColor : iconColor} key='9'/>,
        //     key: '9'
        // },
        {
            label: 'general.playerCharacter',
            icon: <PlayersIcon fill={activeKey === '1' ? selectedIconColor : iconColor} key='1'/>,
            key: '1'
        },
        {
            label: 'GK', icon: <GkIcon fill={activeKey === '2' ? selectedIconColor : iconColor}/>, key: '2'
        },
    ]
    const itemsPerRow = menuItems.length; // Define the number of items you want to display per row

    const span = 24 / itemsPerRow; // Calculate the span dynamically

    return (
        <Row>
            <div className='play-panel'>
                <Row gutter={4} justify="center">
                    {
                        menuItems.map((item, ind) =>
                            <Col key={ind} span={span}>
                                <div className='item-box'>
                                    <Button className={`bottom-tools`}
                                            icon={<SvgIcon className=''>{item.icon}</SvgIcon>}
                                            onClick={() => dispatch(setActivePanel(item.key))}/>
                                    <span className="tools_title" style={{fontSize: '11px'}}><FormattedMessage
                                        id={item.label}/></span>
                                </div>
                            </Col>
                        )
                    }

                </Row>
            </div>
        </Row>

    )
}


const BottomPanel = (props) => {
    const activeKey = useSelector(state => state.activePanel);
    const activeColor = useSelector(state => state.color);
    const [openColorPopup, setOpenColorPopup] = useState(false);
    const {playerSizes, playerType, circleImg} = useSelector(state => state.player);
    const colorPopUpRef = useRef();
    const dispatch = useDispatch();
    const toggleColorPopup = () => {
        setOpenColorPopup(!openColorPopup)
    }
    const handleClickOutside = (event) => {
        if (colorPopUpRef.current && !colorPopUpRef.current.contains(event.target)) {
            setOpenColorPopup(false);
        }
    };

    const panelSwitch = (activeKey) => {
        switch (activeKey) {
            case '1':
                return (<TacticsBoardPlayersSprites modifiedObject={props.modifiedObject} playerSizes={playerSizes}/>)
            case '2':
                return (<TacticsBoardGoalkeepers modifiedObject={props.modifiedObject} playerSizes={playerSizes}/>)
            case '3':
                return (<TacticsBoardToolsPlayers playerSizes={playerSizes}/>)
            case '4':
                return (<TacticsBoardToolsDraw/>)
            case '5':
                return (<TacticsShapes/>)
            case '6':
                return (<TacticsBoardToolsEquipment/>)
            case '7':
                return (<TacticsboardFormation/>)
            case '8':
                return (<CustomPlayers showSquad={props.showSquad} customImagesJson={props.customImagesJson}
                                       addCustomSquad={props.addCustomSquad} playerSizes={playerSizes}/>)
            case '9':
                return (<CustomPlayerNumbers showSquad={props.showSquad} customImagesJson={props.customImagesJson}
                                             addCustomSquad={props.addCustomSquad} playerSizes={playerSizes}/>)
            case '10':
                return (<TacticsBoardToolsPlayers showSquad={props.showSquad} playerSizes={playerSizes}/>)
            case '11':
                return (<TacticsBoardToolsPlayersTriangles playerSizes={playerSizes}/>)
            case '12':
                return (<TacticsBoardPlayerPositions showSquad={props.showSquad} playerSizes={playerSizes}/>)
            case '13':
                return (<TacticsBoardPlayerShirts playerSizes={playerSizes} />)
            default:
                break;
        }
    }

    const getColspan = (order, activeKey) => {
        switch (order) {
            case 1:
                if (['1', '2', '8', '9', '10', '11', '12','13'].includes(activeKey)) {
                    return 3
                } else return 0
            case 2:
                if (['5'].includes(activeKey)) {
                    return 24
                } else if (['4'].includes(activeKey)) {
                    return 20
                }
                else if(['8'].includes(activeKey)){
                    return 9
                }
                else if (['12', '10', '11', '9', '7', '1'].includes(activeKey)) {
                    return 12
                }
                else if (['13'].includes(activeKey)) {
                    return 15
                }
                else if (['2'].includes(activeKey)) {
                    return 13
                } else return 16
            case 3:
                if (['5', '2'].includes(activeKey)) {
                    return 0
                } else if (['13','12', '10', '11', '8', '9', '1', '7'].includes(activeKey)) {
                    return 3
                } else return 4
            case 4:
                if (['5', '4', '7', '8', '9', '10', '11', '12','13'].includes(activeKey)) {
                    return 0
                } else if (['1'].includes(activeKey)) {
                    return 3
                } else return 4
            case 5:
                if (['2'].includes(activeKey)) {
                    return 4
                } else if (['13','12', '10', '11', '8', '9', '1', '7'].includes(activeKey)) {
                    return 3
                } else return 0
            case 6:
                 if (['12', '10', '11', '7'].includes(activeKey)) {
                    return 3
                } else return 0;
            case 7:
                if (['8', '9'].includes(activeKey)) {
                    return 3
                } else return 0;
            case 8:
                if(['7'].includes(activeKey)){
                    return 3
                }
                    else return 0;

            case 9:
                if(['8'].includes(activeKey)) return 3
                else return 0;

            case 10:
                if(["7"].includes(activeKey)) return 3
                else return 0;


            default:
                return 0;
        }

    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);
    return (
        <>
            <div className='fixed-bottom'>
                {activeKey === 'Player' ? <PlayersPanel activeKey={activeKey}/> :
                    <Row>
                        <Col span={getColspan(1, activeKey)} order={1}>
                            <div className='bck'>
                                <div className='back-btn'><span className="tools_title" style={{fontSize: '11px'}}
                                                                onClick={() => dispatch(setActivePanel('Player'))}><FormattedMessage
                                    id='Back'/></span></div>
                            </div>
                        </Col>
                        <Col span={getColspan(2, activeKey)} order={2}>
                            <div onClick={props.props}>
                                {panelSwitch(activeKey)}
                            </div>
                        </Col>
                        <Col span={getColspan(3, activeKey)} order={3}>
                            {!(activeKey === '2' || activeKey === '5') &&
                            <div ref={colorPopUpRef} className="expandable_buttons" onClick={toggleColorPopup}>
                                <span> <FormattedMessage id="general.colors"/></span>
                                <div className="selected_color"
                                     style={{backgroundColor: `${activeColor[panelKeys[activeKey]]}`}}/>
                            </div>
                            }
                        </Col>
                        <Col span={getColspan(4, activeKey)} order={4}>
                            <Popover content={<DirectionsTab/>} className="expandable_buttons" trigger="click">
                                <span> <FormattedMessage id="Rotate"/></span>
                                <div className="rotation">
                                    <RotateIcon/>
                                </div>
                            </Popover>
                        </Col>
                        <Col span={getColspan(5, activeKey)} order={5}>
                            <Popover content={<PlayerSize/>} className="expandable_buttons" trigger="click">
                                <span> <FormattedMessage id="Size"/></span>
                                <div className="rotation">
                                    {playerSizes[panelKeys[activeKey]] === 'small' &&  <SmallSizeIcon size={{width: "18", height: "18"}} fill={'#000000'}/>}
                                    {playerSizes[panelKeys[activeKey]] === 'medium' &&  <MediumSizeIcon size={{width: "18", height: "18"}} fill={'#000000'}/>}
                                    {playerSizes[panelKeys[activeKey]] === 'large' &&  <LargeSizeIcon size={{width: "18", height: "18"}} fill={'#000000'}/>}

                                </div>
                            </Popover>
                        </Col>
                        <Col span={getColspan(6, activeKey)} order={6}>
                            <Popover content={<PlayerSide/>} className="expandable_buttons" trigger="click">
                                <span> <FormattedMessage id="Direction"/></span>
                                <div className="rotation">
                                    <PlayerDirIcon/>
                                </div>
                            </Popover>
                        </Col>
                        <Col span={getColspan(7, activeKey)} order={7}>
                            <div className="expandable_buttons" onClick={props.addCustomSquad}>
                                <span> <FormattedMessage id="Player"/></span>
                                <div className="rotation">
                                    <PlusSolidIcon/>
                                </div>
                            </div>
                        </Col>
                        <Col span={getColspan(8, activeKey)} order={8}>
                            <Popover content={<PlayerType/>} className="expandable_buttons" trigger="click">
                                <span> <FormattedMessage id="general.type"/></span>
                                <div className="rotation">
                                    {playerType === 'shirt' && <PlayerShirtIcon/>}
                                    {playerType === 'circle' && <CirclePlayerIcon/>}
                                    {playerType === 'char' && <PlayersIcon/>}
                                </div>
                            </Popover>
                        </Col>
                        <Col span={getColspan(9, activeKey)} order={9}>
                            <Popover content={<SquadType/>} className="expandable_buttons" trigger="click">
                                <span> <FormattedMessage id="general.type"/></span>
                                <div className="rotation">
                                    {circleImg && <PlayersIcon/>}
                                    {!circleImg && <CirclePlayerIcon/>}
                                </div>
                            </Popover>
                        </Col>
                        <Col span={getColspan(10, activeKey)} order={6}>
                            <Popover content={<TogglePlayerNumbers/>} className="expandable_buttons" trigger="click">
                                <span> Numbers</span>
                                <div className="rotation">
                                    <PlayerDirIcon/>
                                </div>
                            </Popover>
                        </Col>

                    </Row>
                }
            </div>
            {openColorPopup &&
            <TacticsBoardToolsColors modifiedObject={props.modifiedObject} panelKey={activeKey}
                                     setOpenColorPopup={setOpenColorPopup}/>
            }
        </>
    )
}
export default BottomPanel;