const colorPopup = (state = {flag:false,type:'',freeShape:false,text:false,imgWithText:false,showHighlight:false,top:'200px'}, action)=>{
    if(action.type === "SET_COLOR_POPUP"){
        state = action.state
    }
    if(action.type === "RESET_CANVAS"){
        state = {flag:false,type:'',freeShape:false,text:false,imgWithText:false,showHighlight:false,top:'200px'};
    }

    return state;
}

export default colorPopup;