import React, {useEffect, useState} from 'react';
import {Col, Row} from 'antd';
import TacticsBoardToolsIcon from './icon';
import ball1 from '../../assets/images/ball_1.png';
import ball2 from '../../assets/images/ball_2.png';
import flag from '../../assets/images/flag.png';
import ladder1 from '../../assets/images/coordination_ladder_1.png';
import ladder2 from '../../assets/images/coordination_ladder_2.png';
import poleRed from '../../assets/images/pole_red.png';
import poleBlue from '../../assets/images/pole_blue.png';
import cross from '../../assets/images/cross.png';
import dot from '../../assets/images/dot.png';
import hoop from '../../assets/images/hoop.png';
import {useDispatch, useSelector} from "react-redux";
import {BASE_URL_ASSETS, colorName, equipmentsObjsColor} from '../../utils/utils'
import '../../Equipments.scss'
import './tools.scss';
import {FormattedMessage} from "react-intl";
import {clearActiveTab, clearClipBoard, setActiveTab, setSpriteIndex, setSrc} from "../../actions";
import {getMobileView} from "../helpers";
import DirectionsTab from "./Directions";

const TacticsBoardToolsEquipment = (props) => {
    const isColorPopup = useSelector(state => state.colorPopup);
    const selectedColor = useSelector(state => state.color);
    const {spriteIndex} = useSelector(state => state.player);
    const activeTab = useSelector((state)=>state.activeTab).tab;
    const [objectColors, setObjectColors] = useState(equipmentsObjsColor);
    const canvas = useSelector(state => state.canvas)

    const otherEquipSym = ['T', 'Flat', 'Long', 'TopL'];
    const dispatch = useDispatch();
    useEffect(() => {
        selectedColor && updateObjectColors(selectedColor.equipments, isColorPopup.type);
    }, [selectedColor.equipments])

    const updateObjectColors = (selColor) => {
        if (selColor.includes("#")) selColor = colorName[selColor];
        let objColors = equipmentsObjsColor(selColor);
        setObjectColors(objColors)
    }
    const setTab = (id,e)=>{
        if(activeTab === id){
            e.stopPropagation();
            canvas.selection = true;
            canvas.defaultCursor = 'default';
            dispatch(clearActiveTab());
            dispatch(clearClipBoard());
            return;
        }
        dispatch(setActiveTab(id));
    }
    const ItemsList = ()=>{
        const isMobileView = getMobileView();
        const colSpan = isMobileView?6:12
        return(
            <>
                <Col span={colSpan}><TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" id='ball2'
                                                      dataType="ball" hscale={20} image={`${BASE_URL_ASSETS}/assets/images/ball_1.png`} alt="Ball 1"/></Col>
                <Col span={colSpan}><TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" id='ball1'
                                                      dataType="ball" hscale={20} image={`${BASE_URL_ASSETS}/assets/images/ball_2.png`} alt="Ball 2"/></Col>
                <Col span={colSpan}>
                    <div className={`tacticsboard__feature_icon ${activeTab === `SG-${objectColors.SG}-000${spriteIndex}`?'prim':''}`} onClick={(e)=>{
                        setTab(`SG-${objectColors.SG}-000${spriteIndex}`,e)
                    }}>
                        <i classList="Gate-element" ratiofactor={0} dataType="cone-sprite"
                           id={`SG-${objectColors.SG}-000${spriteIndex}`}
                           className={`Gate-element Equipments_scale Gate Gate-SG-${objectColors.SG}-000${spriteIndex}
                             `}/>
                    </div>
                </Col>
                <Col span={colSpan}>
                    <div className="tacticsboard__feature_icon" onClick={(e)=>{
                        setTab(`Stand-${objectColors.Stand}000${spriteIndex}`,e)
                    }}>
                        <i classList="Equipments-element" ratiofactor={1.44} dataType="cone-sprite"
                           id={`Stand-${objectColors.Stand}000${spriteIndex}`}
                           className={`Equipments-element Equipments_scale Stand Stand-${objectColors.Stand}_000${spriteIndex}
                                ${activeTab === `Stand-${objectColors.Stand}000${spriteIndex}`?'prim':''}
                               `}/>
                    </div>
                </Col>
                <Col span={colSpan}>
                    <div className={`tacticsboard__feature_icon ${activeTab === `AngleStand-${objectColors.AngleStand}000${spriteIndex}`?'prim':''}`} onClick={(e)=>{
                        setTab(`AngleStand-${objectColors.AngleStand}000${spriteIndex}`,e)
                    }}>
                        <i classList="Equipments-element" ratiofactor={1.779} dataType="cone-sprite"
                           id={`AngleStand-${objectColors.AngleStand}000${spriteIndex}`}
                           className={`Equipments-element Equipments_scale AngleStand AngleStand-${objectColors.AngleStand}_000${spriteIndex}`}/>
                    </div>
                </Col>
                <Col span={colSpan}>
                    <div className={`tacticsboard__feature_icon ${activeTab === `DummyStand-${objectColors.DummyStand}000${spriteIndex}`?'prim':''}`}  onClick={(e)=>{
                        setTab(`DummyStand-${objectColors.DummyStand}000${spriteIndex}`,e)
                    }}>
                        <i classList="Equipments-element" ratiofactor={1.665} dataType="cone-sprite"
                           id={`DummyStand-${objectColors.DummyStand}000${spriteIndex}`}
                           className={`Equipments-element Equipments_scale DummyStand DummyStand-${objectColors.DummyStand}_000${spriteIndex}`}/>
                    </div>
                </Col>
                {
                    otherEquipSym.map((sym, i) => {
                        let ratioFactor = 0;
                        if (sym === "T") {
                            ratioFactor = 0.755
                        } else if (sym === "Long") {
                            ratioFactor = 1.087;
                        } else if (sym === "TopL") {
                            ratioFactor = 0.882
                        }
                        return (
                            <Col span={colSpan}>
                                <div className={`tacticsboard__feature_icon  ${activeTab === `Cones-${sym}_${objectColors[`Cones-${sym}`]}`?'prim':''}`} key={i} onClick={(e)=>{
                                    setTab(`Cones-${sym}_${objectColors[`Cones-${sym}`]}`,e)
                                }}>
                                    <i classList="Equipments-element" ratiofactor={ratioFactor}
                                       dataType="cone-sprite"
                                       id={`Cones-${sym}_${objectColors[`Cones-${sym}`]}`}
                                       className={`Equipments-element Cone Cone-${sym}_${objectColors[`Cones-${sym}`]}`}/>
                                </div>
                            </Col>
                        )
                    })
                }
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" dataType="hoop"
                                           id="cone_blue1" hscale={25} image={`${BASE_URL_ASSETS}/assets/images/hoop.png`} alt="Hoop"/></Col>
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" dataType="pole"
                                           id="cone_blue2" hscale={25} image={`${BASE_URL_ASSETS}/assets/images/pole_red.png`} alt="Red Pole"/></Col>
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" dataType="pole"
                                           id="cone_blue3" hscale={25} image={`${BASE_URL_ASSETS}/assets/images/pole_blue.png`} alt="Blue Pole"/></Col>
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" dataType="dot"
                                           id="cone_blue4" hscale={25} image={`${BASE_URL_ASSETS}/assets/images/dot.png`} alt="Dot"/></Col>
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" dataType="flag"
                                           id="cone_blue5" hscale={25} image={`${BASE_URL_ASSETS}/assets/images/flag.png`} alt="Flag"/></Col>
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" dataType="cross"
                                           id="cone_blue6" hscale={25} image={`${BASE_URL_ASSETS}/assets/images/cross.png`} alt="Cross"/></Col>
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" id="cone_blue7"
                                           hscale={25} image={`${BASE_URL_ASSETS}/assets/images/coordination_ladder_1.png`} alt="Coordination Ladder 1"/></Col>
                <Col span={colSpan}>
                    <TacticsBoardToolsIcon imgStyle="image_dim_equipment" classList="icon-element" id="cone_blue8"
                                           hscale={25} image={`${BASE_URL_ASSETS}/assets/images/coordination_ladder_2.png`} alt="Coordination Ladder 2"/></Col>
                </>
        )
    }
    return (
        <>
            <div className="tacticsboard__feature_container tacticsboard__feature_container_height container_width"
                 onClick={props.props}>
                <div className="tacticsboard_formated_title" ><FormattedMessage id={'Rotate'} /></div>
                {!getMobileView() && <DirectionsTab/>}
                {getMobileView()?
                    <ItemsList/>:
                    <Row gutter={[7, 7]}>
                        <ItemsList/>
                    </Row>
                }
            </div>
        </>
    )
}

export default TacticsBoardToolsEquipment;