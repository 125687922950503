export const handleKeyEvents = (e,canvas,clearTextSelection,deleteObject,editModeFlag,duplicateOnKeyPress, objToDuplicate)=>{
    switch (e.keyCode) {
        case 13:
            let actObj = canvas.getActiveObject();
            if (!actObj) return;
            if (actObj.name.startsWith('custom_image_')) {
                e.preventDefault();
            }
            break;
        case 46:
            e.preventDefault();
            deleteObject();
        case 8:
            if (!editModeFlag.state) {
                deleteObject();
            }
            break;
        default:
            if (e.ctrlKey  && (e.key === 'c' || e.key === 'C')) {
                e.preventDefault();
                // Handle Ctrl+C
                objToDuplicate();
            }
            else if (e.metaKey && (e.key === 'c' || e.key === 'C')) {
                e.preventDefault();
                // Handle Ctrl+C
                objToDuplicate();
            }
            else if ((e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'V')) {
                e.preventDefault();
                // Handle Ctrl+V
                duplicateOnKeyPress();
            }
            break;
    }
}
