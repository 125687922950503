import {makeArrow} from "../makeArrow";
import {fabric} from "fabric";

export const drawBezierCurveArrow = (props, canvas, customProps, makeCurvePoint, makeCurveCircle, movingObject, updateUndoStates, reinitpath, mouseUp)=>{
    let path = props.path || 'M 100 100 Q 200, 200, 300, 100'
    var line = new fabric.Path(path, {
        perPixelTargetFind: true,
        fill: '', stroke:
        props.color,
        strokeWidth:  props.strokeWidth || (props.scaleProps.strokeWidth) - 2,
        objectCaching: false,
        name: "line",
        objecttype: "curve_line",
        fadeFlag: 'no-fade-out',
        widthFactor: props.widthFactor || 'l0',
        opacity:  props.opacity || 1,
        newOpacity: props.newOpacity || 1,
        isInitialized:true,
        lockMovementX:customProps?.lockMovementX || false,
        lockMovementY:customProps?.lockMovementY || false,
    });
    line.ref_id = props.ref_id
    line.hasBorders = line.hasControls = false;
    if (props.is_dashed) {
        line.set({ strokeDashArray: [5, 5] })
    }
    canvas.add(line);

    var pa = makeArrow(props.pa.left || 100, props.pa.top || 100, line, props,
        props.pa.angle || -45, customProps);

    pa.name = "pa";
    pa.ref_id = props.ref_id;
    pa.widthFactor = 'l1'
    pa.set('fadeFlag', 'no-fade-out')
    canvas.add(pa);

    var p1 = makeCurvePoint(props.p1.left || 200, props.p1.top || 200, null, line, null, props)
    p1.name = "p1";
    p1.ref_id = props.ref_id
    canvas.add(p1);
    let centerX = (100 + 300) / 2;
    let centerY = (100 + 100) / 2;
    let centerCurvePointX = (centerX + 200) / 2;
    let centerCurvePointY = (centerY + 200) / 2;
    var pX = makeCurvePoint(props.pX.left || centerCurvePointX, props.pX.top ||
        centerCurvePointY, null, line, null, props);
    pX.name = "pX";
    pX.ref_id = `${props.ref_id}::active`;
    canvas.add(pX);
    var p0 = makeCurveCircle(props.p0.left || 100, props.p0.top || 100, line, p1, null, props);
    p0.name = "p0";
    p0.ref_id = props.ref_id
    canvas.add(p0);
    var p2 = makeCurveCircle(props.p2.left || 300, props.p2.top || 100, null, p1, line, props);
    p2.name = "p2";
    p2.ref_id = props.ref_id
    canvas.add(p2);
    line.moveTo(0)
    line.objecttype = p0.objecttype = p1.objecttype = p2.objecttype = pa.objecttype = "curve_line"
    line.p0 = p1.p0 = p2.p0 = pa.p0 = p0;
    line.p1 = p0.p1 = p2.p1 = pa.p1 = p1;
    line.p2 = p1.p2 = p0.p2 = pa.p2 = p2;
    line.pa = p0.pa = p1.pa = p2.pa = pa;
    p0.line = p1.line = p2.line = pa.line = line;
    movingObject({ target: line })
    mouseUp({ target: line })
    reinitpath({ target: line })
    line.set('fadeFlag', 'no-fade-out')
    pa.set('fadeFlag', 'no-fade-out')
    updateUndoStates(line)

}