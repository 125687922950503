import { Modal } from "antd";

const Confirm = ({
                     width,
                     direction,
                     formatMessage,
                     title,
                     titleId,
                     description,
                     descriptionId,
                     onOk,
                     okText,
                     okTextId,
                     onCancel,
                     cancelText,
                     cancelTextId,
                     showCancel = true,
                     okButtonProps,
                 }) => {
    Modal.confirm({
        icon: null,
        onOk: () => onOk(),
        width: width || 600,
        direction: direction,
        okButtonProps: { shape: "round", ...okButtonProps },
        className: `modal__confirm_${direction}`,
        onCancel: () => (onCancel ? onCancel() : false),
        okText: okText || formatMessage({ id: okTextId || "general.confirm" }),
        content: descriptionId ? formatMessage({ id: descriptionId }) : description,
        cancelText:
            cancelText || formatMessage({ id: cancelTextId || "general.cancel" }),
        cancelButtonProps: {
            type: "text",
            shape: "round",
            style: { display: showCancel ? "" : "none" },
        },
        title: titleId
            ? formatMessage({ id: titleId })
            : title || formatMessage({ id: "general.confirm" }),
    });
};

export default {
    Confirm,
};
