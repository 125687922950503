import {sendBackShapes} from "./shapes/shapeUtils";
import {transformedPoint} from "./lines/QuadraticLines/transformedPoint";
import {moveLine} from "./lines/moveLine";

export const handleArrowObjectUpdate = (obj, state, multiSelection,moveEnd2,moveEnd,updateCurveArrow,canvas)=>{
    switch (state) {
        case "selected":
            switch (obj.name) {
                case "arrow_line":
                    if (multiSelection) break;
                    obj.square1.set({visible:true});
                    obj.square2.set({visible:true});
                    obj.square1.bringToFront();
                    obj.square2.bringToFront();
                    obj.square1.opacity = .5
                    obj.square2.opacity = .5
                    obj.square1.selectable = true
                    obj.square2.selectable = true
                    obj.square1.hoverCursor = obj.square2.hoverCursor = "pointer"
                    break;
                case "line":
                    if (multiSelection) {
                        if (obj.pa) {
                            obj.pa.opacity = obj.opacity
                            obj.pa.selectable = false
                            obj.pa.hoverCursor = "pointer"
                        }
                        break;
                    }
                    const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                    const lineInd = canvas._objects.findIndex((o) => (o.name === "shadow-line" || o.name === "line") && o.ref_id.includes(obj.ref_id));
                    if (middlePointActInd > -1 && lineInd > -1) {
                        canvas._objects[middlePointActInd].opacity = .5;
                        canvas._objects[middlePointActInd].selectable = true;
                        canvas._objects[middlePointActInd].hoverCursor = "pointer"
                        canvas._objects[middlePointActInd].bringForward()
                        canvas._objects[lineInd].sendToBack();
                    }
                    obj.p0.set({visible:true});
                    obj.p2.set({visible:true});
                    obj.p0.bringToFront();
                    obj.p2.bringToFront();
                    obj.p0.opacity = obj.p2.opacity = .5
                    obj.p0.selectable = obj.p2.selectable = true
                    obj.p0.hoverCursor = obj.p2.hoverCursor = "pointer"
                    if (obj.pa) {
                        obj.pa.opacity = obj.opacity
                        obj.pa.selectable = false
                        obj.pa.hoverCursor = "pointer"
                    }
                    break;
                case "shadow-line":
                    break;
            }
            break;
        case "moving":
            switch (obj.name) {
                case "arrow_line":
                    obj.square1.opacity = 0
                    obj.square2.opacity = 0
                    obj.square1.selectable = false
                    obj.square2.selectable = false
                    moveLine(obj)
                    break;
                case "square1":

                    moveEnd2(obj)
                    break;
                case "square2":
                    moveEnd(obj)
                    break;
                case "pa":
                    break;
                case "p0":
                case "p2":
                    var p1 = obj.p1
                    p1.opacity = 0
                    p1.selectable = false

                    if (obj.line1) {
                        obj.line1.path[0][1] = obj.left;
                        obj.line1.path[0][2] = obj.top;
                    } else if (obj.line3) {
                        obj.line3.path[1][3] = obj.left;
                        obj.line3.path[1][4] = obj.top;
                    }

                    if (obj.name === "p0") {
                        if (obj.pa) {
                            obj.pa.set("left", obj.left)
                            obj.pa.set("top", obj.top)
                            updateCurveArrow(obj.pa, obj.line)
                        }
                    }
                    break;
                case "p1":
                    if (obj.ref_id.includes('shadow')) {
                        obj.line2.path[1][1] = obj.left;
                        obj.line2.path[1][2] = obj.top;
                        break;
                    }
                    obj.p1 = {
                        ...obj.p1,
                        opacity: 1,
                        hoverCursor: "pointer",
                    }
                    if (obj.pa) {
                        obj.pa.opacity = obj.opacity
                        obj.pa.selectable = false
                        obj.pa.hoverCursor = "pointer"
                    }
                    if (obj.line2) {
                        obj.line2.path[1][1] = obj.left;
                        obj.line2.path[1][2] = obj.top;
                    }
                    if (obj.pa) {
                        updateCurveArrow(obj.pa, obj.line)
                    }
                    break;
                case "line":
                    const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                    if (middlePointActInd > -1) {
                        canvas._objects[middlePointActInd].opacity = 0;
                        canvas._objects[middlePointActInd].selectable = false;
                    }
                    obj.p0.opacity = obj.p1.opacity = obj.p2.opacity = 0
                    obj.p0.selectable = obj.p1.selectable = obj.p2.selectable = false
                    if (obj.pa) {
                        obj.pa.opacity = 0
                        obj.pa.selectable = true
                    }
                    var transformedPoints = transformedPoint(obj);
                    obj.p0.left = transformedPoints[0].x;
                    obj.p0.top = transformedPoints[0].y;
                    obj.p2.left = transformedPoints[1].x;
                    obj.p2.top = transformedPoints[1].y;
                    obj.p1.left = transformedPoints[2].x;
                    obj.p1.top = transformedPoints[2].y;
                    if (obj.pa) {
                        obj.pa.left = transformedPoints[0].x;
                        obj.pa.top = transformedPoints[0].y;
                    }
                    break;
                case "shadow-line":
                    break;
                case "player":
                    break;
            }
            break;
    }
    sendBackShapes(canvas);
    // canvas.renderAll()
}
