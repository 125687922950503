import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import '../tools.scss';
import {FormattedMessage} from 'react-intl';
import {PlusOutlined} from '@ant-design/icons';
import {clearActiveTab, clearClipBoard, setActiveTab} from "../../../actions";
import {getMobileView} from "../../helpers";

const getSurName = (playerName) => {
    let fullName = [];
    let hebrewCheck = /[\u0590-\u05FF]/
    let nameArr = playerName.split(' ').filter(f => f !== '');
    if (nameArr.length <= 1) {
        return {name: nameArr[0], isHebrew: hebrewCheck.test(nameArr[0])}
    } else {
        for (let i = 0; i < nameArr.length - 1; i++) {
            fullName.push(nameArr[i][0]);
        }
        fullName.push(nameArr[nameArr.length - 1])
        return {name: fullName.join('.'), isHebrew: hebrewCheck.test(playerName)}
    }
}
const tacticsBoardCustomImg = (name, color = '#000000') => {
    let playerColor;
    let fullName;
    let nameArr = name;
    let imgFirstName = nameArr[0][0];
    let imgLastName = nameArr.length > 1 ? nameArr[nameArr.length - 1][0] : '';
    fullName = nameArr.length > 1 ? `${imgFirstName}${imgLastName}` : `${imgFirstName}`;
    if (color === '#fff' || color === '#F7CF00') {
        playerColor = 'black'
    } else {
        playerColor = 'white'
    }
    const svgString = `<svg width='128' height='128' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' version='1.1' >
                            <circle cx='64' cy='64' r='59' fill='${color}' stroke='rgba(255, 255, 255, 0.9)' stroke-width='8'/>
                            <text text-anchor='middle' x='50%' y='50%' dy='.35em' text-align="center" line-height="24px" font-weight="400" font-family='Bahnschrift' font-size='70px' fill='${playerColor}'>${fullName}</text>
                        </svg>`;
    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
}

const CustomPlayers = (props) => {
    const playerSizesDel = [
        {size: 'small', span: 6, fontSize: 8, imgSize: '24', shirtWidth: '15px', shirtfLeft: '16px'},
        {size: 'medium', span: 12, fontSize: 8, imgSize: '28', shirtWidth: '18px', shirtfLeft: '10px'},
        {size: 'large', span: 6, fontSize: 8, imgSize: '34', shirtWidth: '20px', shirtfLeft: '16px'}
    ];
    const dispatch = useDispatch();


    const [playerImages, setPlayerImages] = useState([]);
    const iTags = playerImages.map(() => React.createRef());
    const [sizee, setSizee] = useState({
        size: 'small',
        span: 6,
        fontSize: 8,
        imgSize: '24',
        shirtWidth: '15px',
        shirtfLeft: '16px'
    })
    const selectedColor = useSelector(state => state.color);
    const customSquads = useSelector(state => state.easyTacticsBoard);
    const activeTab = useSelector((state)=>state.activeTab).tab;
    const canvas = useSelector(state => state.canvas);
    const {playerSizes, circleImg} = useSelector(state => state.player);

    useEffect(() => {
        if (customSquads.customPlayerImagesJson) {
            setPlayerImages(JSON.parse(customSquads.customPlayerImagesJson));
        }
    }, [customSquads.customPlayerImagesJson]);
    useEffect(() => {
        let newSize = playerSizesDel.find(f => f.size === playerSizes.playerSquad);
        if (newSize) setSizee({...newSize});
    }, [playerSizes.playerSquad]);
    const setTab = (id,e)=>{
        if(activeTab === id){
            e.stopPropagation();
            canvas.selection = true;
            canvas.defaultCursor = 'default';
            dispatch(clearActiveTab());
            dispatch(clearClipBoard());
            return;
        }
        dispatch(setActiveTab(id));
    }
    const ItemsList  = ()=>{
        const isMobileView = getMobileView();
        const colSpan = isMobileView?6:12;
        return(
        <>
            {
                playerImages.map((player, i) => {
                    return (
                        <Col key={i} span={colSpan}
                             onClick={(e)=>{
                                 setTab(`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`,e)
                                 iTags[i].current.click();
                             }}
                        >
                            <div className={`player-custom-image  ${getMobileView()?'tacticsboard__feature_icon-sq':'tacticsboard__feature_icon'} ${
                                activeTab===`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`?'prim':''
                            }`}
                                 id={`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`}
                                 datasrc={player.imageUrl}>
                                                    <span
                                                        className="player-custom-image"
                                                        ref={iTags[i]}
                                                        id={`${sizee.size}-${player.name}-${player.shirtNo}-${(player.imageUrl && circleImg) ? 'image' : 'circle'}`}
                                                        datasrc={player.imageUrl}>
                                                        <img
                                                            className="player-custom-image"
                                                            src={(player.imageUrl && circleImg) ? player.imageUrl : tacticsBoardCustomImg(player.shirtNo, selectedColor.playerSquad)}
                                                            height={getMobileView()?'22':sizee.imgSize}
                                                            width={getMobileView()?'22':sizee.imgSize}
                                                        />
                                                    </span>
                                <div
                                    className={getSurName(player.name).isHebrew ? 'shirt_no_right' : 'shirt_no_left'}>
                                                        <span className="custom_player_nameTag rtl" aria-disabled={true}
                                                              style={{
                                                                  fontSize: getMobileView()?'4px':sizee.fontSize,
                                                              }}>{getSurName(player.name).name}</span>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            </>)
    }

    return (
        <div className="tacticsboard__feature_container" onClick={props.props}>
            {
                getMobileView()?
                    <ItemsList/>:
                    <Row gutter={[7, 7]} className="tacticsboard__feature_icons">
                        <ItemsList/>
                    </Row>
            }

        </div>
    )
}

export default CustomPlayers;