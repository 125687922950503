import {fabric} from "fabric";

export const freeDrawHelper = (props)=>{
   let {
       drawBrushType,cDraw,canvas,animation_state,activeFrameVal,undoStates,activeStateIndex,setObjectPadding,setActiveObject,disableDrawer,tempDrawLines,
       prevX,prevY,cPoint,drawArrow,continueDraw,drawLineStates,scalePropsVal,updateDrawProps
   } = props;

    const uuid = require("uuid");
    let id = uuid.v4();
    if (cDraw) {
        id = cPoint.ref_id;
    }
    var path = canvas._objects[canvas._objects.length - 1]
    if (path) {
        path.perPixelTargetFind = true
        if (path.path) {
            var x1 = path.path[path.path.length - 2][1]
            var y1 = path.path[path.path.length - 2][2]

            var x2 = path.path[path.path.length - 1][1]
            var y2 = path.path[path.path.length - 1][2]

            var brushType = "simple";
            let wFactor = 'l0'
            if (document.querySelector(".draw-element.color__primary")) {
                brushType = document.querySelector(".draw-element.color__primary").id.split('::')[0]
            }
            drawBrushType = brushType;
            let drawElement = null;
            let objs = [canvas._objects[canvas._objects.length - 1]];
            if (tempDrawLines.length) objs = [...objs, ...tempDrawLines];

            if (cDraw) {
                brushType = cPoint.brushType;
                wFactor = cPoint.widthFactor;
            }
            if (brushType.includes('dashed')) {
                path.set({ strokeDashArray: [7, 10] });
            }
            if (brushType === "arrow" || brushType === "arrow_dashed" || brushType === "block" || brushType === "block_dashed") {
                drawElement = drawArrow(x1, y1, x2, y2, brushType, canvas.freeDrawingBrush.color, id)
                objs.unshift(drawElement);
            }
            ;
            if (cDraw) {
                if (brushType === "arrow" || brushType === "arrow_dashed" || brushType === "block" || brushType === "block_dashed") {
                    let id = cPoint.ref_id;
                    drawElement.ref_id = id;
                }

                let drewLines = canvas._objects.filter(f => (f.name === 'drawLine' && f.ref_id === id));
                let linePaths = canvas._objects.filter(f => f.name === 'linePath');
                let extendedLine = canvas._objects.filter(f => f.name === 'extendedLine' && f.ref_id === id);
                for (let x = 0; x < extendedLine.length; x++) {
                    canvas.remove(extendedLine[x]);
                }
                for (let i = 0; i < linePaths.length; i++) {
                    canvas.remove(linePaths[i]);
                }
                objs = [...objs, ...drewLines, ...extendedLine];
                drewLines.forEach(e => {
                    canvas.remove(e)
                })
                cDraw = false;
                brushType = cPoint.brushType;
                updateDrawProps({cDraw,tempDrawLines,prevX,prevY,undoStates,activeStateIndex})
            }

            const drawEndPoint = continueDraw(x1, y1, x2, y2, brushType, canvas.freeDrawingBrush.color, id, wFactor);
            canvas.remove(canvas._objects[canvas._objects.length - 1])
            if (animation_state) {
                activeFrameVal.undoStates.splice(activeFrameVal.undoStates.length - 1, 1)
                activeFrameVal.activeStateIndex--
            } else {
                undoStates.splice(undoStates.length - 1, 1)
                activeStateIndex--
            }
            updateDrawProps({cDraw,tempDrawLines,prevX,prevY,undoStates,activeStateIndex})
            canvas.renderAll();
            const group = new fabric.Group(objs, {
                originX: 'center',
                originY: 'center',
                name: "drawLine",
                perPixelTargetFind: true,
                objecttype: `draw_${cPoint ? cPoint.brushType : drawBrushType}`,
                hasBorders: false,
                hasControls: false,
                ref_id: id,
            });

            setObjectPadding(group, 10, 0)
            canvas.add(group);
            group.set('fadeFlag', 'no-fade-out')
            canvas.add(drawEndPoint);
            drawEndPoint.opacity = 0.5;
            let prevXInd = prevX.findIndex(g => g.ref_id === group.ref_id);
            let prevYInd = prevY.findIndex(g => g.ref_id === group.ref_id);
            if (prevXInd > -1) {
                prevX[prevXInd].x = group.left;
                prevY[prevYInd].y = group.top;
            } else {
                prevX.push({ ref_id: group.ref_id, x: group.left });
                prevY.push({ ref_id: group.ref_id, y: group.top });
            }
            updateDrawProps({cDraw,tempDrawLines,prevX,prevY,undoStates,activeStateIndex})
            setActiveObject(group)
            disableDrawer();

            let drState = drawLineStates.find(f => f.ref_id === drawEndPoint.ref_id);
            if (!drState) {
                let ind = drawLineStates.findIndex(f => f.ref_id === drawEndPoint.ref_id && f.name === 'line-end-point_shadow-object');
                if (ind <= -1) {
                    drawLineStates.push({
                        ref_id: drawEndPoint.ref_id,
                        brushType: drawEndPoint.brushType,
                    })
                }
            }
            cDraw = false;
            tempDrawLines = [];
            updateDrawProps({cDraw,tempDrawLines,prevX,prevY,undoStates,activeStateIndex})
            canvas.freeDrawingBrush.width = (scalePropsVal.strokeWidth) - 2;
        }
    }
}