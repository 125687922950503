import {hideOpacity} from "./utils";
import {reAddLineObjs} from "./lines/reAddLineObjs";
import {reAddDrawLine, reAddPlayer, reDrawCurvedPoint} from "./Animations/addCanvasNew";
import {moveLine} from "./lines/moveLine";

export class CanvasReloader{
    constructor(props) {
        let {
            reinitCanvas,scalePropsVal,clone,reAddImageTextObjs,animationState,activeFrameVal,activeFrame,addPlayer,continueDraw,drawQuadratic,drawQuadraticArrow,
            canvas,canvasVar,moveEnd2,setActiveObject,updateUndoStates,setObjectPadding,allFrames,addedObject,addControls,deletedObjects,canvas2,
            isRecording,startEvents,clearEndPoints,reinitFreeShape
        } = props;
        this.reinitCanvas = reinitCanvas;
        this.reinitFreeShape = reinitFreeShape;
        this.clearEndPoints = clearEndPoints;
        this.startEvents = startEvents;
        this.isRecording = isRecording;
        this.canvas2 = canvas2;
        this.deletedObjects = deletedObjects;
        this.addControls = addControls;
        this.addedObject = addedObject;
        this.allFrames = allFrames;
        this.setObjectPadding = setObjectPadding;
        this.updateUndoStates = updateUndoStates;
        this.setActiveObject = setActiveObject;
        this.moveEnd2 = moveEnd2;
        this.canvasVar = canvasVar;
        this.canvas = canvas;
        this.drawQuadraticArrow = drawQuadraticArrow;
        this.drawQuadratic = drawQuadratic;
        this.continueDraw = continueDraw;
        this.addPlayer = addPlayer;
        this.activeFrame = activeFrame;
        this.activeFrameVal = activeFrameVal;
        this.animationState = animationState;
        this.reAddImageTextObjs = reAddImageTextObjs;
        this.clone = clone;
        this.scalePropsVal = scalePropsVal
    }

    addCanvasNew(allF, activeF, callback, isSwitched = false){
        if (!allF?.length) allF = this.allFrames;
        if (allF[activeF.data_num - 1]) {
            let state
            this.canvas2.clear()
            const json_old = this.clone(allF[activeF.data_num - 1].json);
            this.canvas2.loadFromJSON(json_old, (async function () {
                this.canvas2.renderAll();
                if (this.canvas2._objects.length > 0) {
                    for (let j = 0; j < this.canvas2._objects.length; j++) {
                        let obj = this.canvas2._objects[j]
                        if (this.canvas._objects.length > 0) {
                            for (var i = 0; i < this.canvas._objects.length; i++) {
                                var obj2 = this.canvas._objects[i]
                                if (obj.ref_id === obj2.ref_id) {
                                    state = false
                                    break
                                } else {
                                    state = true
                                }
                            }
                        } else {
                            state = true
                        }

                        // Handle deleted objects here
                        if (this.deletedObjects.length && this.deletedObjects.findIndex(o => o.frame === this.activeFrameVal.data_num && o.ObjRefId === obj.ref_id) > -1) state = false;
                        if (state) {
                            const uuid = require("uuid");
                            const id = uuid.v4();
                            if (obj.objecttype === "simple_line" || obj.objecttype === "Line Arrow" || obj.objecttype === "arrow_line" || obj.objecttype === "curve_line" || obj.name === "Line Arrow") {
                                !isSwitched && reAddLineObjs(obj, this.canvas2._objects, id, this.setObjectPadding, moveLine, this.updateUndoStates, this.setActiveObject, this.moveEnd2, this.canvasVar, this.canvas, this.drawQuadraticArrow, this.drawQuadratic, this.scalePropsVal);
                            }
                            else if (obj.name === 'drawLine') {
                                reAddDrawLine(isSwitched, this.canvas2, this.canvas, obj, this.continueDraw, id)
                            } else if (obj.name === 'line-end-point_shadow-object') {
                                reDrawCurvedPoint(isSwitched, this.canvas2, this.canvas, obj, this.continueDraw, id)
                            } else if (obj.name === 'free-shape') {
                                if (!isSwitched) {
                                    obj.ref_id = id
                                    this.canvas.add(obj);
                                    this.addControls(obj);
                                    this.canvas.renderAll();
                                }
                            } else if ((obj.name === 'shape' || (obj.name === 'image' && obj.is_animation === false))) {
                                obj.ref_id = id
                                !isSwitched && this.canvas.add(obj);
                            } else if (obj.name === 'text') {
                                obj.ref_id = id
                                !isSwitched && this.canvas.add(obj)
                            } else {
                                if (obj.name.startsWith('custom_image_')) {
                                    let imgIndd = this.canvas2._objects.findIndex(f => (f.name === 'player_custom_image' || f.objecttype === "sprite-image" || f.name === 'player') && f.ref_id === obj.ref_id);
                                    if (imgIndd > -1) {
                                        obj = this.canvas2._objects[imgIndd];
                                    }
                                }
                                //TODO: Need to check, there is a special case when the players need to be readded using re-add player,because sometimes it works fine without this as well.
                                if (obj.name === 'player' && !obj.isSvg) {
                                    const playerObj = {
                                        ...obj,
                                        polygon:
                                            obj.polygon === "triangle" ? "triangle" : null, // backgroundColor used as custom param for the moment
                                    };
                                    await reAddPlayer(playerObj, this.scalePropsVal, this.canvas, this.addPlayer)
                                } else {
                                    this.canvas.add(obj)
                                }
                                if (obj.name === "player_custom_image" || obj.objecttype === "sprite-image" || obj.name === 'player') {
                                    obj.parentFrame = this.animationState ? this.activeFrameVal.data_num : null
                                    this.reAddImageTextObjs(obj)
                                }
                                this.addedObject({ target: obj, loaded: true });
                                this.canvas.renderAll()
                                this.reinitCanvas()
                            }
                        }
                    }
                }
                this.reinitFreeShape();
                // callback called
                callback && callback();
            }).bind(this))
        } else callback && callback();
    }

    handleBuildCanvas(canvasEl, json, state, allFrs, activeFrame, callback, isHide = false, isSwitched = false){
        canvasEl.loadFromJSON(json, (function () {
            if (state) {
                this.addCanvasNew(allFrs, activeFrame, callback, isSwitched);
            }
            this.reinitCanvas();
            this.reinitFreeShape();
            this.clearEndPoints()
            this.startEvents()
            for (let i = 0; i < canvasEl._objects?.length; i++) {
                this.isRecording && hideOpacity(canvasEl, i)
                if (canvasEl._objects[i].name === 'Line Arrow') {
                    canvasEl._objects[i].removeCanvasListeners();
                    setTimeout(() => {
                        canvasEl.renderAll();
                    }, 100)
                }
            };
            if (!state) {
                if (isHide) {
                    for (let i = 0; i < canvasEl._objects.length; i++) {
                        if (canvasEl._objects[i].is_animation || canvasEl._objects[i].name.startsWith("custom_image_shirtno") || canvasEl._objects[i].name === "pX" || canvasEl._objects[i].name === 'connectionLine') {
                            canvasEl._objects[i].opacity = 0;
                        }
                    }
                    canvasEl.renderAll();
                }
                callback && callback();
            }
        }).bind(this))
    }

}