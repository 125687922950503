const color = (state = {players:"#35ACDD",playerShirts:"#35ACDD",tools:"#fff",shapes:"#35ACDD",equipments:"#35ACDD",playerNo:"#35ACDD",playerSquad:"#35ACDD",playerSquadNumbers:"#35ACDD",playerFormation:"#35ACDD",playerPositions:"#35ACDD"}, action)=>{
    if(action.type === "SET_COLOR"){
        state = action.color
    }
    if(action.type === "RESET_CANVAS"){
        state = {players:"#35ACDD",playerShirts:"#35ACDD",tools:"#fff",shapes:"#35ACDD",equipments:"#35ACDD",playerNo:"#35ACDD",playerSquad:"#35ACDD",playerSquadNumbers:"#35ACDD",playerFormation:"#35ACDD",playerPositions:"#35ACDD"};
    }
    return state;
}

export default color;