import {fabric} from "fabric";
export const ALIGNING_LINE_COLOR = "#FF1EFF"
const ALIGNING_LINE_WIDTH = 1
const ALIGNING_LINE_DASH = false
const DISTANCE_TOLERANCE = 4;
const SCALING_DISTANCE_TOLERANCE = 1
let CURRENT_DISTANCE_TOLERANCE = DISTANCE_TOLERANCE;

/**
 * @description draws alignment guide lines on the selection canvas context
 *
 * @param {fabric.Canvas}
 * @returns void
 */
export function initAligningGuidelines(canvas) {
    const ctx = canvas.getSelectionContext()

    var viewportTransform, zoom = canvas.getZoom()

    function drawVerticalLines(coords) {
        drawLine(
            coords.x,
            coords.y1 > coords.y2 ? coords.y2 : coords.y1,
            coords.x,
            coords.y2 > coords.y1 ? coords.y2 : coords.y1,
            "vertical"
        )
    }

    function drawHorizontalLines(coords) {
        drawLine(
            coords.x1 > coords.x2 ? coords.x2 : coords.x1,
            coords.y,
            coords.x2 > coords.x1 ? coords.x2 : coords.x1,
            coords.y,
            "horizontal"
        )
    }

    /**
     * Draw the guide lines
     *
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @param {string} type
     */
    function drawLine(x1, y1, x2, y2, type) {
        ctx.save()

        const zoom = canvas.getZoom()

        ctx.lineWidth = ALIGNING_LINE_WIDTH / zoom
        ALIGNING_LINE_DASH && ctx.setLineDash([5 / zoom])
        ctx.strokeStyle = ALIGNING_LINE_COLOR
        ctx.transform(...viewportTransform)

        if (type === "vertical") {
            ctx.beginPath()
            ctx.moveTo(x1 - 3 / zoom, y1)
            ctx.lineTo(x1 + 3 / zoom, y1)
            ctx.stroke()

            ctx.beginPath()
            ctx.moveTo(x2 - 3 / zoom, y2)
            ctx.lineTo(x2 + 3 / zoom, y2)
            ctx.stroke()
        } else {
            ctx.beginPath()
            ctx.moveTo(x1, y1 - 3 / zoom)
            ctx.lineTo(x1, y1 + 3 / zoom)
            ctx.stroke()

            ctx.beginPath()
            ctx.moveTo(x2, y2 - 3 / zoom)
            ctx.lineTo(x2, y2 + 3 / zoom)
            ctx.stroke()
        }

        ctx.beginPath()
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.stroke()

        ctx.restore()
    }

    function isInRange(value1, value2) {
        const zoom = canvas.getZoom()
        value1 = Math.round(value1)
        value2 = Math.round(value2)
        for (
            let i = value1 - Math.round(CURRENT_DISTANCE_TOLERANCE / zoom),
                len = value1 + Math.round(CURRENT_DISTANCE_TOLERANCE / zoom);
            i <= len;
            i++
        ) {
            if (i === value2) return true
        }
        return false
    }
    function isInRange2(value1, value2) {
        return Math.abs(value1 - value2) <= DISTANCE_TOLERANCE;
    }

    var verticalLines = []
    var horizontalLines = []
    var horizontalDistance = null
    var verticalDistance = null
    var widthDistance = null
    var heightDistance = null
    var movementLock = null
    var numberGuide = null
    var dualNumbersGuide = null

    /**
     *
     * @param {fabric.Group} group
     * @param {fabric.Point} centerPoint
     * @param {string} originX
     * @param {string} originY
     * @param {boolean} reduce
     */
    function setGroupedPointByOriginRecursively(group, centerPoint, originX, originY, reduce = false) {
        if (reduce) {
            centerPoint.x -= group.getPointByOrigin("center", "center").x
            centerPoint.y -= group.getPointByOrigin("center", "center").y
        } else {
            centerPoint.x += group.getPointByOrigin("center", "center").x
            centerPoint.y += group.getPointByOrigin("center", "center").y
        }

        if (group.group) {
            setGroupedPointByOriginRecursively(group.group, centerPoint, originX, originY, reduce)
        }
    }

    canvas.on("object:moving", (e) => calculateAlignmentGuides(e, DISTANCE_TOLERANCE))
    // canvas.on("object:resizing", (e) => calculateAlignmentGuides(e, SCALING_DISTANCE_TOLERANCE))
    // canvas.on("object:scaling", (e) => calculateAlignmentGuides(e, SCALING_DISTANCE_TOLERANCE))
    canvas.on({
        "mouse:up": onMouseUp,
        "mouse:down": onMouseDown,
        "before:render": beforeRender,
        "after:render": afterRender,
    })

    function calculateAlignmentGuides({target, transform, e: {shiftKey, ctrlKey},}, tolerance = DISTANCE_TOLERANCE) {
        CURRENT_DISTANCE_TOLERANCE = tolerance;
        const propsToExclude = ['p1','p2','p3','pX','square1','square2','c1','c2','custom_image_name']
        if (!transform || !target || propsToExclude.includes(target.name)) return;
        var snappedWidth = false
        var snappedPosition = false
        viewportTransform = canvas.viewportTransform

        var objectsToCompare = []

        const centerPoint = target.getCenterPoint()

        const anchorX = transform.originX
        const anchorY = transform.originY

        const anchorPoint = target.translateToOriginPoint(
            centerPoint,
            anchorX,
            anchorY
        )

        objectsToCompare = canvas.getObjects().filter((obj) => {
            return !obj.group &&
                ((target._objects && !target._objects.includes(obj)) ||
                    (target.children && !target.children.includes(obj)) ||
                    (!target.children && !target._objects)) &&
                obj.isOnScreen() &&
                obj !== target &&
                obj.visible &&
                obj.evented && !propsToExclude.includes(obj.name)

        });

        var targetCenter = target.getPointByOrigin("center", "center")
        if (target.group) {
            setGroupedPointByOriginRecursively(
                target.group,
                targetCenter,
                "center",
                "center"
            )
        }

        const targetBoundingRect = target.getBoundingRect(true)
        const targetWidth = targetBoundingRect.width
        const targetHeight = targetBoundingRect.height
        let targetHalfWidth = targetWidth / 2;
        let targetHalfHeight = targetHeight / 2;
        const targetLeft = targetCenter.x - targetHalfWidth
        const targetRight = targetCenter.x + targetHalfWidth
        const targetTop = targetCenter.y - targetHalfHeight
        const targetBottom = targetCenter.y + targetHalfHeight

        var horizontalInTheRange = false
        var verticalInTheRange = false

        const edge = {}
        const sizeEdge = {}

        //canvas left edge
        if (isInRange2(targetCenter.x, targetWidth/2)) {
            if (transform.action === "drag") {
                snappedPosition = true
                edge.x = targetWidth/2
            }
            if (target.__snappedTo) {
                verticalInTheRange = true
                verticalLines.push({
                    x: 0,
                    y1: 0,
                    y2: canvas.height,
                })
            }
        }
        //canvas right edge
        if (isInRange2((targetCenter.x + targetWidth/2), canvas.width)) {
            if (transform.action === "drag") {
                snappedPosition = true
                edge.x = canvas.width - targetWidth/2
            }

            if (target.__snappedTo) {
                verticalInTheRange = true
                verticalLines.push({
                    x: targetCenter.x + targetWidth/2,
                    y1: 0,
                    y2: canvas.height,
                })
            }
        }

        // canvas top edge
        if (isInRange2(targetCenter.y, targetHeight/2)) {
            if (transform.action === "drag") {
                snappedPosition = true
                edge.y = targetHeight/2
            }

            if (target.__snappedTo) {
                horizontalInTheRange = true
                horizontalLines.push({
                    y: 0,
                    x1: 0,
                    x2: canvas.width,
                })
            }
        }
        //Canvas center snap
        if (isInRange(targetCenter.y, canvas.height/2)) {
            if (transform.action === "drag") {
                snappedPosition = true
                edge.y = canvas.height/2
            }

            if (target.__snappedTo) {
                horizontalInTheRange = true
                horizontalLines.push({
                    y: canvas.height/2,
                    x1: 0,
                    x2: canvas.width
                })
            }
        }
        //Canvas center snap
        if (isInRange(targetCenter.x, canvas.width/2)) {
            if (transform.action === "drag") {
                snappedPosition = true
                edge.x = canvas.width/2
            }
            if (target.__snappedTo) {
                verticalInTheRange = true;
                verticalLines.push({
                    x: canvas.width/2,
                    y1: 0,
                    y2: canvas.height
                });
            }
        }
        // canvas bottom edge
        if (isInRange2((targetCenter.y + targetHeight/2), canvas.height)) {
            if (transform.action === "drag") {
                snappedPosition = true
                edge.y = canvas.height - targetHeight/2
            }

            if (target.__snappedTo) {
                horizontalInTheRange = true
                horizontalLines.push({
                    y: (targetCenter.y + targetHeight/2),
                    x1: 0,
                    x2: canvas.width,
                })
            }
        }

        objectsToCompare.forEach((object) => {
            let verticalLinesLength = verticalLines.length
            let horizontalLinesLength = horizontalLines.length
            var objectCenter = object.getPointByOrigin("center", "center")
            if (object.group) {
                setGroupedPointByOriginRecursively(
                    object.group,
                    objectCenter,
                    "center",
                    "center"
                )
            }

            const objectBoundingRect = object.getBoundingRect(true)
            const objectWidth = objectBoundingRect.width
            const objectHeight = objectBoundingRect.height
            let halfObjectWidth = objectWidth / 2
            let halfObjectHeight = objectHeight / 2
            const objectLeft = objectCenter.x - halfObjectWidth
            const objectRight = objectCenter.x + halfObjectWidth
            const objectTop = objectCenter.y - halfObjectHeight
            const objectBottom = objectCenter.y + halfObjectHeight

            //========================================================
            // snap by the left edge
            if (isInRange(objectLeft, targetLeft)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectLeft + targetWidth / 2
                } else {
                    if (
                        transform.corner === "ml" ||
                        transform.corner === "tl" ||
                        transform.corner === "bl"
                    ) {
                        snappedWidth = true
                        sizeEdge.x = objectLeft
                    }
                }

                if (target.__snappedTo) {
                    const y = target.__snappedTo.y
                    const h = targetHeight / 2

                    verticalInTheRange = true
                    verticalLines.push({
                        x: objectLeft,
                        y1: objectCenter.y < targetCenter.y ? objectBottom : objectTop,
                        y2: targetCenter.y > objectCenter.y ? y - h : y + h,
                    })
                }
            }

            // snap by the top edge
            if (isInRange(objectTop, targetTop)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectTop + targetHeight / 2
                } else {
                    if (target.angle === 0 || target.angle === 360) {
                        if (
                            transform.corner === "mt" ||
                            transform.corner === "tr" ||
                            transform.corner === "tl"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectTop
                        }
                    } else if (target.angle === 90) {
                        if (
                            transform.corner === "ml" ||
                            transform.corner === "tl" ||
                            transform.corner === "bl"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectTop
                        }
                    } else if (target.angle === 180) {
                        if (
                            transform.corner === "mb" ||
                            transform.corner === "bl" ||
                            transform.corner === "br"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectTop
                        }
                    } else if (target.angle === 270) {
                        if (
                            transform.corner === "mr" ||
                            transform.corner === "tr" ||
                            transform.corner === "br"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectTop
                        }
                    }
                }

                if (target.__snappedTo) {
                    horizontalInTheRange = true
                    horizontalLines.push({
                        y: objectTop,
                        x1: objectCenter.x < targetCenter.x ? objectRight : objectLeft,
                        x2: targetCenter.x > objectCenter.x ? targetLeft : targetRight,
                    })
                }
            }

            //========================================================
            // snap by the right edges
            if (isInRange(objectRight, targetRight)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectRight - targetWidth / 2
                } else {
                    if (
                        transform.corner === "mr" ||
                        transform.corner === "tr" ||
                        transform.corner === "br"
                    ) {
                        snappedWidth = true
                        sizeEdge.x = objectRight
                    }
                }

                if (target.__snappedTo) {
                    const y = target.__snappedTo.y
                    const h = targetHeight / 2

                    verticalInTheRange = true
                    verticalLines.push({
                        x: objectRight,
                        y1: objectCenter.y < targetCenter.y ? objectBottom : objectTop,
                        y2: targetCenter.y > objectCenter.y ? y - h : y + h,
                    })
                }
            }

            if (isInRange(objectRight, targetLeft)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectRight + targetWidth / 2
                } else {
                    if (
                        transform.corner === "ml" ||
                        transform.corner === "tl" ||
                        transform.corner === "bl"
                    ) {
                        snappedWidth = true
                        sizeEdge.x = objectRight
                    }
                }

                if (target.__snappedTo) {
                    const y = target.__snappedTo.y
                    const h = targetHeight / 2

                    verticalInTheRange = true
                    verticalLines.push({
                        x: objectRight,
                        y1: targetCenter.y > objectCenter.y ? y - h : y + h,
                        y2: targetCenter.y > objectCenter.y ? objectBottom : objectTop,
                    })
                }
            }

            if (isInRange(objectLeft, targetRight)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectLeft - targetWidth / 2
                } else {
                    if (
                        transform.corner === "mr" ||
                        transform.corner === "tr" ||
                        transform.corner === "br"
                    ) {
                        snappedWidth = true
                        sizeEdge.x = objectLeft
                    }
                }

                if (target.__snappedTo) {
                    const y = target.__snappedTo.y
                    const h = targetHeight / 2

                    verticalInTheRange = true
                    verticalLines.push({
                        x: objectLeft,
                        y1: objectCenter.y < targetCenter.y ? objectBottom : objectTop,
                        y2: targetCenter.y > objectCenter.y ? y - h : y + h,
                    })
                }
            }

            // snap by the bottom edges
            if (isInRange(objectBottom, targetBottom)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectBottom - targetHeight / 2
                } else {
                    if (
                        transform.corner === "mb" ||
                        transform.corner === "br" ||
                        transform.corner === "bl"
                    ) {
                        snappedWidth = true
                        sizeEdge.y = objectBottom
                    }
                }

                if (target.__snappedTo) {
                    const x = target.__snappedTo.x
                    const w = targetWidth / 2

                    horizontalInTheRange = true
                    horizontalLines.push({
                        y: objectBottom,
                        x1: objectCenter.x < targetCenter.x ? objectRight : objectLeft,
                        x2: targetCenter.x > objectCenter.x ? x - w : x + w,
                    })
                }
            }

            if (isInRange(objectBottom, targetTop)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectBottom + targetHeight / 2
                } else {
                    if (target.angle === 0 || target.angle === 360) {
                        if (
                            transform.corner === "mt" ||
                            transform.corner === "tr" ||
                            transform.corner === "tl"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectBottom
                        }
                    } else if (target.angle === 90) {
                        if (
                            transform.corner === "ml" ||
                            transform.corner === "tl" ||
                            transform.corner === "bl"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectBottom
                        }
                    } else if (target.angle === 180) {
                        if (
                            transform.corner === "mb" ||
                            transform.corner === "bl" ||
                            transform.corner === "br"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectBottom
                        }
                    } else if (target.angle === 270) {
                        if (
                            transform.corner === "mr" ||
                            transform.corner === "tr" ||
                            transform.corner === "br"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectBottom
                        }
                    }
                }

                if (target.__snappedTo) {
                    horizontalInTheRange = true
                    horizontalLines.push({
                        y: objectBottom,
                        x1: objectCenter.x < targetCenter.x ? objectRight : objectLeft,
                        x2: targetCenter.x > objectCenter.x ? targetLeft : targetRight,
                    })
                }
            }

            if (isInRange(objectTop, targetBottom)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectTop - targetHeight / 2
                } else {
                    if (target.angle === 0 || target.angle === 360) {
                        if (
                            transform.corner === "mb" ||
                            transform.corner === "br" ||
                            transform.corner === "bl"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectTop
                        }
                    } else if (target.angle === 90) {
                        if (
                            transform.corner === "mr" ||
                            transform.corner === "tr" ||
                            transform.corner === "br"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectTop
                        }
                    } else if (target.angle === 180) {
                        if (
                            transform.corner === "mt" ||
                            transform.corner === "tl" ||
                            transform.corner === "tr"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectTop
                        }
                    } else if (target.angle === 270) {
                        if (
                            transform.corner === "ml" ||
                            transform.corner === "tl" ||
                            transform.corner === "bl"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectTop
                        }
                    }
                }

                if (target.__snappedTo) {
                    horizontalInTheRange = true
                    horizontalLines.push({
                        y: objectTop,
                        x1: objectCenter.x < targetCenter.x ? objectRight : objectLeft,
                        x2: targetCenter.x > objectCenter.x ? targetLeft : targetRight,
                    })
                }
            }

            // Snap edges to centers
            if (isInRange(objectCenter.x, targetRight)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectCenter.x - targetWidth / 2
                } else {
                    if (
                        transform.corner === "mr" ||
                        transform.corner === "tr" ||
                        transform.corner === "br"
                    ) {
                        snappedWidth = true
                        sizeEdge.x = objectCenter.x
                    }
                }

                if (target.__snappedTo) {
                    const y = target.__snappedTo.y
                    const h = targetHeight / 2

                    verticalInTheRange = true
                    verticalLines.push(
                        {
                            x: objectCenter.x,
                            y1: targetCenter.y < objectCenter.y ? y - h : y + h,
                            y2: objectBottom,
                        },
                        {
                            x: objectCenter.x,
                            y1: objectBottom,
                            y2: objectTop,
                        }
                    )
                }
            }

            if (isInRange(objectCenter.x, targetLeft)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectCenter.x + targetWidth / 2
                } else {
                    if (
                        transform.corner === "ml" ||
                        transform.corner === "tl" ||
                        transform.corner === "bl"
                    ) {
                        snappedWidth = true
                        sizeEdge.x = objectCenter.x
                    }
                }

                if (target.__snappedTo) {
                    const y = target.__snappedTo.y
                    const h = targetHeight / 2

                    verticalInTheRange = true
                    verticalLines.push(
                        {
                            x: objectCenter.x,
                            y1: targetCenter.y < objectCenter.y ? y - h : y + h,
                            y2: objectBottom,
                        },
                        {
                            x: objectCenter.x,
                            y1: objectBottom,
                            y2: objectTop,
                        }
                    )
                }
            }

            if (isInRange(objectCenter.y, targetTop)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectCenter.y + targetHeight / 2
                } else {
                    if (target.angle === 0 || target.angle === 360) {
                        if (
                            transform.corner === "mt" ||
                            transform.corner === "tl" ||
                            transform.corner === "tr"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectCenter.y
                        }
                    } else if (target.angle === 90) {
                        if (
                            transform.corner === "ml" ||
                            transform.corner === "tl" ||
                            transform.corner === "bl"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectCenter.y
                        }
                    } else if (target.angle === 180) {
                        if (
                            transform.corner === "mb" ||
                            transform.corner === "bl" ||
                            transform.corner === "br"
                        ) {
                            snappedWidth = true
                            sizeEdge.y = objectCenter.y
                        }
                    } else if (target.angle === 270) {
                        if (
                            transform.corner === "mr" ||
                            transform.corner === "tr" ||
                            transform.corner === "br"
                        ) {
                            snappedWidth = true
                            sizeEdge.x = objectCenter.y
                        }
                    }
                }

                if (target.__snappedTo) {
                    horizontalInTheRange = true
                    horizontalLines.push(
                        {
                            y: objectCenter.y,
                            x1: targetCenter.x < objectCenter.x ? targetRight : targetLeft,
                            x2: objectLeft,
                        },
                        {
                            y: objectCenter.y,
                            x1: objectLeft,
                            x2: objectRight,
                        }
                    )
                }
            }

            if (isInRange(objectCenter.y, targetBottom)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectCenter.y - targetHeight / 2
                } else {
                    if (
                        transform.corner === "mb" ||
                        transform.corner === "bl" ||
                        transform.corner === "br"
                    ) {
                        snappedWidth = true
                        sizeEdge.y = objectCenter.y
                    }
                }

                if (target.__snappedTo) {
                    const x = target.__snappedTo.x
                    const w = targetWidth / 2

                    horizontalInTheRange = true
                    horizontalLines.push(
                        {
                            y: objectCenter.y,
                            x1: targetCenter.x < objectCenter.x ? x - w : x + w,
                            x2: objectLeft,
                        },
                        {
                            y: objectCenter.y,
                            x1: objectLeft,
                            x2: objectRight,
                        }
                    )
                }
            }

            // snap by the centers
            if (isInRange(objectCenter.x, targetCenter.x)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectCenter.x
                }

                if (target.__snappedTo) {
                    verticalInTheRange = true
                    verticalLines.push(
                        {
                            x: objectCenter.x,
                            y1: target.__snappedTo.y,
                            y2: objectTop,
                        },
                        {
                            x: objectCenter.x,
                            y1: target.__snappedTo.y,
                            y2: objectBottom,
                        }
                    )
                }
            }

            if (isInRange(objectLeft, targetCenter.x)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectLeft
                }

                if (target.__snappedTo) {
                    verticalInTheRange = true
                    verticalLines.push(
                        {
                            x: objectLeft,
                            y1: target.__snappedTo.y,
                            y2: objectTop,
                        },
                        {
                            x: objectLeft,
                            y1: target.__snappedTo.y,
                            y2: objectBottom,
                        }
                    )
                }
            }

            if (isInRange(objectRight, targetCenter.x)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.x = objectRight
                }

                if (target.__snappedTo) {
                    verticalInTheRange = true
                    verticalLines.push(
                        {
                            x: objectRight,
                            y1: target.__snappedTo.y,
                            y2: objectTop,
                        },
                        {
                            x: objectRight,
                            y1: target.__snappedTo.y,
                            y2: objectBottom,
                        }
                    )
                }
            }

            if (isInRange(objectCenter.y, targetCenter.y)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectCenter.y
                }

                if (target.__snappedTo) {
                    horizontalInTheRange = true
                    horizontalLines.push(
                        {
                            y: objectCenter.y,
                            x1: target.__snappedTo.x,
                            x2: objectLeft,
                        },
                        {
                            y: objectCenter.y,
                            x1: target.__snappedTo.x,
                            x2: objectRight,
                        }
                    )
                }
            }

            if (isInRange(objectBottom, targetCenter.y)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectBottom
                }

                if (target.__snappedTo) {
                    horizontalInTheRange = true
                    horizontalLines.push(
                        {
                            y: objectBottom,
                            x1: target.__snappedTo.x,
                            x2: objectLeft,
                        },
                        {
                            y: objectBottom,
                            x1: target.__snappedTo.x,
                            x2: objectRight,
                        }
                    )
                }
            }

            if (isInRange(objectTop, targetCenter.y)) {
                if (transform.action === "drag") {
                    snappedPosition = true
                    edge.y = objectTop
                }

                if (target.__snappedTo) {
                    horizontalInTheRange = true
                    horizontalLines.push(
                        {
                            y: objectTop,
                            x1: target.__snappedTo.x,
                            x2: objectLeft,
                        },
                        {
                            y: objectTop,
                            x1: target.__snappedTo.x,
                            x2: objectRight,
                        }
                    )
                }
            }

            if (object.type === 'guideLine') {
                verticalLines.length = verticalLinesLength;
                horizontalLines.length = horizontalLinesLength;
            }
        })

        if (!horizontalInTheRange) {
            horizontalLines.length = 0
            delete target.__snappedTo
        }

        if (!verticalInTheRange) {
            verticalLines.length = 0
            delete target.__snappedTo
        }

        if (transform.action === "drag") {
            if (snappedPosition) {
                // create snap point
                const point = new fabric.Point(
                    typeof edge.x === "number" ? edge.x : targetCenter.x,
                    typeof edge.y === "number" ? edge.y : targetCenter.y
                )

                const position = {...point}

                if (target.group) {
                    setGroupedPointByOriginRecursively(
                        target.group,
                        position,
                        "center",
                        "center",
                        true
                    )
                }

                // snap to point on move
                target.setPositionByOrigin(
                    {
                        x: position.x,
                        y: position.y,
                    },
                    "center",
                    "center"
                )
                target.__snappedTo = point
                target.setCoords()
            }
        } else {
            if (snappedWidth) {
                // create snap point
                const point = new fabric.Point(
                    typeof sizeEdge.x === "number" ? sizeEdge.x : targetCenter.x,
                    typeof sizeEdge.y === "number" ? sizeEdge.y : targetCenter.y
                )

                // Rotate the snap point based on the target object's angle
                const rotatedPoint = fabric.util.rotatePoint(
                    point,
                    target.getCenterPoint(),
                    fabric.util.degreesToRadians(target.angle)
                )
                const position = {...rotatedPoint}

                if (target.group) {
                    setGroupedPointByOriginRecursively(target.group, position, "center", "center", true)
                }

                target.setPositionByOrigin(anchorPoint, anchorX, anchorY)

                target.__snappedTo = point
                target.setCoords()
            }
        }
    }

    function onMouseDown() {
        viewportTransform = canvas.viewportTransform
        zoom = canvas.getZoom()
    }

    function beforeRender() {
        if(canvas?.contextTop) canvas?.clearContext(canvas.contextTop)
    }

    function afterRender() {
        if (canvas._activeObject?.type === "guideLine") {
            movementLock = null
            horizontalDistance = null
            verticalDistance = null
            widthDistance = null
            heightDistance = null
            verticalLines.length = horizontalLines.length = 0
        }

        verticalLines.slice(0, 4).forEach((line) => {
            drawVerticalLines(line)
        })
        horizontalLines.slice(0, 4).forEach((line) => {
            drawHorizontalLines(line)
        })

        movementLock = null
        horizontalDistance = null
        verticalDistance = null
        widthDistance = null
        heightDistance = null
        verticalLines.length = horizontalLines.length = 0
    }

    function onMouseUp() {
        verticalLines.length = horizontalLines.length = 0
        movementLock = null
        numberGuide = null
        dualNumbersGuide = null
        canvas.requestRenderAll()
    }
}