export const updateLines = (obj,canvas,svgPathElements,setSvgPathElementss,updateFrameObjects, updateObjectDPath, activeFrameVal,updateRemovedProps)=>{
        if (obj?.name?.startsWith("custom_image_")) {
            let newObInd = canvas._objects.findIndex(i => (i.name === 'player_custom_image' || i.objecttype === 'sprite-image' || i.name === 'player') && i.ref_id === obj.ref_id);
            if (newObInd > -1) {
                obj = canvas._objects[newObInd];
            }
        }
        const objInd = canvas._objects.findIndex((o) => o.ref_id === `${obj.ref_id}__shadow-object` && o.name === "shadow-line");
        if (objInd > -1) {
            canvas._objects[objInd].path[1][3] = obj.left;
            canvas._objects[objInd].path[1][4] = obj.top;
            let actObjInd = svgPathElements.findIndex((el) => el.id === `line-svg:${activeFrameVal.data_num}:${canvas._objects[objInd].ref_id}`)
            let nextObjInd = svgPathElements.findIndex((el) => el.id === `line-svg:${activeFrameVal.data_num + 1}:${canvas._objects[objInd].ref_id}`)
            if (actObjInd > -1) {
                let actObj = { ...svgPathElements[actObjInd] };
                let d = actObj.d.split(" ");
                d[4] = canvas._objects[objInd].path[1][1].toString();
                d[5] = canvas._objects[objInd].path[1][2].toString();
                d[6] = obj.left.toString();
                d[7] = obj.top.toString();
                actObj.d = d.join(' ');
                svgPathElements[actObjInd] = actObj;
                updateRemovedProps(false,{svgPathElements});
                setSvgPathElementss([...svgPathElements])
                updateObjectDPath(actObj, d);
                // canvas.renderAll()
            }
            if (nextObjInd > -1) {
                let actObj = { ...svgPathElements[nextObjInd] };
                let d = actObj.d.split(" ");
                d[1] = obj.left.toString();
                d[2] = obj.top.toString();
                actObj.d = d.join(' ');
                svgPathElements[nextObjInd] = actObj;
                updateRemovedProps(false,{svgPathElements});
                setSvgPathElementss([...svgPathElements])
                updateObjectDPath(actObj, d, activeFrameVal.data_num + 1);
                // canvas.renderAll()
            }
            updateFrameObjects("update", obj);
        }

}

export const updateLinesHelper = (props)=>{
    // The code executes after the movedObject and cancelMovement function
    let {
        activeObject,svgPathElements,setSvgPathElementss,updateObjectDPath,canvas,updateRemovedProps,activeFrameVal,render
    } = props;
    let refId = activeObject.ref_id;
    if (activeObject.name.startsWith('custom_image_')) {
        let imgInd = canvas._objects.findIndex(f => (f.name === 'player_custom_image' || f.objecttype === 'sprite-image' || f.name === 'player') && f.ref_id === refId);
        if (imgInd > -1) {
            activeObject = canvas._objects[imgInd];
        }
    }
    if (activeObject?.name === "pX") refId = refId.split('::')[0];
    if (!refId.includes("__shadow-object")) refId = `${refId}__shadow-object`

    const shodowLineInd = canvas._objects.findIndex((o) => o.ref_id === refId && o.name === "shadow-line");
    if (svgPathElements[0] === undefined) return
    let actObjInd = svgPathElements.findIndex((el) => el.id === `line-svg:${activeFrameVal.data_num}:${refId}`)
    let nextObjInd = svgPathElements.findIndex((el) => el.id === `line-svg:${activeFrameVal.data_num + 1}:${refId}`)
    if (actObjInd > -1 && shodowLineInd > -1) {
        canvas._objects[shodowLineInd].path[1][3] = activeObject.left;
        canvas._objects[shodowLineInd].path[1][4] = activeObject.top;
        let currObj = { ...svgPathElements[actObjInd] };
        let d = currObj.d.split(" ");
        d[4] = canvas._objects[shodowLineInd].path[1][1].toString();
        d[5] = canvas._objects[shodowLineInd].path[1][2].toString();
        d[6] = activeObject.left.toString();
        d[7] = activeObject.top.toString();
        currObj.d = d.join(' ');
        svgPathElements[actObjInd] = currObj;
        setSvgPathElementss([...svgPathElements])
        updateObjectDPath(currObj, d);
    }
    if (nextObjInd > -1) {
        let nextObj = { ...svgPathElements[nextObjInd] };
        let d = nextObj.d.split(" ");
        d[1] = activeObject.left.toString();
        d[2] = activeObject.top.toString();
        d[4] = ((activeObject.left + parseFloat(d[6])) / 2).toString();
        d[5] = ((activeObject.top + parseFloat(d[7])) / 2).toString();
        nextObj.d = d.join(' ');
        svgPathElements[nextObjInd] = nextObj;
        setSvgPathElementss([...svgPathElements])
        updateObjectDPath(nextObj, d, activeFrameVal.data_num + 1);
    }
    render && canvas.renderAll();

}