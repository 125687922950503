import React from 'react';
import {FormattedMessage} from "react-intl";
import {Button} from "antd";
import {SvgIcon} from "@mui/material";
import {LargeSizeIcon, MediumSizeIcon, SmallSizeIcon} from "../../assets/icons";
import {panelKeys} from "./index";
import {setPlayerSizes} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {getMobileView} from "../helpers";
import TogglePlayerNumbers from "./TogglePlayerNumbers";

const PlayerSize = () => {
    const activeKey = useSelector(state => state.activePanel);
    const {playerSizes} = useSelector(state => state.player);
    const dispatch = useDispatch();
    const changePlayerSize = (size) => {
        let sizeObj = {...playerSizes};
        if (activeKey) {
            if (activeKey === '1') sizeObj = {...sizeObj, players: size};
            if (activeKey === '2') sizeObj = {...sizeObj, goalKeepers: size};
            if (activeKey === '8') sizeObj = {...sizeObj, playerSquad: size};
            if (activeKey === '9') sizeObj = {...sizeObj, playerSquadNumbers: size};
            if (activeKey === '12') sizeObj = {...sizeObj, playerPositions: size};
            if (activeKey === '7') sizeObj = {...sizeObj, playerFormation: size};
            if (activeKey === '13') sizeObj = {...sizeObj, playerShirts: size};
            if (['10', '11'].includes(activeKey)) sizeObj = {...sizeObj, playerNo: size};
            dispatch(setPlayerSizes(sizeObj));
        }
    }
    return (
        <div className={`size_container`}>
            <div className="switch_container">
                <span className="size_title"> <FormattedMessage id="general.size"/></span>
            </div>
            <div className="expandable_sizes size-buttons">
                <Button onClick={() => changePlayerSize('small')} icon={<SvgIcon><SmallSizeIcon
                    fill={playerSizes[panelKeys[activeKey]] === 'small' ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
                <Button onClick={() => changePlayerSize('medium')} icon={<SvgIcon><MediumSizeIcon
                    fill={playerSizes[panelKeys[activeKey]] === 'medium' ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
                <Button onClick={() => changePlayerSize('large')} icon={<SvgIcon><LargeSizeIcon
                    fill={playerSizes[panelKeys[activeKey]] === 'large' ? 'blue' : getMobileView() ? '#000000' : 'white'}/></SvgIcon>}/>
            </div>
        </div>
    )
}
export default PlayerSize;