import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {fabric} from "fabric";
import {Col, Drawer, message, Modal, Row, notification} from 'antd';
import Modals from './components/Modals/modals';
import {injectIntl} from 'react-intl';
import PleaseRotate from './components/PleaseRotate';
import './utils/fabric-overrides';
import {initAligningGuidelines} from './utils/guideLines';
import {setCustomPlayerImageDimensions} from './utils/addCustomPlayerImage';
import {
    BASE_URL_ASSETS,
    checkHebrew,
    defaultTime,
    hideOpacity,
    shadow,
    showOpacity
} from './utils/utils';
import TacticsBoardTools from './components/tools';
import TacticsBoardHeader from './components/header';
import './tacticsboard.scss';
import {useDispatch, useSelector} from 'react-redux';
import {
    setAnimation,
    setAnimationStates,
    setBackground,
    setBrush,
    setColorPopup,
    setColorsState,
    setConnect,
    setDrawState,
    setEditingMode,
    setObjectsState,
    setObjUpdatedType,
    setSelectedColor,
    setShowDrawer,
    setTools,
} from './actions';
import TacticsBoardBackgrounds from './components/backgrounds';
import TacticsBoardAnimationsActions from './components/animations';
import TacticsBoardToolsHeader from './components/tacticsboardtoolsheader';
import PathShadowHolder from './components/tools/pathHolder';
import "./components/fabric/AnnotateLine";
import {FormationLoading, Loading} from './loadinga';
import {handleArrowObjectUpdate} from "./utils/updateArrowObject";
import {handleReaddedText} from "./utils/reAddImageTextObjs";
import {handleObjectUpdate} from "./utils/updateObjectsStates";
import {handleToolsClick} from "./utils/toolsClick";
import {reinitLineObject} from "./utils/lines/reinitLineObject";
import {handleObjectType} from "./utils/updateObjectType";
import {getFabricImageFromURL, handleImages} from "./utils/addImage";
import {addPlayerShirt, handleAddPlayer, handleAddPlayerTriangle} from "./utils/addPlayer";
import {applyWidthChangeEffects} from "./utils/lines/reinitAfterWidthChange";
import {
    actionHandler,
    anchorWrapper, freeShapeReInitialized,
    getControls,
    handleFreeShape,
} from "./utils/shapes/freeShape";
import {cancelMovement} from "./utils/cancelMovement";
import {createArrow} from "./utils/lines/drawArrow";
import {sendBackShapes} from "./utils/shapes/shapeUtils";
import {exitFullScreen, fullScreen} from "./utils/screen-resolutions/screenUtils";
import {reinitializeLines} from "./utils/lines/reinitLines";
import {setBrushType} from "./utils/canvasUtils/brushType";
import {handleTextSelection} from "./utils/clearTextSelection";
import {handleCenterPoint} from "./utils/lines/setCenterCurvePoint";
import {handleCurveActivePoint} from "./utils/lines/setCurveActivePoint";
import {handleCurveShadowPoint} from "./utils/lines/makeShadowCurvePoint";
import {handleClearSelection} from "./utils/lines/clearSelection";
import {handleFirstName} from "./utils/players/getSurName";
import {createCurveCircle, createCurvePoint} from "./utils/lines/makeCurve";
import {handleKeyEvents} from "./utils/keyEvents";
import {useInitProcess} from "./customHooks/useInitProcess";
import {usePlayAnimation} from "./customHooks/usePlayAnimation";
import {useStopAnimation} from "./customHooks/useStopAnimation";
import {CanvasReloader} from "./utils/canvasReloader";
import {useRemoveFrame} from "./customHooks/useRemoveFrame";
import {useAddFrame} from "./customHooks/useAddFrame";
import {updateShadowLine} from "./utils/HandleShadowObjs/updateShadowLine";
import {manageDeletedObjs} from "./utils/HandleShadowObjs/deleteShadowObjects";
import {handleUpdateControls} from "./utils/Object-Controls/canvasUpdateControls";
import {canvasSetBorders} from "./utils/Object-Controls/canvasSetBorders";
import {useUpdateObjects} from "./customHooks/useUpdateObjects";
import {useUpdateAll} from "./customHooks/useUpdateAll";
import {updateAllProperties} from "./utils/updateAllProps";
import {drawBezierCurveArrow} from "./utils/lines/QuadraticLines/drawQuadraticArrow";
import {drawBezierCurve} from "./utils/lines/QuadraticLines/drawQuadratic";
import {initializePath} from "./utils/lines/QuadraticLines/reinitpath";
import {updateLines, updateLinesHelper} from "./utils/HandleShadowObjs/updateSelectedObjsLine";
import {addShadowHelper} from "./utils/HandleShadowObjs/addShadowObjects";
import {useDeleteObject} from "./customHooks/useDeleteObject";
import {reArrangeHelper} from "./utils/HandleShadowObjs/reArrangeShadow";
import {updateCanvasResize} from "./utils/canvasUtils/updateCanvasResize";
import {freeDrawHelper} from "./utils/lines/FreeDraw/updateFreeDraw";
import {handleUndoUpdates} from "./utils/undo-redo-events/updateUndoStates";
import {handleBeforeClick} from "./utils/MouseEvents/mouseDownBefore";
import {addClones} from "./utils/HandleShadowObjs/addDuplicateObject";
import {useCreateSelection} from "./customHooks/Fabric Events/useCreateSelection";
import {useUpdateSelection} from "./customHooks/Fabric Events/useUpdateSelection";
import {useModify} from "./customHooks/Fabric Events/useModify";
import {useObjectMoving} from "./customHooks/Fabric Events/useObjectMoving";
import {useMovedObject} from "./customHooks/Fabric Events/useMovedObject";
import {moveEnd2Helper, moveEndHelper} from "./utils/lines/moveEnd";
import {moveLine} from "./utils/lines/moveLine";
import {saveHelper} from "./utils/savingCanvasData";
import {useUndo} from "./customHooks/Undo-Redo Manager/useUndo";
import {useScaling} from "./customHooks/Fabric Events/useScaling";
import {useRedo} from "./customHooks/Undo-Redo Manager/useRedo";
import {collectData} from "./utils/collectData";
import {getContainerHeight} from "./utils/canvasUtils/canvasContainerHeight";
import {checkEventedObjects, dropMove, dropObj, dropUp} from "./utils/drag-dop/dropper";
import preloadImages from "./utils/readImages";
import BottomPanel from "./MobileComponents/BottomPanel";
import LeftHeader from "./MobileComponents/LeftHeader";
import {getMobileView} from "./components/helpers";
import {duplicateObject} from "./utils/duplicate";
window.Buffer = window.Buffer || require("buffer").Buffer;
let canvas, canvas2, activeFrameVal, initWidth, initHeight, canvasInitial, animation_state = false, pitchContainerBox,
    canvasDimensions, undoStates = [], activeStateIndex = 0, draggableElement = null, canvasData = '',
    containerDimensions, scalePropsVal, dObjs;
let image=-`${BASE_URL_ASSETS}/assets/images/light_p3.jpg`;
let cDraw = false, cPoint = null,
    hasAnimationStarted = false,
    drawBrushType = '', isRecording = false, prevX = [], prevY = [], prevArX = 0, prevArY = 0,
    drawLineStates = [], tempDrawLines = [], mpLeft = 0, mpTop = 0,
    editingMode = false;
export const isMobile = (userAgent = navigator.userAgent) => /Mobi/.test(userAgent);
let isAnimationOpen = false;
let isDoneClicked = false;
let shadowFrames = [];
let svgPathElements = [];
let allFrames = [];
let deletedObjects = [];
let canvasVar;
let cb;
let eventedObjs = [];

// To DELETE ON PRODUCTION
let localCanvasDimensions = localStorage.getItem('containerDimensions');
let localBackground = localStorage.getItem('background');
let localCanvas = localStorage.getItem('canvas');
let animationsDatals = localStorage.getItem('animationData');
const api = axios.create({
    // baseURL: 'http://localhost:5001' // add the address of your own server on which node js is being hosted
    baseURL: `${process.env.REACT_APP_API_ADDRESS || 'https://api-animation.easycoach.club'}/` // add the address of your own server on which node js is being hosted
});
const TacticsBoard = ({isOld = false, intl,isBasketballClub=false, presentationMode, customImagesJson, isAnimation = false, canvasJson = localCanvas, animationsData = animationsDatals, canvasBackground = localBackground, canvasContainerDimensionsSave = localCanvasDimensions, onDone, onClose, onShowPreviousDrawings, onFailTacticsBoard, addCustomSquad, hideSquadPlayer
 }) => {
    const dispatch = useDispatch();
    const showMobileTools = (type) => {
        dispatch(setTools(type));
    }
    const clipBoard = useSelector(state => state.clipBoard);
    const toolsToShow = useSelector(state => state.tools);
    const showDrawer = useSelector(state => state.showDrawer);
    const editModeFlag = useSelector(state => state.editMode);
    const actColor = useSelector(state => state.color);
    const background = useSelector(state => state.background);
    let animationState = useSelector(state => state.animation);
    const animationStates = useSelector(state => state.animationStates);
    const bgUpdate = useSelector(state => state.backgroundUpdate);
    const objSpeed = useSelector(state => state.speed);
    const loader = useSelector(state => state.loader)


    const propsArray = ['newOpacity','strokeSrc','polygon','padding', 'selectionBackgroundColor', 'shirtNo,nameText', 'perPixelTargetFind', 'hasControls', 'hasBorders', 'selectable', 'id', 'class', 'name', 'ref_id', 'objecttype', 'x1', 'x2', 'y1', 'y2', 'pointType', 'shapeType']

    const [loading, setLoading] = useState(false);
    const [activeBar, setActiveBar] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isFullScreen, setFullScreen] = useState(false)
    const [downloadingProgress, setDownloadingStatus] = useState("Processing your video, please wait...");
    const [isPlaying, setPlay] = useState(false);
    const [frControlVisibility, setFrControlVisibility] = useState(true);
    const [closedModel, setClosedModel] = useState(false);
    const [isDownloadingVideo, setIsDownloading] = useState(false);
    const [uploadingState, setUploadingState] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState("stop");
    const [canvasBackgroundClass, setCanvasBackgroundClass] = useState(null);
    const [bPanel, showBPanel] = useState(false);
    let [hasAnimationStopped, setAnimationStatus] = useState(false);
    let [isAnimating, setIsAnimating] = useState(false);
    let [animationPlaying, setPlayStatus] = useState(false);

    const pitchRef = useRef(null);
    const canvasRef = useRef(null);

    const {handleProcess} = useInitProcess();
    const {handleStopAnimation} = useStopAnimation();
    const {handleRemoveFrame} = useRemoveFrame();
    const {appendFrame} = useAddFrame();
    const {handleFrameObjs} = useUpdateObjects();
    const {handleAllUpdate} = useUpdateAll();
    const {deleteObjectHelper} = useDeleteObject();
    const {createSelection} = useCreateSelection();
    const {updateSelection} = useUpdateSelection();
    const {modifyObject} = useModify();
    const {movingHelper} = useObjectMoving();
    const {movedHelper} = useMovedObject();
    const {undoHelper} = useUndo();
    const {redoHelper} = useRedo();
    const {scalingHelper} = useScaling();
    // Change Background Based on Version / Background selection


    const {formatMessage} = intl;

    const [activeFrameTime, setActiveFrameTime] = useState(defaultTime);
    const [activeFrame, setActiveFrame] = useState('');
    const [frames, setFrames] = useState([]);

    const [frameObjects, setFrameObjects] = useState([]);
    const [svgPathElementss, setSvgPathElementss] = useState([]);

    const reRenderCanvas = () => {
        canvas.renderAll();
    };
    const handleDragStart = (e) => {
        draggableElement = e.target
    }
    const canvasContainerHeight = (el) => {
        let props = {
            el, isAnimationOpen, isOld,bPanel
        }
        return getContainerHeight(props);
    }

    const importAll = (r) => {
        return r.keys().map(r);
    };

    useEffect(()=>{
        console.log('re-rendered')
        if (isOld) {
            setCanvasBackgroundClass("p0")
        } else if (background) {
            setCanvasBackgroundClass(background);
        } else if (canvasBackground && !closedModel) {
            let newBg = canvasBackground && canvasBackground.startsWith('p') ? canvasBackground = 'light_' + canvasBackground : canvasBackground
            setCanvasBackgroundClass(newBg);
        }
    },[isOld, background, closedModel, canvasBackground])


    useEffect(() => {
        fabric.Canvas.prototype.getAbsoluteCoords = function(object) {
            return {
                left: object.left + this._offset.left,
                top: object.top + this._offset.top,
            };
        };
    }, []);

    useEffect(()=>{
       getMobileView() &&  updateWindowDimensions();
    },[bPanel])
    useEffect(() => {
        const DarkBlue = importAll(
            require.context(
                "./assets/Attacking/DarkBlue",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const Goali = importAll(
            require.context(
                "./assets/Attacking/Goali",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const Green = importAll(
            require.context(
                "./assets/Attacking/Green",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const LightBlue = importAll(
            require.context(
                "./assets/Attacking/LightBlue",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const Red = importAll(
            require.context(
                "./assets/Attacking/Red",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const White = importAll(
            require.context(
                "./assets/Attacking/White",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const Yellow = importAll(
            require.context(
                "./assets/Attacking/Yellow",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const equipment1 = importAll(
            require.context(
                "./assets/images/AngleStand",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );

        const equipment2 = importAll(
            require.context(
                "./assets/images/Cones",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const equipment3 = importAll(
            require.context(
                "./assets/images/DummyStand",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const equipment4 = importAll(
            require.context(
                "./assets/images/Gate",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const equipment5 = importAll(
            require.context(
                "./assets/images/HomeIcons",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const equipment6 = importAll(
            require.context(
                "./assets/images/logo",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const equipment7 = importAll(
            require.context(
                "./assets/images/Stand",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        const others = importAll(
            require.context(
                "./assets/images",
                false,
                /\.(png|jpe?g|svg|webp)$/
            )
        );
        initProcess();
    }, [canvasBackgroundClass]);
    useEffect(()=>{
        let copiedObject = clipBoard.copiedObject;
        checkEventedObjects({copiedObject,canvas,setEventedObjs,eventedObjs})
        cb = clipBoard.copiedObject
    },[clipBoard])

    useEffect(() => {
        return () => {
            // Clear undo states
            undoStates = [];
            activeStateIndex = 0;
            ////
            //Clear frames
            if (canvas) {
                clearAllStates();
            }
            // close animation bar
            isAnimationOpen = false;
            dispatch(setAnimation(false));
            ////
            setCanvasBackgroundClass("");
            dispatch(setBackground(''));
            setClosedModel(true);
        }
    }, []);

    const setEventedObjs = (objs)=>{
        eventedObjs = [...objs]
    }

    const addControls = (polygon) => {
        getControls(polygon, canvas, anchorWrapper, actionHandler)
    }

    const addFreeShape = (props = null) => {
        return handleFreeShape(props, canvas, addControls)
    }

    const updatePreviousShadows = (refId) => {
        let tempFrames = allFrames ? allFrames[activeFrameVal.data_num - 1].json : frames[activeFrameVal.data_num - 1].json
        let parsedJson = JSON.parse(tempFrames);
        let targetObj = parsedJson.objects.find(f => (f.name === 'image' || f.name === 'player' || f.name === 'player_custom_image') && f.ref_id === refId)

        return {
            left: targetObj.left,
            top: targetObj.top,
        }
    }
    const addDuplicateObject = (obj, shadowObj, d, resolve, isHide) => {

        let props = {
            obj,
            shadowObj,
            d,
            resolve,
            isHide,
            updatePreviousShadows,
            canvas,
            svgPathElements,
            setSvgPathElementss,
            svgPathElementss,
            reAddImageTextObjs,
            propsArray,
            activeFrameVal,
            updateRemovedProps,
            makeShadowCurvePoint,
            scalePropsVal
        }
        addClones(props).then(()=>{
            reRenderCanvas();
        }).catch(e=>{
            console.log('error',e)
        });
    }
    const updateArrowLinesArray = () => {
        if (animationState) {
            let tempArr = canvas._objects.filter(obj => obj.name === 'line' || obj.name === 'arrow_line' || obj.name === 'arrow' || obj.name === 'Line Arrow' || obj.name === 'pa');
            shadowFrames[activeFrameVal.data_num].arrowLines = [...tempArr];
            setFrameObjects([...shadowFrames]);
        }
    }

    const addShadowObjects = (actFrame, callback, isHide = false) => {
        deleteShadowObjects(() => {
            addShadowHelper(canvas, actFrame, callback, isHide, activeFrameVal, shadowFrames, animationPlaying, deletedObjects, addDuplicateObject,reRenderCanvas);
        });
    }

    const duplicateOnKeyPress = ()=>{
        if(dObjs){
            const props = {
                canvas,reinitFreeShape,updateUndoStates,moveLine,
                moveEnd2,movingObject, reinitpath, mouseUp
            }
            duplicateObject(props,dObjs);
            dObjs = null;
        }
    }
    const objToDuplicate = ()=>{
         dObjs = canvas.getActiveObject();
    }

    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    const reCreateProps = (canvasProps = false, dimension = false, canvasDim = false, frameVal = false) => {
        if (dimension) {
            pitchContainerBox = dimension.pitchContainerBox;
            containerDimensions = dimension.containerDimensions;
            scalePropsVal = dimension.scalePropsVal;
        } else if (canvasDim) {
            canvasDimensions = canvasDim.canvasDimensions;
        } else if (frameVal) {
            allFrames = frameVal.allFrames;
            animation_state = frameVal.animation_state;
            shadowFrames = frameVal.shadowFrames;
            svgPathElements = frameVal.svgPathElements;
            activeFrameVal = frameVal.activeFrameVal;
            prevX = [];
            prevY = [];
            initWidth = frameVal.initWidth;
            initHeight = frameVal.initHeight;
        } else {
            canvas = canvasProps.canvas;
            canvasVar = canvasProps.canvasVar;
            initWidth = canvasProps.initWidth;
            initHeight = canvasProps.initHeight
            canvas2 = canvasProps.canvas2;
        }

    }
    const initProcess =useCallback( async () => {
        let customProps = {
            reCreateProps,
            background,
            bgUpdate,
            handleDragStart,
            canvasContainerHeight,
            onKeyDown,
            isJson,
            canvasData,
            canvasDimensions,
            updateFrame,
            undoStates,
            isMobile,
            canvasBackgroundClass,
            pitchContainerBox,
            containerDimensions,
            canvas,
            canvasVar,
            scalePropsVal,
            initWidth,
            initHeight,
            canvas2,
            setFreeBrush,
            canvasUpdateControls,
            canvasSetBorders,
            addMoreFrame,
            startEvents,
            canvasJson,
            canvasContainerDimensionsSave,
            animationsData,
            updateObjectsScale,
            updateWindowDimensions,
            setFrames,
            allFrames,
            animation_state,
            setFrameObjects,
            shadowFrames,
            setSvgPathElementss,
            svgPathElements,
            setActiveFrame,
            activeFrameVal,
            prevX,
            prevY,
            addShadowObjects,
            reInitArrowLines,
            reinitFreeShape,
            sendBackShapes,
            reinitCanvas,
            clearEndPoints,
            convertToJson,
            isAnimation,
            setCanvasDimensionsProps,
            setIsMobileView,
            pitchRef,
            canvasRef,
            showBPanel
        };
        handleProcess(customProps);
    },[handleProcess])


    const setActiveFrameTimeFunc = (value) => {
        setActiveFrameTime(value)
        activeFrame.time = parseInt(value)
    }

    const savingCanvasData = (callback) => {
        let props = {
            callback,
            setLoading,
            deleteShadowObjects,
            convertToJson,
            addShadowObjects,
            filterShadowFrames,
            shadowFrames,
            svgPathElements,
            animationState,
            initWidth,
            initHeight,
            allFrames,
            activeFrameVal,
            reInitArrowLines,
            setFrames,
            canvas
        }
        saveHelper(props)
    }

    const saveCanvas = () => {
        if (canvas?.getActiveObject()) canvas.discardActiveObject();
        savingCanvasData((canvasAnimationData, canvasDJson) => {
            localStorage.setItem("animationData", canvasAnimationData)
            localStorage.setItem("canvas", canvasDJson);
            localStorage.setItem("background", canvasBackgroundClass);
            localStorage.setItem("containerDimensions", JSON.stringify(canvasDimensions));
            setLoading(false);
            notification.success({
                message: formatMessage({ id: "Canvas Saved" }),
            });

        });
    }
    const deleteShadowObjByName = (obj) => {
        let objInd = canvas._objects.findIndex((o) => o.ref_id?.includes(obj.ref_id) && o.ref_id?.includes("shadow"));
        if (objInd > -1) {
            canvas._objects.forEach((o, i) => {
                if (o.ref_id.includes(obj.ref_id) && o.ref_id.includes("shadow")) {
                    canvas.remove(canvas._objects[i])
                }
            })
        } else return;
        if (objInd > -1) deleteShadowObjByName(obj);
    }
    const updateDeletedObjs = (delObjs = false) => {
        if (delObjs) {
            deletedObjects = delObjs.deletedObjects;
        }
    }

    const deleteObject = useCallback(() => {
        let props = {
            canvas, deleteShadowObjByName, returnAllObjects, shadowFrames, svgPathElements, deletedObjects,
            updateUndoRedoState, updateFrameObjects, updateArrowLinesArray, activeFrameVal, updateDeletedObjs,reRenderCanvas
        };

        deleteObjectHelper(props);
    },[deleteObjectHelper])

    const onKeyDown = (e) => {
        handleKeyEvents(e, canvas, clearTextSelection, deleteObject, editModeFlag, duplicateOnKeyPress, objToDuplicate);
    }

    const startEvents = () => {
        canvas.__eventListeners = {}
        canvas.on({
            'object:modified': modifiedObject,
            'object:scaling': scalingObject,
            'object:scaled': scaledObject,
            'object:added': addedObject,
            'mouse:up': mouseUp,
            'mouse:move':mouseMove,
            'mouse:over': mouseOver,
            'object:moving': movingObject,
            'object:moved': movedObject,
            'selection:created': selectionCreated,
            'selection:updated': selectionUpdated,
            'selection:cleared': selectionCleared,
            'drop': dragLeave,
            'mouse:down': mouseDown,
            'mouse:down:before': mouseDownBefore,
            'text:changed': textChanged,
            'object:rotating': rotatingObject,
        });
        initAligningGuidelines(canvas);
    }

    const rotatingObject = (e) => {
        if (e.target.name === "player") {
            var text = e.target._objects[e.target._objects.length - 1]
            text.rotate(-e.target.angle)
        }
        if (e.target.name === "player_custom_image") {
            let refId = e.target.ref_id;
            if (!refId) return;
            let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === refId);
            let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === refId);
            if (nameInd > -1 && shirtInd > -1) {
                e.target.setTextPosition(canvas._objects[nameInd], canvas)
            }
        }
    }

    const stopEvents = () => {
        canvas.__eventListeners = {};
    }

    const canvasUpdateControls = () => {
        handleUpdateControls(canvas)
    }


    // enable text editing on full screen mode
    const _original_initHiddenTextarea = fabric.IText.prototype.initHiddenTextarea;
    fabric.util.object.extend(fabric.IText.prototype, /** @lends fabric.IText.prototype */ {
        //fix for : IText not editable when canvas is in a fullscreen element on chrome
        // https://github.com/fabricjs/fabric.js/issues/5126
        initHiddenTextarea: function () {

            _original_initHiddenTextarea.call(this);
            this.canvas.wrapperEl.appendChild(this.hiddenTextarea);
        }
    });

    const clone = (obj) => {
        if (obj === null || typeof obj !== "object" || "isActiveClone" in obj)
            return obj;
        if (obj instanceof Date) return new obj.constructor(obj);
        const temp = Object.create(Object.getPrototypeOf(obj));
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                obj["isActiveClone"] = null;
                temp[key] = clone(obj[key]);
                delete obj["isActiveClone"];
            }
        }
        return Object.assign(temp, obj);
    };

    const setFreeBrush = () => {
        canvas.freeDrawingBrush.width = (scalePropsVal.strokeWidth - 2)
    }

    const setActiveObject = (obj) => {
        canvas.discardActiveObject();
        canvas.setActiveObject(obj);
    }

    const setCanvasDimensionsProps = () => {
        const outerCanvasContainer = pitchContainerBox
        const windowWidth = window.innerWidth
        const ratio = canvas.getWidth() / canvas.getHeight();
        let containerWidth = canvasContainerHeight(outerCanvasContainer).width
        const scale = containerWidth / canvas.getWidth();
        const zoom = canvas.getZoom() * scale;

        canvasDimensions = {
            windowWidth: windowWidth,
            containerWidth: containerWidth,
            ratio: ratio,
            scale: scale,
            zoom: zoom,
            zoom_val: canvas.getZoom()
        }
    }

    const updateObjectsScale = (canvasEl) => {
        canvasEl.setDimensions({
            width: canvasDimensions.containerWidth,
            height: canvasDimensions.containerWidth / canvasDimensions.ratio
        });
        canvasEl.setViewportTransform([canvasDimensions.zoom, 0, 0, canvasDimensions.zoom, 0, 0]);
    }


    const updateWindowDimensions = () => {
        if(!canvas) return;
        setCanvasDimensionsProps()
        updateObjectsScale(canvas)
        canvasSetBorders(canvas, isMobile)
        canvas._objects.forEach(obj => {
            updateCanvasResize(obj, setObjectPadding)
        })
        reRenderCanvas();
    };

    const setMpVals = (middlePointAct) => {
        mpLeft = middlePointAct.left;
        mpTop = middlePointAct.top;
    }
    const setCurveActivePoint = (obj) => {
        handleCurveActivePoint(obj, canvas, mpLeft, mpTop, setCenterCurvePoint, updateCurveArrow, startEvents, stopEvents, setMpVals)
    }

    const movingObject = useCallback((e) => {
        let props = {
            e, canvas, updateArrowObject, setCurveActivePoint, setCenterCurvePoint, animation_state, animationState,
            allFrames, frames
        }
        movingHelper(props).then(()=>{
            // reRenderCanvas()
        }).catch((e)=>{
            console.log(e);

        });
    },[movingHelper]);

    const setCenterCurvePoint = (middlePoint, render = true) => {
        handleCenterPoint(middlePoint, canvas,render);
    }

    const reAddActivePoint = (obj) => {
        if (!obj) obj = canvas.getActiveObject();
        if (!obj) return;
        let refId = obj.ref_id;
        stopEvents();
        if (obj.name === "pX") {
            obj.setCoords();
        } else {
            const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id?.includes(refId) && o.name === "pX");
            if (middlePointActInd > -1) {
                canvas._objects[middlePointActInd].setCoords();
            }
        }
        startEvents();
    }

    const movedObject = useCallback( (e) => {
        let props = {
            e,
            canvas,
            updateArrowLinesArray,
            prevX,
            prevY,
            continueDraw,
            animationState,
            updateSelectedObjsLine,
            updateDrawControls,
            prevArX,
            prevArY,
            reinitpath,
            setCenterCurvePoint,
            reAddActivePoint,
            svgPathElements,
            setSvgPathElementss,
            updateArrowObject,
            shadowLineUpdate,
            updateRemovedProps,
            activeFrameVal
        };
        movedHelper(props).then(()=>{
            reRenderCanvas()
        }).catch(err=>{
            console.log(err)
        })
    },[movedHelper])

    const reinitCanvas = () => {
        // dispatch(setObjectsState({
        //     objectActive: false,
        //     line: false,
        //     text: false,
        //     object: false,
        //     player: false,
        //     shape: false
        // }))
        for (var i = 0; i < canvas._objects.length; i++) {
            var obj = canvas._objects[i];
            obj.shadow = ""
            if (obj.hasOwnProperty('newOpacity')) {
                obj.opacity = obj.newOpacity
            } else {
                if (obj.selectable && (obj.name !== "pX")) {
                    obj.opacity = 1
                }
            }

            updateCanvasResize(obj, setObjectPadding)
            reinitLineObject(obj, canvas, returnObject, setLineToCircle)
            if (obj.name === 'custom_image_shirtno') obj.opacity = 0;
        }
        reRenderCanvas();
    }


    const reArrangeShadow = () => {
        let props = {
            canvas,
            shadowFrames,
            activeFrameVal,
            svgPathElements,
            setSvgPathElementss,
            updateObjectDPath,
            updateFrameObjects,
            updateRemovedProps
        }
        reArrangeHelper(props);

    }
    const reinitFreeShape = (duplicatedObj = null) => {
        if (duplicatedObj) return freeShapeReInitialized(duplicatedObj, addFreeShape, stopEvents, startEvents, canvas);
        else freeShapeReInitialized(duplicatedObj, addFreeShape, stopEvents, startEvents, canvas);

    }
    const reinitAllEvents = (event = false) => {
        reinitCanvas();
        addedTextObjs();
        reInitArrowLines();
        reinitFreeShape();
        startEvents();
    }

    const updateActiveIndex = (ind) => {
        activeStateIndex = ind
    }
    const undo = useCallback(() => {
        let props = {
            setActiveBar,
            animationState,
            activeFrameVal,
            animation_state,
            isAnimationOpen,
            deletedObjects,
            svgPathElements,
            shadowFrames,
            setFrameObjects,
            addShadowObjects
            ,
            reinitAllEvents,
            canvas,
            activeStateIndex,
            undoStates,
            frames,
            stopEvents,
            reArrangeShadow,
            reinitCanvas,
            addedTextObjs,
            reinitFreeShape,
            reInitArrowLines,
            startEvents,
            drawLineStates,
            showShadowObjects,
            updateRemovedProps,
            allFrames,
            updateDeletedObjs,
            updateActiveIndex
        };
        undoHelper(props);
    },[undoHelper])

    const redo = useCallback(() => {
        let props = {
            setActiveBar,
            animationState,
            activeFrameVal,
            animation_state,
            deletedObjects,
            svgPathElements,
            shadowFrames,
            setFrameObjects,
            addShadowObjects,
            reinitAllEvents,
            canvas,
            activeStateIndex,
            undoStates,
            stopEvents,
            reArrangeShadow,
            reinitCanvas,
            addedTextObjs,
            reinitFreeShape,
            reInitArrowLines,
            startEvents,
            showShadowObjects,
            updateRemovedProps,
            updateDeletedObjs,
            updateActiveIndex
        };
        redoHelper(props)
    },[redoHelper])
    const addedTextObjs = () => {
        let imgArr = canvas._objects.filter(f => f.name === 'player_custom_image');
        if (imgArr.length) {

            imgArr.forEach(e => {
                let nameTextIn = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === e.ref_id);
                let shirtIn = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === e.ref_id && f.type === 'group')

                if (nameTextIn == -1 || shirtIn == -1) {
                    reAddImageTextObjs(e);
                }
            })
        }
    }

    const pushUndoStates = (obj) => {
        if (animation_state || animationState) {
            activeFrameVal.undoStates.push(obj);
        } else {
            undoStates.push(obj);
        }
    }
    const addedUndoRedoState = (currObj, tempArr, json, action) => {
        var obj = {
            target: currObj,
            name: currObj.name,
            ref_id: currObj.ref_id,
            json,
            action,
            shadowObjects: tempArr
        }
        pushUndoStates(obj)
        if (animation_state || animationState) {
            for (var i = activeFrameVal.undoStates.length - 2; i > activeFrameVal.activeStateIndex; i--) {
                activeFrameVal.undoStates.splice(i, 1)
            }
            activeFrameVal.activeStateIndex = activeFrameVal.undoStates.length - 1
        } else {
            for (var i = undoStates.length - 2; i > activeStateIndex; i--) {
                undoStates.splice(i, 1)
            }
            activeStateIndex = undoStates.length - 1
        }
    }

    const updateUndoRedoState = (currObj, action) => {
        if (!canvas || (currObj.ref_id?.includes("shadow")) || (currObj.name === 'drawLine')) return;
        let json = '';
        let tempArr = [];
        if (animationState && animation_state && activeFrameVal?.data_num > -1) {
            const currFrameObjs = shadowFrames[activeFrameVal.data_num]?.objects;
            if (currFrameObjs) {
                tempArr = currFrameObjs.map((o) => {
                    return {ref_id: o.ref_id, d: o.d};
                })
            }
            if (canvas._objects.findIndex((o) => o.ref_id?.includes('shadow-object')) > -1) {
                const currFrameJson = convertToJson(canvas);
                canvas2.clear();
                canvas2.loadFromJSON(currFrameJson, function () {
                    for (let i = 0; i < canvas2._objects.length; i++) {
                        if (canvas2._objects[i].ref_id?.includes('shadow-object')) {
                            canvas2.remove(canvas2._objects[i])
                        }
                    }
                    json = convertToJson(canvas2);
                    addedUndoRedoState(currObj, tempArr, json, action)
                })
            } else {
                json = convertToJson(canvas);
                addedUndoRedoState(currObj, tempArr, json, action)
            }
        } else {
            json = convertToJson(canvas);
            addedUndoRedoState(currObj, tempArr, json, action)
        }

    }

    const modifiedObject = useCallback((e) => {
        let props = {
            e,
            canvas,
            animationState,
            shadowFrames,
            frames,
            animation_state,
            allFrames,
            updateArrowLinesArray,
            updateFrameObjects,
            updateObjectsStates,
            reinitAfterWidthChange,
            reinitpath,
            updateUndoRedoState,
            setFrames,
            updateRemovedProps
        }
        modifyObject(props)
    },[frames,modifyObject])
    const updateAllProps = (currentObj) => {
        updateAllProperties(currentObj, allFrames, setFrames, animationState);
    }

    const shadowLineUpdate = (activeObject, render=true) => {
        let props = {
            activeObject,
            svgPathElements,
            setSvgPathElementss,
            updateObjectDPath,
            canvas,
            updateRemovedProps,
            activeFrameVal,
            render
        }
        updateLinesHelper(props)
    }

    const scaledObject = (e) => {
        const activeObject = e.target;
        if (!activeObject) {
            return;
        }
        if (activeObject.name === 'player_custom_image') {
            console.log("Image needs to be resized");
            setCustomPlayerImageDimensions(canvas, activeObject, activeObject.scaleX, activeObject.scaleY);
            canvas.requestRenderAll();

        }
        shadowLineUpdate(activeObject);
    }

    const scalingObject = useCallback((e) => {
        let props = {
            e, canvas
        };
        scalingHelper(props);
    },[scalingHelper])

    const addedObject = (e) => {
        if(e.target && !e.target.pCanvas){
            const {width:cWidth,height:cHeight} = canvas;
            e.target.set({pCanvas:{width:cWidth,height:cHeight}})
        }
        // if(!e.target.formation) dispatch(setShowDrawer(false));
        if (e.target?.name?.startsWith("custom_image_")) return;
        if ((e.target.name === 'drawLine') || (e.target.name === 'linePath') || (e.target.name === 'extendedLine')) return;
        if ((animationState || animation_state) && e.target.is_animation) {
            updateFrameObjects("add", e.target)
        } else if ((animationState || animation_state) && (e.target.name === 'image' && !e.target.is_animation)) {
            updateAllFrames(activeFrameVal.data_num, null, e.target, true)
        }
        if (e.target.formation) return;
        updateUndoRedoState(e.target, "added")
    }

    const updateCurveArrow = (obj, curve) => {
        var x1 = curve.path[curve.path.length - 2][1]
        var y1 = curve.path[curve.path.length - 2][2]

        var x2 = curve.path[curve.path.length - 1][1]
        var y2 = curve.path[curve.path.length - 1][2]

        var angle = Math.atan2(y2 - y1, x2 - x1)
        obj.set("angle", (angle * (180 / (3.142)) - 92))
    }


    const updateArrowObject = (obj, state, multiSelection = false) => {
        handleArrowObjectUpdate(obj, state, multiSelection, moveEnd2, moveEnd, updateCurveArrow, canvas)
    }

    const updateInputText = (text) => {
        document.querySelectorAll(".tacticsboard__header_lftextra_input").forEach(el => {
            el.value = text
        })
    }
    const updateObjColorState = (object) => {
        if (object.name === "image") {
            dispatch(setColorsState(true))
        }
    }
    const updateObjectsStates = (object) => {
        let updatedObj = handleObjectUpdate(object, canvas, updateInputText, updateObjColorState, isMobile, activeFrameVal)
        if (updatedObj) {
            dispatch(setObjectsState(updatedObj))
        }
    }


    const updateDrawControls = (drawControls = false, drMode = false) => {
        if (drawControls) {
            prevY = drawControls.prevY;
            prevX = drawControls.prevX;
            prevArY = drawControls.prevArY;
            prevArX = drawControls.prevArX;
        } else {
            editingMode = drMode.editingMode
        }
    }
    const updateObjectType = (obj) => {
        let updatedDesType = handleObjectType(obj, canvas);
        updatedDesType && dispatch(setObjUpdatedType(updatedDesType));
    }

    const selectionCreated = useCallback((e) => {
        let props = {
            e,
            canvas,
            isMobile,
            updateDrawControls,
            updateArrowObject,
            reAddActivePoint,
            setCenterCurvePoint,
            updateObjectType,
            prevArX,
            prevArY,
            prevX,
            prevY,
            animationState,
            animation_state,
            editingMode,
            setActiveBar,
            updateObjectsStates,
        }
        createSelection(props);
    },[createSelection])

    const clearEndPoints = () => {
        let endP = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object');
        for (let i = 0; i < endP.length; i++) {
            endP[i].opacity = 0;
            endP[i].evented = false;
        }
        canvas.renderAll();
    }

    const selectionUpdated = useCallback((e) => {
        let props = {
            e, canvas, updateObjectsStates, clearTextSelection, editingMode, prevX, prevY, isMobile,
            prevArX, prevArY, updateArrowObject, setCenterCurvePoint, startEvents, stopEvents,
            reAddActivePoint, updateObjectType, updateDrawControls, animationState, objSpeed
        };
        updateSelection(props)
    },[updateSelection]);

    const updateSelectedObjsLine = (obj) => {
        updateLines(obj, canvas, svgPathElements, setSvgPathElementss, updateFrameObjects, updateObjectDPath, activeFrameVal, updateRemovedProps);
    }
    const clearTextSelection = (dObj, updated = false) => {
        handleTextSelection(dObj, updated, stopEvents, startEvents, getSurName, canvas);

    }
    const selectionCleared = (e) => {
        dispatch(setSelectedColor(''));
        dispatch(setObjUpdatedType(''));
        dispatch(setConnect(false));
        updateObjectsStates({name: "clear"})
        if (e?.deselected && e?.deselected.length === 1) {
            if (e.deselected[0].name?.startsWith("custom_image")) {
                if (editingMode) {
                    clearTextSelection(e.deselected[0]);
                }
            }
        }
        clearSelection();
        setEditingMode(false);
        editingMode = false;
    }

    const dragLeave = (e) => {
        toolsClick({target: draggableElement}, "drag", e.e.clientX - 30, e.e.clientY - 30, e)
    }

    const convertToJson = (canvasEl) => {
        return JSON.stringify(clone(canvasEl.toJSON(['team','lockMovementX','lockMovementY','isSvg','pCanvas','customImage','polygon','position', 'isPlayer', 'initialSize', 'startAngle',  'startAngleD','direction', 'arrowAngle', 'arrowFill', 'animationProps', 'time', 'parentFrame', 'bgrOpacity', 'fadeFlag', 'showHighlight', 'highlightColor', 'hasBg', 'fillStyle', 'formation', 'sizeFactor', 'bgOpacity', 'shirtNo', 'nameText', 'textLength', 'scaleX', 'scaleY', 'left', 'top', 'is_animation', 'widthFactor', 'size', 'color', 'hoverCursor', 'imageFileName', 'newOpacity', 'padding', 'perPixelTargetFind', 'hasControls', 'hasBorders', 'selectable', 'id', 'class', 'name', 'ref_id', 'objecttype', 'x1', 'x2', 'y1', 'y2', 'pointType', 'shapeType', 'brushType'])))
    }

    const drawArrow = (x1, y1, x2, y2, type, color, id) => {
        let updatedObj = createArrow(x1, y1, x2, y2, type, color, id, scalePropsVal);
        if (updatedObj) return updatedObj
        else return null

    }
    const continueDraw = (x1, y1, x2, y2, type, color, id, wFactor) => {
        let obj
        obj = new fabric.Circle({
            left: x2,
            top: y2,
            hasBorders: false,
            hasControls: false,
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
            originX: 'center',
            originY: 'center',
            radius: 15,
            hoverCursor: 'crosshair',
            fill: 'yellow',
            brushType: type,
            name: 'line-end-point_shadow-object',
            strokeWidth: 1,
            selectable: false,
            ref_id: id,
            opacity: 0.5,
            is_animation: false,
            widthFactor: wFactor
        });
        return obj
    }
    const mouseOver = (e) => {
        if (e.target) {

            let object = e.target;
            if (object.name === 'p0' || object.name === 'p2') {
                let actObj = canvas.getActiveObject();
                if (actObj) {
                    if (actObj && actObj.name !== 'line' && actObj.ref_id?.split('::')[0] === object.ref_id) {
                        let currentLineInd = canvas._objects.findIndex(f => f.name === 'line' && f.ref_id === actObj.ref_id.split('::')[0]);
                        if (currentLineInd > -1) {
                            canvas.discardActiveObject();
                            canvas.setActiveObject(canvas._objects[currentLineInd]);
                            canvas.renderAll();
                        }
                    }
                }
            }
        }

    }

    const updateBeforeClick = (midProps = false, cProps = false) => {
        if (midProps) {
            mpLeft = midProps.mpLeft;
            mpTop = midProps.mpTop;
        } else {
            tempDrawLines = cProps.tempDrawLines;
            cDraw = cProps.cDraw;
            cPoint = cProps.cPoint
        }
    }
    const mouseDownBefore = (e) => {
        let props = {
            e, dispatch, canvas, mpLeft, mpTop, tempDrawLines, cDraw, cPoint, updateBeforeClick
        }
        handleBeforeClick(props)
    }
    const mouseMove = (e) =>{
        dropMove({cb,canvas,e,setCenterCurvePoint})
    }
    const mouseDown = (e) => {
        if(e.target && e.target.name === 'arrow'){
            canvas.setActiveObject(e.target.line)
        }
        dispatch(setColorPopup(false));
        const obj = canvas.getActiveObject();
        if(cb){
            dropObj({cb,e,canvas,drawQuadratic,drawQuadraticArrow,dispatch})
        }
        if (obj) {
            switch (obj.name) {
                case "square1":
                case "square2":
                case "p0":
                case "p1":
                case "p2":
                    obj.shadow = shadow
                    break;
                default:
                    break;
            }
        }
        canvas.renderAll()
    }
    const textChanged = (e) => {
        if (e.target.name === "text") {
            if (e.target.text.length > 0) e.target.set('opacity', 1)
            updateInputText(e.target.text);
            if(checkHebrew(e.target.text)){
                e.target.direction = 'rtl';
            }
            else{
                e.target.direction = 'ltr';
            }

        } else if (e.target.name === "custom_image_name") {
            let ind = canvas._objects.findIndex(f => (f.name === 'player' || f.name === 'image' || f.name === 'player_custom_image') && f.ref_id === e.target.ref_id);
            if (ind > -1) canvas._objects[ind].setTextPosition(e.target, canvas);
        }
    }

    const mouseUp = (e) => {
        const obj = canvas.getActiveObject();
        if(!e.target?.isInitialized) dropUp(cb,{canvas,dispatch,clipBoard});
        if (e.target) {
            if (e.target.selectable && e.target.type !== "activeSelection" && !e.target.ref_id?.includes('shadow')) {
                updateOpacity(e.target)
            }
            if (e.target.name === "pX" || e.target.name === "p0" || e.target.name === "p1" || e.target.name === "p2" || e.target.name === "square2" || e.target.name === "square1") {
                let lineInd = canvas._objects.findIndex(f => ((f.name.includes('line') && (f.ref_id === e.target.ref_id)) || (f.name.includes('line') && f.ref_id === e.target.ref_id.split('::')[0])))
                canvas.discardActiveObject();
                if (lineInd > -1) {
                    canvas.setActiveObject(canvas._objects[lineInd])
                }
            }
            if(!e.target.isInitialized) dropUp(cb,{canvas,dispatch});
        }

        var drawType = document.querySelector(".draw-element.color__primary");
        if (cDraw || drawType) {
            updateAfterDraw();
        };

        if (obj) {
            if (obj.type === 'activeSelection') {
                obj._objects.forEach((ob) => {
                    updateArrowObject(ob, "selected", true)
                })
            } else
                updateArrowObject(obj, "selected")
        }
        canvas.renderAll()
    }

    const updateSvgs = (d, pXInd, svgInd, ob, shadowObjInd) => {
        svgPathElements[svgInd].d = d;
        setSvgPathElementss([...svgPathElements])
        canvas._objects[pXInd].sendBackwards();
        let frameObj = shadowFrames[activeFrameVal.data_num].objects.find(f => f.ref_id === ob.ref_id)
        if (frameObj) {
            frameObj.d = d.split(' ');
            frameObj.left = canvas._objects[shadowObjInd].left;
            frameObj.top = canvas._objects[shadowObjInd].top;
        }
        canvas.discardActiveObject()
        canvas.renderAll()
    }
    const cancelMove = () => {
        cancelMovement(svgPathElements, updateShadowLine, shadowLineUpdate, updateSvgs, animationState, activeFrameVal, canvas);
    }

    const updateOpacity = (obj) => {
        if (!obj) return;
        if (obj.hasOwnProperty('newOpacity')) {
            obj.opacity = obj.newOpacity
            obj.set('opacity', obj.newOpacity)
        } else {
            switch (obj.name) {
                case "square1":
                case "square2":
                case "p0":
                // case "p1":
                case "pX":
                case "p2":
                    obj.shadow = ""
                    obj.opacity = .5
                    break;
                default:
                    obj.opacity = 1
                    break;
            }
        }
    }
    const updateDrawProps = (drawProps = false) => {
        if (drawProps) {
            cDraw = drawProps.cDraw;
            tempDrawLines = drawProps.tempDrawLines;
            prevX = drawProps.prevX;
            prevY = drawProps.prevY;
            undoStates = drawProps.undoStates;
            activeStateIndex = drawProps.activeStateIndex;
        }
    }

    const updateAfterDraw = () => {
        let props = {
            drawBrushType,
            cDraw,
            canvas,
            animation_state,
            activeFrameVal,
            undoStates,
            activeStateIndex,
            setObjectPadding,
            setActiveObject,
            disableDrawer,
            tempDrawLines,
            prevX,
            prevY,
            cPoint,
            drawArrow,
            continueDraw,
            drawLineStates,
            scalePropsVal,
            updateDrawProps
        }
        freeDrawHelper(props)
    }

    const clearSelection = () => {
        handleClearSelection(stopEvents, startEvents, canvas);
    }
    const returnObject = (ref_id, name) => {
        for (var i = 0; i < canvas._objects.length; i++) {
            var obj = canvas._objects[i]
            if (obj.ref_id === ref_id && obj.name === name) {
                return obj
            }
        }
    }

    const returnAllObjects = (ref_id) => {
        var objects = []
        for (var i = 0; i < canvas._objects.length; i++) {
            var obj = canvas._objects[i]
            if (obj.ref_id.includes(ref_id)) {
                objects.push(obj)
            }
        }
        return objects
    }

    const setLineToCircle = (circle, line1, line2, line3) => {
        circle.line1 = line1;
        circle.line2 = line2;
        circle.line3 = line3;
    }

    const reinitAfterWidthChange = (e) => {
        applyWidthChangeEffects(e, returnObject, canvas, setLineToCircle, setObjectPadding);
    }
    const reinitpath = (e, callback,isDropped=false) => {
        initializePath(e, callback, canvas, reAddActivePoint, returnObject, stopEvents, setLineToCircle, setObjectPadding, startEvents,isDropped);
    }


    const moveEnd = (obj, is_set) => {
        moveEndHelper(obj, is_set, canvas)
    }
    const moveEnd2 = (obj) => {
        moveEnd2Helper(obj, canvas)
    }

    const drawQuadratic = (props, customProps = false) => {
        drawBezierCurve(props, canvas, customProps, makeCurvePoint, makeCurveCircle, movingObject, updateUndoStates, reinitpath, mouseUp)
    }
    const drawQuadraticArrow = (props, customProps = false) => {
        drawBezierCurveArrow(props, canvas, customProps, makeCurvePoint, makeCurveCircle, movingObject, updateUndoStates, reinitpath, mouseUp);
    }

    const makeCurveCircle = (left, top, line1, line2, line3, props) => {
        let curveCircle = createCurveCircle(left, top, line1, line2, line3, props, setObjectPadding);
        return curveCircle
    }
    const makeCurvePoint = (left, top, line1, line2, line3, props) => {
        let curvedPoint = createCurvePoint(left, top, line1, line2, line3, props, setObjectPadding, setLineToCircle);
        return curvedPoint;
    }
    const makeShadowCurvePoint = (left, top, line1, line2, line3, scaleProps) => {
        let updatedCurvePoint = handleCurveShadowPoint(left, top, line1, line2, line3, scaleProps, setObjectPadding, setLineToCircle)
        return updatedCurvePoint
    }
    const upliftCb = ()=>{
        cb = null
    }
    const updateUndoProps = (undoProps = false) => {
        if (undoProps) {
            undoStates = undoProps.undoStates;
            activeStateIndex = undoProps.activeStateIndex;
            activeFrameVal = undoProps.activeFrameVal;
        }
    }
    const updateUndoStates = (line) => {
        let props = {
            line, animationState, activeFrameVal, updateUndoProps, undoStates, activeStateIndex, animation_state
        }
        handleUndoUpdates(props);
    }
    const addImage = (type, src, left, top, scaleProps, customProps = [], isAnimate = false, formation = {
        action: false,
        index: 0,
        imageFileName: ''
    }, fn = 0, objColor = 'LightBlue',isDropped=false) => {
        handleImages(type, src, left, top, scaleProps, customProps, isAnimate, formation, fn, objColor, canvas, setObjectPadding, isMobileView, animationState, activeFrameVal, updateUndoRedoState,isDropped,dispatch);
    };
    const setObjectPadding = (obj, val, val2) => {
        if (window.innerWidth < 1100 || isMobile()) {
            obj.set("padding", val)
        } else {
            obj.set("padding", val2)
        }
        canvas.renderAll()
    }
    const addPlayer = (props, scaleProps, sizeFactor, formation = {action: false, index: 0}, customProps = null,isDropped=false,res=false) => {
        if (props.polygon === "triangle") {
            handleAddPlayerTriangle(props, scaleProps, sizeFactor, formation, customProps, updateUndoRedoState, canvas, animationState, activeFrameVal, setActiveObject,isDropped,dispatch);
            res && res('resolved');
        }
        else if(props.isSvg){
            addPlayerShirt(props, scaleProps, sizeFactor, formation, customProps, updateUndoRedoState, canvas, animationState, activeFrameVal, setActiveObject,isDropped,dispatch,res);
        }
        else{
            handleAddPlayer(props, scaleProps, sizeFactor, formation, customProps, updateUndoRedoState, canvas, animationState, activeFrameVal, setActiveObject, isDropped, dispatch);
            res && res('resolved');
        }
    }
    const getSurName = (playerName) => {
        let surName = handleFirstName(playerName);
        return surName
    }
    const reAddImageTextObjs = (obj, isShadow = false, clonedObj, sprites = false) => {
        handleReaddedText(obj, isShadow, clonedObj, sprites, shadowFrames, activeFrameVal, canvas)
    }
    const disableDrawer = () => {
        canvas.isDrawingMode = false
        canvas.renderAll()
        dispatch(setDrawState(false));
        dispatch(setBrush(null));
        cDraw = false;
    }

    const toolsClick = (e, type, left_val, top_val, event) => {
        handleToolsClick({e, type, event, canvas, addImage, drawQuadratic, drawQuadraticArrow, disableDrawer, scalePropsVal, startEvents, stopEvents, addPlayer, addFreeShape, setActiveObject, showMobileTools, getSurName, actColor, isMobileView, setObjectPadding, updateUndoStates, moveEnd2, canvasVar,dispatch})
    }

    const clearAllStates = () => {
        localCanvas = '';
        localBackground = '';
        setActiveFrame("");
        activeFrameVal = {}
        setActiveFrameTime(defaultTime)
        setFrames([])
        shadowFrames = []
        allFrames = []
        setFrameObjects([])
        svgPathElements = []
        setSvgPathElementss([]);
        canvasInitial = ''
        canvas.clear();
        canvas2.clear();
        deletedObjects = [];
    }

    const onCloseModal = () => {
        // Clear undo states
        Modals.Confirm({
            formatMessage,
            descriptionId: "Are you sure you want to close?",
            onOk: () => {
                undoStates = [];
                activeStateIndex = 0;
                //Clear frames
                if (canvas) {
                    clearAllStates();
                }
                // close animation bar
                isAnimationOpen = false;
                dispatch(setAnimation(false));
                if (onClose) {
                    onClose();
                }
                setCanvasBackgroundClass("");
                animation_state = false;
                dispatch(setAnimation(false));

                dispatch(setBackground(""));
                setClosedModel(true);
            },
        });
    }

    const onDoneDrawing = (imageB64) => {
        //play and generate video base 64
        // imageBase64 = imageB64;
        isDoneClicked = true;
        // if (isAnimation) {
        //     exportVideo()
        // } else {
        //     savingDataInDB(imageB64);
        // };
        savingDataInDB(imageB64);
    };
    const updateAllFrames =useCallback( (ind, objProps, objj, animateAbleObj = false) => {
        let props = {
            allFrames,
            canvas,
            shadowFrames,
            setFrameObjects,
            setFrames,
            updateRemovedProps,
            ind,
            objProps,
            objj,
            animateAbleObj
        };
        handleAllUpdate(props);
    },[handleAllUpdate])

    const updateFrameObjects = useCallback((type, objj = null) => {
        let props = {
            type,
            objj,
            updateAllFrames,
            setFrameObjects,
            shadowFrames,
            setSvgPathElementss,
            svgPathElements,
            activeFrameVal,
            animationState,
            canvas,
            updateRemovedProps
        }
        handleFrameObjs(props)

    },[handleFrameObjs])
    const updateObjectDPath = (actObj, d, ind) => {
        if (!actObj) return;
        if (!ind && activeFrameVal) {
            ind = activeFrameVal.data_num
        }
        if (shadowFrames[ind].objects.length) {
            const shadowFramObjs = [...shadowFrames[ind].objects]
            const objInd = shadowFramObjs.findIndex((o) => actObj.id.includes(o.ref_id));
            if (objInd > -1) {
                shadowFrames[ind].objects[objInd] = {...shadowFramObjs[objInd], d};
                setFrameObjects([...shadowFrames]);
            }
        }
    }


    const deleteShadowObjects = (callback) => {
        manageDeletedObjs({deleteShadowObjects, shadowFrames, canvas, activeFrameVal, callback, updateRemovedProps});
    }

    const showShadowObjects = (objs = []) => {
        if (!shadowFrames.length && !animation_state) return;
        for (let i = 0; i < canvas._objects.length; i++) {
            if (canvas._objects[i].ref_id.includes('shadow-object') && canvas._objects[i].objecttype !== "shadow_curve_point_active" && canvas._objects[i].objecttype !== "shadow_curve_point" && canvas._objects[i].opacity === 0 && canvas._objects[i].text?.length !== 0) {
                canvas._objects[i].opacity = 0.3;
            }
            showOpacity(canvas, i)
        }
        reRenderCanvas()
    }

    const updateFramejson = (callback) => {
        reInitArrowLines();
        deleteShadowObjects(() => {
            allFrames = allFrames ? allFrames : [...frames];
            allFrames[activeFrameVal.data_num].json = convertToJson(canvas);
            setFrames(allFrames)
            callback && callback()
        })
    }

    const reInitArrowLines = () => {
        reinitializeLines(canvas, reinitpath)
    }
    const switchFrame = (frame) => {
        if (frame.data_num === undefined) return;
        if (frame.data_num === activeFrameVal.data_num) return;
        if (canvas.getActiveObject()) canvas.discardActiveObject();
        if (frame.data_num > activeFrameVal.data_num) {
            updateArrowLinesArray();
        }
        updateFramejson(() => {
            updateFrame(frame, () => {
                addShadowObjects(activeFrameVal,()=>{
                    reInitArrowLines()
                    sendBackShapes(canvas)
                });
            }, true, true);
        });
        setBrushType(canvas, drawLineStates);
    }

    // ANIMATION CODE
    const updateFrame = (frame, callback, state = true, isSwitched = false) => {
        if (frame) {
            stopEvents()
            deleteShadowObjects(() => {
                setActiveFrame(frame);
                activeFrameVal = frame
                buildCanvas(canvas, frame.json, state, allFrames, activeFrameVal, callback, false, isSwitched)
                setActiveFrameTime(frame.time)
            });

        }
    }

    var blobToBase64 = function (blob, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            var dataUrl = reader.result;
            callback(dataUrl);
        };
        reader.readAsDataURL(blob);
    };

    const onSavingInDBError = ({error, uploader}) => {
        if (uploader) {
            uploader.abort();
        }
        setLoading(false);
        setUploadingState(false);
        // setExportVideoSize(true);
        setRecordingStatus('stop');
        console.log('ERROR', error.message);
        message.error(formatMessage({id: "general.SomethingWentWrong"}));
    }


    const savingDataInDB = (base64 = "", video = {}) => {
        // Saving data to DB
        //TODO: On production Developer can bring the function back here.
    }
    const filterShadowFrames = (frames) => {
        let tempFrames = [...frames];
        for (let i = 0; i < tempFrames.length; i++) {
            tempFrames[i] = {...tempFrames[i], undoStates: []}
        }
        return tempFrames;
    }

    const setCanvasBackgroundEl = (canvasEl, callback) => {
        let pitchSrc = canvasBackgroundClass.includes('default')?`${BASE_URL_ASSETS}/assets/images/${canvasBackgroundClass}.png`:`https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/${canvasBackgroundClass}.jpg`;
        getFabricImageFromURL(pitchSrc,(fabricImage)=>{
            let newSrc = fabricImage.getSrc();
            callback && callback(newSrc)
        })
    }

    function exportVideo(isEnd = false) {
        if (animationState && activeFrameVal.data_num === 0 && frames.length === 1 && !canvas._objects.length) {
            alert('No frames added');
            return;
        }
        deleteShadowObjects(() => {
            allFrames = allFrames ? allFrames : [...frames];
            allFrames[activeFrameVal.data_num].json = convertToJson(canvas);
            let props = {
                setIsDownloading, setRecordingStatus, setCanvasBackgroundEl, filterShadowFrames,
                shadowFrames, initWidth, initHeight, svgPathElementss, canvasBackgroundClass,
                api, setLoading, stopCurvedAnimation, blobToBase64, savingDataInDB, formatMessage,
                isDoneClicked, onDone, setDownloadingStatus, frames
            }
            collectData(props)
        })
    }

    const addMoreFrame = useCallback((state) => {
        let props = {
            setActiveFrameTime,
            canvas,
            canvas2,
            state,
            frameObjects,
            frames,
            allFrames,
            shadowFrames,
            updateFrameObjects,
            stopEvents,
            convertToJson,
            deleteShadowObjects,
            setFrames,
            setActiveFrame,
            activeFrameVal,
            buildCanvas,
            addShadowObjects,
            reInitArrowLines,
            sendBackShapes,
            updateRemovedProps
        };
        appendFrame(props);
    },[appendFrame]);
    const updateRemovedProps = (frameProps = false, pathElems = false, shadowFr = false, totalFrames = false) => {
        if (frameProps) {
            allFrames = frameProps.allFrames;
            activeFrameVal = frameProps.activeFrameVal;
        } else if (pathElems) {
            svgPathElements = pathElems.svgPathElements;
        } else if (shadowFr) {
            shadowFrames = shadowFr.shadowFrames;
        } else {
            allFrames = totalFrames.allFrames
        }
    }
    const removeFrame = useCallback((frame) => {
        let props = {
            setFrameObjects,
            shadowFrames,
            setSvgPathElementss,
            svgPathElements,
            setFrames,
            allFrames,
            activeFrameVal,
            frames, stopEvents, canvas, frame, setActiveFrame,
            setActiveFrameTime, buildCanvas, addShadowObjects,
            reInitArrowLines,
            updateRemovedProps,
            intl
        }
        handleRemoveFrame(props);
    },[handleRemoveFrame]);

    const activeAnimationBoard = () => {
        if (!canvas) return;
        isAnimationOpen = !isAnimationOpen;
        stopEvents();
        if (animationState || animation_state && activeFrame) {
            activeFrame.json = convertToJson(canvas)
            buildCanvas(canvas, canvasInitial, false, frames, activeFrame, () => {
                reInitArrowLines();
            })
        } else {
            canvasInitial = convertToJson(canvas)
            buildCanvas(canvas, activeFrame.json, false, frames, activeFrame, () => {
                addShadowObjects(activeFrameVal);
                reInitArrowLines();
            })
        }
        startEvents();
        dispatch(setAnimation(!animationState));
        animation_state = !animationState
        clearEndPoints();
    }

    const buildCanvas = (canvasEl, json, state, allFrs, activeFrame, callback, isHide = false, isSwitched = false) => {
        if (canvas.getActiveObject()) canvas.discardActiveObject();
        let props = {
            reinitCanvas,
            reinitFreeShape,
            clearEndPoints,
            isRecording,
            hideOpacity,
            deletedObjects,
            addControls,
            addedObject,
            allFrames,
            setObjectPadding,
            clone,
            startEvents,
            updateUndoStates,
            setActiveObject,
            moveEnd2,
            canvasVar,
            canvas,
            canvas2,
            drawQuadraticArrow,
            drawQuadratic,
            scalePropsVal,
            continueDraw,
            addPlayer,
            activeFrame,
            activeFrameVal,
            animationState,
            reAddImageTextObjs
        }
        const canvasLoader = new CanvasReloader(props);
        canvasLoader.handleBuildCanvas(canvasEl, json, state, allFrs, activeFrame, callback, isHide, isSwitched);
    }
    const pauseAnimation = () => {
        if (!animationStates.pause && animationStates.play) {
            dispatch(setAnimationStates({
                play: true,
                pause: true,
            }))
            hasAnimationStarted = false
        }
    }
    const setFirstFrameActive = () => {
        setActiveFrame(frames[frames.length - 1]);
        activeFrameVal = frames[frames.length - 1];
        buildCanvas(canvas, frames[frames.length - 1].json, false, frames, activeFrameVal, () => {
            addShadowObjects(activeFrameVal,()=>{
                reInitArrowLines();
                sendBackShapes(canvas);
                startEvents();
            });
        });
    };
    const stopCurvedAnimation =useCallback( async (foreStop = false) => {
        // !foreStop && stopRecording()
        handleStopAnimation({
            canvas,
            reCreateAnimationProps,
            setFirstFrameActive,
            hasAnimationStarted,
            animationPlaying,
            isAnimating,
            setFrControlVisibility,
            setDownloadingStatus,
            hasAnimationStopped,
            setAnimationStatus,
            setPlayStatus
        });
    },[handleStopAnimation]);

    const onCloseDraw = () => {
        dispatch(setShowDrawer(false))
        dispatch(setColorPopup(false))
    };


    const reCreateAnimationProps = (animVal1 = false, animVal2 = false, animVal3 = false) => {
        if (animVal1) {
            allFrames = animVal1.allFrames;
            setAnimationStatus(animVal1.hasAnimationStopped);
        } else if (animVal2) {
            setIsAnimating(animVal2.isAnimating);

        } else {
            setIsAnimating(animVal3.isAnimating);
            hasAnimationStarted = animVal3.hasAnimationStarted;
            setPlayStatus(animVal3.animationPlaying);
        }
    }
    const {handlePlayAnimation} = usePlayAnimation({
        activeFrameVal,
        convertToJson,
        animationState,
        stopEvents,
        canvasBackgroundClass,
        frames,
        shadowFrames,
        setFrControlVisibility,
        hasAnimationStopped,
        deleteShadowObjects,
        allFrames,
        canvas,
        isAnimating,
        stopCurvedAnimation,
        reCreateAnimationProps
    });

    const playCurvedAnimation = useCallback(async () => {
        canvas.discardActiveObject();
        handlePlayAnimation();
    },[handlePlayAnimation])
    const showDrawerBtn = () => {
        dispatch(setShowDrawer(true))
    };

    return (
        <>
            {
                (!canvasJson && !background && !isOld) ?
                    <TacticsBoardBackgrounds onClose={onCloseModal} isBasketballClub={isBasketballClub}/>
                    :
                    <Modal
                        width="100%"
                        footer={null}
                        visible={true}
                        onOk={() => {
                        }}
                        style={{top: 0}}
                        onCancel={() => {
                        }}
                        className={activeBar ? "tacticsboard__modal" : "tacticsboard__modal1"}
                        wrapClassName='tacticsboard__modal_wrap'
                        okText={formatMessage({id: "general.close"})}
                        cancelText={'Cancel'}
                        cancelButtonProps={{shape: 'round', style: {display: 'none'}}}
                        okButtonProps={{shape: 'round', type: "danger", size: "small", style: {fontSize: 12}}}
                        title={
                            toolsToShow ?
                                <TacticsBoardToolsHeader/>
                                :
                                <TacticsBoardHeader
                                    updateAllProps={updateAllProps}
                                    frControlVisibility={frControlVisibility}
                                    activeAnimationBoard={activeAnimationBoard}
                                    movingObject={movingObject}
                                    isMobileView={isMobileView}
                                    activeBar={activeBar}
                                    mouseUp={mouseUp}
                                    downloadingProgress={downloadingProgress}
                                    reinitpath={reinitpath}
                                    updateUndoStates={updateUndoStates}
                                    moveEnd2={moveEnd2}
                                    moveLine={moveLine}
                                    returnAllObjects={returnAllObjects}
                                    onCloseDrawing={onCloseModal}
                                    saveCanvas={saveCanvas}
                                    undoEvent={undo}
                                    redoEvent={redo}
                                    presentationMode={presentationMode}
                                    recordingStatus={recordingStatus}
                                    isDownloadingVideo={isDownloadingVideo}
                                    onDoneDrawing={onDone && onDoneDrawing}
                                    exportVideo={animationState ? () => exportVideo() : ""}
                                    modifiedObject={modifiedObject}
                                    deleteCanvasObject={deleteObject}
                                    onFailTacticsBoard={onFailTacticsBoard}
                                    background={background || canvasBackground}
                                    onShowPreviousDrawings={onShowPreviousDrawings}
                                    reinitFreeShape={reinitFreeShape}
                                    cancelMove={cancelMove}
                                    activeFrame={activeFrame}
                                />
                        }
                    >
                        {
                            (loading || uploadingState) &&
                            <Loading downloadingProgress={downloadingProgress} uploadingState={uploadingState}/>
                        }
                        <PleaseRotate/>
                        <Row className="flex_auto" align="stretch" justify="space-between"
                             gutter={[0, 0]}>
                            {!isMobileView &&
                            <Col flex="0 0 216px" className="tacticsboard-tools-top">
                                <TacticsBoardTools props={toolsClick} modifiedObject={modifiedObject}
                                                   isMobileView={isMobileView} addCustomSquad={addCustomSquad}
                                                   customImagesJson={customImagesJson}/>
                            </Col>
                            }
                            <Drawer className="tactictics__board_drawer" title="Tools" placement="left"
                                    onClose={onCloseDraw} visible={showDrawer}>
                                <Col flex="0 0 216px" className="tacticsboard-tools-top">
                                    <TacticsBoardTools props={toolsClick} modifiedObject={modifiedObject}
                                                       isMobileView={isMobileView} addCustomSquad={addCustomSquad}
                                                       customImagesJson={customImagesJson}/>
                                </Col>
                            </Drawer>
                            <Col flex="1 1 auto" id="pitchContainerBox" ref={pitchRef}>
                                <div style={{display: toolsToShow && 'none'}} id="tacticsboard__canvas_container"
                                     className="tacticsboard__pitch_container">
                                    <canvas id="tacticsboard__canvas" ref={canvasRef}/>
                                    <canvas id="tacticsboard__canvas__test d__none" style={{height: "0", width: "0"}}/>
                                </div>
                                <TacticsBoardAnimationsActions
                                    isMobileView={isMobileView}
                                    isPlaying={isPlaying}
                                    activeFrameTime={activeFrameTime}
                                    setActiveFrameTimeFunc={setActiveFrameTimeFunc}
                                    playAnimation={playCurvedAnimation}
                                    pauseAnimation={pauseAnimation}
                                    stopAnimation={stopCurvedAnimation}
                                    updateFrame={switchFrame}
                                    addMoreFrame={addMoreFrame}
                                    removeFrame={removeFrame}
                                    frControlVisibility={frControlVisibility}
                                    setActiveFrame={setActiveFrame}
                                    isDownloadingVideo={isDownloadingVideo}
                                    activeFrame={activeFrame}
                                    setFrames={setFrames}
                                    frames={frames}/>
                            </Col>

                            {isMobileView &&
                            <LeftHeader updateUndoStates={updateUndoStates}
                                        moveEnd2={moveEnd2}
                                        activeBar={activeBar}
                                        moveLine={moveLine}
                                        returnAllObjects={returnAllObjects}
                                        onCloseDrawing={onCloseModal}
                                        undoEvent={undo}
                                        redoEvent={redo}
                                        modifiedObject={modifiedObject}
                                        deleteCanvasObject={deleteObject}
                                        movingObject={movingObject}
                                        mouseUp={mouseUp}
                                        exportVideo={exportVideo}
                                        reinitFreeShape={reinitFreeShape}
                                        reinitpath={reinitpath}
                                        cancelMove={cancelMove}
                                        isMobileView={isMobileView}
                                        activeFrame={activeFrame}
                                        fullScreen = {fullScreen}
                                        exitFullScreen = {exitFullScreen}
                                        isFullScreen={isFullScreen}
                                        setFullScreen = {setFullScreen}
                                        showDrawerBtn = {showDrawerBtn}
                                        onCloseModal={onCloseModal}
                                        undo={undo}
                                        redo = {redo}
                                        deleteObject = {deleteObject}
                                        showBpanel = {showBPanel}
                                        bPanel = {bPanel}
                                        toolsClick={toolsClick}
                                        addCustomSquad={addCustomSquad}
                                        customImagesJson={customImagesJson}


                            />
                            }
                            <PathShadowHolder svgElements={svgPathElementss}/>
                            {loader && <FormationLoading/>}
                            {bPanel &&  <BottomPanel props={toolsClick} modifiedObject={modifiedObject} addCustomSquad={addCustomSquad} customImagesJson={customImagesJson}/>}

                        </Row>
                    </Modal>
            }
        </>
    )
}

export default injectIntl(TacticsBoard);