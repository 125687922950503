import React, {useEffect} from "react";
import {Button} from "antd";
import {SvgIcon} from "@mui/material";
import {
    AppendIcon,
    CrossIcon,
    DrawerMenuIcon,
    ExitFullScreenIcon,
    FullscreenIcon,
    PlayersIcon,
    RemoveIcon
} from "../assets/icons";
import TacticsBoardLeftSide from "../components/header/leftside";
import {moveLine} from "../utils/lines/moveLine";
import {exitFullScreen, fullScreen} from "../utils/screen-resolutions/screenUtils";
import MobileTools from "./MobileTools";
import Draggable from "react-draggable";
import dragImg from "../assets/images/drag.png";
import {FormattedMessage} from "react-intl";
import TacticsBoardLeftSideExtra from "../components/header/leftsideextra";
import {useDispatch, useSelector} from "react-redux";
import {setIsModalOpen, setModalLeft, setModalTop} from "../actions";

const LeftHeader = ({ isMobileView, updateUndoStates, frControlVisibility, updateAllProps, exportVideo, cancelMove, deleteCanvasObject, reinitFreeShape, moveEnd2, moveLine, returnAllObjects, onCloseDrawing, undoEvent, redoEvent, modifiedObject, movingObject, mouseUp, reinitpath, activeBar, activeFrame, fullScreen, exitFullScreen, setFullScreen, isFullScreen,showDrawerBtn, onCloseModal, undo, redo, deleteObject, showBpanel, bPanel  })=>{
    const {isModalOpen, modalTop, modalLeft, textField} = useSelector(state => state.player);
    const objectStates = useSelector(state => state.canvasObjectStates);
    const dispatch = useDispatch();
    const canvas = useSelector(state => state.canvas);
    useEffect(() => {
        let obj = canvas?.getActiveObject()
        if (!obj) return
        if (isMobileView) {
            if (objectStates.line) {
                dispatch(setModalLeft(obj.left + 'px'))
                dispatch(setModalTop(obj.top > 170 ? obj.top - 150 + 'px' : obj.top + obj.height + 20 + 'px'))
            } else if (objectStates.shape) {
                dispatch(setModalLeft(obj.left - 100 + 'px'))
                dispatch(setModalTop(obj.top > 170 ? obj.top - (obj.height) - 150 + 'px' : obj.top + obj.getScaledHeight() + 50 + 'px'))
            } else {
                dispatch(setModalLeft(obj.left - 100 + 'px'))
                dispatch(setModalTop(obj.top > 170 ? obj.top - 180 + 'px' : obj.top + 80 + 'px'))
            }
        }
    }, [objectStates, dispatch])
    return(
        <div className={"left_header_menu"}>

            {bPanel?<MobileTools isMobileView={isMobileView}/>:
                <TacticsBoardLeftSide updateUndoStates={updateUndoStates}
                                      moveEnd2={moveEnd2}
                                      activeBar={activeBar}
                                      moveLine={moveLine}
                                      returnAllObjects={returnAllObjects}
                                      onCloseDrawing={onCloseModal}
                                      undoEvent={undo}
                                      redoEvent={redo}
                                      modifiedObject={modifiedObject}
                                      deleteCanvasObject={deleteObject}
                                      movingObject={movingObject}
                                      mouseUp={mouseUp}
                                      exportVideo={exportVideo}
                                      reinitFreeShape={reinitFreeShape}
                                      reinitpath={reinitpath}
                                      cancelMove={cancelMove}
                                      isMobileView={isMobileView}
                                      activeFrame={activeFrame}
                />

            }

            {isModalOpen &&
            <Draggable bounds="body" handle=".draggable_div">
                <div style={{ left: modalLeft, top: modalTop }} className={`tacticsboard__header_lft_extra_dt_container component_editor_modal ${objectStates.objectActive ? '' : 'd__none'}`}>
                    <div className="editor_modal_header">
                        <div className={"draggable_div"}>
                            <img src={dragImg} height={24} width={24} draggable="false" />
                            <span>{textField?'Text Editor':<FormattedMessage id='general.componentEditor'/>}</span>
                        </div>
                        <Button onClick={()=>dispatch(setIsModalOpen(!isModalOpen))} className={`tacticsBoard_mainTool_icon`}
                                icon={<SvgIcon><CrossIcon /></SvgIcon>} />
                    </div>
                    <div className="editor_modal_body">
                        <TacticsBoardLeftSideExtra exportVideo={exportVideo} updateUndoStates={updateUndoStates}
                                                   cancelMove={cancelMove} reinitFreeShape={reinitFreeShape}
                                                   deleteCanvasObject={deleteCanvasObject} moveEnd2={moveEnd2}
                                                   moveLine={moveLine} returnAllObjects={returnAllObjects}
                                                   modifiedObject={modifiedObject} movingObject={movingObject} updateAllProps={updateAllProps}
                                                   mouseUp={mouseUp} reinitpath={reinitpath} activeFrame={activeFrame} />
                    </div>
                </div>
            </Draggable>
            }


            <div className="fullScreen_button">
                {!isFullScreen ?
                    <Button className="menu_button" onClick={() => {
                        fullScreen(isFullScreen, setFullScreen)
                    }} icon={<SvgIcon
                        style={{marginRight: '-6px', marginTop: '5px'}}><FullscreenIcon
                        fill={'#595959'}/></SvgIcon>}/>
                    :
                    <Button className="menu_button" onClick={() => {
                        exitFullScreen(isFullScreen, setFullScreen)
                    }} icon={<SvgIcon
                        style={{marginRight: '-6px', marginTop: '5px'}}><ExitFullScreenIcon
                        fill={'#595959'}/></SvgIcon>}/>
                }
            </div>


            <div style={{position:'fixed',top:'88%'}}>
                {!bPanel?<Button className="menu_button" icon={<AppendIcon/>}
                         onClick={() => showBpanel(true)}/>:
                    <Button className="menu_button" icon={<RemoveIcon/>} onClick={() => showBpanel(false)}/>}
            </div>


        </div>
    )
}
export default LeftHeader;