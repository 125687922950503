import {fabric} from "fabric";

export const handleFreeShape = (props,canvas,addControls)=>{
    const uuid = require("uuid");
    var polygon;
    let newPoints;
    let id = uuid.v4();
    if (props) id = props.ref_id;
    const left = canvas.getWidth() / 2, top = canvas.getHeight() / 2,
        width = 30, height = 20;
    if (props) {
        newPoints = [
            {x: props.points[0].x, y: props.points[0].y}, //left top
            {x: props.points[1].x, y: props.points[1].y},  //right top
            {x: props.points[2].x, y: props.points[2].y}, //right bottom
            {x: props.points[3].x, y: props.points[3].y}, //left bottom

        ]
    } else {
        newPoints = [
            {x: left, y: top}, //left top
            {x: left + width, y: top},  //right top
            {x: left + width - 5, y: top + height}, //right bottom
            {x: left + 5, y: top + height}, //left bottom

        ]
    }

    polygon = new fabric.Polygon(newPoints
        , {
            left: props ? props.left : left,
            top: props ? props.top : top,
            fill: props ? props.fill : 'transparent',
            strokeWidth: 0.3,
            stroke: props ? props.stroke : '#fff',
            scaleX: 4,
            scaleY: 4,
            objectCaching: false,
            bgOpacity: props ? props.bgOpacity : 1,
            transparentCorners: false,
            perPixelTargetFind: true,
            fadeFlag: props ? props.fadeFlag : 'no-fade-out',
            cornerColor: 'blue',
            ref_id: id,
            name: 'free-shape',
            hasBorders: false,
            lockMovementX:props?.lockMovementX || false,
            lockMovementY:props?.lockMovementY || false,
        });

    addControls(polygon)
    return polygon
}

export const getControls = (polygon,canvas,anchorWrapper,actionHandler)=>{
    var lastControl = polygon.points.length - 1;
    polygon.cornerStyle = 'circle';
    polygon.cornerColor = 'black';
    polygon.controls = polygon.points.reduce(function (acc, point, index) {
        console.log('this',this)
        acc['p' + index] = new fabric.Control({
            positionHandler:  function polygonPositionHandler(dim, finalMatrix, fabricObject) {
                if (!fabricObject.canvas) fabricObject.canvas = canvas
                var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
                    y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
                return fabric.util.transformPoint(
                    { x: x, y: y },
                    fabric.util.multiplyTransformMatrices(
                        fabricObject.canvas.viewportTransform,
                        fabricObject.calcTransformMatrix()
                    )
                );
            },
            actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
            actionName: 'modifyPolygon',
            pointIndex: index
        });
        return acc;
    }, {});
}

export const handlePolygonPosition = (dim, finalMatrix, fabricObject,canvas)=>{
    if (!fabricObject.canvas) fabricObject.canvas = canvas
    var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
        y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
    return fabric.util.transformPoint(
        {x: x, y: y},
        fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix()
        )
    );
}
// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current trasnform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed,
export function actionHandler(eventData, transform, x, y) {
    var polygon = transform.target,
        currentControl = polygon.controls[polygon.__corner],
        mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
        polygonBaseSize = getObjectSizeWithStroke(polygon),
        size = polygon._getTransformedDimensions(0, 0),
        finalPointPosition = {
            x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
            y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
        };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    return true;
}
// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
export function anchorWrapper(anchorIndex, fn) {
    return function (eventData, transform, x, y) {
        var fabricObject = transform.target,
            absolutePoint = fabric.util.transformPoint({
                x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
            }, fabricObject.calcTransformMatrix()),
            actionPerformed = fn(eventData, transform, x, y),
            newDim = fabricObject._setPositionDimensions({}),
            polygonBaseSize = getObjectSizeWithStroke(fabricObject),
            newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
            newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
        fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
        return actionPerformed;
    }
}
function getObjectSizeWithStroke(object) {
    var stroke = new fabric.Point(
        object.strokeUniform ? 1 / object.scaleX : 1,
        object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

export const freeShapeReInitialized = (duplicatedObj,addFreeShape,stopEvents,startEvents,canvas)=>{
    if (duplicatedObj) {
        let prevShape = {
            ref_id: duplicatedObj.ref_id,
            points: duplicatedObj.points,
            left: duplicatedObj.left,
            top: duplicatedObj.top,
            stroke: duplicatedObj.stroke,
            fill: duplicatedObj.fill,
            bgOpacity: duplicatedObj.bgOpacity,
            fadeFlag: duplicatedObj.fadeFlag,
            lockMovementX:duplicatedObj.lockMovementX,
            lockMovementY:duplicatedObj.lockMovementY,
        }
        let newShape = addFreeShape(prevShape);
        return newShape
    } else {
        let tempFreeShapes = canvas._objects.filter(f => f.name === 'free-shape')
        if (tempFreeShapes.length > 0) {
            tempFreeShapes.forEach(e => {
                let prevObj = e;
                let prevShape = {
                    ref_id: prevObj.ref_id,
                    points: prevObj.points,
                    left: prevObj.left,
                    top: prevObj.top,
                    stroke: prevObj.stroke,
                    fill: prevObj.fill,
                    bgOpacity: prevObj.bgOpacity,
                    fadeFlag: prevObj.fadeFlag,
                    lockMovementX:prevObj.lockMovementX,
                    lockMovementY:prevObj.lockMovementY,
                }
                stopEvents();
                canvas.remove(prevObj);
                canvas.add(addFreeShape(prevShape));
                canvas.renderAll()
                startEvents();
            })
        }
    }
}
