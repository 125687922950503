export const generalEN = {
  general: {
    save: "Save",
    name: "Name",
    required: "Required",
    addFile: "Add File",
    uploadFile: "Upload File",
    addDrawing: "Add Drawing",
    description: "Description",
    youtubeLink: "YouTube Link",
    selectBackground: "Select Background",
    folder: "Folder",
    cancel: "Cancel",
    position:"Positions",
    squadImages:"Squad",
    squadNumbers:"Squad Numbers",
    createFolder: "Create Folder",
    success: "Success",
    errorOccured: "Something went wrong, please try again",
    myFiles: "My Files",
    search: "Search",
    filesStorage: "Files Storage",
    download: "Download",
    properties: "Properties",
    format: "Format",
    filters: "Filters",
    added: "Added",
    size: "Size",
    file: "File",
    enterName: "Enter name",
    confirm: "Confirm",
    fileWillBeDeleted: "File will be deleted",
    ok: "Ok",
    edit: "Edit",
    delete: "Delete",
    editFile: "Edit File",
    open: "Open",
    general: "General",
    my: "My",
    ifaDrills: "IFA Drills",
    myDrills: "My Drills",
    ageGroups: "Age Groups",
    difficulty: "Difficulty",
    categories: "Categories",
    subCategories: "Sub Categories",
    all: "All",
    tags: "Tags",
    educationalDrills: "Educational Drills",
    clearFilters: "Clear Filters",
    addMyDrill: "Add My Drill",
    viewMore: "View More",
    allAges: "All Ages",
    done: "Done",
    addToCategories: "Add To Categories",
    removeFromAllCategories: "Remove From All Categories",
    myCategories: "My Categories",
    drillWillBeRemovedFromMyCategories:
      "Drill will be removed from My Categories",
    addCategory: "Add Category",
    editCategories: "Edit Categories",
    addDrill: "Add Drill",
    addSubCategory: "Add Sub Category",
    veryEasy: "Very Easy",
    easy: "Easy",
    medium: "Medium",
    hard: "Hard",
    veryHard: "Very Hard",
    uploadImage: "Upload Image",
    addYoutubeLink: "YouTube Link",
    addTrainingDrill: "Add Drill",
    explanation: "Explanation",
    showAdditionalParams: "Show Additional Params",
    trainingDrillPlan: "Drills Plan",
    saveDrill: "Save Drill",
    optional: "Optional",
    hideAdditionalParams: "Hide Additional Params",
    aim: "Aim",
    organisation: "Organisation",
    coachPoints: "Coach Points",
    topics: "Topics",
    equipment: "Equipment",
    playersNo: "Players No.",
    time: "Time",
    editDrill: "Edit Drill",
    IFATemplates: "IFA Plans",
    myTemplates: "My Plans",
    space: "Space",
    addPlan: "Add Plan",
    players: "Players",
    age: "Age",
    createdBy: "Created By",
    drills: "Drills",
    tactic: "Tactics",
    halfPitch: "Half Pitch",
    quarterPitch: "Quarter Pitch",
    sessionsTemplates: "Sessions Plans",
    sessionPlanWillBeDeleted: "Session Plan will  be deleted",
    trainingDrillWillBeDeleted: "Drill will be deleted",
    title: "Title",
    addSessionPlan: "Add Session Plan",
    buildup: "Buildup",
    plan: "Plan",
    ages: "Ages",
    "time(mins)": "Time",
    minutes: "Minutes",
    planNavigation: "Plan Navigation",
    saveChanges: "Save Changes",
    cancelEdit: "Cancel Edit",
    drillsLibrary: "Drills Library",
    createDrill: "Create Drill",
    addFreeText: "Add Free Text",
    planNavigationExplanation:
      "You can drag and drop the exercises and also use the arrows to move them",
    drillWillBeDeleted: "Drill will be deleted",
    details: "Details",
    info: "Info",
    sets: "Sets",
    comment: "Comment",
    addComment: "Add Comment",
    "time/set": "Time/Set",
    "break(sec)": "Break (sec)",
    "totalTime(mins)": "Total time (mins)",
    remove: "Remove",
    timeDetails: "Time Details",
    selectCategory: "Select Category",
    select: "Select",
    freeText: "Free Text",
    selectTrainingDrill: "Select Training Drill",
    sessionPlan: "Session Plan",
    avatar: "Avatar",
    videoLink: "Video Link",
    files: "Files",
    submission: "Submission",
    submissionWillBeDeleted: "Submission will be deleted",
    actions: "Actions",
    itemsSelectedWillBeDeleted: "Items selected will be deleted",
    unselectAll: "Unselect all",
    move: "Move",
    moveTo: "Move To",
    moveHere: "Move here",
    items: "items",
    here: "here",
    share: "Share",
    unselect: "Unselect",
    cannotOpenSelectedFolder: "Can't open selected folder",
    cannotMoveToSelectedFolder: "Can't move to selected folder",
    editImage: "Edit Image",

    user_permissions: {
      courses: "Courses",
      classes: "Classes",
      assignments: "Assignments",
      grades: "Grades",
      syllabuses: "Syllabuses",
      settings: "Settings",

      syllabus_admin: "Syllabus Admin",
      grades_admin: "Grades Admin",
      grades_class_staff: "Grades Class Staff",
      grades_course_staff: "Grades Course Staff",
      settings_admin: "Settings Admin",
      users_admin: "Users Admin",
      users_organization_admin: "Users Organization Admin",
      organizations_admin: "Organizations Admin",
      certificates_admin: "Certificates Admin",
      assignments_admin: "Assignments Admin",
      assignments_course_staff: "Assignments Course Staff",
      assignments_class_staff: "Assignments Class Staff",
      classes_admin: "Classes Admin",
      classes_organization_admin: "Classes Organizations Admin",
      courses_admin: "Courses Admin",
      courses_organization_admin: "Courses Organization Admin",
    },
    permissions: "Permissions",
    addAnimation: "Add Animation",
    youtube: "YouTube",
    addFrame: "Add Frame",
    allSelectionsWillBeDiscardedWhenOpeningFolder:
      "All Selections will be discarded when opening folder",
    itemsSelected: "Items Selected",
    view: "View",
    attach: "Attach",
    selectedFiles: "Selected Files",
    fileWillBeUnselected: "File will be unselected",
    filesLibrary: "Files Library",
    selectFiles: "Select Files",
    attachSelectedFiles: "Attach Selected Files",
    sessionPlans: "Sessions Plans",
    drill: "Drill",
    drillWillBeRemoved: "Drill will be removed",
    enterDrillName: "Enter drill name",
    selectPlan: "Select Plan",
    planWillBeRemoved: "Plan will be removed",
    drillCategories: "Drill Categories",
    filesAndAttachements: "Files & Attachements",

    trainings: {
      Balls: "Balls",
      Flags: "Flags",
      Cones: "Cones",
      Hurdles: "Hurdles",
      Mannequins: "Mannequins",
      TargetGoals: "Target Goals",
      SpeedLadders: "Speed Ladders",
      PitchMarkers: "Pitch Markers",
      AgilityCones: "Agility Cones",
      Hoops: "Hoops",
      Bibs: "Bibs",
    },

    sharedWith: "Shared with",
    shared: "Shared with me",
    my_shared: "Shared by me",
    cannotShareFilesMessage: "You don't have permission to share item selected",
    cannotMoveFilesMessage:
      "You don't have permission to move one of items selected",
    cannotDeleteFilesMessage:
      "You don't have permission to delete one of items selected",
    cannotEditFilesMessage: "You don't have permission to edit item selected",
    processingVideo: "Processing video",
    transcodingVideo: "Transcoding video",
    uploadingVideo: "Uploading video",
    shareFilesAndFolders: "Share Files & Folders",
    itemsToShare: "Items to Share",
    users: "Users",
    addUser: "Add User",
    editor: "Editor",
    view_and_download: "View & Download",
    lms_manager: "Manager",
    lms_student: "Student",
    lms_staff: "Staff",
    lms_lecturer: "Lecturer",
    selectUsers: "Select Users",
    manager: "Manager",
    userWillBeRemoved: "User will be removed",
    user: "User",
    mixedPermissions: "Mixed Permissions",
    seeAll: "See all",
    usersAndPermissions: "Users & Permissions",
    custom: "Custom",
    studentFiles: "Student Files",
    shared_with_student: "Shared with student",
    shared_with_me: "Shared with me",
    moveToSharedFolderTitle: "Change who has access?",
    moveToSharedFolderMessage:
      "Items moved will be visible to anyone who has access to ",
    drillTime: "Drill Time",
    editTrainingDrill: "Edit Training Drill",
    shareDrill: "Share Drill",
    fullPitch: "Full Pitch",
    other: "Other",
    close: "Close",
    addWebLink: "Add Web Link",
    link: "Link",
    webLink: "Web link",
    openInNewTab: "Open",
    goTo: "Go to",
    web_link: "Web Link",
    uploadVideo: "Upload Video",
    createSessionPlan: "Create Session Plan",
    sessionsPlansLibrary: "Sessions Plans Library",
    retrievingDocument: "Retrieving Document",
    editSubCategory: "Edit Sub category",
    createCategory: "Create Category",
    selectDate: "Select Date",
    fieldType: "Field Type",
    playerNumber:"Player Number",
    colorFill: "Color Fill",
    nameColor: "Name color",
    nameBackground: "Name background",
    componentEditor: "Component Editor",
    direction: "Direction",
    highlight: "Highlight",
    fading: "Fading",
    numbers:"Numbers",
    supportLines: "Support Lines",
    selectPitch: "Select Pitch",
    playerCharacter: "Characters",
    playerName:"Player Name",
    opacity:'Opacity',
    duplicate:"Duplicate",
    label:"Label",
    layers:"Layer",
    reset:"Reset",
    decreaseSize: "Decrease Size",
    increaseSize: "Increase Size",
    sendForward: "Send Forward",
    sendBackward: "Send Backward",
    showDirection: "Show Direction",
    showHighlight: "Show highlight",
    objectSettings: "Object Settings",
    objectSetting: "Object Setting",
    characters:"Characters",
    goalKeeper: "GoalKeepers",
    tools: "Tools",
    shapes: "Shapes",
    formation: "Formations",
    color: "Color",
    colors: "Colors",
    playersCircles: "Players Circles",
    playersTriangles: "Players Triangles",
    rotate: "Rotate",
    previousDrawing: "Previous Drawing",
    processingFileWait: "Processing file, please wait ...",
    savingAnimations: "Saving Animation Images",
    savingImage: "Saving Drawing Image",
    componentSetting: "Component Settings",
    exit: "Exit",
  },
  "tag.Ball Control and Footwork": "Ball Control and Footwork",
  "tag.Compactness": "Compactness",
  "tag.Control & Restraint": "Control & Restraint",
  "tag.Counter Attacking": "Counter Attacking",
  "tag.Cover & Balance": "Cover & Balance",
  "tag.Creativity": "Creativity",
  "tag.Crossing & Finishing": "Crossing & Finishing",
  "tag.Delay": "Delay",
  "tag.Goalkeeping": "Goalkeeping",
  "tag.Heading": "Heading",
  "tag.Low Block": "Low Block",
  "tag.Movement": "Movement",
  "tag.Overloads": "Overloads",
  "tag.Passing": "Passing",
  "tag.Penetration": "Penetration",
  "tag.Playing out from the back": "Playing out from the back",
  "tag.Possession": "Possession",
  "tag.Pressing": "Pressing",
  "tag.Shooting": "Shooting",
  "tag.Skills": "Skills",
  "tag.Support": "Support",
  "tag.Support Play": "Support Play",
  "tag.Turning": "Turning",
  "tag.Zone 1": "Zone 1",
  "tag.Zone 2": "Zone 2",
  "tag.Zone 3": "Zone 3",
  "tag.Zone 4": "Zone 4",
  "general.objectSettings": "Object Settings",
  "general.objectSetting": "Object Settings",
  "general.border": "Border",
  "general.resize":"Resize",
  "general.text":"Text",
  "general.type":"Type",
  "general.shirts":'Shirts',
  "general.triangles":'Triangles',
};

export const generalIL = {
  general: {
    save: "שמור",
    name: "שם",
    required: "חובה",
    addFile: "הוסף קובץ",
    uploadFile: "העלה קובץ",
    addDrawing: "הוסף ציור",
    description: "הסבר",
    youtubeLink: "YouTube קישור",
    selectBackground: "בחר רקע",
    folder: "תקייה",
    cancel: "בטל",
    createFolder: "צור תקייה",
    success: "בוצע בהצלחה",
    errorOccured: "משהו השתבש, נסה שוב",
    myFiles: "הקבצים שלי",
    search: "חיפוש",
    filesStorage: "ספריית קבצים",
    download: "הורד",
    properties: "properties",
    format: "סוג",
    added: "התווסף",
    size: "גודל",
    file: "קובץ",
    filters: "מסננים",

    enterName: "הכנס שם",
    confirm: "לאשר",
    fileWillBeDeleted: "הקובץ ימחק!",
    ok: "אשר",
    edit: "ערוך",
    delete: "מחר",
    editFile: "ערוך קובץ",
    open: "פתח",
    general: "כללי",
    my: "שלי",
    ifaDrills: "תרגילי התאחדות",
    myDrills: "התרגילים שלי",
    ageGroups: "קבוצות גיל",
    difficulty: "קושי",
    categories: "קטגוריות",
    subCategories: "קטגוריות משנה",
    all: "הכל",
    tags: "תגיות",
    educationalDrills: "תרגילים לימודיים",
    clearFilters: "נקה מסננים",
    addMyDrill: "הוסף לתרגילים שלי",
    viewMore: "צפה בעוד",
    allAges: "כל הגילאים",
    done: "סגור",
    addToCategories: "הוסף לקטגרויות",
    removeFromAllCategories: "הסר מכל הקטגריות",
    myCategories: "הקטגוריות שלי",
    drillWillBeRemovedFromMyCategories: "התרגיל יוסר מהקטגריות שלי",
    addCategory: "הוסף קטגוריה",
    editCategories: "ערוך קטגרויות",
    addDrill: "הוסף תרגיל",
    addSubCategory: "הוסף קטגוריית משנה",
    veryEasy: "קל מאוד",
    easy: "קל",
    medium: "בינוני",
    hard: "קשה",
    veryHard: "מאוד קשה",
    uploadImage: "העלה תמונה",
    addYoutubeLink: "YouTube קישור",
    addTrainingDrill: "הוסף תרגיל",
    explanation: "הסבר",
    showAdditionalParams: "הצג פרמטרים נוספים",
    trainingDrillPlan: "תכנון תרגיל",
    saveDrill: "שמור תרגיל",
    optional: "רשות",
    hideAdditionalParams: "הכבה פרמטרים נוספים",
    aim: "מטרה",
    organisation: "ארגון",
    coachPoints: "נקודות מאמן באימון",
    topics: "נושאים",
    equipment: "ציוד",
    playersNo: "מספר שחקנים",
    time: "זמן",
    editDrill: "ערוך תרגיל",
    IFATemplates: "תוכניות אימון התאחדות",
    myTemplates: "תוכניות אימון שלי",
    space: "שטח",
    addPlan: "הוסף תוכנית אימון",
    players: "שחקנים",
    age: "גיל",
    createdBy: "נוצר על ידי",
    drills: "תרגילים",
    tactic: "שיטת משחק",
    halfPitch: "חצי מגרש",
    quarterPitch: "רבע מגרש",
    sessionsTemplates: "תוכניות אימון",
    sessionPlanWillBeDeleted: "תוכנית האימון תמחק",
    trainingDrillWillBeDeleted: "התרגיל ימחק",
    title: "נושא",
    addSessionPlan: "הוסף תוכנית אימון",
    buildup: "מבנה האימון",
    plan: "תוכנית",
    ages: "גיל",
    "time(mins)": "זמן",
    minutes: "דקות",
    planNavigation: "סיכום האימון",
    saveChanges: "שמור שינויים",
    cancelEdit: "בטל עריכה",
    drillsLibrary: "ספריית תרגילים",
    createDrill: "צור תרגיל",
    addFreeText: "הוסף טקסט חופשי",
    planNavigationExplanation:
      "תוכל לגרור את התרגילים ממקום למקום וגם להזיז בעזרת החצים",
    drillWillBeDeleted: "התרגיל ימחק",
    details: "פרטים",
    info: "פרטי התרגיל",
    sets: "חזרות",
    comment: "תגובה",
    addComment: "הוסף תגובה",
    "time/set": "זמן חזרה(דק)",
    "break(sec)": "זמן הפסקה(שניות)",
    "totalTime(mins)": "זמן כלל(דקות)",
    remove: "הסר",
    timeDetails: "פירוט זמן",
    selectCategory: "בחר קטגוריה",
    select: "בחר",
    freeText: "טקסט חופשי",
    selectTrainingDrill: "בחרר תרגיל",
    sessionPlan: "תוכנית אימון",
    videoLink: "קישור לוידאו",
    files: "קובץ",
    submission: "הכנס",
    submissionWillBeDeleted: "ההכנה תמחק",
    actions: "פעולות",
    itemsSelectedWillBeDeleted: "הפריטים הנבחרים ימחקו",
    unselectAll: "הסר בחירה מהכל",
    move: "העבר",
    moveTo: "העבר ל",
    moveHere: "העבר לכן",
    items: "פריטים",
    here: "כאן",
    share: "שתף",
    unselect: "הסר בחירה",
    cannotOpenSelectedFolder: "לא ניתן לפתוח את התיקייה הנבחרת",
    cannotMoveToSelectedFolder: "לא ניתן להעביר לתקייה הנבחרת",
    editImage: "ערוך תמונה",

    user_permissions: {
      courses: "קורסים",
      classes: "שעורים",
      assignments: "משימות",
      grades: "ציונים",
      syllabuses: "סילבוס",
      settings: "הגדרות",

      syllabus_admin: "מנהל סילבוסים",
      grades_admin: "ניתן לתת ציונים בכל המשימות והמבחנים",
      grades_class_staff: "ניתן לתת ציונים כאיש צוות בשיעור",
      grades_course_staff: "ניתן לתת ציונים לכל הקורס כאיש צוות",
      settings_admin: "מנהל הגדרות",
      users_admin: "מנהל משתמשים",
      users_organization_admin: "מנהל משתמשים בארגונים",
      organizations_admin: "מנהל ארגונים",
      certificates_admin: "מנהל תעודות",
      assignments_admin: "יכול ליצור משימות בכל הקורסים",
      assignments_course_staff: "יכול ליצור משימות רק בקרוסים כאיש צוות",
      assignments_class_staff: "יכול ליצור משימות בשיעורים בהם איש צוות",
      classes_admin: "מנהל שיעורים בכל הארגונים",
      classes_organization_admin: "מנהל כל השיעורים בארגון אליו משוייך",
      courses_admin: "מנהל קורסים בכל הארגונים",
      courses_organization_admin: "מנהל קורסים רק בארגון עצמו",
    },
    avatar: "Avatar",
    permissions: "הרשאות",
    addAnimation: "Add Animation",
    youtube: "YouTube",
    addFrame: "Add Frame",
    allSelectionsWillBeDiscardedWhenOpeningFolder:
      "All Selections will be discarded when opening folder",
    itemsSelected: "Items Selected",
    view: "View",
    attach: "Attach",
    selectedFiles: "Selected Files",
    fileWillBeUnselected: "File will be unselected",
    filesLibrary: "Files Library",
    selectFiles: "Select Files",
    attachSelectedFiles: "Attach Selected Files",
    sessionPlans: "Sessions Plans",
    drill: "Drill",
    drillWillBeRemoved: "Drill will be removed",
    enterDrillName: "Enter drill name",
    selectPlan: "Select Plan",
    planWillBeRemoved: "Plan will be removed",
    drillCategories: "Drill Categories",
    filesAndAttachements: "Files & Attachements",

    trainings: {
      Balls: "כדורים",
      Flags: "דגלים",
      Cones: "קונוסים",
      Hurdles: "משוכות",
      Mannequins: "דמוי שחקן",
      TargetGoals: "שערים",
      SpeedLadders: "סולמות",
      PitchMarkers: "סימוני מגרש",
      AgilityCones: "קונוסים זריזות",
      Hoops: "חישוקים",
      Bibs: "גופיות",
    },

    sharedWith: "Shared with",
    shared: "Shared with me",
    my_shared: "Shared by me",
    cannotShareFilesMessage: "You don't have permission to share item selected",
    cannotMoveFilesMessage:
      "You don't have permission to move one of items selected",
    cannotDeleteFilesMessage:
      "You don't have permission to delete one of items selected",
    cannotEditFilesMessage: "You don't have permission to edit item selected",
    processingVideo: "Processing video",
    transcodingVideo: "Transcoding video",
    uploadingVideo: "Uploading video",
    shareFilesAndFolders: "Share Files & Folders",
    itemsToShare: "Items to Share",
    users: "Users",
    addUser: "Add User",
    editor: "Editor",
    view_and_download: "View & Download",
    lms_manager: "Manager",
    lms_student: "Student",
    lms_staff: "Staff",
    lms_lecturer: "Lecturer",
    selectUsers: "Select Users",
    manager: "Manager",
    userWillBeRemoved: "User will be removed",
    user: "User",
    mixedPermissions: "Mixed Permissions",
    seeAll: "See all",
    usersAndPermissions: "Users & Permissions",
    custom: "Custom",
    studentFiles: "Student Files",
    shared_with_student: "Shared with student",
    shared_with_me: "Shared with me",
    moveToSharedFolderTitle: "Change who has access?",
    moveToSharedFolderMessage:
      "Items moved will be visible to anyone who has access to ",
    drillTime: "Drill Time",
    editTrainingDrill: "Edit Training Drill",
    shareDrill: "Share Drill",
    fullPitch: "Full Pitch",
    other: "Other",
    close: "Close",
    addWebLink: "Add Web Link",
    link: "Link",
    webLink: "Web link",
    openInNewTab: "Open",
    goTo: "Go to",
    web_link: "Web Link",
    uploadVideo: "Upload Video",
    createSessionPlan: "Create Session Plan",
    sessionsPlansLibrary: "Sessions Plans Library",
    retrievingDocument: "Retrieving Document",
    editSubCategory: "Edit Sub category",
    createCategory: "Create Category",
    selectDate: "Select Date",

    fieldType: "סוג מגרש",
    supportLines: "קווי עזר",
    selectPitch: "בחר מגרש",
    playerCharacter: "דמויות שחקנים",
    goalKeeper: "שוערים",
    tools: "כלים",
    shapes: "צורות",
    formation: "שיטת משחק",
    color: "צבע",
    playersCircles: "עיגולי שחקנים",
    playersTriangles: "משלושים",
    rotate: "לסובב",
    previousDrawing: "ציורים קודמים",
    processingFileWait: "מכין קובץ, נא המתן ...",
    downloadImage: "הורד תמונה",
    savingAnimations: "שומר את תמונות האנימציה",
    savingImage: "שומר תמונות",
    componentSetting: "הגדרות האובייקט",
    exit: "יציאה",
    pleaseRotateDevice: "אנא הפוך את המכשיר",
    normal: "רגיל",
    undo: "בטל",
    redo: "בצע שוב",
    changePitches: "שנה מגרשים",
  },
  "Are you sure you want to close?": "האם אתה בטוח שברצונך לסגור?",
  "tag.Control & Restraint": "שליטה ואיפוק",
  "tag.Cover & Balance": "כיסוי ואיזון",
  "tag.Delay": "לעכב",
  "tag.Playing out from the back": "משחק מאחור",
  "tag.Skills": "מיומנויות",
  "tag.Support Play": "תמיכה במשחק",
  "tag.Ball Control and Footwork": "שליטה בכדור ועבודת רגליים",
  "tag.Compactness": "צפיפות",
  "tag.Counter Attacking": "התקפות מתפרצות",
  "tag.Creativity": "יצירתיות",
  "tag.Crossing & Finishing": "קרוסים וסיומות",
  "tag.Goalkeeping": "שוערים",
  "tag.Heading": "נגיחות",
  "tag.Low Block": "הגנה נמוכה",
  "tag.Movement": "תנועתיות",
  "tag.Overloads": "העמסה",
  "tag.Passing": "פסים",
  "tag.Penetration": "כניסה לעומק(חדירה)",
  "tag.build up": "בילדאפ",
  "tag.Possession": "החזקת כדור",
  "tag.Pressing": "לחץ",
  "tag.Shooting": "בעיטות",
  "tag.Support": "תומכים",
  "tag.Turning": "תפניות",
  "tag.Change Direction": "שינוי כיוון(כובד משחק)",
  "tag.Dribble": "דריבל",
  "tag.Zone 1": "Zone 1",
  "tag.Zone 2": "Zone 2",
  "tag.Zone 3": "Zone 3",
  "tag.Zone 4": "Zone 4",
  "general.normal": "נורמלי",
  "general.objectSettings": "Object Settings",
  "general.exportVideo": "Export Video",
  "general.pleaseRotateDevice": "Please, rotate your device!",
  "general.background": "Background",
  "general.border": "Border",
  "general.showHighlight": "Show highlight",
  "general.nameBackground": "Name background",
  "general.reset": "Reset",
  "general.nameColor": "Name color",
  "general.textColor": "Text color",
  "general.colorFill": "Color Fill",
  "general.color": "צבע",
  "general.colors": "צבע",
  "general.character": "Character",
  "general.playerCharacters": "דמויות שחקנים",
  "general.playersCircles": "עיגולי שחקנים",
  "general.tools": "כלים",
  "general.formation": "שיטת משחק",
  "general.squad": "שיחידת",
  "general.squadImages": "שיחידת תמונות",
  "general.squadNumbers": "שיחידת מספרי",
  "general.processingFileWait": "מכין קובץ, נא המתן...",
  "general.goalKeeper": "שוער",
  "general.rotate": "סובב",
  "general.howLongTheRpeOpen": "לכמה זמן הRPE יהיה פתוח לאחר אימון/משחק?",
  "general.addEdit": "הוסף/ערוך",
  "general.playersTriangles": "משלושים",
  "general.circles": "עיגולים",
  "general.triangles": "משולשים",
  "general.subTeam": "קבוצת משנה",
  "general.presentationSharedSuccessfully": "המצגת שותפה בהצלחה",
  "general.presentationUnsharedSuccessfully": "שיתוף המצגת בוטל בהצלחה",
  "general.armyJoinDate": "תאריך גיוס לצבא",
  "general.savingAnimations": "שומר את תמונות האנימציה",
  "general.savingImage": "שומר תמונות",

  "general.selectPitch": "בחר מגרש",
  "general.supportLines": "קווי עזר",
  "general.fieldType": "סוג מגרש",
  "general.componentEditor": "עורך אובייקט",
  "general.componentSetting": "הגדרות האובייקט",
  "general.objectSetting": "הגדרות האובייקט",
  "general.connect": "חיבור שחקנים",
  "general.changePitches": "שנה מגרש",
  "general.cancelMove": "בטל תנועה",
  "general.direction": "גיוון",
  "general.highlight": "הבלט אובייקט",
  "general.fading": "Fading",
  "general.layers": "שכבות",
  "general.opacity": "שקיפות",
  "general.size": "גודל",
  "general.label": "טקסט",
  "general.showDirection": "הראה כיוון",
  "general.fadingAnimation": "Fading Animation",
  "general.sendForward": "העבר קדימה",
  "general.sendBackward": "העבר אחורה",
  "general.decreaseSize": "להקטין",
  "general.increaseSize": "להגדיל",
  "general.shirtNumber": "מספר חולצה",
  "general.playerName": "שם שחקן",
  "general.playerNumber": "מספר שחקן",
  "general.innerText": "טקסט פנימי",
  "general.exit": "בטל וצא",
  "general.previousDrawing": "ציורים קודמים",
  "general.downloadImage": "הורד תמונה",
  "general.isFullTraining": "Full Training?",
  "general.loadMorePastEvents": "נסה לטעון עוד אירועים קודמים",
  "general.loadMoreFutureEvents": "נסה לטעון עוד אירועים עתידיים",
  "general.accountNotActivated": "Account has not been activated yet",
  "general.playerStatus": "זמינות השחקן",
  "general.createNewSeason": "יצירת עונה חדשה",
  "general.keepPlayers": "שמור שחקנים",
  "general.cleanPlayers": "מחק שחקנים",
  "general.takePlayersFromAnotherTeam": "לקחת שחקנים מקבוצה אחרת",
  "general.replaced(Mins)": "הוחלף (דקות)",
  "general.enter(Mins)": "הכנס (דקות)",
  "general.pcmaReports": "PCMA Reports",
  "general.filesLibrary": "ספריית קבצים",
  "general.thisSeason": "העונה הנוכחית",
  "general.last30Days": "30 הימים האחרונים",
  "general.last7Days": "7 הימים האחרונים",
  "general.keepStuff": "שמור צוות",
  "general.cleanStuff": "מחק צוות",
  "general.selectStuff": "בחר את הצוות",
  "general.selectRole": "בחר תפקיד",
  "general.privateTasks": "משימות פרטניות",
  "general.participatingPlayer": "שחקן משתתף",
  "general.notDone": "לא בוצע",
  "general.clearAll": "נקה הכל",
  "general.playersTasks": "משימות לשחקנים",
  "general.allowPlayerFiles": "אפשר לשחקנים העלאת קבצים",
  "general.taskStatus": "סטטוס משימה",
  "general.mySubmission": "ההגשה שלי",
  "general.position": "עמדה",
  "general.duplicate": "שכפול",
  "general.resize":"Resize",
  "general.text":"Text",
  "general.type":"Type",
  "general.shirts":'Shirts',
};
