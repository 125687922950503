import {sendBackShapes} from "../utils/shapes/shapeUtils";
import {notification} from "antd"
export const useRemoveFrame = ()=>{
    const handleRemoveFrame = (props)=>{
        let {
            setFrameObjects,
            shadowFrames,
            setSvgPathElementss,
            svgPathElements,
            setFrames,
            allFrames,
            activeFrameVal,
            frames,stopEvents,canvas,frame,setActiveFrame,
            setActiveFrameTime,buildCanvas,addShadowObjects,
            reInitArrowLines,updateRemovedProps,intl
        } = props;
        const { formatMessage } = intl;

        if (frames.length > 1 || allFrames.length > 1) {
            stopEvents()
            let cFrames = [...frames];
            let shadowFramesObjects = [...shadowFrames];
            cFrames = cFrames.filter(cFrame => cFrame.name !== frame.name);
            let tempFrames = [];
            let promises = [];
            for (let i = 0; i < cFrames.length; i++) {
                promises[i] = new Promise((resolve, reject) => {
                    try {
                        if (cFrames[i].name !== frame.name) {
                            tempFrames.push(cFrames[i])
                            resolve(true)
                        }
                    } catch (e) {
                        reject(false);
                    }
                })
            }
            Promise.all(promises).then((values) => {
                if (values) {

                    cFrames = tempFrames;
                    cFrames = cFrames.map((f, i) => {
                        return { ...f, data_num: i };
                    });
                    shadowFramesObjects = shadowFramesObjects.filter(f => f.frame !== frame.name);
                    setFrames(cFrames);
                    allFrames = cFrames;
                    setActiveFrame(cFrames[cFrames.length - 1]);
                    setActiveFrameTime(cFrames[cFrames.length - 1].time);
                    activeFrameVal = cFrames[cFrames.length - 1]
                    activeFrameVal.data_num = cFrames.indexOf(cFrames[cFrames.length - 1]);
                    updateRemovedProps({allFrames,activeFrameVal})
                    let frameNo = frame.data_num;
                    let firstFrameSvgEls = [];
                    let zeroFrameSvgEls = [];
                    if (frameNo === 1) {
                        firstFrameSvgEls = svgPathElements.filter(o => o.id.startsWith(`line-svg:${frame.data_num}:`));
                    }
                    if (frameNo === 0) {
                        zeroFrameSvgEls = svgPathElements.filter(o => o.id.startsWith(`line-svg:${frame.data_num + 1}:`));
                        cFrames[0].undoStates.filter(f => f.action === 'init');
                        cFrames[0].undoStates = cFrames[0].undoStates.filter(f => f.action === 'init');
                    }
                    svgPathElements = svgPathElements.filter(o => !o.id.startsWith(`line-svg:${frame.data_num}:`));
                    for (let i = frameNo; i < cFrames.length; i++) {
                        svgPathElements = svgPathElements.map(el => {
                            if (el.id.startsWith(`line-svg:${cFrames[i].data_num + 1}:`)) {
                                el.id = el.id.replace(`line-svg:${cFrames[i].data_num + 1}:`, `line-svg:${cFrames[i].data_num}:`)
                                // for D
                                if (i === 1) {
                                    const prevElInd = firstFrameSvgEls.findIndex(o => o.id.endsWith(el.id.split(":")[2]))
                                    let d = el.d.split(" ");
                                    if (prevElInd > -1) {
                                        const prevElD = firstFrameSvgEls[prevElInd].d.split(" ");
                                        d[1] = prevElD[1];
                                        d[2] = prevElD[2];
                                        d[4] = prevElD[1];
                                        d[5] = prevElD[2];
                                        el.d = d.join(' ');
                                    }
                                } else if (i === 0) {
                                    svgPathElements = svgPathElements.filter(o => o.id !== zeroFrameSvgEls[0].id)
                                } else {
                                    const prevElInd = svgPathElements.findIndex(o => o.id.startsWith(`line-svg:${cFrames[i - 1].data_num}:`) && o.id.endsWith(el.id.split(":")[2]))
                                    let d = el.d.split(" ");
                                    if (prevElInd > -1) {
                                        const prevElD = svgPathElements[prevElInd].d.split(" ");
                                        d[1] = prevElD[6];
                                        d[2] = prevElD[7];
                                        d[4] = prevElD[6];
                                        d[5] = prevElD[7];
                                        el.d = d.join(' ');
                                    }
                                }
                            }
                            return el;
                        })
                    }
                    updateRemovedProps(false,{svgPathElements})
                    setSvgPathElementss(svgPathElements)
                    if (cFrames[frameNo] && frameNo !== 0) {
                        let indexNo = cFrames[frameNo].data_num
                        shadowFramesObjects[indexNo].objects = shadowFramesObjects[indexNo].objects.map(o => {
                            let prevFrObjs = shadowFramesObjects[indexNo - 1].objects;
                            const ind = prevFrObjs.findIndex(ob => ob.ref_id === o.ref_id)
                            if (ind > -1) {
                                o.d[1] = prevFrObjs[ind].left;
                                o.d[2] = prevFrObjs[ind].top;
                                o.d[4] = prevFrObjs[ind].left;
                                o.d[5] = prevFrObjs[ind].top;
                            }
                            return o;
                        });
                    }
                    shadowFrames = shadowFramesObjects;
                    updateRemovedProps(false,false, {shadowFrames})
                    setFrameObjects(shadowFramesObjects);
                    buildCanvas(canvas, cFrames[cFrames.length - 1].json, false, cFrames, activeFrameVal, () => {
                        addShadowObjects(activeFrameVal);
                        reInitArrowLines();
                        setTimeout(() => {
                            sendBackShapes(canvas)
                        }, 100)
                    })
                }
            }).catch(error => {
                console.error(error.message)
            });
        } else {
            notification.error({
                message: formatMessage({ id: "general.cantDeleteLastFrame" }),
            });
        }
    }
    return {handleRemoveFrame}
}