import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AppDrawer from "../../../AppDrawer";
import { setBackground } from "../../actions";
import "./backgrounds.scss";
import PleaseRotate from "../../components/PleaseRotate";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import {BASE_URL_ASSETS} from "../../utils/utils";

let BASKETBALL_BACKGROUND_PITCHES = ["p2", "p1"];
let FUTSAL_PITCHES = ["p13","p11","p12"];
let BACKGROUND_PITCHES = ["p7", "p3", "p9", "p2", "p4", "p5", "p6", "p1", "p8"];
let DEFAULT_PITCHES =  ["p7", "p3", "p9", "p2", "p4", "p5", "p6", "p1","p8"];

const TacticsBoardBackgrounds = ({ onClose, isBasketballClub }) => {
  const [selectedBackground, setSelectedBackground] = useState("default_p7");
  const [fieldType, setFieldType] = useState("default");
  const [isLined, setLined] = useState(false);
  const leftDir = document.body.dir === 'ltr' || document.body.dir === '';

  const dispatch = useDispatch();

  useEffect(() => {
    if (isBasketballClub) {
      tacticsBoardBackgroundType("basketBall");
    }
  }, [isBasketballClub]);

  const direction = "ltr";

  const changeTacticsBoardBackground = () => {
    dispatch(setBackground(""));
    dispatch(setBackground(selectedBackground));
  };
  const onSelectBackgroundToGo = (background) => {
    console.log('bg',background)
    dispatch(setBackground(""));
    dispatch(setBackground(background));
  };
  const tacticsBoardBackgroundType = (type) => {
    let selectedBg = selectedBackground.slice(-2);
    if (isLined) {
      setFieldType(`line_${type}`);
      setSelectedBackground(`line_${type}_${selectedBg}`);
    } else {
      setFieldType(type);
      setSelectedBackground(`${type}_${selectedBg}`);
    }
  };
  const onChangeLine = (e) => {
    let selectedBg = selectedBackground.slice(-2);
    let tempFieldType = fieldType.split("_").filter((f) => f !== "line")[0];
    if (e.target.checked) {
      setLined(true);
      setFieldType(`line_${fieldType}`);
      setSelectedBackground(`line_${fieldType}_${selectedBg}`);
    } else {
      setLined(false);
      setFieldType(tempFieldType);
      setSelectedBackground(`${tempFieldType}_${selectedBg}`);
    }
  };

  let backgrounOptions = BACKGROUND_PITCHES;
  if (fieldType.includes("basketBall")) {
    backgrounOptions = BASKETBALL_BACKGROUND_PITCHES;
  }
  else if (fieldType.includes("futsal")) {
    backgrounOptions = FUTSAL_PITCHES;
  }
  else if (fieldType.includes("default")) {
    backgrounOptions = DEFAULT_PITCHES;
  }

  return (
    <AppDrawer
      width="100%"
      type="simple"
      onClose={onClose}
      titleId="general.selectBackground"
    >
      <PleaseRotate />
      <Row className="tacticsBoard__pitchType">
        <Col xs={14} sm={14} md={10} lg={10} xl={8} xxl={8}>
          <div className="tacticsboard_field_wrapper">
            <span className="fieldType">
              <FormattedMessage id="general.fieldType" />
            </span>
            <span onClick={() => tacticsBoardBackgroundType("default")}>
              <img
                  src={`${BASE_URL_ASSETS}/assets/images/default-bg.png`}
                  alt="default img"
                  height={24}
                  width={24}
              />
            </span>
            <span onClick={() => tacticsBoardBackgroundType("deepGreen")}>
              <img
                src={fieldType.includes("deepGreen") ? `${BASE_URL_ASSETS}/assets/images/deepGreenS.png` : `${BASE_URL_ASSETS}/assets/images/deepGreenU.png`}
                alt="Field deepGreen img"
                height={24}
                width={24}
              />
            </span>
            <span onClick={() => tacticsBoardBackgroundType("dark")}>
              <img
                src={fieldType.includes("dark") ? `${BASE_URL_ASSETS}/assets/images/fieldDark.png` : `${BASE_URL_ASSETS}/assets/images/darkUnselected.png`}
                alt="Field Dark img"
                height={24}
                width={24}
              />
            </span>
            <span onClick={() => tacticsBoardBackgroundType("light")}>
              <img
                src={fieldType.includes("light") ? `${BASE_URL_ASSETS}/assets/images/lightSelected.png` : `${BASE_URL_ASSETS}/assets/images/fieldLight.png`}
                alt="Field Light img"
                height={24}
                width={24}
              />
            </span>
            <span onClick={() => tacticsBoardBackgroundType("white")}>
              <img
                src={fieldType.includes("white") ? `${BASE_URL_ASSETS}/assets/images/whiteSelected.png` : `${BASE_URL_ASSETS}/assets/images/fieldWhite.png`}
                alt="Field White img"
                height={24}
                width={24}
              />
            </span>
            <span onClick={() => tacticsBoardBackgroundType("basketBall")}>
              <img
                src={`${BASE_URL_ASSETS}/assets/images/ball_2.png`}
                alt="Basket Ball img"
                height={24}
                width={24}
              />
            </span>
            <span onClick={() => tacticsBoardBackgroundType("futsal")}>
              <img
                src={`${BASE_URL_ASSETS}/assets/images/ball_3.webp`}
                alt="futsal img"
                height={24}
                width={24}
              />
            </span>
          </div>
        </Col>
        { !['basketBall','futsal'].includes(fieldType) && (
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <div className="tacticsboard_building_lines" >
              <label
                  style={{
                    paddingInlineEnd: !leftDir ? "24px" : "0", // Apply padding only when leftDir is true
                    paddingRight: !leftDir ? "24px" : "0",      // Apply paddingRight only when leftDir is true
                  }}
                  htmlFor="lines"
                  className="fieldType"
              >
                <FormattedMessage id="general.supportLines" />
              </label>
              <input
                type="checkbox"
                id="lines"
                name="lines"
                onChange={onChangeLine}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row
        dir={direction}
        gutter={[0, 24]}
        className={`tacticsboard__backgrounds`}
      >
        {backgrounOptions.map((p, i) => {
          const bKey = `pitch_${i}`;
          return (
            <>
              <Col key={bKey} span={6} className="pitch_container_col">
                <div className="background_pitch_container">
                  <div
                    onClick={() => setSelectedBackground(`${fieldType}_${p}`)}
                    onDoubleClick={() =>
                      onSelectBackgroundToGo(`${fieldType}_${p}`)
                    }
                    className={`tacticsboard__background tacticsboard__background_${fieldType}_${p} primary_border_mark ${selectedBackground ===
                      `${fieldType}_${p}` && "primary_border_marked"}`}
                  />
                  <div className="tacticsBoard_start_drawing">
                    <Button
                      onClick={() =>
                        onSelectBackgroundToGo(`${fieldType}_${p}`)
                      }
                      type="primary"
                      size="default"
                    >
                      <FormattedMessage id="general.selectPitch" />
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          );
        })}
      </Row>
    </AppDrawer>
  );
};

export default TacticsBoardBackgrounds;