import React from 'react';
import { Button } from 'antd';

import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import './frames.scss';
const TacticsBoardAnimationsFrames = ({ addMoreFrame, removeFrame, updateFrame, activeFrame, frames }) => {
    const { direction } = useSelector(state => state.editorSettings);
    return (
        <div dir={direction} className='tacticsboard__animations_frames'>
            {
                frames.map((frame, i) => {
                    return (
                        <div onClick={() => updateFrame(frame.name === activeFrame.name ? "" : frame)} key={i} className={`tacticsboard__animations_frame ${activeFrame?.data_num === frame?.data_num && 'primary__background'}`} >
                            {activeFrame?.data_num === frame?.data_num && <div onClick={() => removeFrame(frame)} className='tacticsboard__animations_frame_remove background__red' ><span>x</span></div>}
                            {activeFrame?.data_num === frame?.data_num ? `${i + 1}/${frames.length}` : i + 1}
                        </div>
                    )
                })
            }
            <Button dir={direction} onClick={() => addMoreFrame(true)} type='primary' size='small' shape='round' className='tacticsboard__animations_frames_add' ><FormattedMessage id="general.addFrame" /></Button>
        </div>
    )
}

export default TacticsBoardAnimationsFrames;