export class Pitch {
     constructor(canvas,team,single=false){
         this.team = team;
         this.single = single;
         this.canvas = canvas;
         if(canvas.wrapperEl.classList[1].startsWith('line'))  this.pitchName = canvas.wrapperEl.classList[1].split('_')[2];
         else this.pitchName = canvas.wrapperEl.classList[1].split('_')[1];
         this.pitchAngles = {
             'p1':this.team === 'right'?270:90,
             'p2':this.team === 'right'?270:90,
             'p3':this.team === 'right'?270:90,
             'p4':this.team === 'right'?180:0,
             'p5':this.team === 'right'?220:40,
             'p6':this.team === 'right'?90:270,
             'p7':this.team === 'right'?180:0,
             'p8':this.team === 'right'?180:0,
             'p9':this.team === 'right'?270:90,
             'p11':this.team === 'right'?180:0,
             'p12':this.team === 'right'?180:0,
             'p13':this.team === 'right'?180:0,
         }
         this.singlePitchAngles = {
             'p1':this.team === 'right'?90:270,
             'p2':this.team === 'right'?90:270,
             'p3':this.team === 'right'?90:270,
             'p4':this.team === 'right'?0:180,
             'p5':this.team === 'right'?20:220,
             'p6':this.team === 'right'?90:270,
             'p7':this.team === 'right'?0:180,
             'p8':this.team === 'right'?0:180,
             'p9':this.team === 'right'?90:270,
             'p11':this.team === 'right'?0:180,
             'p12':this.team === 'right'?0:180,
             'p13':this.team === 'right'?0:180,
         }
         this.trianglePitchAngles = {
             'p1':this.team === 'right'?180:0,
             'p2':this.team === 'right'?180:0,
             'p3':this.team === 'right'?180:0,
             'p4':this.team === 'right'?90:270,
             'p5':this.team === 'right'?130:310,
             'p6':this.team === 'right'?180:0,
             'p7':this.team === 'right'?90:270,
             'p8':this.team === 'right'?90:270,
             'p9':this.team === 'right'?180:0,
             'p11':this.team === 'right'?90:270,
             'p12':this.team === 'right'?90:270,
             'p13':this.team === 'right'?90:270,
         }
     }

     getPlayerAngle(){
         if(this.canvas.wrapperEl.classList[1].includes('basketBall')){
             this.pitchName = this.pitchName === 'p1'?'p7':this.pitchName;
         }
         if(this.single){
             return this.singlePitchAngles[this.pitchName];
         }
         else{
             return this.pitchAngles[this.pitchName];
         }
     }
     getTriangleAngle(){
         if(this.canvas.wrapperEl.classList[1].includes('basketBall')){
             this.pitchName = this.pitchName === 'p1'?'p7':this.pitchName;
         }
         return this.trianglePitchAngles[this.pitchName];
     }

}