export const handleFirstName = (playerName)=>{
    let fullName = [];
    let nameArr = playerName.split(' ');
    nameArr = nameArr.filter(f => f !== '')
    if (nameArr.length <= 1) {
        return nameArr[0];
    } else {
        for (let i = 0; i < nameArr.length - 1; i++) {
            fullName.push(nameArr[i][0]);
        }
        fullName.push(nameArr[nameArr.length - 1])
        return fullName.join('.')
    }
}