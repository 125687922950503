import {fabric} from 'fabric';
export const setLayers = (canvas,obj)=>{
    // let obj = canvas.getActiveObject();
    let totalCount = canvas._objects.length;
    let playersCount = canvas._objects.filter(obj=>(obj.name === 'player' || obj.name === 'image' || obj.name === 'player_custom_image')).length;
    let linesCount = canvas._objects.filter(line=>line.name === 'arrow' || obj.name === 'square1' || obj.name === 'square2' ||  line.name === 'pa' || line.name === 'arrow_line' || line.name === 'line' || line.name === 'Line Arrow' || line.name === 'freeDraw').length;
    let shapeCount = canvas._objects.filter(shape=>shape.name === 'shape' || shape.name === 'free-shape').length;

    if(obj.name === 'player' || obj.name === 'image' || obj.name === 'player_custom_image'){
        for(let i =0;i<totalCount;i++){
            canvas.bringForward(obj);
        }
    }
    else if(obj.name === 'arrow' || obj.name === 'square1' || obj.name === 'square2' || obj.name === 'pa' || obj.name === 'arrow_line' || obj.name === 'line' || obj.name === 'Line Arrow' || obj.name === 'freeDraw'){
        for(let i =0;i<totalCount;i++){
            canvas.bringForward(obj);
        }
        for(let j=0; j<(playersCount+linesCount);j++){
            canvas.sendBackwards(obj);
        }
    }
    else if(obj.name === 'shape' || obj.name === 'free-shape'){

        for(let i =0;i<playersCount+linesCount+shapeCount;i++){
            canvas.sendToBack(obj);
        }
    }
    canvas.renderAll()

}