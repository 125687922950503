import {message} from "antd";
import {io} from "socket.io-client";
import {generateVideoImage} from "./utils";
import {downloadFile} from "../components/helpers";

export const collectData = (props) => {
    let {
        setIsDownloading,setRecordingStatus,setCanvasBackgroundEl,filterShadowFrames,
        shadowFrames,initWidth,initHeight,svgPathElementss,canvasBackgroundClass,
        api,setLoading,stopCurvedAnimation, blobToBase64,savingDataInDB,formatMessage,
        isDoneClicked,onDone, setDownloadingStatus,canvas,frames
    } = props;
    const uuid = require("uuid");
    let id = uuid.v4();
    setIsDownloading(true)
    setRecordingStatus('playing');
    setCanvasBackgroundEl(canvas, async (src) => {

        let datals = {
            frames: filterShadowFrames(frames),
            shadowFrames: shadowFrames,
            canvasWidth: initWidth,
            canvasHeight: initHeight,
            svgPaths: svgPathElementss,
            bg: canvasBackgroundClass,
            img: src
        };

        try {
            await api.post(`/generateVideo/chrome/async${id}`, {
                data: JSON.stringify(datals),
            });
            // await api.post(`/generateVideo/async${id}`, {
            //     data: datals,
            // });
        } catch (e) {
            setLoading(false);
            console.log('error', e);
            setIsDownloading(false)
            setRecordingStatus('stop');
            stopCurvedAnimation();
            setDownloadingStatus('');
            message.error(formatMessage({ id: "general.SomethingWentWrong" }));
            return;
        }
    })

    const videoProcessTimeout = setTimeout(()=>{
        timeOutVideoProcess({setLoading,setIsDownloading,setRecordingStatus,stopCurvedAnimation,setDownloadingStatus,formatMessage})
    }, 600000);

    const socket = io(`${process.env.API_SOCKET_ADDRESS || 'https://socket-animation.easycoach.club'}`); //UNCOMMENT ON PRODUCTION
    socket.on(`receive-message${id}`, message => {
        setDownloadingStatus(message);
    });
    socket.on(`animation-complete-${id}`, message => {
        if (message) {
            clearTimeout(videoProcessTimeout);
            const videoData = message;
            if (isDoneClicked && onDone) {
                const blob = new Blob([], { type: 'video/mp4' });
                blobToBase64(blob, (base64) => {
                    generateVideoImage(canvas, function (base64Image) {
                        savingDataInDB(base64Image, videoData);
                        isDoneClicked = false;
                        setIsDownloading(true)
                    })
                });
            } else {
                downloadFile({ fileUrl: message.s3_video_full_url, fileName: 'tacticsboard.mp4' });
                setLoading(false);
                setIsDownloading(false)
                setRecordingStatus('stop');
                stopCurvedAnimation();
            }
        }
    });
}
const timeOutVideoProcess = (props) => {
    let {
        setLoading,setIsDownloading,setRecordingStatus,stopCurvedAnimation,setDownloadingStatus,formatMessage
    } = props;
    setLoading(false);
    setIsDownloading(false)
    setRecordingStatus('stop');
    stopCurvedAnimation();
    setDownloadingStatus('');
    message.error(formatMessage({ id: "general.SomethingWentWrong" }));
}