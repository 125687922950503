export const useUpdateAll = ()=>{
     const handleAllUpdate = (props)=>{
         let {allFrames,canvas,shadowFrames,setFrameObjects,setFrames,updateRemovedProps,ind, objProps, objj, animateAbleObj} = props;

         let tempFrames = [...allFrames]
         let customImageNameIndex = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === objj.ref_id);
         let customImageShirtIndex = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === objj.ref_id);
         if (!animateAbleObj) {
             for (let i = ind + 1; i < shadowFrames.length; i++) {
                 shadowFrames[i].objects = [...shadowFrames[i].objects, objProps];
             }
         }
         for (let frameInd = ind + 1; frameInd < tempFrames.length; frameInd++) {
             let parsedJson = JSON.parse(tempFrames[frameInd].json)
             let objs = parsedJson.objects;
             if (!animateAbleObj) {
                 objs.push(objj.toJSON(['customImage', 'name', 'ref_id', 'hoverCursor', 'shirno', 'nameText', 'is_animation', 'objecttype', 'shirtNo']));
                 if (customImageNameIndex > -1) objs.push(canvas._objects[customImageNameIndex].toJSON(['customImage', 'ref_id', 'name', 'shirtNo']));
                 if (customImageShirtIndex > -1) objs.push(canvas._objects[customImageShirtIndex].toJSON(['customImage', 'ref_id', 'name', 'shirtNo']));
             }
             else {
                 objs.push(objj.toJSON(['customImage', 'name', 'ref_id', 'is_animation', 'imageFileName', 'objecttype']))
             }

             parsedJson.objects = [...objs];
             tempFrames[frameInd].json = JSON.stringify(parsedJson);

         }
         updateRemovedProps(false,false,{shadowFrames})
         setFrameObjects([...shadowFrames]);
         setFrames(tempFrames)
    }
    return{handleAllUpdate}
}