import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { SvgIcon } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ReactDOM from 'react-dom';
import TacticsBoardLeftSideExtra from './leftsideextra';
import {
    CrossIcon,
    DrawerDelete,
    RedoIcon,
    Settings,
    SelectIcon,
    UndoIcon,
    PitchesIcon,
    CancelMoveIcon,
    ConnectIcon,
    DuplicateIconHeader,
    TextIconEditor,
    TextSolidIcon,
    PlusCircleIcon, MinusCircleIcon, Lock, Unlock
} from '../../assets/icons';
import './leftside.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearActiveTab,
    clearClipBoard,
    setBrush,
    setColorPopup,
    setDrawState, setIsModalOpen,
    setModalLeft, setModalTop,
    setSelectedColor, setTeam, setTextField
} from "../../actions";
import Draggable from "react-draggable";
import { fabric } from "fabric";
import TacticsBoardLeftSidePitches from "./leftSidePitches";
import Dropdown from "antd/es/dropdown";
import dragImg from '../../assets/images/drag.png';
import SpeedDropDown from "../animations/dropDownMenu";
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import {updateSize} from "../../utils/updateSize";
import {BASE_URL_ASSETS} from "../../utils/utils";
let pitch, pitchVal;
const TacticsBoardLeftSide = ({ isMobileView, updateUndoStates, frControlVisibility, updateAllProps, exportVideo, cancelMove, deleteCanvasObject, reinitFreeShape, moveEnd2, moveLine, returnAllObjects, onCloseDrawing, undoEvent, redoEvent, modifiedObject, movingObject, mouseUp, reinitpath, activeBar, activeFrame }) => {
    const canvas = useSelector(state => state.canvas)
    const drawState = useSelector(state => state.draw)
    const objectStates = useSelector(state => state.canvasObjectStates)
    const isColorPopup = useSelector(state => state.colorPopup);
    const setConnect = useSelector(state => state.connectObject);
    const {isModalOpen, modalTop, modalLeft, textField} = useSelector(state => state.player);
    const dispatch = useDispatch();
    const background = useSelector(state => state.background);
    const [isLocked, setLock] = useState(false);
    const direction = "ltr";
    const propsArray = ['newOpacity', 'padding', 'nameText', 'shirtNo', 'selectionBackgroundColor', 'perPixelTargetFind', 'hasControls', 'hasBorders', 'evented', 'selectable', 'id', 'class', 'name', 'ref_id', 'objecttype', 'x1', 'x2', 'y1', 'y2', 'pointType', 'shapeType']
    useEffect(() => {
        if(isModalOpen) showModal(false,true);
        let obj = canvas?.getActiveObject();
        if(obj){
            setLock(obj.lockMovementX);
        }
    }, [objectStates])
    useEffect(()=>{
        if(window.canvas){
            pitchVal = window.canvas.wrapperEl.classList[1];
            pitch = pitchVal.slice(-2);
        }
    },[canvas])
    const lockMovement = ()=>{
        let obj = canvas.getActiveObject();
        if(obj){
            obj.lockMovementX = !obj.lockMovementX;
            obj.lockMovementY = !obj.lockMovementY;
        }
        setLock(obj.lockMovementX);
    }
    const clonedCustomImage = (obj, newId) => {
        canvas.discardActiveObject();
        if (!obj) return;
        let imageInd = canvas._objects.findIndex(f => f.name === 'player_custom_image' && f.ref_id === obj.ref_id);
        if (imageInd === -1) return;
        let customImage = canvas._objects[imageInd];
        let refId = customImage.ref_id;
        let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === refId);
        let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === refId);
        customImage.clone(function (clonedImage) {
            clonedImage.set({
                left: clonedImage.left + 30,
                top: clonedImage.top + 10,
                evented: true,
                ref_id: newId,
            });
            clonedImage._objects[0].set({
                ref_id: newId,
            });
            clonedImage._objects[1].set({
                ref_id: newId,
            });
            if (customImage.crossOrigin) clonedImage.set({ crossOrigin: customImage.crossOrigin });
            if (customImage.is_animation) clonedImage.set({ is_animation: customImage.is_animation });
            if (customImage.nameText) {
                const nameTxt = { ...customImage.nameText }
                clonedImage.set({ nameText: nameTxt });
            }
            if (customImage.shirtNo) {
                const shirtNo = { ...customImage.shirtNo }
                clonedImage.set({ shirtNo });
            }
            if (nameInd > -1 && shirtInd > -1) {
                canvas._objects[nameInd].clone(function (clonedTextName) {
                    clonedTextName.set({
                        left: clonedTextName.left + 30,
                        top: clonedTextName.top + 10,
                        evented: false,
                        ref_id: newId,
                    });
                    canvas._objects[shirtInd].clone(function (clonedShirtNo) {
                        clonedShirtNo._objects.forEach(f => f.ref_id = newId);
                        clonedShirtNo.set({
                            left: clonedShirtNo.left + 30,
                            top: clonedShirtNo.top + 10,
                            evented: false,
                            ref_id: newId,
                        });
                        canvas.add(clonedImage, clonedTextName, clonedShirtNo);
                        canvas.discardActiveObject();
                        canvas.setActiveObject(clonedImage);
                        canvas.renderAll();
                    }, propsArray);
                }, propsArray);
            } else {
                canvas.add(clonedImage);
                canvas.discardActiveObject();
                canvas.setActiveObject(clonedImage);
                canvas.renderAll();
            }
        }, propsArray);
    }
    const reDrawFreeShape = (obj, id, activeSel = false) => {
        let cObj = reinitFreeShape(obj);
        cObj.ref_id = id;
        cObj.left = obj.left + 3;
        cObj.top = obj.top + 10;
        canvas.add(cObj);
        if (!activeSel) {
            canvas.discardActiveObject();
            canvas.setActiveObject(cObj);
        }
    }
    const duplicateObject = () => {
        const uuid = require("uuid");
        const id = uuid.v4();
        let obj = canvas.getActiveObject()
        if (obj) {
            if (obj.type === "activeSelection") {
                let objs = [];
                canvas.discardActiveObject();
                let promises = [];
                for (let i = 0; i < obj._objects.length; i++) {
                    if (!obj._objects[i].name.startsWith('custom_image_')) {
                        promises[i] = new Promise((resolve, reject) => {
                            const idd = uuid.v4();
                            let actObj = obj._objects[i];
                            if (actObj.name === 'p0' || actObj.name === 'p1' || actObj.name === 'pX' || actObj.name === 'p2') {
                                resolve();
                                return;
                            }
                            if (actObj.name === 'line') {
                                actObj.clone(function (clonedLine) {
                                    clonedLine.set({
                                        left: clonedLine.left + 30,
                                        top: clonedLine.top + 10,
                                        evented: true,
                                        ref_id: idd
                                    });

                                    actObj.p0.clone(function (clonedP0) {
                                        clonedP0.set({
                                            left: clonedP0.left + 30,
                                            top: clonedP0.top + 10,
                                            evented: true,
                                            ref_id: idd
                                        });

                                        actObj.p1.clone(function (clonedP1) {
                                            clonedP1.set({
                                                left: clonedP1.left + 30,
                                                top: clonedP1.top + 10,
                                                evented: true,
                                                ref_id: idd
                                            });

                                            actObj.p2.clone(function (clonedP2) {
                                                clonedP2.set({
                                                    left: clonedP2.left + 30,
                                                    top: clonedP2.top + 10,
                                                    evented: true,
                                                    ref_id: idd
                                                });

                                                if (actObj.pa) {
                                                    actObj.pa.clone(function (clonedPa) {
                                                        clonedPa.set({
                                                            left: clonedPa.left + 30,
                                                            top: clonedPa.top + 10,
                                                            evented: true,
                                                            ref_id: idd
                                                        })
                                                        clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = clonedPa.objecttype = "curve_line"
                                                        clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedPa.p0 = clonedP0;
                                                        clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedPa.p1 = clonedP1;
                                                        clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedPa.p2 = clonedP2;
                                                        clonedLine.pa = clonedP0.pa = clonedP1.pa = clonedP2.pa = clonedPa;
                                                        clonedP0.line = clonedP1.line = clonedP2.line = clonedPa.line = clonedLine;
                                                        //For pX (curve line active point)
                                                        let pX;
                                                        const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(actObj.ref_id) && o.name === "pX");
                                                        if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                                        pX.clone(function (clonedPX) {
                                                            clonedPX.set({
                                                                left: clonedPX.left + 30,
                                                                top: clonedPX.top + 10,
                                                                evented: true,
                                                                ref_id: `${idd}::active`
                                                            })
                                                            canvas.add(clonedLine, clonedPa, clonedP0, clonedP1, clonedP2, clonedPX);
                                                            clonedLine.moveTo(0)
                                                            objs.push(clonedLine);
                                                            resolve(clonedLine);
                                                            updateUndoStates(clonedLine)
                                                        }, propsArray)
                                                    }, propsArray);

                                                } else {
                                                    clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = "curve_line"
                                                    clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedP0;
                                                    clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedP1;
                                                    clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedP2;
                                                    clonedP0.line = clonedP1.line = clonedP2.line = clonedLine;

                                                    let pX;
                                                    const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(actObj.ref_id) && o.name === "pX");
                                                    if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                                    pX.clone(function (clonedPX) {
                                                        clonedPX.set({
                                                            left: clonedPX.left + 30,
                                                            top: clonedPX.top + 10,
                                                            evented: true,
                                                            ref_id: `${idd}::active`
                                                        })
                                                        canvas.add(clonedLine, clonedP0, clonedP1, clonedP2, clonedPX);
                                                        clonedLine.moveTo(0)
                                                        objs.push(clonedLine);
                                                        resolve(clonedLine);
                                                        updateUndoStates(clonedLine)
                                                    }, propsArray)
                                                }
                                            }, propsArray)

                                        }, propsArray);

                                    }, propsArray);

                                }, propsArray);
                            } else if (actObj.name === 'arrow_line' || actObj.name === 'square1' || actObj.name === 'square2') {
                                if (actObj.name === 'square1' || actObj.name === 'square2') {
                                    actObj = actObj.line
                                }

                                actObj.clone(function (clonedLine) {
                                    clonedLine.set({
                                        left: clonedLine.left + 30,
                                        top: clonedLine.top + 10,
                                        evented: true,
                                        ref_id: idd
                                    });

                                    actObj.square1.clone(function (clonedSq1) {
                                        clonedSq1.set({
                                            left: clonedSq1.left + 30,
                                            top: clonedSq1.top + 10,
                                            evented: true,
                                            ref_id: idd
                                        });
                                        clonedSq1.line = clonedLine;

                                        actObj.square2.clone(function (clonedSq2) {
                                            clonedSq2.set({
                                                left: clonedSq2.left + 30,
                                                top: clonedSq2.top + 10,
                                                evented: true,
                                                ref_id: idd
                                            });
                                            clonedSq2.line = clonedLine;

                                            if (actObj.arrow) {
                                                actObj.arrow.clone(function (clonedArrow) {
                                                    clonedArrow.set({
                                                        left: clonedArrow.left + 30,
                                                        top: clonedArrow.top + 10,
                                                        evented: true,
                                                        ref_id: idd
                                                    });
                                                    clonedArrow.line = clonedLine;

                                                    clonedLine.customType = clonedArrow.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                                    clonedLine.square1 = clonedArrow.square1 = clonedSq2.square1 = clonedSq1;
                                                    clonedLine.square2 = clonedArrow.square2 = clonedSq1.square2 = clonedSq2;
                                                    clonedLine.arrow = clonedSq1.arrow = clonedSq2.arrow = clonedArrow;

                                                    canvas.add(clonedLine, clonedArrow, clonedSq1, clonedSq2);
                                                    canvas.renderAll();
                                                    moveLine(clonedLine, "simple")
                                                    moveEnd2(clonedSq1);
                                                    objs.push(clonedLine, clonedSq1, clonedSq2);
                                                    resolve(clonedLine);
                                                    updateUndoStates(clonedLine)
                                                }, propsArray)
                                            } else {
                                                clonedLine.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                                clonedLine.square1 = clonedSq2.square1 = clonedSq1;
                                                clonedLine.square2 = clonedSq1.square2 = clonedSq2;

                                                canvas.add(clonedLine, clonedSq1, clonedSq2);
                                                canvas.renderAll();
                                                moveLine(clonedLine, "simple")

                                                objs.push(clonedLine, clonedSq1, clonedSq2);
                                                resolve(clonedLine);
                                                updateUndoStates(clonedLine)
                                            }
                                        }, propsArray);

                                    }, propsArray);

                                }, propsArray);
                            } else if (actObj.name === "player_custom_image") {
                                let customImage = actObj || canvas.getActiveObject();
                                let refId = customImage.ref_id;
                                let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === refId);
                                let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === refId);
                                customImage.clone(function (clonedImage) {
                                    clonedImage.set({
                                        left: clonedImage.left + 30,
                                        top: clonedImage.top + 10,
                                        evented: true,
                                        ref_id: idd,
                                    });
                                    clonedImage._objects[0].set({
                                        ref_id: idd,
                                    });
                                    clonedImage._objects[1].set({
                                        ref_id: idd,
                                    });
                                    if (customImage.crossOrigin) clonedImage.set({ crossOrigin: customImage.crossOrigin });
                                    if (customImage.is_animation) clonedImage.set({ is_animation: customImage.is_animation });
                                    if (nameInd > -1 && shirtInd > -1) {
                                        canvas._objects[nameInd].clone(function (clonedTextName) {
                                            clonedTextName.set({
                                                left: clonedTextName.left + 30,
                                                top: clonedTextName.top + 10,
                                                evented: false,
                                                ref_id: idd,
                                            });
                                            canvas._objects[shirtInd].clone(function (clonedShirtNo) {
                                                clonedShirtNo._objects.forEach(e => e.ref_id = idd)
                                                clonedShirtNo.set({
                                                    left: clonedShirtNo.left + 30,
                                                    top: clonedShirtNo.top + 10,
                                                    evented: false,
                                                    ref_id: idd,
                                                });
                                                canvas.add(clonedImage, clonedTextName, clonedShirtNo);
                                                objs.push(clonedImage, clonedTextName, clonedShirtNo)
                                                resolve(true)
                                                canvas.renderAll();
                                            }, propsArray);
                                        }, propsArray);
                                    } else {
                                        canvas.add(clonedImage);
                                        objs.push(clonedImage)
                                        resolve(true)
                                        canvas.renderAll();
                                    }
                                }, propsArray);
                            } else if (actObj.name === 'free-shape') {
                                reDrawFreeShape(actObj, idd, true);
                                objs.push(actObj);
                                resolve(actObj);
                            } else {
                                let formationObj = actObj.formation
                                actObj.clone(function (clonedObj) {
                                    clonedObj.set({
                                        left: clonedObj.left + 30,
                                        top: clonedObj.top + 10,
                                        evented: true,
                                        ref_id: idd,
                                    });
                                    if (clonedObj.name === "player") {
                                        if (formationObj) {
                                            clonedObj.set({ formation: true })
                                        }
                                        clonedObj._objects.forEach(obj => {
                                            obj.ref_id = idd
                                        })
                                        let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                                        canvas._objects[nameInd].clone(function (clonedTextName) {
                                            clonedTextName.set({
                                                left: clonedTextName.left + 30,
                                                top: clonedTextName.top + 10,
                                                name: 'custom_image_name',
                                                evented: true,
                                                hasControls: false,
                                                ref_id: clonedObj.ref_id,
                                            });
                                            canvas.add(clonedTextName);
                                            objs.push(clonedTextName);
                                        })
                                    }
                                    if (clonedObj.name === "image") {
                                        if (formationObj) {
                                            clonedObj.set({ formation: true })
                                        }
                                        if (actObj.imageFileName) {
                                            clonedObj.set({ imageFileName: actObj.imageFileName });
                                        }
                                        if (actObj.crossOrigin) {
                                            clonedObj.set({ crossOrigin: actObj.crossOrigin });
                                        }

                                    }
                                    if (actObj.is_animation) {
                                        clonedObj.set({ is_animation: actObj.is_animation });
                                    }
                                    if (actObj.objecttype === 'sprite-image') {
                                        let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                                        canvas._objects[nameInd].clone(function (clonedTextName) {
                                            clonedTextName.set({
                                                left: clonedTextName.left + 30,
                                                top: clonedTextName.top + 10,
                                                name: 'custom_image_name',
                                                evented: true,
                                                hasControls: false,
                                                ref_id: clonedObj.ref_id,
                                            });
                                            canvas.add(clonedTextName);
                                            objs.push(clonedTextName);
                                        })
                                    }
                                    canvas.add(clonedObj);
                                    objs.push(clonedObj)
                                    resolve(clonedObj)
                                    canvas.renderAll();
                                }, propsArray);
                            }
                        })
                    }
                }
                Promise.allSettled(promises).then((values) => {
                    if (objs.length) {
                        canvas.discardActiveObject();
                        var sel = new fabric.ActiveSelection(objs, {
                            canvas: canvas,
                        });
                        canvas.setActiveObject(sel);
                        canvas.renderAll();
                    }
                })
            } else {
                if (obj.name === 'drawLine') {
                    let endP = canvas._objects.find(f => f.name === 'line-end-point_shadow-object' && f.ref_id === obj.ref_id);
                    obj.clone(function (clonedLine) {
                        clonedLine.set({
                            left: clonedLine.left + 30,
                            top: clonedLine.top + 10,
                            evented: true,
                            name: 'drawLine',
                            ref_id: id,
                            hasControls: false,
                            selectionBackgroundColor: 'transparent'
                        })
                        if (endP.brushType === "arrow" || endP.brushType === "arrow_dashed") {
                            clonedLine._objects[0].set('name', 'drawElementArrow');
                        } else if (endP.brushType === "block" || endP.brushType === "block_dashed") {
                            clonedLine._objects[0].set('name', 'drawElementBlock');
                        }
                        canvas.add(clonedLine);
                        canvas.discardActiveObject();
                        canvas.sendBackwards(clonedLine);
                        let grp = clonedLine;
                        let items = clonedLine._objects;
                        grp._restoreObjectsState();
                        canvas.remove(clonedLine);
                        const newGroup = new fabric.Group(items, {
                            originX: 'center',
                            originY: 'center',
                            name: "drawLine",
                            perPixelTargetFind: true,
                            hasBorders: false,
                            hasControls: false,
                            ref_id: clonedLine.ref_id,
                            objecttype: obj.objecttype
                        });
                        canvas.add(newGroup);
                    })
                    endP.clone(function (clonedPoint) {
                        clonedPoint.set({
                            left: clonedPoint.left + 30,
                            top: clonedPoint.top + 10,
                            name: 'line-end-point_shadow-object',
                            brushType: endP.brushType,
                            widthFactor: endP.widthFactor,
                            evented: true,
                            ref_id: id
                        })
                        canvas.add(clonedPoint);
                        canvas.bringForward(clonedPoint);
                        canvas.renderAll();

                    })
                } else if (obj.name === 'free-shape') {
                    reDrawFreeShape(obj, id);
                } else if (obj.name === 'Line Arrow') {
                    obj.clone(function (clonedLine) {
                        clonedLine.set({
                            left: clonedLine.left + 30,
                            top: clonedLine.top + 10,
                            ref_id: id,
                            name: obj.name,
                            size: obj.size,
                            type: obj.type,
                            color: obj.color,
                            stroke: obj.stroke,
                            selectionBackgroundColor: 'transparent',
                        });
                        canvas.add(clonedLine);
                        canvas.discardActiveObject();
                        canvas.setActiveObject(clonedLine);
                        canvas.renderAll();
                    })
                } else if (obj.name === 'p0' || obj.name === 'p1' || obj.name === 'pX' || obj.name === 'p2' || obj.name === 'line') {
                    if (obj.name === 'p0' || obj.name === 'p1' || obj.name === 'pX' || obj.name === 'p2') {
                        obj = obj.line
                    } else if (obj.name === 'pX') {
                        let refId = obj.ref_id.split("::")[0];
                        const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(refId) && o.objecttype === "curve_line" && o.name === "line");
                        if (curveLineInd > -1) obj = canvas._objects[curveLineInd];
                    }
                    obj.clone(function (clonedLine) {
                        clonedLine.set({
                            left: clonedLine.left + 30,
                            top: clonedLine.top + 10,
                            widthFactor: obj.widthFactor,
                            evented: true,
                            ref_id: id
                        });

                        obj.p0.clone(function (clonedP0) {
                            clonedP0.set({
                                left: clonedP0.left + 30,
                                top: clonedP0.top + 10,
                                evented: true,
                                ref_id: id
                            });

                            obj.p1.clone(function (clonedP1) {
                                clonedP1.set({
                                    left: clonedP1.left + 30,
                                    top: clonedP1.top + 10,
                                    evented: true,
                                    ref_id: id
                                });

                                obj.p2.clone(function (clonedP2) {
                                    clonedP2.set({
                                        left: clonedP2.left + 30,
                                        top: clonedP2.top + 10,
                                        evented: true,
                                        ref_id: id
                                    });

                                    if (obj.pa) {
                                        obj.pa.clone(function (clonedPa) {
                                            clonedPa.set({
                                                left: clonedPa.left + 30,
                                                top: clonedPa.top + 10,
                                                evented: true,
                                                ref_id: id
                                            })
                                            clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = clonedPa.objecttype = "curve_line"
                                            clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedPa.p0 = clonedP0;
                                            clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedPa.p1 = clonedP1;
                                            clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedPa.p2 = clonedP2;
                                            clonedLine.pa = clonedP0.pa = clonedP1.pa = clonedP2.pa = clonedPa;
                                            clonedP0.line = clonedP1.line = clonedP2.line = clonedPa.line = clonedLine;

                                            //For pX (curve line active point)
                                            let pX;
                                            const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                                            if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                            pX.clone(function (clonedPX) {
                                                clonedPX.set({
                                                    left: clonedPX.left + 30,
                                                    top: clonedPX.top + 10,
                                                    evented: true,
                                                    ref_id: `${id}::active`
                                                })
                                                canvas.add(clonedLine, clonedPa, clonedP0, clonedP1, clonedP2, clonedPX);
                                                clonedLine.moveTo(0)
                                                canvas.discardActiveObject();
                                                movingObject({ target: clonedLine })
                                                mouseUp({ target: clonedLine })
                                                reinitpath({ target: clonedLine })
                                                updateUndoStates(clonedLine)
                                            }, propsArray)
                                        }, propsArray);

                                    } else {
                                        clonedLine.objecttype = clonedP0.objecttype = clonedP1.objecttype = clonedP2.objecttype = "curve_line"
                                        clonedLine.p0 = clonedP1.p0 = clonedP2.p0 = clonedP0;
                                        clonedLine.p1 = clonedP0.p1 = clonedP2.p1 = clonedP1;
                                        clonedLine.p2 = clonedP1.p2 = clonedP0.p2 = clonedP2;
                                        clonedP0.line = clonedP1.line = clonedP2.line = clonedLine;
                                        //For pX (curve line active point)
                                        let pX;
                                        const curveLineInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                                        if (curveLineInd > -1) pX = canvas._objects[curveLineInd];
                                        pX.clone(function (clonedPX) {
                                            clonedPX.set({
                                                left: clonedPX.left + 30,
                                                top: clonedPX.top + 10,
                                                evented: true,
                                                ref_id: `${id}::active`
                                            })
                                            canvas.add(clonedLine, clonedP0, clonedP1, clonedP2, clonedPX);
                                            clonedLine.moveTo(0)
                                            canvas.discardActiveObject();
                                            movingObject({ target: clonedLine })
                                            mouseUp({ target: clonedLine })
                                            reinitpath({ target: clonedLine })
                                            updateUndoStates(clonedLine)
                                        }, propsArray)
                                    }
                                }, propsArray)

                            }, propsArray);

                        }, propsArray);

                    }, propsArray);
                } else if (obj.name === 'arrow_line' || obj.name === 'square1' || obj.name === 'square2') {
                    if (obj.name === 'square1' || obj.name === 'square2') {
                        obj = obj.line
                    }

                    obj.clone(function (clonedLine) {
                        clonedLine.set({
                            left: clonedLine.left + 30,
                            top: clonedLine.top + 10,
                            widthFactor: obj.widthFactor,
                            evented: true,
                            ref_id: id
                        });

                        obj.square1.clone(function (clonedSq1) {
                            clonedSq1.set({
                                left: clonedSq1.left + 30,
                                top: clonedSq1.top + 10,
                                evented: true,
                                ref_id: id
                            });
                            clonedSq1.line = clonedLine;
                            obj.square2.clone(function (clonedSq2) {
                                clonedSq2.set({
                                    left: clonedSq2.left + 30,
                                    top: clonedSq2.top + 10,
                                    evented: true,
                                    ref_id: id
                                });
                                clonedSq2.line = clonedLine;

                                if (obj.arrow) {
                                    obj.arrow.clone(function (clonedArrow) {
                                        clonedArrow.set({
                                            left: clonedArrow.left + 30,
                                            top: clonedArrow.top + 10,
                                            evented: true,
                                            ref_id: id
                                        });
                                        clonedArrow.line = clonedLine;
                                        clonedLine.customType = clonedArrow.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                        clonedLine.square1 = clonedArrow.square1 = clonedSq2.square1 = clonedSq1;
                                        clonedLine.square2 = clonedArrow.square2 = clonedSq1.square2 = clonedSq2;
                                        clonedLine.arrow = clonedSq1.arrow = clonedSq2.arrow = clonedArrow;

                                        canvas.add(clonedLine, clonedArrow, clonedSq1, clonedSq2);
                                        canvas.renderAll();
                                        moveLine(clonedLine, "simple")
                                        moveEnd2(clonedSq1)

                                        canvas.discardActiveObject();
                                        canvas.setActiveObject(clonedLine);
                                        updateUndoStates(clonedLine)
                                    }, propsArray)
                                } else {
                                    clonedLine.customType = clonedSq1.customType = clonedSq2.customType = 'arrow';
                                    clonedLine.square1 = clonedSq2.square1 = clonedSq1;
                                    clonedLine.square2 = clonedSq1.square2 = clonedSq2;
                                    canvas.add(clonedLine, clonedSq1, clonedSq2);
                                    canvas.renderAll();
                                    moveLine(clonedLine, "simple")
                                    canvas.discardActiveObject();
                                    canvas.setActiveObject(clonedLine);
                                    updateUndoStates(clonedLine)
                                }
                            }, propsArray);

                        }, propsArray);

                    }, propsArray);
                } else if (obj.name.includes("custom_image")) {
                    clonedCustomImage(obj, id)
                } else {
                    canvas.getActiveObject().clone(function (clonedObj) {
                        let actObj = canvas.getActiveObject()
                        let formationObj = actObj.formation
                        clonedObj.set({
                            left: clonedObj.left + 30,
                            top: clonedObj.top + 10,
                            evented: true,
                            ref_id: id,
                        });
                        if (clonedObj.name === "player") {
                            if (formationObj) {
                                clonedObj.set({ formation: true })
                            }
                            clonedObj._objects.forEach(obj => {
                                obj.ref_id = id
                            })
                            let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                            canvas._objects[nameInd].clone(function (clonedTextName) {
                                clonedTextName.set({
                                    left: clonedTextName.left + 30,
                                    top: clonedTextName.top + 10,
                                    name: 'custom_image_name',
                                    evented: true,
                                    hasControls: false,
                                    ref_id: clonedObj.ref_id,
                                });
                                canvas.add(clonedTextName);
                            })
                        }
                        if (clonedObj.name === "image") {
                            if (formationObj) {
                                clonedObj.set({ formation: true })
                            }
                            if (actObj.imageFileName) {
                                clonedObj.set({ imageFileName: actObj.imageFileName });
                            }
                            if (actObj.crossOrigin) {
                                clonedObj.set({ crossOrigin: actObj.crossOrigin });
                            }
                            if (canvas.getActiveObject().imageFileName) {
                                clonedObj.set({ imageFileName: canvas.getActiveObject().imageFileName });
                            }
                            if (canvas.getActiveObject().crossOrigin) {
                                clonedObj.set({ crossOrigin: canvas.getActiveObject().crossOrigin });
                            }
                        }
                        if (canvas.getActiveObject().is_animation) {
                            clonedObj.set({ is_animation: canvas.getActiveObject().is_animation });
                        }
                        if (actObj.objecttype === 'sprite-image') {
                            let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === actObj.ref_id);
                            canvas._objects[nameInd].clone(function (clonedTextName) {
                                clonedTextName.set({
                                    left: clonedTextName.left + 30,
                                    top: clonedTextName.top + 10,
                                    name: 'custom_image_name',
                                    evented: true,
                                    hasControls: false,
                                    ref_id: clonedObj.ref_id,
                                });
                                canvas.add(clonedTextName)
                            })
                        }
                        canvas.add(clonedObj);
                        canvas.discardActiveObject();
                        canvas.setActiveObject(clonedObj);
                        canvas.renderAll();
                    }, propsArray);
                }
            }
        }
        canvas.renderAll();
    }
    const showModal = (txt=false,close=false) => {
        let obj = canvas.getActiveObject();
        if (!obj) return;
        let objLeft = obj.left*canvas.getZoom();
        let objTop = obj.top*canvas.getZoom();
        if (isMobileView) {
            if (objectStates.line) {
                dispatch(setModalLeft(objLeft + 'px'))
                dispatch(setModalTop(objTop > 170 ? objTop - 150 + 'px' : objTop + obj.height + 20 + 'px'))
            } else if (objectStates.shape) {
                dispatch(setModalLeft(((objLeft - 100) > 0 ? objLeft - 100 : 100) + 'px'))
                dispatch(setModalTop(objTop > 170 ? ((objTop - (obj.height) - 150 > 0 ? objTop - (obj.height) - 150 : 150)) + 'px' : ((objTop + obj.getScaledHeight() + 50) > 0 ? objTop + obj.getScaledHeight() + 50 : 50) + 'px'))
            } else {
                dispatch(setModalLeft(objLeft - 100 + 'px'))
                dispatch(setModalTop(objTop > 170 ? objTop - 180 + 'px' : objTop + 80 + 'px'))
            }
        } else if (window.innerWidth > 992 && window.innerWidth < 1100) {
            dispatch(setModalLeft(objLeft > 730 ? objLeft - 200 + 'px' : objLeft + 'px'))
            dispatch(setModalTop(objTop > 150 ? objTop - 100 + 'px' : objTop + obj.height + 'px'))
        } else {
            if (objectStates.line) {
                if (obj.name !== 'pX' && obj.name !== 'p0' && obj.name !== 'p2' && obj.name !== 'square1' && obj.name !== 'square2') {
                    dispatch(setModalLeft(objLeft + 250 + 'px'))
                    dispatch(setModalTop(objTop > 150 ? objTop - 130 + 'px' : objTop + obj.height + 80 + 'px'))
                }
            } else if (objectStates.shape) {
                if (obj.name === 'free-shape') objTop = obj.pathOffset.y
               dispatch(setModalLeft(((objLeft + 200 > 0 ? objLeft + 200 : 200)) + 'px'))
                dispatch(setModalTop(objTop > 150 ? ((objTop - ((obj.getScaledHeight() * 2)) - 50) > 0 ? objTop - ((obj.getScaledHeight() * 2)) - 50 : 150) + 'px' : ((objTop + obj.getScaledHeight() + 50) > 0 ? objTop + obj.getScaledHeight() + 50 : 150) + 'px'))
            } else {
                dispatch(setModalLeft(objLeft + 200 + 'px'))
                dispatch(setModalTop(objTop > 150 ? objTop - 170 + 'px' : objTop + (obj.getScaledHeight()*2) + 'px'))
            }
        }
        dispatch(setTextField(txt));
        dispatch(setIsModalOpen(!close));
    };
    const onClose = () => {
        if (onCloseDrawing) {
            onCloseDrawing();
        }
    }
    const drawCanvas = () => {
        canvas.isDrawingMode = false;
        canvas.defaultCursor = 'default';
        canvas.selection = true;
        dispatch(clearActiveTab());
        dispatch(clearClipBoard());
        canvas.renderAll()
        dispatch(setDrawState(false));
        dispatch(setBrush(null))
    }
    const connectObjects = () => {
        let ref_id;
        let obj = canvas.getActiveObjects().filter((f) => f.name === 'player' || f.name === 'player_custom_image' || f.objecttype === 'sprite-image')
        if (obj && obj.length > 1 && obj.length <= 2) {
            let lines = canvas._objects.filter((f) => f.name === 'connectionLine' && f.ref_id.includes(obj[0].ref_id) && f.ref_id.includes(obj[1].ref_id))
            if (lines.length) {
                return
            }
            else {
                ref_id = obj[0].ref_id + '_' + obj[1].ref_id;
                let from = obj[0].calcTransformMatrix();
                let to = obj[1].calcTransformMatrix();
                let line = makeLine([from[4], from[5], to[4], to[5]], ref_id);
                canvas.add(line);
                line.sendToBack()
                canvas.renderAll()
            }
        }
    }
    const makeLine = (coords, ref_id) => {
        return new fabric.Line(coords, {
            name: 'connectionLine',
            ref_id: ref_id,
            fill: '#fff',
            stroke: '#fff',
            strokeWidth: 4,
            selectable: false
        });
    }
    const setHighlighter = () => {
        dispatch(setSelectedColor(''));
        dispatch(setColorPopup({ flag: !isColorPopup.flag, type: 'highlight', freeShape: false, text: false, imgWithText: false, showHighlight: true }))
    }

    const handleUpdateSize = (state) => {
        updateSize({state, canvas, setConnect,modifiedObject});
    }


    return (
        <div dir={direction} className="tacticsboard__header_lft_container">
            <span className={'tacticsBoard_logo'}>
                <img src={`${BASE_URL_ASSETS}/assets/images/logo/logoTransparent.png`} height={53} width={180} />
            </span>
            {objectStates.objectActive &&
                <span className={"editor_setting"}>
                    <Tooltip overlayClassName="tacticsboard_tooltip" title={'Object Setting'}>
                        {
                            !isMobileView && <Button onClick={()=>showModal()} className="tacticsBoard_rgt_btn" type="default" icon={<Settings fill={'#000'} />}><span className='button_inner_text'><FormattedMessage id="general.componentSetting" /></span></Button>

                        }
                    </Tooltip>
                </span>
            }
            <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="general.undo" />}>
                <Button size='small' onClick={undoEvent} className={`tacticsboard__header_lft_btn`}
                    icon={<SvgIcon className={activeBar ? "tacticsboard__header_lft_icn" : "tacticsboard__header_lft_icn1"}><UndoIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
            </Tooltip>
            <Tooltip overlayClassName="tacticsboard_tooltip" onClick={redoEvent}
                title={<FormattedMessage id="general.redo" />}>
                <Button size='small' className={`tacticsboard__header_lft_btn`} icon={<SvgIcon
                    className={activeBar ? "tacticsboard__header_lft_icn" : "tacticsboard__header_lft_icn1"}><RedoIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
            </Tooltip>
            {setConnect &&
                <Tooltip overlayClassName="tacticsboard_tooltip" onClick={connectObjects} title={<FormattedMessage id="Connect" />}>
                    <Button size='default' className={`tacticsboard__header_lft_btn`}
                        icon={<SvgIcon><ConnectIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
                </Tooltip>
            }
            <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="Select" />}>
                <Button onClick={drawCanvas} className={`tacticsboard__header_lft_btn ${drawState ? '' : 'color__primary'}`} icon={<SvgIcon className={activeBar ? "tacticsboard__header_rgt_icn" : "tacticsboard__header_rgt_icn1"} ><SelectIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
            </Tooltip>
            {
                !(pitchVal?.includes('futsal') || pitchVal?.includes('basketBall')) &&
                <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="general.changePitches" />}>
                    <Dropdown className="PitchesDropDown" trigger={["click"]} arrow overlay={<TacticsBoardLeftSidePitches type="image" modifiedObject={modifiedObject} />} placement="bottomCenter" >
                        <Button className={'tacticsboard__header_lft_btn'} icon={<SvgIcon><PitchesIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
                    </Dropdown>
                </Tooltip>
            }
            {(objectStates.objectActive && !isMobileView) &&
                <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="general.delete" />}>
                    <Button onClick={deleteCanvasObject} size='default' className={`tacticsboard__header_lft_btn`}
                        icon={<SvgIcon><DrawerDelete fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
                </Tooltip>
            }
            {(objectStates.objectActive &&( objectStates.player || objectStates.playerImage || objectStates.sprite) && !isMobileView) &&
                <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="general.text" />}>
                    <Button onClick={()=>showModal(true)} size='default' className={`tacticsboard__header_lft_btn`}
                        icon={<SvgIcon><TextSolidIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
                </Tooltip>
            }
            {(objectStates.objectActive && !isMobileView) &&
            <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="general.duplicate" />}>
                <Button onClick={duplicateObject} size='default' className={`tacticsboard__header_lft_btn`}
                        icon={<SvgIcon><DuplicateIconHeader fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
            </Tooltip>
            }
            {(objectStates.reverse && exportVideo && activeFrame.data_num > 0) &&
                <Tooltip overlayClassName="tacticsboard_tooltip" onClick={cancelMove} title={<FormattedMessage id="Cancel Move" />} >
                    <Button className={`tacticsboard__header_lft_btn`} icon={<SvgIcon><CancelMoveIcon fill={isMobileView ? '#595959' : '#fff'} /></SvgIcon>} />
                </Tooltip>
            }
            {(objectStates.objectActive && !isMobileView) &&
            <>
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'increase'}>
                    <Button size='large' className={`tacticsboard__header_lft_btn`}  onClick={() => handleUpdateSize("increase")}   icon={<PlusCircleIcon fill={'#fff'} />} />
                </Tooltip>
                <Tooltip overlayClassName="tacticsboard_tooltip" title={'decrease'}>
                    <Button size='large' className={`tacticsboard__header_lft_btn`}  onClick={() => handleUpdateSize("decrease")}  icon={<MinusCircleIcon fill={'#fff'} />} />
                </Tooltip>
            </>
            }
            {(objectStates.reverse || objectStates.text || objectStates.shape || objectStates.object) &&
            <Tooltip overlayClassName="tacticsboard_tooltip" onClick={lockMovement} title={<FormattedMessage id={isLocked?'Unlock':'Lock'} />} >
                <Button className={`tacticsboard__header_lft_btn`} icon={
                    <SvgIcon>
                        {isLocked?<Lock fill={isMobileView ? '#595959' : '#fff'}/>:<Unlock fill={isMobileView ? '#595959' : '#fff'} />}
                    </SvgIcon>}
                />
            </Tooltip>
            }
        {(isModalOpen && !isMobileView) &&
                <Draggable bounds="body" handle=".draggable_div">
                    <div style={{ left: modalLeft, top: modalTop }} className={`tacticsboard__header_lft_extra_dt_container component_editor_modal ${objectStates.objectActive ? '' : 'd__none'}`}>
                        <div className="editor_modal_header">
                            <div className={"draggable_div"}>
                                <img src={`${BASE_URL_ASSETS}/assets/images/drag.png`} height={24} width={24} draggable="false" />
                                <span>{textField?'Text Editor':<FormattedMessage id='general.componentEditor'/>}</span>
                            </div>
                            <Button onClick={()=>showModal(false,true)} className={`tacticsBoard_mainTool_icon`}
                                icon={<SvgIcon><CrossIcon /></SvgIcon>} />
                        </div>
                        <div className="editor_modal_body">
                            <TacticsBoardLeftSideExtra exportVideo={exportVideo} updateUndoStates={updateUndoStates}
                                cancelMove={cancelMove} reinitFreeShape={reinitFreeShape}
                                deleteCanvasObject={deleteCanvasObject} moveEnd2={moveEnd2}
                                moveLine={moveLine} returnAllObjects={returnAllObjects}
                                modifiedObject={modifiedObject} movingObject={movingObject} updateAllProps={updateAllProps}
                                mouseUp={mouseUp} reinitpath={reinitpath} activeFrame={activeFrame} textField={textField} />
                        </div>
                    </div>
                </Draggable>
            }
            {(objectStates.time && !isMobileView) &&
            <Tooltip overlayClassName="tacticsboard_tooltip" title={<FormattedMessage id="general.delete"/>}>
                <SpeedDropDown frControlVisibility={frControlVisibility} isMobileView={isMobileView}/>
            </Tooltip>
            }
        </div>
    )
}

export default TacticsBoardLeftSide;