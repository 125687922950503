export const addShadowHelper = (canvas,actFrame, callback, isHide, activeFrameVal, shadowFrames, animationPlaying, deletedObjects, addDuplicateObject,reRenderCanvas)=>{
    if (!animationPlaying || shadowFrames[activeFrameVal.data_num]?.objects !== 'undefined') {
        if (actFrame.data_num) {
            const prevFrame = { ...shadowFrames[activeFrameVal.data_num - 1] };
            const currFrameObjs = [...shadowFrames[activeFrameVal.data_num]?.objects];
            if (!prevFrame.objects.length) {
                callback && callback();
            }
            let promises = [];
            for (const file of prevFrame.objects) {
                promises[prevFrame.objects.indexOf(file)] = new Promise((resolve, reject) => {
                    let aniObj = canvas._objects.find((o) => o.ref_id === file.ref_id);
                    if (file.name === 'player_custom_image' || ((file.name === 'image' || file.name === 'player') && file.nameText)) {
                        aniObj = canvas._objects.find((o) => (o.ref_id === file.ref_id) && (o.name === 'player_custom_image' || o.objecttype === 'sprite-image' || o.name === "player"));
                    }
                    const currObj = currFrameObjs?.find((o) => o.ref_id.includes(file.ref_id));
                    let d = [];

                    if (currObj && currObj.d)
                        d = currObj.d;
                    if (aniObj && !(deletedObjects.length && deletedObjects.findIndex(o => o.frame === activeFrameVal.data_num - 1 && o.ObjRefId === aniObj.ref_id) > -1)) {
                        addDuplicateObject(aniObj, file, d, resolve, isHide)
                    } else resolve(file.ref_id)
                })
            }

            Promise.all(promises).then((values) => {
                if (values.length && values[prevFrame.objects.length - 1] !== "undefined") {
                    callback && callback();
                }
            })

        }
    }
    reRenderCanvas();
}