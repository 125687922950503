import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import TacticsBoardToolsIcon from "../icon";
import ovalSolid from "../../../assets/images/oval_solid.png";
import ellipseSolid from "../../../assets/images/ellipse_solid.png";
import squareSolid from "../../../assets/images/square_solid.png";
import freeShape from "../../../assets/images/selection.png";
import {BASE_URL_ASSETS, drawsObjsWithColor} from "../../../utils/utils";

import "../tools.scss";
import { useDispatch, useSelector } from "react-redux";
import { setColorPopup, setSelectedColor } from "../../../actions";
import { getMobileView } from "../../helpers";

const TacticsBoardShapes = (props) => {
  const dispatch = useDispatch();
  const isColorPopup = useSelector((state) => state.colorPopup);
  const selectedColor = useSelector((state) => state.selectedColor);
  const updatedObjectType = useSelector((state) => state.updatedObjectType);
  const canvas = useSelector((state) => state.canvas);

  const [objectColors, setObjectColors] = useState(drawsObjsWithColor);
  const [borderStyle, setBorderStyle] = useState("dotted");

  useEffect(() => {
    selectedColor && updateObjectColors(selectedColor, isColorPopup.type);
  }, [selectedColor]);

  const shapeBorder = (val) => {
    setBorderStyle(val);
  };
  const getObjectType = () => {
    let obj = canvas.getActiveObject();
    if (!obj) return;
    let desType = obj.objecttype;
    if (obj.name === "text") desType = "text";
    else if (obj.name === "Line Arrow") desType = "Line Arrow";
    else if (obj.objecttype === "simple_line" && obj.strokeDashArray)
      desType = "simple_line_dashed";
    else if (obj.objecttype === "arrow_line" && obj.strokeDashArray && obj.pa)
      desType = "arrow_line_dashed";
    else if (obj.objecttype === "curve_line" && obj.strokeDashArray && !obj.pa)
      desType = "curve_line_dashed";
    else if (obj.objecttype === "curve_line" && obj.pa && !obj.strokeDashArray)
      desType = "curve_arrow_line";
    else if (obj.objecttype === "curve_line" && obj.strokeDashArray && obj.pa)
      desType = "curve_arrow_line_dashed";
    else if (obj.type === "polygon") desType = "free_shape_fill";
    else if (obj.objecttype === "star") {
      if (obj.shapeType === "outlined") desType = "star_outlined";
      else desType = "star_solid";
    } else if (obj.objecttype === "equipments" && obj.imageFileName) {
      const name = obj.imageFileName.split(":")[1].split("-")[0];
      desType = name;
      if (name === "Cones")
        desType = obj.imageFileName.split(":")[1].split("_")[0];
    }
    return desType;
  };

  function rgba2hex(rgba) {
    let hexRegx = /^#([A-Fa-f0-9]{3}){1,2}$/;
    if (!hexRegx.test(rgba)) {
      rgba = rgba.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
      );
      return rgba && rgba.length === 4
        ? "#" +
            ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
        : "";
    } else return rgba;
  }

  const updateObjectColors = (selColor, type) => {
    if (type === "ind_object") type = getObjectType();
    let objColors = { ...objectColors };
    objColors[type] = selColor;
    setObjectColors(objColors);
  };

  const handleClickOutside = (event) => {
    dispatch(setColorPopup(false));
  };
  const changeColor = (e, type) => {
    var top = e.clientY + "px";
    dispatch(setSelectedColor(""));
    dispatch(setColorPopup({ flag: !isColorPopup.flag, type, top }));
  };
  const ItemList = () => {
    const isMobileView = getMobileView();
    const colSpan = isMobileView ? 6 : 12;
    return (
      <>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="shape-element"
            id={`star_outlined::${objectColors.free_shape_fill}::${objectColors.free_shape_border}::${borderStyle}`}
            image={`${BASE_URL_ASSETS}/assets/images/selection.png`}
            alt="Star Outlined"
          />
        </Col>

        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="shape-element"
            id={`square_solid::${objectColors.square_solid_fill}::${objectColors.square_solid_border}::${borderStyle}`}
            image={`${BASE_URL_ASSETS}/assets/images/square_solid.png`}
            alt="Square Solid"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="shape-element"
            id={`ellipse_solid::${objectColors.ellipse_solid_fill}::${objectColors.ellipse_solid_border}::${borderStyle}`}
            image={`${BASE_URL_ASSETS}/assets/images/oval_solid.png`}
            alt="Oval Solid"
          />
        </Col>
        <Col span={colSpan}>
          <TacticsBoardToolsIcon
            imgStyle="image_dim"
            classList="shape-element"
            id={`oval_solid::${objectColors.oval_solid_fill}::${objectColors.oval_solid_border}::${borderStyle}`}
            image={`${BASE_URL_ASSETS}/assets/images/ellipse_solid.png`}
            alt="Ellipse Solid"
          />
        </Col>
      </>
    );
  };

  return (
    <div className="tacticsboard__feature_container tacticsboard__feature_container_height">
      {getMobileView() ? (
        <ItemList props />
      ) : (
        <Row gutter={[7, 7]}>
          <ItemList props />
        </Row>
      )}
    </div>
  );
};

export default TacticsBoardShapes;
