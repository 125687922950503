import React, {useEffect, useState} from 'react';
import {Button} from "antd";
import {SvgIcon} from "@mui/material";
import {EquipmentsIcon, FormationIcon, PlayersIcon, ToolsIcon} from "../assets/icons";
import {FormattedMessage} from "react-intl";
import {setActivePanel} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "../index";
const iconColor = '#000000', selectedIconColor = '#5A5FE7'
let pitchVal, pitch;
const MobileTools = ({isMobileView})=>{
    const dispatch = useDispatch();
    const activeKey = useSelector(state => state.activePanel);
    const canvas = useSelector(state => state.canvas);
    const [playersMenu, setPlayerMenu] = useState(true)
    const [showFormation, setShowFormation] = useState(true);
    const [openColorPopup, setOpenColorPopup] = useState(false)

    const [open, setopen] = useState(true);
    useEffect(()=>{
        if(window.canvas){
            pitchVal = window.canvas.wrapperEl.classList[1];
            setShowFormation(!(pitchVal?.includes('futsal') || pitchVal?.includes('basketBall')))
        }
    },[canvas])
    const togglePlayerMenu = (key) => {
        dispatch(setActivePanel( "Player"))
        setopen(true);
        setPlayerMenu(key)
    }
    const toggleOpen = (key) => {
        if (key === activeKey && open && !isMobileView) {
            setopen(false)
        } else {
            dispatch(setActivePanel(key))
            setopen(true)
            setOpenColorPopup(false)
        }
    }
    return(
    <>
    <div onClick={() => togglePlayerMenu(false)} className={`tools_element ${activeKey === '1' ? 'selectedPanel' : ''}`}>
        <Button className={`tacticsBoard_mainTool_icon`}
                icon={<SvgIcon className=''><PlayersIcon fill={activeKey === '1' ? selectedIconColor : iconColor} /></SvgIcon>} />
        <span className="tools_title"><FormattedMessage id="general.players" /></span>
    </div>
    <div onClick={() => toggleOpen('4')} className={`tools_element ${activeKey === '4' ? 'selectedPanel' : ''}`}>
        <Button className={`tacticsBoard_mainTool_icon`}
                icon={<SvgIcon className=''><ToolsIcon fill={activeKey === '4' ? selectedIconColor : iconColor} /></SvgIcon>} />
        <span className="tools_title"><FormattedMessage id="general.tools" /></span>
    </div>
    <div onClick={() => toggleOpen('5')} className={`tools_element ${activeKey === '5' ? 'selectedPanel' : ''}`}>
        <Button className={`tacticsBoard_mainTool_icon`}
                icon={<SvgIcon className=''><FormationIcon fill={activeKey === '5' ? selectedIconColor : iconColor} /></SvgIcon>} />
        <span className="tools_title"><FormattedMessage id="general.shapes" /></span>
    </div>
    <div onClick={() => toggleOpen('6')} className={`tools_element ${activeKey === '6' ? 'selectedPanel' : ''}`}>
        <Button className={`tacticsBoard_mainTool_icon`}
                icon={<SvgIcon className=''><EquipmentsIcon fill={activeKey === '6' ? selectedIconColor : iconColor} /></SvgIcon>} />
        <span className="tools_title"><FormattedMessage id="general.equipment" /></span>
    </div>
        {
            showFormation &&
            <div onClick={() => toggleOpen('7')} className={`tools_element ${activeKey === '7' ? 'selectedPanel' : ''}`}>
            <Button className={`tacticsBoard_mainTool_icon`}
                    icon={<SvgIcon className=''><FormationIcon
                        fill={activeKey === '7' ? selectedIconColor : iconColor}/></SvgIcon>}/>
            <span className="tools_title"><FormattedMessage id="general.formation"/></span>
        </div>
        }
</>
    );
}

export default MobileTools;