import {canvasSetBorders} from "../../utils/Object-Controls/canvasSetBorders";
import {returnObjectOfObject, shadow} from "../../utils/utils";
import {
    highlightBackground,
    setBackgroundOpacity,
    setConnect,
    setEditingMode, setObjSpeed, setOpacity,
    setShowDrawer, setTextBackCol,
    textBackgroundToggler
} from "../../actions";
import {setFreeDrawControls} from "../../utils/lines/FreeDraw/setFreeDrawControls";
import {setObjectControls} from "../../utils/Object-Controls/setObjectControls";
import {setSelectionBackground} from "../../utils/canvasUtils/setSelectionbg";
import {useDispatch} from "react-redux";

export const useCreateSelection = ()=>{
    const dispatch = useDispatch();
    const createSelection = (props)=>{
        let {
            e,canvas,isMobile,updateDrawControls,
            updateArrowObject,reAddActivePoint,setCenterCurvePoint,updateObjectType,
            prevArX,prevArY,prevX,prevY,animationState,animation_state,editingMode,setActiveBar,updateObjectsStates
        } = props
        let object = e.target;
        if (!object) return;
        canvasSetBorders(canvas,isMobile);
        updateObjectType(e.target);
        dispatch(setEditingMode(false))
        dispatch(setShowDrawer(false))
        dispatch(setConnect(false))
        if (object.name === 'player_custom_image' || object.objecttype === 'sprite-image' || object.name === "player") {
            let frInd = canvas._objects.findIndex(o => o.ref_id === object.ref_id && o.name.includes('custom_image_name'));
            if (frInd > -1) {
                let textObj = canvas._objects[frInd].hasBg
                if (textObj === undefined) canvas._objects[frInd].set({ hasBg: true });
                dispatch(textBackgroundToggler(canvas._objects[frInd].hasBg))
            }
        }
        if (object.name === 'player_custom_image' || object.name === "player") {
            var player = returnObjectOfObject(object, object.ref_id, "circle");
            if(player){
                if (player.showHighlight === undefined) player.set({ showHighlight: false });
                dispatch(highlightBackground(player.showHighlight))
            }

        }
        if (object.name === 'text' && object.backgroundColor) {
            dispatch(setBackgroundOpacity(true))
        } else {
            dispatch(setBackgroundOpacity(false))
        }
        if (e.target?.type === "LineAnnotateArrow") {
            e.target.addCanvasListeners()
        }
        if (object.type !== "activeSelection" && object.name.startsWith("custom_image_")) {
            dispatch(setEditingMode(true));
            editingMode = true;
            updateDrawControls(false,{editingMode})
            if (object.name === "custom_image_name") {
                object.enterEditing();
                object?.hiddenTextarea?.focus();
            } else {

                object.enterEditing();
                object?.hiddenTextarea?.focus();
            }
            return;
        }
        ;
        if (object.name === 'drawLine') {
            setFreeDrawControls(object,canvas,prevArX,prevArY,prevX,prevY,updateDrawControls);
        }
        // updateActivePanel(object)
        object.shadow = shadow;
        if (object) {
            if (object.type === "LineAnnotateArrow") object.addCanvasListeners()
            setSelectionBackground(object, canvas);
            setActiveBar(true)
            if (object.type === 'activeSelection') {
                let obj = canvas.getActiveObjects().filter((f) => f.name === 'player' || f.name === 'player_custom_image' || f.objecttype === 'sprite-image')
                if (obj && obj.length > 1 && obj.length <= 2) {
                    dispatch(setConnect(true))
                } else {
                    dispatch(setConnect(false))
                }
                if (object._objects.length === 1) {
                    canvas.discardActiveObject();
                    canvas.renderAll();
                    return;
                } else object.hasControls = true;

                object.hasBorders = true;

                let arrLines = object._objects.filter(f => f.name === 'drawLine');
                let endps = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object');
                if (arrLines.length) {
                    for (let i = 0; i < endps.length; i++) {
                        endps[i].opacity = 0;
                        endps[i].setCoords();
                    }
                }
                setObjectControls(object, 'activeSelection')
                object._objects.forEach((obj) => {
                    updateArrowObject(obj, "selected", true);
                })
            } else {
                if (object.is_animation && animationState) {
                    if (!object.time) object.set('time', 3000);
                    dispatch(setObjSpeed(object.time));
                }
                let refId = e.target.ref_id;
                if (object?.name === "pX") refId = refId.split("::")[0];

                if (object?.name === "player_custom_image" || object?.objecttype === 'sprite-image' || object?.name === 'player') {

                    for (let i = 0; i < canvas._objects.length; i++) {
                        if (canvas._objects[i].ref_id === object.ref_id && canvas._objects[i].name?.startsWith("custom_image_")) {
                            canvas._objects[i].shadow = shadow;
                        }
                    }
                }
                const middlePointInd = canvas._objects.findIndex((o) => o.ref_id?.includes(refId) && o.name === "p1");
                if (middlePointInd > -1) {
                    canvas._objects[middlePointInd].setCoords();
                    setCenterCurvePoint(canvas._objects[middlePointInd],false);
                }
                updateArrowObject(object, "selected");
                const pointInd = canvas._objects.findIndex((o) => o.name === "pX" && o.ref_id.includes(refId));
                if (pointInd > -1) {
                    canvas._objects[pointInd].opacity = 0.5;
                    canvas._objects[pointInd].evented = true;
                    const shadowObjInd = canvas._objects.findIndex((o) => o.name === "shadow-object" && o.ref_id.includes(refId));
                    const lineInd = canvas._objects.findIndex((o) => (o.name === "shadow-line" || o.name === "line") && o.ref_id.includes(refId));
                    canvas._objects[pointInd].bringForward()
                    // lineInd > -1 && canvas._objects[lineInd].sendToBack()
                    shadowObjInd > -1 && canvas._objects[shadowObjInd].sendToBack()
                    canvas.renderAll();
                }
                canvas.renderAll()
                reAddActivePoint();
            }
            if (object.name === 'text') {
                dispatch(setTextBackCol(object.backgroundColor));
            }

            updateObjectsStates(object)
            //enabled zigzag line events
            if (object.type === "LineAnnotateArrow") object.addCanvasListeners();
            if ((animationState || animation_state) && object.name === 'player' || object.name === 'image') {
                let currPx = canvas._objects.findIndex(f => f.name === 'pX' && f.ref_id === `${object.ref_id}__shadow-object::active`);
                if (currPx > -1) canvas.sendBackwards(canvas._objects[currPx]);
                canvas.bringToFront(object);
            }
            if (object.name === 'free-shape' || object.name === 'shape' || object.name === 'text') {
                if (object.hasOwnProperty('bgOpacity')) dispatch(setOpacity(object.bgOpacity));
            } else {
                if (object.hasOwnProperty('opacity')) dispatch(setOpacity(object.opacity || null));
                if (object.hasOwnProperty('newOpacity')) dispatch(setOpacity(object.newOpacity || null));
            }
            canvas.renderAll()
        }

    }
    return {createSelection}
}