import {updateShadowLine} from "../../utils/HandleShadowObjs/updateShadowLine";
import {setConnectLineDim, setImageDimensions, setPlayerDim} from "../../utils/utils";

export const useScaling = ()=>{
    const scalingHelper = (props)=>{
        let {
            e,canvas
        } = props;
        if (!e.target) return;
        updateShadowLine(e.target,canvas);
        if (e.target.name.includes('custom_image') || e.target.objecttype === 'sprite-image' || e.target.name === "player") {
            let activeObject = e.target;
            let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === activeObject.ref_id);
            let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === activeObject.ref_id);
            let imgInd = canvas._objects.findIndex(f => (f.name === 'player_custom_image' || f.objecttype === 'sprite-image' || f.name === "player") && f.ref_id === activeObject.ref_id);
            for (let i = 0; i < canvas._objects.length; i++) {
                if (canvas._objects[i].name === 'connectionLine' && canvas._objects[i].ref_id.includes(activeObject.ref_id)) {
                    setConnectLineDim(canvas, canvas._objects[i])
                }
            }
            if (activeObject.name === 'player_custom_image') {
                if (nameInd > -1 && shirtInd > -1) {
                    activeObject.setTextPosition(canvas._objects[nameInd], canvas)
                    setImageDimensions(canvas._objects[nameInd], canvas._objects[shirtInd], canvas._objects[imgInd], activeObject, canvas)
                }
            } else if (activeObject.objecttype === 'sprite-image' || activeObject.name === "player") {
                if (nameInd > -1 && imgInd > -1) {
                    canvas._objects[imgInd].setTextPosition(canvas._objects[nameInd], canvas)
                }
            } else {
                if (nameInd > -1 && shirtInd > -1 && imgInd > -1) {
                    setImageDimensions(canvas._objects[nameInd], canvas._objects[shirtInd], canvas._objects[imgInd], activeObject, canvas)
                } else if (nameInd > -1 && imgInd > -1) {
                    setPlayerDim(canvas._objects[nameInd], canvas._objects[imgInd], canvas)
                }
            }
        }
        canvas.renderAll();
    }
    return {scalingHelper}
}

