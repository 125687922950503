import {fabric} from "fabric";

export const addObjectShadows = (props, obj, resolve, isHide) => {
    let {
        left,
        top, height,
        width,
        scaleX,
        scaleY,
        scaleProps, nameText, shirtNo, d,
        canvas,svgPathElements,setSvgPathElementss,svgPathElementss,reAddImageTextObjs,propsArray,activeFrameVal,updateRemovedProps,makeShadowCurvePoint
    } = props;
    let dPath = `M ${props.x1} ${props.y1} Q ${(props.x1 + props.x2) / 2}, ${(props.y1 + props.y2) / 2}, ${props.x2} ${props.y2}`;
    if (d.length) {
        dPath = d.join(' ');
    }
    let objOpacity = 0.3;
    if (isHide) {
        objOpacity = 0;
    }
    var line = new fabric.Path(dPath, {
        perPixelTargetFind: true,
        fill: '',
        stroke: 'black',
        opacity: objOpacity,
        selectable: false,
        selection: false,
        evented: false,
        is_animation: false,
        strokeWidth: props.scaleProps.strokeWidth / 2,
        objectCaching: false,
        name: "shadow-line",
        objecttype: "shadow_curve_line",
        selectionBackgroundColor: ''
    });
    line.ref_id = props.ref_id
    line.path[0][1] = left;
    line.path[0][2] = top;
    line.hasBorders = line.hasControls = false;
    canvas.add(line);
    let svgId = `line-svg:${activeFrameVal.data_num}:${props.ref_id}`;
    if (!svgPathElements.length || !svgPathElements.find((sv) => sv.id === svgId)) {
        //Set props to shadow path holder
        const pathObj = {
            d: dPath,
            id: svgId
        }
        svgPathElements = [...svgPathElements, pathObj];
        updateRemovedProps(false,{svgPathElements})
        setSvgPathElementss([...svgPathElementss, pathObj])
    }
    var pX = makeShadowCurvePoint(d.length ? parseFloat(d[4]) : (props.x1 + props.x2) / 2, d.length ? parseFloat(d[5]) : props.y1, null, line, null, scaleProps)
    pX.name = "pX";
    pX.evented = false;
    pX.objecttype = 'shadow_curve_point_active';
    pX.selectable = true;
    pX.selectionBackgroundColor = '';
    pX.ref_id = `${props.ref_id}::active`;
    canvas.add(pX);
    pX.sendToBack()
    var p1 = makeShadowCurvePoint(d.length ? parseFloat(d[4]) : (props.x1 + props.x2) / 2, d.length ? parseFloat(d[5]) : props.y1, null, line, null, scaleProps)
    p1.name = "p1";
    p1.opacity = 0;
    p1.evented = false;
    p1.objecttype = 'shadow_curve_point';
    p1.selectable = false;
    p1.selectionBackgroundColor = '';
    p1.ref_id = props.ref_id;
    canvas.add(p1);
    canvas.sendBackwards(p1);
    line.moveTo(0)
    obj.clone(function (clonedObj) {
        clonedObj.set({
            left,
            top,
            height,
            width,
            scaleX,
            scaleY,
            ref_id: props.ref_id,
            opacity: objOpacity,
            name: "shadow-object",
            evented: false,
            selectable: false,
            selection: false,
            is_animation: false,
            hoverCursor: "arrow",
            forAnimationPath: true,
        });
        canvas.add(clonedObj);
        if (obj.name === 'player' || obj.name === 'player_custom_image') clonedObj._objects[2]?.set({ direction: false })
        if (obj.name === "player_custom_image") {
            clonedObj.set('nameText', nameText);
            clonedObj.set('shirtNo', shirtNo);
            reAddImageTextObjs(obj, true, clonedObj)
        }
        if (obj.objecttype === 'sprite-image' || obj.name === "player") {
            clonedObj.set('nameText', nameText);
            reAddImageTextObjs(obj, true, clonedObj)
        }
        canvas.sendBackwards(clonedObj);
        canvas.sendBackwards(pX);
        resolve(obj.name);
    }, propsArray);
}