import {addLine} from "./addLine";
import {addArrow} from "./addArrow";
import {addAnnotationToCanvas} from "../addAnnotationToCanvas";
import {setLayers} from "../layring";
import {sendBackShapes} from "../shapes/shapeUtils";

export  const reAddLineObjs = (obj, tempObjArr, id,setObjectPadding,moveLine,updateUndoStates,setActiveObject,moveEnd2,canvasVar,canvas,drawQuadraticArrow,drawQuadratic,scalePropsVal) => {
    if (obj.objecttype === 'simple_line' && obj.name === 'arrow_line') {
        let customProps = {
            x1: obj.x1,
            y1: obj.y1,
            x2: obj.x2,
            y2: obj.y2,
            left: obj.left,
            top: obj.top,
            strokeWidth: obj.strokeWidth,
            color: obj.stroke,
            ref_id: id,
            is_dashed: obj.strokeDashArray ? true : false,
            scaleProps: scalePropsVal,
            widthFactor: obj.widthFactor,
            newOpacity:obj.newOpacity,
            opacity:obj.opacity,
            lockMovementX:obj.lockMovementX,
            lockMovementY:obj.lockMovementY,
        }
        let ind = canvas._objects.findIndex(f=>f.ref_id === obj.ref_id);
        if(ind>-1) return
        addLine(customProps, true,setObjectPadding,moveLine,updateUndoStates,setActiveObject,canvas);
    }
    else if (obj.objecttype === 'arrow_line' && obj.name === 'arrow_line') {
        let arInd = tempObjArr.findIndex(f => f.name === 'arrow' && f.ref_id === obj.ref_id);
        let arrow = tempObjArr[arInd]
        let customProps = {
            x1: obj.x1,
            y1: obj.y1,
            x2: obj.x2,
            y2: obj.y2,
            left: obj.left,
            top: obj.top,
            strokeWidth: obj.strokeWidth,
            color: obj.stroke,
            ref_id: id,
            is_dashed: obj.strokeDashArray ? true : false,
            arrowAngle: arrow.angle,
            arrowWidth: arrow.width,
            arrowHeight: arrow.height,
            arrowColor: arrow.fill,
            widthFactor: obj.widthFactor,
            scaleProps: scalePropsVal,
            newOpacity:obj.newOpacity,
            opacity:obj.opacity,
            lockMovementX:obj.lockMovementX,
            lockMovementY:obj.lockMovementY,
        }
        let ind = canvas._objects.findIndex(f=>f.ref_id === obj.ref_id);
        if(ind>-1) return
        addArrow(customProps, true, {moveLine, moveEnd2, setObjectPadding, canvas, setActiveObject, updateUndoStates});
    }
    else if (obj.objecttype === 'curve_line' && obj.name === 'line') {
        let p0In = tempObjArr.findIndex(f => f.name === 'p0' && f.ref_id === obj.ref_id);
        let pXIn = tempObjArr.findIndex(f => f.name === 'pX' && f.ref_id === `${obj.ref_id}::active`);
        let p1In = tempObjArr.findIndex(f => f.name === 'p1' && f.ref_id === obj.ref_id);
        let p2In = tempObjArr.findIndex(f => f.name === 'p2' && f.ref_id === obj.ref_id);
        let paIn = tempObjArr.findIndex(f => f.name === 'pa' && f.ref_id === obj.ref_id);
        let customProps = {
            path: obj.path,
            ref_id: id,
            strokeWidth: obj.strokeWidth,
            stroke: obj.stroke,
            color: obj.stroke,
            left: obj.left,
            top: obj.top,
            is_dashed: obj.strokeDashArray ? true : false,
            newOpacity:obj.newOpacity,
            opacity:obj.opacity,
            pa: paIn > -1 ? {
                left: tempObjArr[paIn].left, top: tempObjArr[paIn].top, height: tempObjArr[paIn].height,
                width: tempObjArr[paIn].width, fill: tempObjArr[paIn].fill, angle: tempObjArr[paIn].angle,
                opacity:obj.opacity,
            } : {},
            p0: p0In > -1 ? { left: tempObjArr[p0In].left, top: tempObjArr[p0In].top } : {},
            p1: p1In > -1 ? { left: tempObjArr[p1In].left, top: tempObjArr[p1In].top } : {},
            p2: p2In > -1 ? { left: tempObjArr[p2In].left, top: tempObjArr[p2In].top } : {},
            pX: pXIn > -1 ? { left: tempObjArr[pXIn].left, top: tempObjArr[pXIn].top } : {},
            scaleProps: scalePropsVal,
            widthFactor: obj.widthFactor,
            lockMovementX:obj.lockMovementX,
            lockMovementY:obj.lockMovementY,
        }
        let ind = canvas._objects.findIndex(f=>f.ref_id === obj.ref_id);
        if(ind>-1) return
        if (paIn > -1) {
            drawQuadraticArrow(customProps, true)
        }
        else {
            drawQuadratic(customProps, true)
        }
    }
    else if (obj.name === 'Line Arrow') {
        let customProps = {
            x1: obj.x1,
            y1: obj.y1,
            x2: obj.x2,
            y2: obj.y2,
            left: obj.left,
            top: obj.top,
            size: obj.size,
            color: obj.custom.arrowA.color,
            ref_id: id,
            arrowSize: obj.arrowSize,
            newOpacity:obj.newOpacity,
            opacity:obj.opacity,
            lockMovementX:obj.lockMovementX,
            lockMovementY:obj.lockMovementY,

        }
        let ind = canvas._objects.findIndex(f=>f.ref_id === obj.ref_id);
        if(ind>-1) return
        const newAnnotate = addAnnotationToCanvas(canvas, [customProps.x1, customProps.y1, customProps.x2, customProps.y2], 'arrow', customProps, true,canvasVar);
        newAnnotate.custom.arrowA.color = obj.custom.arrowA.color;
        canvas.add(newAnnotate);
        canvas.sendToBack(newAnnotate);
        sendBackShapes(canvas);
    }
}
