import color from './color';
import text from './text';
import draw from './draw';
import brush from './brush';
import tools from './tools';
import player from './player';
import canvas from './canvas';
import settings from './settings';
import animation from './animation';
import activePanel from './activePanel';
import background from './background';
import colorsState from './colorsState';
import animationStates from './animationStates';
import canvasObjectStates from './canvasObjectStates';
import textBackColor from './textBackColor';
import colorPopup from './colorPopup';
import selectedColor from './selectedColor';
import updatedObjectType from './updatedObjectType';
import opacity from './opacity';
import editMode from './editMode';
import easyTacticsBoard from './easyTacticsBoard';
import {combineReducers} from 'redux';
import showDrawer from "./showDrawer";
import backgroundOpacity from "./textBackgroundOpacity";
import textBackgroundToggle from "./textBackgroundToggle";
import highlightBg from "./highlightBg";
import backgroundUpdate from "./updatedCanvasBackgroundClass";
import connectObject from "./connectObject";
import getWhitePitch from "./getWhitePitch";
import speed from "./objSpeed";
import ObjectDefaultColors from "./ObjectsDefaultColors";
import clipBoard from "./clipBoard";
import activeTab from "./activeTab";
import loader from "./loader";
const allReducer = combineReducers({
    text: text,
    loader:loader,
    activeTab:activeTab,
    editMode:editMode,
    opacity:opacity,
    draw: draw,
    tools: tools,
    brush: brush,
    color: color,
    canvas: canvas,
    player: player,
    editorSettings: settings,
    animation: animation,
    background: background,
    activePanel: activePanel,
    colorsState: colorsState,
    animationStates: animationStates,
    canvasObjectStates: canvasObjectStates,
    textBackColor: textBackColor,
    colorPopup: colorPopup,
    selectedColor: selectedColor,
    updatedObjectType: updatedObjectType,
    showDrawer:showDrawer,
    backgroundOpacity:backgroundOpacity,
    easyTacticsBoard:easyTacticsBoard,
    textBackgroundToggle:textBackgroundToggle,
    highlightBg:highlightBg,
    backgroundUpdate:backgroundUpdate,
    connectObject:connectObject,
    getWhitePitch:getWhitePitch,
    ObjectDefaultColors:ObjectDefaultColors,
    speed:speed,
    clipBoard:clipBoard,
})

export default allReducer;
