import React, { useEffect, useRef, useState } from 'react';
import TacticsBoardToolsDraw from './draw';
import { SvgIcon } from '@mui/material';
import { Button, Col } from 'antd';
import TacticsBoardToolsColors from './colors';
import TacticsBoardToolsPlayers from './players';
import CustomPlayers from './customPlayers/customPlayers';
import TacticsShapes from './shapes/shapes';
import TacticsBoardGoalkeepers from './goolkeepers/goalkeepers';
import TacticsBoardToolsEquipment from './equipment';
import {
    ArrowPlayer, CustomPlayerNumberIcon,
    EquipmentsIcon,
    FormationIcon,
    LargeSizeIcon,
    MediumSizeIcon,
    PlayerNumberIcon, PlayerShirtIcon,
    PlayersIcon, PlayerTriangle,
    SmallSizeIcon,
    ToolsIcon,
} from "../../assets/icons";
import './tools.scss';
import {setActivePanel, setColorPopup, setPlayerSizes, setTeam} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import TacticsBoardPlayersSprites from "./players-sprites";
import TacticsboardFormation from "./formation";
import CustomPlayerNumbers from "./customPlayers/customPlayerNumbers";
import TacticsBoardToolsPlayersTriangles from './triangles';
import TacticsBoardPlayerPositions from "./playerPositions";
import {FormattedMessage} from "react-intl";
import PlayerSize from "./PlayerSize";
import TacticsBoardPlayerShirts from "./player-shirts";
import PlayerType from "./PlayerType";
import {getMobileView} from "../helpers";
import PlayerSide from "./PlayerSide";
import SquadType from "./SquadType";
import TogglePlayerNumbers from "./TogglePlayerNumbers";

export const panelKeys = {
    '1': 'players',
    '2': 'goalKeepers',
    '4': 'tools',
    '5': 'shapes',
    '6': 'equipments',
    '7': 'playerFormation',
    '8': 'playerSquad',
    '9': 'playerSquadNumbers',
    '10': 'playerNo',
    '11': 'playerNo',
    '12': 'playerPositions',
    '13': 'playerShirts',
};

const iconColor = '#000000', selectedIconColor = '#5A5FE7'
let pitch, pitchVal;

const TacticsBoardTools = (props) => {
    const dispatch = useDispatch();

    const activeKey = useSelector(state => state.activePanel);
    const activeColor = useSelector(state => state.color);
    const {playerSizes} = useSelector(state => state.player);
    const canvas = useSelector(state => state.canvas);
    const leftDir = document.body.dir === 'ltr' || document.body.dir === '';

    const [playerImages, setPlayerImages] = useState([])
    const [playersMenu, setPlayerMenu] = useState(true)
    const [open, setopen] = useState(true)
    const [openColorPopup, setOpenColorPopup] = useState(false);



    const colorPopUpRef = useRef()

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    },[]);
    useEffect(()=>{
        if(window.canvas){
            pitchVal = window.canvas.wrapperEl.classList[1];
            pitch = pitchVal.slice(-2);
            dispatch(setTeam('left'))
        }
    },[canvas])
    useEffect(() => {
        setopen(false)
        if (props?.customImagesJson) {
            setPlayerImages(JSON.parse(props?.customImagesJson))
        }
    }, [])
    const handleClickOutside = (event) => {
        if (colorPopUpRef.current && !colorPopUpRef.current.contains(event.target)) {
            setOpenColorPopup(false);
        }
    };

    const isMobile = (userAgent = navigator.userAgent) => /Mobi/.test(userAgent);

    const changePanel = (key) => {
        dispatch(setColorPopup(false))
        if (!key) {
            return
        }
        dispatch(setActivePanel(key))
    }
    const toggleOpen = (key) => {
        if (key === activeKey && open && !isMobile()) {
            setopen(false)
        } else {
            dispatch(setActivePanel(key))
            setopen(true)
            setOpenColorPopup(false)
        }
    }
    const togglePlayerMenu = (key) => {
        dispatch(setActivePanel( "10"))
        setopen(true);
        setPlayerMenu(key)
    }
    const toggleColorPopup = () => {
        setOpenColorPopup(!openColorPopup)
    }
    const panelSwitch = (activeKey) => {
        switch (activeKey) {
            case '1':
                return (<TacticsBoardPlayersSprites modifiedObject={props.modifiedObject} playerSizes={playerSizes} />)
            case '2':
                return (<TacticsBoardGoalkeepers modifiedObject={props.modifiedObject} playerSizes={playerSizes} />)
            case '3':
                return (<TacticsBoardToolsPlayers playerSizes={playerSizes} />)
            case '4':
                return (<TacticsBoardToolsDraw />)
            case '5':
                return (<TacticsShapes />)
            case '6':
                return (<TacticsBoardToolsEquipment />)
            case '7':
                return (<TacticsboardFormation />)
            case '8':
                return (<CustomPlayers showSquad={props.showSquad} customImagesJson={props.customImagesJson} addCustomSquad={props.addCustomSquad} playerSizes={playerSizes} />)
            // case '9':
            //     return (<CustomPlayerNumbers showSquad={props.showSquad} customImagesJson={props.customImagesJson} addCustomSquad={props.addCustomSquad} playerSizes={playerSizes} />)
            case '10':
                return (<TacticsBoardToolsPlayers showSquad={props.showSquad} playerSizes={playerSizes} />)
            case '11':
                return (<TacticsBoardToolsPlayersTriangles  playerSizes={playerSizes} />)
            case '12':
                return (<TacticsBoardPlayerPositions showSquad={props.showSquad} playerSizes={playerSizes} />)
            case '13':
                return (<TacticsBoardPlayerShirts playerSizes={playerSizes} />)
            default:
                break;
        }
    }

    const changePlayerSize = (size) => {
        let sizeObj = { ...playerSizes };
        if (activeKey) {
            if (activeKey === '1') sizeObj = { ...sizeObj, players: size };
            if (activeKey === '2') sizeObj = { ...sizeObj, goalKeepers: size };
            if (activeKey === '8') sizeObj = { ...sizeObj, playerSquad: size };
            if (activeKey === '9') sizeObj = { ...sizeObj, playerSquadNumbers: size };
            if (['10', '11','12','13'].includes(activeKey)) sizeObj = { ...sizeObj, playerNo: size };
            dispatch(setPlayerSizes(sizeObj));
        }
    }

    return (
        <div  onClick={props.props}
            className="tacticsboard__tools_container tools_container_style tools_container_overeflow  tacticsBoard_tools_menu_container">
            <Col className={"tacticsboard__tools_wrapper"} flex="0 0 80px" style={{ background: '#ffffff' }}>
                {
                    playersMenu ?
                        <>
                            <div onClick={() => togglePlayerMenu(false)} className={`tools_element ${activeKey === '3' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><PlayersIcon fill={activeKey === '3' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title"><FormattedMessage id="general.players" /></span>
                            </div>
                            <div onClick={() => toggleOpen('4')} className={`tools_element ${activeKey === '4' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><ToolsIcon fill={activeKey === '4' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title"><FormattedMessage id="general.tools" /></span>
                            </div>
                            <div onClick={() => toggleOpen('5')} className={`tools_element ${activeKey === '5' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><FormationIcon fill={activeKey === '5' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title"><FormattedMessage id="general.shapes" /></span>
                            </div>
                            <div onClick={() => toggleOpen('6')} className={`tools_element ${activeKey === '6' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><EquipmentsIcon fill={activeKey === '6' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title"><FormattedMessage id="general.equipment" /></span>
                            </div>
                            {!(pitchVal?.includes('futsal') || pitchVal?.includes('basketBall')) && <div onClick={() => toggleOpen('7')} className={`tools_element ${activeKey === '7' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><FormationIcon fill={activeKey === '7' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title"><FormattedMessage id="general.formation" /></span>
                            </div>}
                        </>
                        :
                        <div className='tools_menu_drawer'>
                            <div onClick={() => togglePlayerMenu(true)} className="back_to_player">
                                <Button style={{transform:`scaleX(${leftDir?1:-1})`}} className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><ArrowPlayer /></SvgIcon>} />
                                <span className="tools_title selectedTitle"><FormattedMessage id="Back" /></span>
                            </div>
                            <div onClick={() => toggleOpen('10')} className={`tools_element ${activeKey === '10' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><PlayerNumberIcon fill={activeKey === '10' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title">{isMobile() ? <FormattedMessage id="general.numbers" /> : <FormattedMessage id="general.numbers"/>}</span>
                            </div>
                            <div onClick={() => toggleOpen('12')} className={`tools_element ${activeKey === '12' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><PlayerNumberIcon fill={activeKey === '12' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title">{isMobile() ? <FormattedMessage id="general.position" /> : <FormattedMessage id="general.position" />}</span>
                            </div>
                            <div onClick={() => toggleOpen('13')} className={`tools_element ${activeKey === '13' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon className=''><PlayerShirtIcon fill={activeKey === '13' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title">{isMobile() ? <FormattedMessage id="general.shirts" /> : <FormattedMessage id="general.shirts"/>}</span>
                            </div>
                            <div onClick={() => toggleOpen('11')} className={`tools_element ${activeKey === '11' ? 'selectedPanel' : ''} `}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                    icon={<SvgIcon className=''><PlayerTriangle fill={activeKey === '11' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title">{isMobile() ? <FormattedMessage id="general.triangles" /> :  <FormattedMessage id="general.triangles" />}</span>
                            </div>
                            <div onClick={() => toggleOpen('1')} className={`tools_element ${activeKey === '1' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon className=''><PlayersIcon fill={activeKey === '1' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title">{isMobile() ? <FormattedMessage id='general.character'/> : <FormattedMessage id='general.playerCharacter'/>}</span>
                            </div>
                            <div onClick={() => toggleOpen('2')} className={`tools_element ${activeKey === '2' ? 'selectedPanel' : ''}`}>
                                <Button className={`tacticsBoard_mainTool_icon`}
                                        icon={<SvgIcon className=''><PlayersIcon fill={activeKey === '2' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                <span className="tools_title"> <FormattedMessage id='general.goalKeeper'/></span>
                            </div>

                                    <div onClick={() => toggleOpen('8')} className={`tools_element ${activeKey === '8' ? 'selectedPanel' : ''}`}>
                                        <Button className={`tacticsBoard_mainTool_icon`}
                                            icon={<SvgIcon className=''><PlayersIcon fill={activeKey === '8' ? selectedIconColor : iconColor} /></SvgIcon>} />
                                        <span className="tools_title">{isMobile() ? <FormattedMessage id='general.squadImages'/> : <FormattedMessage id='general.squadImages'/>}</span>
                                    </div>
                                    {/*<div onClick={() => toggleOpen('9')} className={`tools_element ${activeKey === '9' ? 'selectedPanel' : ''}`}>*/}
                                    {/*    <Button className={`tacticsBoard_mainTool_icon`}*/}
                                    {/*        icon={<SvgIcon className=''><CustomPlayerNumberIcon fill={activeKey === '9' ? selectedIconColor : iconColor} /></SvgIcon>} />*/}
                                    {/*    <span className="tools_title">{isMobile() ? <FormattedMessage id='general.numbers'/> : <FormattedMessage id='general.squadNumbers'/>}</span>*/}
                                    {/*</div>*/}
                        </div>
                }
            </Col>
            {open &&
                <Col flex="0 0 136px" className="sub_menu_container">
                    {
                        panelSwitch(activeKey)
                    }
                    <div className="expandable_bottom_container">
                        {(activeKey === '7' && !['p4', 'p5', 'p6', 'p1'].includes(pitch) && !getMobileView()) &&
                        <PlayerSide/>
                        }
                        {activeKey === '8' && <SquadType/>}
                        {!(activeKey === '4' || activeKey === '5' || activeKey === '6') &&
                           <PlayerSize/>
                        }
                        {
                            activeKey === "7" && <TogglePlayerNumbers/>
                        }
                        {
                            (activeKey === '7') &&
                            <PlayerType/>
                        }
                        {!(activeKey === '2' || activeKey === '5') &&
                            <div ref={colorPopUpRef} className="expandable_buttons" onClick={toggleColorPopup}>
                                <div className="selected_color" style={{ backgroundColor: `${activeColor[panelKeys[activeKey]]}` }} />
                                <span> <FormattedMessage id="general.colors" /></span>
                            </div>
                        }
                        {(activeKey === '8' || activeKey === '9') &&
                            <div className="expandable_buttons add_playerButton" onClick={props.addCustomSquad}>
                                <span>+</span>
                                <span> <FormattedMessage id="general.players" /></span>
                            </div>
                        }
                    </div>
                </Col>
            }
            {
                openColorPopup &&
                <TacticsBoardToolsColors modifiedObject={props.modifiedObject} panelKey={activeKey} setOpenColorPopup={setOpenColorPopup} />
            }
        </div>
    )
}

export default TacticsBoardTools;