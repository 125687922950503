import {setColorPopup} from "../../actions";
import {updatePaths} from "../../utils/undo-redo-events/undo-redo";
import {updateAllCustomPlayerImageDimensions} from "../../utils/addCustomPlayerImage";
import {sendBackShapes} from "../../utils/shapes/shapeUtils";
import {useDispatch} from "react-redux";
import {reinitZigZag} from "./useUndo";

export const useRedo = ()=>{
    const dispatch = useDispatch();
    const redoHelper = (props)=>{
        let {
            setActiveBar,animationState,activeFrameVal,animation_state,deletedObjects,svgPathElements,shadowFrames,setFrameObjects,addShadowObjects
            ,reinitAllEvents,canvas,activeStateIndex,undoStates,stopEvents,reArrangeShadow,reinitCanvas,addedTextObjs,reinitFreeShape,
            reInitArrowLines,startEvents,showShadowObjects,updateRemovedProps,updateDeletedObjs,updateActiveIndex
        } = props;
        dispatch(setColorPopup(false))
        let undoVal, stateIndex
        setActiveBar(false);
        if (animationState) {
            undoVal = activeFrameVal.undoStates
            stateIndex = activeFrameVal.activeStateIndex
        } else {
            undoVal = undoStates
            stateIndex = activeStateIndex
        }
        if (undoVal[stateIndex]) {
            if (undoVal[stateIndex].action === 'deleted') {
                let tempOb = undoVal[stateIndex].target
                let delInd = deletedObjects?.findIndex(f => parseInt(f.deletedObj?.left) === parseInt(tempOb.left) && parseInt(f.deletedObj?.top) === parseInt(tempOb.top) && f.ObjRefId === tempOb.ref_id);
                if (delInd > -1) {
                    const { currentDeletedPath, nextDeletedPath, deletedObj } = deletedObjects[delInd];
                    svgPathElements.push(currentDeletedPath)
                    svgPathElements.push(nextDeletedPath)
                    shadowFrames[activeFrameVal.data_num].objects.push(deletedObj)
                    setFrameObjects([...shadowFrames]);
                    deletedObjects.splice(delInd, 1);
                    updateRemovedProps(false,false,{shadowFrames});
                    updateDeletedObjs({deletedObjects})
                }
            };
        }

        if (undoVal.length > 0 && undoVal[stateIndex]) {
            stopEvents()
            let pop_num = 1

            if (stateIndex < undoVal.length - 1) {
                stateIndex = stateIndex + pop_num
            }
            if (undoVal[stateIndex]) {
                let json
                if (undoVal[stateIndex].action === "array") {
                    json = undoVal[stateIndex].data[undoVal[stateIndex].data.length - 1].json
                } else {
                    json = undoVal[stateIndex].json
                }
                //Shadows
                if (animation_state && activeFrameVal.data_num > 0) {
                    let shadowObjs = []
                    if (undoVal[stateIndex].shadowObjects) shadowObjs = [...undoVal[stateIndex].shadowObjects];

                    canvas.loadFromJSON(json, function () {
                        canvas.renderAll();
                        if (undoVal[stateIndex].shadowObjects) {
                            addShadowObjects(activeFrameVal, () => {
                                updatePaths(true, shadowObjs, canvas, showShadowObjects, reArrangeShadow, reinitCanvas, addedTextObjs, reinitFreeShape, reInitArrowLines, startEvents);
                                updateAllCustomPlayerImageDimensions(canvas);
                            }, true);
                        } else {
                            reArrangeShadow();
                            updateAllCustomPlayerImageDimensions(canvas);
                            reinitAllEvents('redo');
                        }

                    });
                } else {
                    canvas.loadFromJSON(json, function () {
                            updateAllCustomPlayerImageDimensions(canvas);
                            reinitZigZag(canvas);
                            canvas.renderAll();
                            reinitAllEvents('redo')

                    })
                }
                sendBackShapes(canvas);
                if (undoVal[stateIndex].name === 'line-end-point_shadow-object' && undoVal[stateIndex].action === 'added') {
                    let ind = canvas._objects.findIndex(f => f.name === 'line-end-point_shadow-object' && f.ref_id === undoVal[stateIndex].ref_id);
                    if (ind > -1) {
                        canvas._objects[ind].brushType = undoVal[stateIndex].target.brushType;
                        canvas._objects[ind].opacity = undoVal[stateIndex].target.opacity;
                    }
                }

                let endPs = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object');
                if (endPs.length) {
                    endPs.forEach(e => {
                        e.opacity = 0;
                        e.evented = false;
                    });
                    canvas.renderAll();
                }
            }
        }

        if (animationState) {
            activeFrameVal.activeStateIndex = stateIndex
        } else {
            activeStateIndex = stateIndex
            updateActiveIndex(activeStateIndex);
        }
    }
    return {redoHelper}
}