import {fabric} from "fabric";
import {colorName} from "./utils";
import "../components/fabric/AnnotateLine";
export const addAnnotationToCanvas = (canvas, coords, type = "arrow", props1, customProps = false,canvasVar) => {
    let options = {
        custom: {
            arrowA: {
                type: type || "arrow"
            },
            arrowB: {
                type: type || "arrow"
            },
            arrowColor: {
                color: '#333333'
            },
        }
    };
    if (!canvas) canvas = canvasVar;
    let arrow1 = true;
    const uuid = require("uuid");
    const id = uuid.v4();
    if (!options.custom.arrowA.type || !type) arrow1 = false;
    let props = {
        canvas,
        objectName: 'Line Arrow',
        objecttype: 'Line Arrow',
        name: 'Line Arrow',
        objectId: options.objectId,
        ref_id: customProps ? props1.ref_id : id,
        left: props1.left,
        top: props1.top,
        is_animation: false,
        strokeWidth: 10,
        stroke: 'transparent',
        // stroke:options.custom.arrowColor.color,
        perPixelTargetFind: true,
        strokeLineCap: 'round',
        color: props1.color ? props1.color : 'White',
        size: props1.size ? props1.size : 'l0',
        opacity:customProps ? props1.opacity  : 1,
        newOpacity:customProps ? props1.newOpacity  : 1,
        arrow2: false,
        arrow1: arrow1,
        custom: {
            type: "arrow",
            locked: false,
            arrowA: {
                type: options.custom.arrowA.type, // arrow , circle , arc
                arrowSize: 8,
                arcFill: 'transparent',
                color: '#fff'
            },
            arrowB: {
                type: options.custom.arrowB.type, // arrow , circle
                arrowSize: 5,
                arcFill: 'transparent',
                color: '#fff'
            },
            transformMatrix: [
                0.9996190815146402,
                0.02759876576637272,
                -0.02759876576637272,
                0.9996190815146402,
                1084.5,
                134
            ],
        },
        arrowSize: customProps ? props1.arrowSize : (props1.scaleProps.height / 2) - 4
    };
    let newAnnotate = new fabric.LineAnnotateArrow(coords, { ...props });
    newAnnotate.set({ ...props, selectionBackgroundColor: '' });
    const col = colorName[props1.color] || 'White';
    newAnnotate.custom.arrowA.color = props1.color;
    newAnnotate.set('color', col);
    import(`../assets/sinWave-${col}/sinWave-${props1.size ? props1.size : 'l0'}-${col}.png`).then(zigzag => {
        fabric.util.loadImage(zigzag.default, function (img) {
            newAnnotate.set('stroke', new fabric.Pattern({
                source: img,
                repeat: 'repeat-x'
            }))
            canvas.renderAll();
        }, { crossOrigin: 'anonymous' })
    })
    return newAnnotate;


}