import React, { useState } from 'react';
import { SvgIcon } from '@mui/material';
import TacticsBoardAnimationsFrames from './frames';
import { PlaySolidIcon, StopSolidIcon } from '../../assets/icons';
import './animations.scss';
import { useSelector } from 'react-redux';
import SpeedDropDown from "./dropDownMenu";

const TacticsBoardAnimationsActions = ({ isDownloadingVideo, isPlaying, isMobileView, playAnimation, stopAnimation, updateFrame, addMoreFrame, removeFrame, setActiveFrame, activeFrame, setFrames, frames, frControlVisibility }) => {
    const animationState = useSelector(state => state.animation)
    const animationStates = useSelector(state => state.animationStates)
    const objectStates = useSelector(state => state.canvasObjectStates)
    const [isMobile, setIsMobile] = useState(false)


    return (
        <div dir={"ltr"} className={`${isMobile ? 'w_100' : ''} tacticsboard__animations_conatiner ${animationState ? '' : 'd__none'} ${isDownloadingVideo ? 'd_none' : ''}`}>
            <div className={`animation_wrapper_main`}>
                <div className="animation_wrapper">
                    {frControlVisibility ?
                        <TacticsBoardAnimationsFrames
                            updateFrame={updateFrame}
                            addMoreFrame={addMoreFrame}
                            removeFrame={removeFrame}
                            setActiveFrame={setActiveFrame}
                            activeFrame={activeFrame}
                            setFrames={setFrames}
                            frames={frames}
                        /> : <div></div>
                    }
                    {(objectStates.time && isMobileView) && <SpeedDropDown frControlVisibility={frControlVisibility} isMobileView={isMobileView}/>}
                </div>
            </div>
            <div className={`animation_controls`}>
                <div className="tacticsboard__animations_actions_icon" onClick={isDownloadingVideo ? () => { } : stopAnimation} ><SvgIcon className={`${animationStates?.play || animationStates?.pause ? '' : 'opacity__low'}`}><StopSolidIcon /></SvgIcon></div>
                <div className={`tacticsboard__animations_actions_icon ${isPlaying && 'disabled'}`} onClick={playAnimation} ><SvgIcon className={`${animationStates?.play ? 'primary__icon' : ''}`} ><PlaySolidIcon /></SvgIcon></div>
            </div>
        </div>
    )
}

export default TacticsBoardAnimationsActions;