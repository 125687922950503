import React from 'react';
import { Drawer, Grid } from 'antd';
import { useSelector } from 'react-redux';
import AppDrawerAvatarHeader from './AppDrawerAvatarHeader';
import AppDrawerSimpleHeader from './AppDrawerSimpleHeader';
import AppDrawerSimpleFooter from './AppDrawerSimpleFooter';
import { injectIntl } from 'react-intl';

const AppDrawer = ({
    intl,
    onClose,
    avatar,
    avatarType,
    title,
    titleId,
    onClickTitle,
    subtitle,
    subtitleBold,
    children,
    width = 480,
    drawerHeader,
    type = "simple",
    showFooter = false,
    fullOnMobileView = false,

    // Absolute
    isAbsolute = false,

    // Confirm
    confirmOnClose = false,

    // Footer
    footerLabel,
    footerLabelId,
    footerActions,
    onFooterAction,
    footerActionLabel,
    footerActionLabelId,
}) => {

    const { useBreakpoint } = Grid;
    const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

    const { formatMessage } = intl;

    const { direction } = useSelector(state => state.editorSettings);

    const onCloseDrawer = (alert) => {
        onClose();
        // onClose();
    }

    return (
        <Drawer
            visible={true}
            autoFocus={true}
            closable={false}
            keyboard={false}
            className="app-simple-drawer"
            maskClosable={!confirmOnClose}
            push={isAbsolute ? false : null}
            getContainer={isAbsolute ? false : 'body'}
            onClose={() => onCloseDrawer(confirmOnClose)}
            placement={direction === "ltr" ? "right" : "left"}
            style={isAbsolute ? { position: 'absolute' } : null}
            width={((!md && !lg && !xl && !xxl) || (!xl && !xxl && fullOnMobileView)) ? '100%' : width}
            title={
                type === "custom" ?
                    drawerHeader :
                    type === "simple" ?
                        <AppDrawerSimpleHeader
                            onClickTitle={onClickTitle}
                            confirmOnClose={confirmOnClose}
                            title={title} titleId={titleId}
                            onCloseSideMenuDrawer={onCloseDrawer}
                        />
                        :
                        <AppDrawerAvatarHeader
                            title={title}
                            avatar={avatar}
                            titleId={titleId}
                            subtitle={subtitle}
                            avatarType={avatarType}
                            onClickTitle={onClickTitle}
                            subtitleBold={subtitleBold}
                            confirmOnClose={confirmOnClose}
                            onCloseSideMenuDrawer={onCloseDrawer}
                        />
            }
            footer={
                showFooter ?
                    <AppDrawerSimpleFooter
                        footerLabel={footerLabel}
                        footerLabelId={footerLabelId}
                        footerActions={footerActions}
                        onFooterAction={onFooterAction}
                        footerActionLabel={footerActionLabel}
                        footerActionLabelId={footerActionLabelId}
                    />
                    :
                    null
            }
        >
            {children}
        </Drawer>
    )
}

export default injectIntl(AppDrawer);