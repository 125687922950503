export const handleCenterPoint = (middlePoint,canvas, render)=>{
    let middlePointActInd = -1, curveLineInd = -1, ObjInd = -1, ObjShadowInd = -1;
    curveLineInd = canvas._objects.findIndex((o) => o.ref_id === middlePoint.ref_id && (o.objecttype === "shadow_curve_line" || o.objecttype === "curve_line"));
    middlePointActInd = canvas._objects.findIndex((o) => o.ref_id.includes(middlePoint.ref_id) && o.name === "pX");
    ObjShadowInd = canvas._objects.findIndex((o) => middlePoint.ref_id.includes(o.ref_id) && (o.name === 'shadow-object' || o.name === "p0"));
    ObjInd = canvas._objects.findIndex((o) => middlePoint.ref_id.includes(o.ref_id) && ((middlePoint.ref_id.includes('shadow') && !o.ref_id.includes('shadow')) || o.name === 'p2'));
    if (ObjInd > -1 && ObjShadowInd > -1 && curveLineInd > -1 && middlePointActInd > -1) {
        let middlePointAct = canvas._objects[middlePointActInd];
        let centerX = (canvas._objects[curveLineInd].path[0][1] + canvas._objects[curveLineInd].path[1][3]) / 2;
        let centerY = (canvas._objects[curveLineInd].path[0][2] + canvas._objects[curveLineInd].path[1][4]) / 2;
        let centerCurvePointX = (centerX + middlePoint.left) / 2;
        let centerCurvePointY = (centerY + middlePoint.top) / 2;
        middlePointAct.left = centerCurvePointX;
        middlePointAct.top = centerCurvePointY;

        render && canvas.renderAll();
    }
}