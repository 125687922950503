import React, {useEffect, useState} from 'react';
import './../../attacking-players.scss';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "antd";
import {clearActiveTab, clearClipBoard, setActiveTab} from "../../actions";
import DirectionsTab from "./Directions";
import {getMobileView} from "../helpers";

const TacticsBoardPlayersSprites = (props) => {
    const activeCol = useSelector(state => state.color);
    const {playerSizes, spriteIndex} = useSelector(state => state.player);
    const activeTab = useSelector((state)=>state.activeTab).tab;
    const symbols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
    const iTags = symbols.map(() => React.createRef());
    const [activeColor, setActiveColor] = useState({name: 'LightBlue', prefix: 'lb', symbol: 'L'});
    const [sizee, setSizee] = useState({size: 'small', span: 12, fontSize: 8, sm: `${activeColor.prefix}-xs`})
    const canvas = useSelector(state => state.canvas);
    const dispatch = useDispatch();
    useEffect(() => {
        switch (activeCol.players) {
            case "#35ACDD":
                setActiveColor({name: 'LightBlue', prefix: 'lb', symbol: 'A'})
                break;
            case "#003E60":
                setActiveColor({name: 'DarkBlue', prefix: 'db', symbol: 'A'})
                break;
            case "#F7CF00":
                setActiveColor({name: 'Yellow', prefix: 'yellow', symbol: 'A'})
                break;
            case "#C40E12":
                setActiveColor({name: 'Red', prefix: 'red', symbol: 'R'})
                break;
            case "#fff":
                setActiveColor({name: 'White', prefix: 'white', symbol: 'A'})
                break;
            case "#23803C":
                setActiveColor({name: 'Green', prefix: 'green', symbol: 'G'})
                break;
            default:
                setActiveColor({name: 'LightBlue', prefix: 'lb', symbol: 'A'})
        }
    }, [activeCol.players])

    useEffect(() => {
        let newSize = playerSizesDel.find(f => f.size === playerSizes.players);
        if (newSize) setSizee({...newSize});
    }, [playerSizes.players, activeColor])

    const isMobile = (userAgent = navigator.userAgent) => /Mobi/.test(userAgent);
    const playerSizesDel = [
        {size: 'small', span: 12, fontSize: 8, sm: isMobile() ? `${activeColor.prefix}-xs` : `${activeColor.prefix}`},
        {size: 'medium', span: 12, fontSize: 8, sm: isMobile() ? `${activeColor.prefix}-xs` : `${activeColor.prefix}`},
        {size: 'large', span: 12, fontSize: 8, sm: isMobile() ? `${activeColor.prefix}-xs` : `${activeColor.prefix}`}
    ];
    const setTab = (id,e)=>{
        if(activeTab === id){
            e.stopPropagation();
            canvas.selection = true;
            canvas.defaultCursor = 'default';
            dispatch(clearActiveTab());
            dispatch(clearClipBoard());
            return;
        }
        dispatch(setActiveTab(id));
    }
    const ItemsList = ()=> {
        const isMobileView = getMobileView();
        const colSpan = isMobileView?4:sizee.span;
        return(
            symbols.map((symbol, i) => {
                    let ratioFactor = 0;
                    if (symbol === 'K')
                        ratioFactor = 0.57
                    if (symbol === 'E')
                        ratioFactor = 1.20
                    if (symbol === 'C')
                        ratioFactor = 1.20
                    return (
                        <Col span={colSpan} onClick={(e)=>{
                            setTab(`${sizee.sm}-${symbol}000${spriteIndex}`,e)
                            iTags[i].current.click();
                        }}>
                            <div key={i} className={`${getMobileView()?'tacticsboard__feature_icon-sprite':'tacticsboard__feature_icon'}  ${activeTab === `${sizee.sm}-${symbol}000${spriteIndex}`?'prim':''}`} >
                                <i ref={iTags[i]} className={`sprites-element ${sizee.sm} ${sizee.sm}-${symbol}000${spriteIndex} 
                                        ${sizee.sm} ${sizee.sm}-${symbol}000${spriteIndex}`
                                }
                                   ratiofactor={ratioFactor}
                                   id={`${activeColor.name}_${symbol}000${spriteIndex}_${playerSizes.players}`}/>
                            </div>
                        </Col>
                    )
                })
        )
    }

    return (
        <div className="tacticsboard_sprite_main">
            {!getMobileView() && <DirectionsTab/>}
            <div className="sprites-container tacticsboard__mobile_container">
                <div className="players-sprites" onClick={props.props}>
                    {getMobileView()?<Row>
                        <div className='sprites-mobile'>
                            <ItemsList/>
                        </div>
                    </Row>:
                        <Row gutter={[7, 7]}>
                        <ItemsList/>
                    </Row>}
                </div>
            </div>
        </div>
    )
}

export default TacticsBoardPlayersSprites;
