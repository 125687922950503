export const moveLine = (line) => {
    var oldCenterX = (line.x1 + line.x2) / 2,
        oldCenterY = (line.y1 + line.y2) / 2,
        deltaX = line.left - oldCenterX,
        deltaY = line.top - oldCenterY;
    if (line.arrow) {
        line.arrow.set({
            'left': line.x1 + deltaX,
            'top': line.y1 + deltaY
        }).setCoords();
    }

    line.square1?.set({
        'left': line.x2 + deltaX,
        'top': line.y2 + deltaY
    }).setCoords();

    line.square2?.set({
        'left': line.x1 + deltaX,
        'top': line.y1 + deltaY
    }).setCoords();

    line.set({
        'x1': line.x1 + deltaX,
        'y1': line.y1 + deltaY,
        'x2': line.x2 + deltaX,
        'y2': line.y2 + deltaY
    });

    line.set({
        'left': (line.x1 + line.x2) / 2,
        'top': (line.y1 + line.y2) / 2
    });
}