import {canvasSetBorders} from "../../utils/Object-Controls/canvasSetBorders";
import {
    highlightBackground, setBackgroundOpacity,
    setConnect,
    setEditingMode, setObjSpeed,
    setObjUpdatedType, setOpacity,
    setSelectedColor,
    setShowDrawer, setTextBackCol,
    textBackgroundToggler
} from "../../actions";
import {defaultTime, returnObjectOfObject, shadow} from "../../utils/utils";
import {clearSelectionTwo} from "../../utils/lines/clearSelectionTwo";
import {setObjectControls} from "../../utils/Object-Controls/setObjectControls";
import {fabric} from "fabric";
import {setSelectionBackground} from "../../utils/canvasUtils/setSelectionbg";
import {useDispatch} from "react-redux";

export const useUpdateSelection = ()=>{
    const dispatch = useDispatch();
    const updateSelection = (props)=>{
        let {
            e,canvas,updateObjectsStates,clearTextSelection,editingMode,prevX,prevY,isMobile,
            prevArX,prevArY,updateArrowObject,setCenterCurvePoint,startEvents,stopEvents,
            reAddActivePoint,updateObjectType,updateDrawControls,animationState,objSpeed
        } = props;

        const object = e.target;

        canvasSetBorders(canvas,isMobile);
        dispatch(setEditingMode(false))
        dispatch(setShowDrawer(false))
        dispatch(setConnect(false))
        if (!object) return;
        dispatch(setObjUpdatedType(''));
        dispatch(setSelectedColor(''));
        if (object.type !== "activeSelection" && object.name.startsWith("custom_image_")) {
            dispatch(setEditingMode(true));
            editingMode = true;
            updateDrawControls(false,{editingMode})
            if (object.name === "custom_image_name") {
                object.enterEditing();
                object?.hiddenTextarea?.focus();
            } else {
                object.enterEditing();
                object?.hiddenTextarea?.focus();
            }
            updateObjectsStates(object)
            return;
        };
        if (object.name === 'player_custom_image' || object.objecttype === 'sprite-image' || object.name === "player") {
            let frInd = canvas._objects.findIndex(o => o.ref_id === object.ref_id && o.name.includes('custom_image_name'));
            if (frInd > -1) {
                let textObj = canvas._objects[frInd].hasBg
                if (textObj === undefined) canvas._objects[frInd].set({ hasBg: true });
                dispatch(textBackgroundToggler(canvas._objects[frInd].hasBg))
            }
        }
        if (object.name === 'player_custom_image' || object.name === "player") {
            if (object.name === 'player_custom_image' || object.name === "player") {
                var player = returnObjectOfObject(object, object.ref_id, "circle");
                if(player){
                    if (player.showHighlight === undefined) player.set({ showHighlight: false });
                    dispatch(highlightBackground(player.showHighlight))
                }
            }
        }
        if (object.name === 'text' && object.backgroundColor) {
            dispatch(setBackgroundOpacity(true))
        } else {
            dispatch(setBackgroundOpacity(false))
        }
        let drawLineCount = canvas._objects.filter(o => o.name === 'drawLine');
        if (drawLineCount.length && (object.name === 'drawLine')) {
            let endP = canvas._objects.find(f => f.name === 'line-end-point_shadow-object');
            if (endP) {
                endP.opacity = 0;
            }
        }
        if (e.deselected[0]?.name.includes('custom_image_') && object.name !== 'custom_image_name' && e.target.name !== 'custom_image_shirtno') {
            clearTextSelection(e.deselected[0], true);
            editingMode = false;
            updateDrawControls(false,{editingMode})
        }
        if (object.name === 'drawLine') {
            let endP = canvas._objects.find(f => f.name === 'line-end-point_shadow-object' && f.ref_id === object.ref_id);
            let type = 'simple';

            if (endP) {
                endP.opacity = 0.5;
                endP.evented = true;
                type = endP.brushType
            }
            let otherEndPs = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object' && f.ref_id !== object.ref_id);
            for (let i = 0; i < otherEndPs.length; i++) {
                otherEndPs[i].opacity = 0;
                otherEndPs[i].evented = false;
            }

            if (type === "simple" || type === "arrow" || type === "block") {
                canvas.freeDrawingBrush.strokeDashArray = 0;
            } else {
                canvas.freeDrawingBrush.strokeDashArray = [10, 5]
            }
            let prevXInd = prevX.findIndex(g => g.ref_id === object.ref_id);
            let prevYInd = prevY.findIndex(g => g.ref_id === object.ref_id);
            if (prevXInd > -1) {
                prevX[prevXInd].x = object.left;
                prevY[prevYInd].y = object.top;
            }
            prevArX = object.left;
            prevArY = object.top;
            updateDrawControls({prevX,prevY,prevArX,prevArY})
        }
        clearSelectionTwo(object.ref_id, startEvents, stopEvents, canvas)
        object.shadow = shadow
        setObjectControls(object, object.objecttype)
        updateObjectsStates(object)

        if (object) {
            if (object.name !== 'drawLine') {
                let otherEndPs = canvas._objects.filter(f => f.name === 'line-end-point_shadow-object' && f.ref_id !== object.ref_id);
                otherEndPs.forEach(e => {
                    e.opacity = 0;
                    e.evented = false;
                })
            }
            if (object.name === 'pX') {
                let id = object.ref_id.split('::');
                let otherPs = canvas._objects.filter(f => (f.name === 'p0' || f.name === 'p2') && f.ref_id === id[0]);
                for (let i = 0; i < otherPs.length; i++) {
                    otherPs[i].opacity = 0.5;
                    otherPs[i].hoverCursor = 'pointer';
                }
                object.opacity = 0.5;
                canvas.renderAll();
            }
            if (object.type === 'activeSelection') {
                if (object._objects.length > 1) {
                    object.hasControls = false;
                    let objs = object._objects;
                    objs = objs.filter(f => f.name === 'player_custom_image' || f.name === 'player' || f.objecttype === 'sprite-image');
                    let imgCount = objs.filter(f => f.name === 'player_custom_image' || f.name === 'player' || f.objecttype === 'sprite-image');
                    if (imgCount.length > 0) {
                        canvas.discardActiveObject();
                        for (let i = 0; i < objs.length; i++) {
                            switch (objs[i].name) {
                                case 'player_custom_image':
                                    let nameInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === objs[i].ref_id);
                                    let shirtInd = canvas._objects.findIndex(f => f.name === 'custom_image_shirtno' && f.ref_id === objs[i].ref_id);
                                    if (nameInd > -1) {
                                        objs = [...objs, canvas._objects[nameInd]]
                                        if (shirtInd > -1) {
                                            objs = [...objs, canvas._objects[shirtInd]]
                                        }
                                    }
                                    break;
                                case 'player':
                                case 'image':
                                    let playerInd = canvas._objects.findIndex(f => f.name === 'custom_image_name' && f.ref_id === objs[i].ref_id);
                                    if (playerInd > -1) {
                                        objs = [...objs, canvas._objects[playerInd]]
                                    }
                                    break;
                            }
                        }
                        var sel = new fabric.ActiveSelection(objs, {
                            canvas: canvas,
                        });
                        canvas.setActiveObject(sel);
                        canvas.renderAll();
                    }
                    let obj = canvas.getActiveObjects().filter((f) => f.name === 'player' || f.name === 'player_custom_image' || f.objecttype === 'sprite-image')
                    if (obj && obj.length > 1 && obj.length <= 2) {
                        dispatch(setConnect(true))
                    } else {
                        dispatch(setConnect(false))
                    }
                }
                object._objects.forEach((obj) => {
                    updateArrowObject(obj, "selected")
                })
            } else {
                let refId = e.target.ref_id;
                if (object?.name === "pX") refId = refId.split("::")[0];
                if (object.is_animation && animationState) {
                    if (!object.time) object.set('time', defaultTime);
                    dispatch(setObjSpeed(object.time));
                }
                console.log('speed', objSpeed)
                updateArrowObject(object, "selected")
                if (object?.name === "player_custom_image" || object?.objecttype === 'sprite-image' || object?.name === 'player') {
                    for (let i = 0; i < canvas._objects.length; i++) {
                        if (canvas._objects[i].ref_id === object.ref_id && canvas._objects[i].name.startsWith("custom_image_")) {
                            canvas._objects[i].shadow = shadow;
                        }
                    }
                }
                const middlePointInd = canvas._objects.findIndex((o) => o.ref_id.includes(refId) && o.name === "p1");
                if (middlePointInd > -1) {
                    canvas._objects[middlePointInd].setCoords();
                    setCenterCurvePoint(canvas._objects[middlePointInd],false);
                }
                updateArrowObject(object, "selected");
                reAddActivePoint();
            }

            if (object.name === 'text') {
                dispatch(setTextBackCol(object.backgroundColor));
            }

            if (object.objecttype === 'ball' || object.objecttype === 'disabled_borders' || object.objecttype === 'ellipse_outlined' || object.objecttype === 'ellipse_solid') {
                setObjectControls(object, object.objecttype)
            }
            setSelectionBackground(object, canvas);
            //enabled zigzag line events
            if (object?.type === "LineAnnotateArrow") object.addCanvasListeners();
        }
        updateObjectType(object);
        if (object.name === 'free-shape' || object.name === 'shape' || object.name === 'text') {
            if (object.hasOwnProperty('bgOpacity')) dispatch(setOpacity(object.bgOpacity));
        } else {
            if (object.hasOwnProperty('newOpacity')) dispatch(setOpacity(object.newOpacity));
            if (object.hasOwnProperty('opacity')) dispatch(setOpacity(object.opacity));
        }
        canvas.renderAll();

    }
    return {updateSelection}
}