import React, {useEffect, useState} from "react";
import './tools.scss'

import {getMobileView} from "../helpers";
import {useDispatch, useSelector} from "react-redux";
import {setTeam} from "../../actions";
import {BASE_URL_ASSETS} from "../../utils/utils";


const FormationBar = ({classList, getImageWidthHeight, props}) => {
    return (
        <div className="tacticsboard__formation_container" onClick={props.props}>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f1"><img id="f1"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation325.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f1"><img
                    id="f1" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f2"><img id="f2"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation433.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f2"><img
                    id="f2" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f3"><img id="f3"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation442.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f3"><img
                    id="f3" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f4"><img id="f4"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation451.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f4"><img
                    id="f4" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f5"><img id="f5"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation313.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f5"><img
                    id="f5" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f6"><img id="f6"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation332.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f6"><img
                    id="f6" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f7"><img id="f7"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation233.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f7"><img
                    id="f7" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
            <div className="tacticsboard__formation_f3">
                <div className={classList + " tacticsboard__formation"} id="f7"><img id="f8"
                                                                                     className={"formation-element"}
                                                                                     src={`${BASE_URL_ASSETS}/assets/icons/Formation323.png`}
                                                                                     width={getImageWidthHeight(true)}
                                                                                     height={getImageWidthHeight()}/>
                </div>
                <div className={classList + " tacticsboard__formation tacticsboard__formation_hoverd"} id="f8"><img
                    id="f8" className={classList + " formation-element"}
                    src={`${BASE_URL_ASSETS}/assets/icons/plusHoverImage.png`} width={50} height={50}/>
                </div>
            </div>
        </div>
    )
}


const TacticsboardFormation = (props) => {
    const dispatch = useDispatch();
    const {playerSizes, team, playerType, showPlayerNumbers} = useSelector(state => state.player);
    console.log(playerType);
    const [sizee, setSizee] = useState({size: 'small', span: 12, fontSize: 50, radius: 30});
    const formationsSizesDel = [
        {size: 'small', span: 12, fontSize: 50, radius: 30},
        {size: 'medium', span: 12, fontSize: 90, radius: 62},
        {size: 'large', span: 12, fontSize: 130, radius: 92}
    ];
    let pitchVal = window.canvas.wrapperEl.classList[1];
    let pitch = pitchVal.slice(-2);
    const getImageWidthHeight = (width = false) => {
        if (getMobileView()) {
            return 50;
        } else return (width ? 110 : 114)
    }

    useEffect(() => {
        const newSize = formationsSizesDel.find(f => f.size === playerSizes.playerFormation);
        newSize && setSizee(newSize);
    }, [playerSizes.playerFormation]);

    useEffect(() => {
        if (['p4', 'p5', 'p6', 'p1'].includes(pitch)) {
            dispatch(setTeam('left'))
        }
    }, [])


    return (
        <>
            {
                getMobileView() ?
                    <div className={'mob-formation-container'}>
                        <FormationBar classList={`formation-element ${team} ${sizee.size} ${playerType} ${showPlayerNumbers.playerFormation}`}
                                      getImageWidthHeight={getImageWidthHeight} props={props}/>
                    </div> :
                    <FormationBar classList={`formation-element ${team} ${sizee.size} ${playerType} ${showPlayerNumbers.playerFormation}`}
                                  getImageWidthHeight={getImageWidthHeight} props={props}/>
            }


        </>
    )
}
export default React.memo(TacticsboardFormation)