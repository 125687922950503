import {resetCanvas, setAnimation, setBackgroundUpdate, setCanvas, setDefaultColor, whitePitch} from "../actions";
import {fabric} from "fabric";
import {getObjectDimensions} from "../utils/canvasUtils/canvasDimensions";
import {getCanvas} from "../utils/fabric-overrides";
import {sendBackShapes} from "../utils/shapes/shapeUtils";
import {useDispatch} from "react-redux";
import {updateCanvasResize} from "../utils/canvasUtils/updateCanvasResize";

export const useInitProcess =  ()=>{
    const dispatch = useDispatch();

    const handleProcess = async (customProps) =>{
        let {
            reCreateProps,
            background,
            showBPanel,
            bgUpdate,
            handleDragStart,
            canvasContainerHeight,
            onKeyDown,
            isJson,
            canvasData,
            canvasDimensions,
            updateFrame,
            undoStates,
            isMobile,
            canvasBackgroundClass,
            pitchContainerBox,
            containerDimensions,
            canvas,
            canvasVar,
            scalePropsVal,
            initWidth,
            initHeight,
            canvas2,
            setFreeBrush,
            canvasUpdateControls,
            canvasSetBorders,
            addMoreFrame,
            startEvents,
            canvasJson,
            canvasContainerDimensionsSave,
            animationsData,
            updateObjectsScale,
            updateWindowDimensions,
            setFrames,
            allFrames,
            animation_state,
            setFrameObjects,
            shadowFrames,
            setSvgPathElementss,
            svgPathElements,
            setActiveFrame,
            activeFrameVal,
            prevX,
            prevY,
            addShadowObjects,
            reInitArrowLines,
            reinitFreeShape,
            sendBackShapes,
            reinitCanvas,
            clearEndPoints,
            convertToJson,
            isAnimation,
            setCanvasDimensionsProps,
            setIsMobileView,pitchRef,canvasRef
        } = customProps;



        if (background?.includes('white')) {
            dispatch(setDefaultColor("#000000"))
            dispatch(whitePitch(true))
        } else {
            dispatch(setDefaultColor("#fff"))
            dispatch(whitePitch(false))
        }
        if (bgUpdate) {
            canvasRef.current.parentElement.classList = "canvas-container";
            canvasRef.current.parentElement.classList.add(canvasBackgroundClass);
            dispatch(setBackgroundUpdate(false))
            return;
        }
        if (canvasBackgroundClass) {
            dispatch(resetCanvas());
            fabric.Object.prototype.toObject = (function (toObject) {
                return function (propertiesToInclude) {
                    propertiesToInclude = (propertiesToInclude || []).concat(
                        ['team','id','isSvg','pCanvas',"fillStyle", "hasBg",'polygon','arrowFill', 'isPlayer', 'startAngle','startAngleD', 'position', 'direction', 'showHighlight', 'highlightColor', 'initialSize', 'bgOpacity', 'speed', 'time']// custom attributes
                    );
                    return toObject.apply(this, [propertiesToInclude]);
                };
            })(fabric.Object.prototype.toObject);
            var images = document.querySelectorAll('.custom-drawer-images');
            [].forEach.call(images, function (img) {
                img.addEventListener('dragstart', handleDragStart, false);
            });
            pitchContainerBox = pitchRef.current;
            if (!pitchContainerBox) return;
            containerDimensions = {
                width: canvasContainerHeight(pitchContainerBox).width,
                height: canvasContainerHeight(pitchContainerBox).height,
            }
            scalePropsVal = getObjectDimensions(containerDimensions);
            reCreateProps(false,{pitchContainerBox,containerDimensions,scalePropsVal})
            window.addEventListener("resize", ()=>{
                showBPanel(false)
                updateWindowDimensions();
            });
            window.addEventListener('keydown', onKeyDown);
            canvas = getCanvas(containerDimensions);
            canvasVar = canvas;
            initWidth = canvas.width;
            initHeight = canvas.height;
            // add canvas to windows
            window.canvas = canvas;
            canvas.selectionKey = ['ctrlKey', 'metaKey'];
            canvas2 = new fabric.Canvas("tacticsboard_canvas_test", {
                targetFindTolerance: 10,
                selection: true,
                preserveObjectStacking: true,
                height: 0,
                width: 0,
            });
            reCreateProps({canvas, canvasVar, initWidth, initHeight, canvas2});
            canvasRef.current.parentElement.classList.add(canvasBackgroundClass)
            setFreeBrush()
            canvasUpdateControls()
            canvasSetBorders(canvas)
            addMoreFrame()
            // canvas events
            startEvents()
            // check here whether the canvas is a new canvas or being reinitialized
            // If canvasJson from DB
            if (canvasJson) {
                if (isJson(canvasJson)) {
                    canvasData = canvasJson;
                } else {
                    const response = await fetch(canvasJson);
                    const json = await response.json();
                    console.log("json", json);
                    canvasData = JSON.stringify(json);
                }
            }
            if (canvasJson) {
                // If previously saved canvasDimensions
                let canvasAnimationData;
                if (canvasContainerDimensionsSave) {
                    if (isJson(canvasContainerDimensionsSave)) {
                        canvasDimensions = JSON.parse(canvasContainerDimensionsSave);
                    } else {
                        const response = await fetch(canvasContainerDimensionsSave);
                        const json = await response.json();
                        console.log("json", json);
                        canvasDimensions = json;
                    }
                    reCreateProps(false,false,{canvasDimensions})
                }
                if (JSON.parse(canvasJson).isModified) {
                    canvasAnimationData = JSON.parse(canvasJson).animations;
                } else {
                    if (animationsData) {
                        if (isJson(animationsData)) {
                            canvasAnimationData = JSON.parse(animationsData);
                        } else {
                            const response = await fetch(animationsData);
                            const json = await response.json();
                            console.log("json", json);
                            canvasAnimationData = json;
                        }
                    }
                }


                updateObjectsScale(canvas);
                canvasSetBorders(canvas,isMobile)
                canvas._objects.forEach(obj => {
                    updateCanvasResize(obj)
                })
                updateWindowDimensions();
                canvas.renderAll()
                if (canvasAnimationData) {
                    const { frames1, shadowFrames1, svgPathElements1, animationState1, initWidth1, initHeight1, activeColor1 } = canvasAnimationData;
                    let newActiveFrame = frames1[frames1.length - 1]
                    setFrames(frames1);
                    allFrames = frames1;
                    dispatch(setAnimation(animationState1));
                    animation_state = animationState1;
                    setFrameObjects(shadowFrames1);
                    shadowFrames = shadowFrames1;
                    setSvgPathElementss(svgPathElements1);
                    svgPathElements = svgPathElements1;
                    setActiveFrame(newActiveFrame);
                    activeFrameVal = newActiveFrame;
                    prevX = [];
                    prevY = [];
                    initWidth = initWidth1;
                    initHeight = initHeight1
                    reCreateProps(false,false,false,{allFrames,animation_state,shadowFrames,svgPathElements,activeFrameVal,prevX,prevY,initWidth,initHeight})
                    if (animationState1) {
                        setTimeout(() => {
                            updateFrame(newActiveFrame, () => {
                                addShadowObjects(newActiveFrame);
                                reInitArrowLines();
                            },true,true);
                        }, 300)
                        reinitFreeShape()
                        sendBackShapes(canvas)
                    } else {
                        canvas.loadFromJSON(canvasData, function () {
                            canvas.renderAll();
                            updateObjectsScale(canvas)
                            reinitCanvas()
                            // startEvents()
                            undoStates = []
                            setTimeout(() => {
                                canvas.renderAll();
                            }, 200)
                            reInitArrowLines();
                            reinitFreeShape()
                            sendBackShapes(canvas)
                        })
                        clearEndPoints();
                        canvas.renderAll();
                    }
                } else {
                    canvas.loadFromJSON(canvasData, function () {
                        canvas.renderAll();
                        updateObjectsScale(canvas)
                        reinitCanvas()
                        undoStates = []
                        setTimeout(() => {
                            canvas.renderAll();
                        }, 200)
                        reInitArrowLines();
                        reinitFreeShape()
                        sendBackShapes(canvas)
                    })
                    clearEndPoints();
                    canvas.renderAll();
                }
            } else {
                var obj = {
                    target: "init",
                    name: "init",
                    ref_id: "init",
                    json: convertToJson(canvas),
                    action: "init",
                }
                undoStates.push(obj);
            }
            if (isAnimation) {
                dispatch(setAnimation(isAnimation));
            }
            if (!canvasData) {
                setCanvasDimensionsProps()
            }
            dispatch(setCanvas(canvas));
        }
        if (window.innerWidth < 992) {
            console.log('isMobile', isMobile())
            setIsMobileView(true)
        }
    }

    return {handleProcess}

}
