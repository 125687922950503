export const reAddDrawLine = (isSwitched,canvas2,canvas,obj,continueDraw,id)=>{
    if (!isSwitched) {
        let cPointInd = canvas2._objects.findIndex(f => f.name === 'line-end-point_shadow-object' && f.ref_id === obj.ref_id);
        if (cPointInd > -1) {
            let newEndPoint = continueDraw(1, 2, canvas2._objects[cPointInd].left, canvas2._objects[cPointInd].top, canvas2._objects[cPointInd].brushType, 'none', id);
            newEndPoint.set('widthFactor', canvas2._objects[cPointInd].widthFactor);
            canvas.add(newEndPoint);
            newEndPoint.evented = false;
            newEndPoint.set({opacity:0})
            canvas.renderAll();
        }
        obj.ref_id = id
        canvas.add(obj)
    }
}
export const reDrawCurvedPoint = (isSwitched,canvas2,canvas,obj,continueDraw,id)=>{
    if (!isSwitched) {
        let lineInd = canvas2._objects.findIndex(f => f.name === 'drawLine' && f.ref_id === obj.ref_id);
        if (lineInd > -1) {
            canvas2._objects[lineInd].ref_id = id
            canvas.add(canvas2._objects[lineInd]);
        }
        let newEndPoint = continueDraw(1, 2, obj.left, obj.top, obj.brushType, 'none', id);
        newEndPoint.set('widthFactor', obj.widthFactor);
        canvas.add(newEndPoint);
        newEndPoint.evented = false;
        newEndPoint.set({opacity:0})
        canvas.renderAll()
    }
}
export const reAddPlayer = (obj,scalePropsVal,canvas,addPlayer)=>{
    let customProps = {
        nameText: obj.nameText,
        sizeFactor: obj.sizeFactor,
        fillStyle: obj.fillStyle,
        fontWeight: obj.fontWeight,
        showHighlight: obj._objects[0]?.showHighlight,
        highlightColor: obj._objects[0]?.highlightColor,
        direction:obj._objects[2]?.direction,
        startAngle:obj.hasOwnProperty('startAngleD') ? obj.startAngleD:0,
        arrowAngle:obj._objects[2]?.angle,
        scaleX:obj.scaleX,
        scaleY:obj.scaleY,
        position:obj.position,
        lockMovementX:obj.lockMovementX,
        lockMovementY:obj.lockMovementY,
    }
    return new Promise(res=>{
        addPlayer({
            isSvg:obj.isSvg,
            polygon:obj.polygon,
            left: obj.left,
            fontSize:obj.isSvg?obj._objects[obj._objects.length-1].fontSize: obj._objects[1].fontSize,
            top: obj.top,
            angle:obj.angle,
            text:obj.isSvg?obj._objects[obj._objects.length-1].text: obj._objects[1].text,
            ref_id: obj.ref_id,
            color: obj.isSvg?obj._objects[2].fill:obj._objects[0].fill
        }, scalePropsVal, obj.sizeFactor, true, customProps,false,res)
    })

}