export  function toRgba(hex, opacity) {
    let hexRegx = /^#([A-Fa-f0-9]{3}){1,2}$/
    if (!hexRegx.test(hex)) {
        return hex
    }
    else {
        let tempHexArr = hex.split('').filter(o => o !== '#');
        hex = tempHexArr.join('');
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        var r = parseInt(hex.substring(0, 2), 16),
            g = parseInt(hex.substring(2, 4), 16),
            b = parseInt(hex.substring(4, 6), 16);

        /* Backward compatibility for whole number based opacity values. */

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }
}