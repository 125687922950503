const canvasObjectStates = (state = {objectActive: false, line: false, sprite:false ,draw: false, text: false, object: false, player: false, shape: false,playerImage:false,reverse:false, activeSelection:false,time:false,direction:false,svg:false}, action)=>{
    if(action.type === "SET_OBJECTS_STATE"){
        state = {objectActive: action.action.objectActive, line: action.action.line, text: action.action.text, sprite:action.action.sprite, object: action.action.object, player: action.action.player, draw: action.action.draw, shape: action.action.shape, playerImage: action.action.playerImage,reverse:action.action.reverse,
            activeSelection:action.action.activeSelection,time:action.action.time,direction:action.action.direction,triangle:action.action.triangle, svg:action.action.svg}
    }
    if(action.type === "RESET_CANVAS"){
        state = {objectActive: false, line: false, text: false, object: false, triangle:false, svg:false, player: false, draw: false, sprite:false, shape: false,playerImage: false,reverse:false, activeSelection:false,time:false,direction:false};
    }

    return state;
}

export default canvasObjectStates;