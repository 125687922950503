import React, { useEffect } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import './styles.scss';

const AppDrawerSimpleHeader = ({ intl, confirmOnClose, titleId, title, onCloseSideMenuDrawer }) => {

    const { direction } = useSelector(state => state.editorSettings);

    return (
        <>
            <div className={`app-drawer-simple-header ${direction}`} >
                <div className='color__white' >{titleId ? <FormattedMessage id={titleId} /> : title}</div>
                <Button onClick={() => onCloseSideMenuDrawer(confirmOnClose)} type="text" shape="circle" >
                    <CloseOutlined className='app-drawer-simple-header-icon color__white' />
                </Button>
            </div>
        </>
    );
}

export default injectIntl(AppDrawerSimpleHeader);