export const saveHelper = (props)=>{
    let {
        callback,setLoading,deleteShadowObjects,convertToJson,addShadowObjects,filterShadowFrames,shadowFrames,svgPathElements,
        animationState,initWidth,initHeight,allFrames,activeFrameVal,reInitArrowLines,setFrames,canvas
    } = props;
    setLoading(true);
    deleteShadowObjects(() => {
        reInitArrowLines();
        const canvasNewJson = convertToJson(canvas);
        if (animationState) {
            allFrames[activeFrameVal.data_num].json = canvasNewJson;
            activeFrameVal.json = canvasNewJson;
            setFrames(allFrames)
        }
        addShadowObjects(activeFrameVal);

        setTimeout(() => {
            let animationData = {
                frames1: filterShadowFrames(allFrames),
                shadowFrames1: shadowFrames,
                svgPathElements1: svgPathElements,
                animationState1: animationState,
                initWidth1: initWidth,
                initHeight1: initHeight,
                isModified: true,
            }
            animationData = JSON.stringify(animationData);
            if (callback) {
                callback(animationData, canvasNewJson);
            }
        }, 400)

    })
}