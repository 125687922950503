import {fabric} from "fabric";
import {setDrawState} from "../../actions";

export const handleBeforeClick = (props)=>{
    let { e,dispatch,canvas,mpLeft,mpTop,tempDrawLines,cDraw,cPoint,updateBeforeClick} = props;

    if (e.target) {
        if (e.target.name === 'pX') {
            mpLeft = 0;
            mpTop = 0;
            mpLeft = e.target.left;
            mpTop = e.target.top;
        }
        updateBeforeClick({mpLeft,mpTop})
        let object = e.target;

        if (object.name === 'line-end-point_shadow-object') {
            let strokeColor;
            let brushType = object.brushType;
            let group = canvas.getActiveObject();
            let items = group._objects;
            let cWidth = items[0].strokeWidth;
            canvas.freeDrawingBrush.width = cWidth;
            group._restoreObjectsState();
            canvas.remove(group);
            if (brushType === "arrow" || brushType === "arrow_dashed" || brushType === "block" || brushType === "block_dashed") {
                cWidth = items[1].strokeWidth;
                canvas.freeDrawingBrush.width = cWidth;
                items = items.filter(o => o.name !== 'drawElementBlock');
                items = items.filter(o => o.name !== 'drawElementArrow');
            }
            for (let i = 0; i < items.length; i++) {
                items[i].set({ hasControls: false, selectionBackgroundColor: '', name: 'linePath' });
                tempDrawLines.push(items[i]);
                canvas.sendToBack(items[i]);
                canvas.add(items[i]);
            }
            cDraw = true;
            cPoint = e.target;
            updateBeforeClick(false,{tempDrawLines,cDraw,cPoint});
            let pointer = canvas.getPointer(e);
            let posX = pointer.x;
            let posY = pointer.y;
            if (group._objects.length > 1) {
                strokeColor = group._objects[1].stroke
            } else {
                strokeColor = group._objects[0].stroke
            }
            canvas.freeDrawingBrush.color = strokeColor
            let line = new fabric.Line([cPoint.left, cPoint.top, posX, posY], {
                stroke: strokeColor,
                strokeWidth: canvas.freeDrawingBrush.width,
                ref_id: cPoint.ref_id,
                name: 'extendedLine'

            });
            if (brushType === "simple" || brushType === "arrow" || brushType === "block") {
                canvas.freeDrawingBrush.strokeDashArray = 0
            } else {
                canvas.freeDrawingBrush.strokeDashArray = [7, 10];
                line.set({ strokeDashArray: [7, 10] });
            }
            canvas.add(line);
            canvas.discardActiveObject();
            canvas.isDrawingMode = true
            canvas.renderAll()
            dispatch(setDrawState(true));
            canvas.remove(cPoint);
        }
    }
}