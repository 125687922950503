export const clearSelectionTwo = (id,startEvents,stopEvents,canvas) => {
    stopEvents();
    for (var i = 0; i < canvas._objects.length; i++) {
        var obj = canvas._objects[i]
        if (obj.ref_id !== id) {
            obj.shadow = ""
            if (obj.name === 'arrow_line') {
                if(!obj.square1) return;
                obj.square1.opacity = 0
                obj.square2.opacity = 0
                obj.square1.selectable = false
                obj.square2.selectable = false
                obj.square1.hoverCursor = obj.square2.hoverCursor = "default"
            } else if (obj.name === 'line') {
                const middlePointActInd = canvas._objects.findIndex((o) => o.ref_id.includes(obj.ref_id) && o.name === "pX");
                if (middlePointActInd > -1) {
                    canvas._objects[middlePointActInd].opacity = 0;
                    canvas._objects[middlePointActInd].selectable = false;
                }
                if(!obj.p0) return;
                    obj.p0.opacity = 0
                    obj.p1.opacity = 0
                    obj.p2.opacity = 0
                    obj.p0.selectable = false
                    obj.p1.selectable = false
                    obj.p2.selectable = false
                    obj.p0.hoverCursor = obj.p1.hoverCursor = obj.p2.hoverCursor = "default"

            }
        }
    }
    canvas.renderAll();
    startEvents()
}